(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('WebProgramController', WebProgramController);

    WebProgramController.$inject = ['ProgramService', 'ProgramVersionService', 'ProgramSummaryService', 'ProgramWarningService',
        'AssetSetService', 'UtilService', 'AlertService', '$state', '$location'];

    function WebProgramController(programService, programVersionService, programSummaryService, programWarningService, assetSetService,
        utilService, alertService, $state, $location) {
        var wpc = this;
        wpc.confirm = {};
        wpc.collapsed = false;
        wpc.program = programService.getProgram;
        wpc.data = programSummaryService.getProgramData();
        //create versions
        wpc.formatVersion = utilService.formatVersion;
        wpc.createWorkingVersion = createWorkingVersion;
        wpc.createMajorVersion = createMajorVersion;
        wpc.createMinorVersion = createMinorVersion;

        wpc.nextMajorVersion = nextMajorVersion;
        wpc.nextMinorVersion = nextMinorVersion;
        wpc.nextWorkingVersion = nextWorkingVersion;
        wpc.getMisMatchedVersionMessage = getWarningMessages;
        wpc.isValidVersion = utilService.isValidVersionProgram;

        //set edit to disabled if no permissions or during loading process
        wpc.isEditDisabled = isEditDisabled;

        //reset before opening modal dialog
        wpc.reset = reset;

        //create copy
        wpc.createCopy = createCopy;

        //save form
        wpc.save = save;
        wpc.cancel = cancel;
        wpc.cancelButtonText = cancelButtonText;

        //discard
        wpc.discard = discard;
        wpc.canBeDiscarded = utilService.canBeDiscarded;
        wpc.discardButtonText = discardButtonText;
        wpc.discardMessage = utilService.discardMessage;

        //check if program meta has properties and values
        wpc.containsValue = containsValue;
        wpc.hasValue = hasValue;
        wpc.removeAt = utilService.removeAt;
        wpc.requiredOnAction = requiredOnAction;
        wpc.loadIvrAssetSets = loadIvrAssetSets;
        wpc.loadIvrAssetSetsSummary = loadIvrAssetSetsSummary;
        wpc.validateName = validateName;
        wpc.parseKeywords = parseKeywords;
        wpc.setTrackingMode = setTrackingMode;
        wpc.keywords = wpc.program().entity.meta.keywords.toString();

        wpc.trackingOptions = [{
            code: 1,
            description: 'When the URL is opened'
        },
            {
            code: 2,
            description: 'Using the API'
        }];

        wpc.validURL = utilService.validURL;
        (function onLoad() {
            if (!wpc.program().entity.meta) {
                wpc.program().entity.meta = {};
            }

            getTrackingMode();
            if (wpc.data) {
                loadIvrAssetSets();
            } else {
                loadIvrAssetSetsSummary();
            }
            wpc.program().entity.meta.openMode = { code: 2, description: 'in the current window'};

        })();

        function validateName() {
            if (wpc.program().entity.name && wpc.program().entity.name.match(/\"/g)) {
                wpc.nameError = true;
            } else {
                wpc.nameError = false;
            }
        }

        function loadIvrAssetSets() {
            return assetSetService.search(['p0', 'p1']).then(function (data) {
                wpc.data.ivrAssetSets = data;
                wpc.data.ivrAssetSets.content.forEach(function (assetSet, index, array) {
                    array[index].entity.summary = assetSetService.getSummary(assetSet.entity);
                });
            }).finally(function () {
                //set default value
                if (!wpc.program().entity.emmiCode) {
                    wpc.program().entity.meta.assetSets = [];
                    if (wpc.data.ivrAssetSets && wpc.data.ivrAssetSets.total && wpc.data.ivrAssetSets.total > 0) {
                        wpc.program().entity.meta.assetSets.push(wpc.data.ivrAssetSets.content[0].entity);
                    }
                } else {
                    loadIvrAssetSetsSummary();
                }
            });
        }

        function loadIvrAssetSetsSummary() {
            if (containsValue('assetSets')) {
                wpc.program().entity.meta.assetSets.forEach(function (assetSet, index, array) {
                    if (!array[index].summary) {
                        array[index].summary = assetSetService.getSummary(assetSet);
                    }
                });
            }
        }

        function reset(form) {
            alertService.removeAlert();
        }

        function isEditDisabled() {
            return programService.isEditDisabled() || wpc.loading;
        }

        function containsValue(prop) {
            var entity = wpc.program().entity;
            var containsValue = false;
            if (entity && entity.meta && prop in entity.meta) {
                var property = entity.meta[prop];
                if (property) {
                    containsValue = property.length > 0;
                }
            }
            return containsValue;
        }

        function hasValue(prop) {
            var entity = wpc.program().entity;
            if (entity && entity.meta && prop in entity.meta) {
                var obj = entity.meta[prop];
                return obj && 'id' in obj;
            }
            return false;
        }

        function requiredOnAction() {
            var curAction = programService.getCurrentAction();
            if (curAction && (curAction === "Create Major Version" || curAction === "Create Minor Version")) {
                return true;
            }
            return false;
        }

        function isFormValid(form, action) {
            var isValid = false;
            if (form.$name === 'programForm') {
                isValid = !wpc.nameError && form && form.$valid && wpc.validURL(wpc.program().entity.meta.url);

                if (!containsValue('assetSets')) {
                    form.$valid = false;
                    isValid = false;
                }

            } else if (form.$name === 'programForm') {

                if (action === 'Create Major Version' || action === "Create Minor Version") {
                    isValid = !wpc.nameError && form && form.$valid;
                }
            }

            if (!isValid) {
                alertService.error("Validation errors", action);
                $('body').removeClass('modal-open');
            } else {
                form.$setPristine();
            }
            form.$setSubmitted();
            return isValid;
        }

        function ivrTypeID() {
            if (wpc.program().entity.meta.ivrType && wpc.program().entity.meta.ivrType.name === 'Prevent') {
                wpc.program().entity.meta.ivrType.id = '1'
            } else if (wpc.program().entity.meta.ivrType && wpc.program().entity.meta.ivrType.name === 'Transition') {
                wpc.program().entity.meta.ivrType.id = '2'

            } else {
                // nothing
            }
        }

        function save(form) {
            wpc.submitted = true;
            if (!wpc.nameError) {
                ivrTypeID();
                var actionWord = "Save Program";
                if (!wpc.program().entity.emmiCode) {
                    actionWord = "Create Program";
                }
                wpc.loading = true;

                programService.save()
                    .then(function (response) {
                        form.$setPristine();
                        alertService.success(wpc.program().entity.name, actionWord);
                    })
                    .catch(function (response) {
                        alertService.error(response, actionWord);
                    })
                    .finally(function () {
                        delete wpc.loading;
                        loadIvrAssetSetsSummary();
                    });
            }else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function cancel() {
            var entity = wpc.program().entity;
            var emmiCode = entity.emmiCode;
            var versioned = entity.versionedToLocal || entity.version;
            utilService.navigate(emmiCode, versioned, $state);
        }

        function cancelButtonText() {
            var entity = wpc.program().entity;
            var emmiCode = entity.emmiCode;
            var text = "Return to Composer Home";
            if (emmiCode) {
                if (entity.version || entity.versionedToLocal) {
                    text = "Return to Version List Page";
                }
            }
            return text;
        }

        function discardButtonText() {
            var program = wpc.program();
            if (program && program.entity) {
                return program.entity.versionedToLocal ? 'REVERT' : 'DELETE';
            } else {
                return 'DELETE';
            }
        }

        function discard() {
            var entity = wpc.program().entity;
            var emmiCode = entity.emmiCode;
            var versionedToLocal = entity.versionedToLocal;
            wpc.loading = true;
            programService.discard()
                .then(function (response) {
                    utilService.navigate(emmiCode, versionedToLocal, $state);
                })
                .catch(function (response) {
                    alertService.error(response, 'discard program');
                })
                .finally(function () {
                    delete wpc.loading;
                });
        }

        function createCopy(form) {
            programService.createCopy(wpc.confirm.inputText);
            programService.setEditable(true);
            $('body').removeClass('modal-open');
        }

        function setNotes() {
            var entity = wpc.program().entity;
            if (entity) {
                if (!entity.version) {
                    entity.version = utilService.newVersion();
                }
                entity.version.comment = wpc.confirm.inputText;
            }
        }


        function createWorkingVersion(form) {
            wpc.submitted = true;
            if (!wpc.nameError) {
                setNotes();
                wpc.loading = true;
                var operation = 'create working version';
                programVersionService.createWorkingVersion(wpc.program())
                    .then(function (response) {
                        var program = wpc.program();
                        program.entity.version = response.entity.version;
                        alertService.customSuccess('Working version for program ' + program.entity.name + ' is successfully created in the Composer.');
                        form.$setPristine();
                        programService.setCurrentAction("");
                    })
                    .catch(function (response) {
                        alertService.error(response, operation);
                    })
                    .finally(function () {
                        delete wpc.loading;
                        loadIvrAssetSetsSummary();
                    });
            } else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function createMajorVersion(form) {
            wpc.submitted = true;
            if (isFormValid(form, "Create Major Version")) {
                if (!wpc.nameError) {
                setNotes();
                wpc.loading = true;
                var operation = 'create major version';
                programVersionService.createMajorVersion(wpc.program())
                    .then(function (response) {
                        var program = wpc.program();
                        program.entity.version = response.entity.version;
                        alertService.customSuccess('Major version for program ' + program.entity.name + ' is successfully created in the Composer.');
                        programService.setCurrentAction("");
                    })
                    .catch(function (response) {
                        alertService.error(response, operation);
                    })
                    .finally(function () {
                        delete wpc.loading;
                        loadIvrAssetSetsSummary();
                    });
                }
                $('body').removeClass('modal-open');
            }
        }

        function createMinorVersion(form) {
            wpc.submitted = true;
            if (isFormValid(form, "Create Minor Version")) {
                if (!wpc.nameError) {
                setNotes();
                wpc.loading = true;
                var operation = 'create minor version';
                programVersionService.createMinorVersion(wpc.program())
                    .then(function (response) {
                        var program = wpc.program();
                        program.entity.version = response.entity.version;
                        alertService.customSuccess('Minor version for program ' + program.entity.name + ' is successfully created in the Composer.');
                        programService.setCurrentAction("");
                    })
                    .catch(function (response) {
                        alertService.error(response, operation);
                    })
                    .finally(function () {
                        delete wpc.loading;
                        loadIvrAssetSetsSummary();
                    });
                }
                $('body').removeClass('modal-open');
            }
        }

        function getCompleteVersion(obj) {
            return obj.major + '.' + obj.minor + '.' + obj.working
        }

        function nextMajorVersion() {
            programService.setCurrentAction("Create Major Version");
            return programVersionService.nextMajorVersion(wpc.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        warningMessages = getWarningMessages(version, verobj.major);
                    } else {
                        version = '1.0.0';
                    }
                    return { resultMessage: version, warningMessages: warningMessages };
                })
        }

        function nextMinorVersion() {
            programService.setCurrentAction("Create Minor Version");
            return programVersionService.nextMinorVersion(wpc.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        warningMessages = getWarningMessages(version, verobj.major);
                    } else {
                        version = '0.1.0';
                    }
                    return { resultMessage: version, warningMessages: warningMessages };
                })
        }

        function nextWorkingVersion() {
            programService.setCurrentAction("Create Working Version");
            return programVersionService.nextWorkingVersion(wpc.program())
                .then(function (response) {
                    var verobj = response;
                    var version = '0.0.1';
                    if (verobj) {
                        version = verobj.major + '.' + verobj.minor + '.' + verobj.working;
                    }
                    return { resultMessage: version, warningMessages: [] };
                })
        }

        function getWarningMessages(nextProgramVersion, nextProgramMajor) {
            return programWarningService.getWarningMessages(nextProgramVersion, nextProgramMajor);
        }

        function parseKeywords(){
            wpc.program().entity.meta.keywords = utilService.parseKeywords(wpc).split(',');
            utilService.cleanKeywords(wpc.program().entity.meta.keywords);
        }

        function setTrackingMode(type) {
            if (type === 'start') {
                if (parseInt(wpc.trackingStartSelect) === 1) {
                    wpc.program().entity.meta.trackingStart = wpc.trackingOptions[0];
                } else if (parseInt(wpc.trackingStartSelect) === 2) {
                    wpc.program().entity.meta.trackingStart = wpc.trackingOptions[1];
                }
            }
            if (type === 'end') {
                if (parseInt(wpc.trackingCompleteSelect) === 1) {
                    wpc.program().entity.meta.trackingComplete = wpc.trackingOptions[0];
                } else if (parseInt(wpc.trackingCompleteSelect) === 2) {
                    wpc.program().entity.meta.trackingComplete = wpc.trackingOptions[1];
                }
            }
        }

        function getTrackingMode(){
            if (wpc.program().entity.meta.trackingStart) {
                wpc.trackingStartSelect = wpc.program().entity.meta.trackingStart.code.toString();
            } else {
                wpc.trackingStartSelect = '1';
                wpc.program().entity.meta.trackingStart = wpc.trackingOptions[0];
            }
            if (wpc.program().entity.meta.trackingComplete) {
                wpc.trackingCompleteSelect = wpc.program().entity.meta.trackingComplete.code.toString();
            } else {
                wpc.trackingCompleteSelect = '1';
                wpc.program().entity.meta.trackingComplete = wpc.trackingOptions[0];
            }
        }


    }
})(window.angular);
