(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('selectEditionModal', selectEditionModal);

    selectEditionModal.$inject = ['$modal'];

    function selectEditionModal($modal) {

        return {
            restrict: 'A',
            link: link,
            scope: {
                selectedEdition: '=',
                editionShowingForm: '@'
            }
        };

        function link(scope, element, attrs) {
            var modal = {};

            element.on('click', function () {
                modal = $modal({
                    templateUrl: 'app/program/create/editions/select/edition/editions.select.modal.html',
                    scope: scope
                })
            });
            scope.$on('selected-edition', function (event, selectedEdition) {
                scope.selectedEdition.name = selectedEdition.name;
                scope.selectedEdition.version = selectedEdition.version;
                scope.selectedEdition.editionId = selectedEdition.editionId;
                scope.selectedEdition.formattedVersion = selectedEdition.formattedVersion;

                scope.editionShowingForm = 'version';
            });

            scope.$on('restore-edition', function (event, selectedEdition) {
                scope.selectedEdition.name = selectedEdition.name;
                scope.selectedEdition.version = selectedEdition.version;
                scope.selectedEdition.editionId = selectedEdition.editionId;
                scope.selectedEdition.formattedVersion = selectedEdition.formattedVersion;
                scope.selectedEdition.assetSet = selectedEdition.assetSet;
                scope.selectedEdition.meta = selectedEdition.meta;

                scope.editionShowingForm = 'edition';
            });

            scope.$on('back-to-edition', function (event) {
                scope.editionShowingForm = 'edition';
            });

            scope.$on('selected-version', function (event, selectedEdition) {
                scope.selectedEdition.version = selectedEdition.version;
                scope.selectedEdition.formattedVersion = selectedEdition.formattedVersion;
                scope.selectedEdition.meta = selectedEdition.meta;
                scope.editionShowingForm = 'edition';
            });

            element.on('$destroy', function() {
                if(modal.hide !== undefined) {
                    modal.hide();
                }
            });
        }
    }
})(window.angular);
