(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('publishEntity', publishEntity);

    publishEntity.$inject = ['$modal', '$alert'];

    function publishEntity($modal, $alert) {
        return {
            restrict: 'A',
            link: link,
            scope: {
                objectTypeToBePublished: '@'
            }
        };
        function link(scope, element, attr) {
            element.on('click', function () {
                $modal({
                    templateUrl: 'app/reference/common/publish/publish.modal.html',
                    scope: scope,
                    controller: 'PublishController',
                    controllerAs: 'pc',
                    resolve: {
                        publishResource: function () {
                            return scope.objectTypeToBePublished;
                        },
                        successCallback: function () {
                            return function () {
                                var objectType = scope.objectTypeToBePublished;
                                $alert({
                                    content: [
                                        objectType, ' published successfully.'
                                    ].join('')
                                });
                            }
                        },
                        errorCallback: function () {
                            return function(error) {
                                var objectType = scope.objectTypeToBePublished;
                                $alert({
                                    type: 'danger',
                                    duration: 10,
                                    content: [
                                        objectType + ' not published due to error: '+ error.data.errorMessage
                                    ].join(',')
                                });
                            }
                        }
                    }
                })
            });
        }
    }
})(window.angular);