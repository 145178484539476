(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('ProgramController', ProgramController);

    ProgramController.$inject = ['ProgramService', 'EditionsService', 'ProgramVersionService', 'ProgramSummaryService', 'ProgramWarningService',
        'AssetSetService',
        'UtilService', 'VideoService', 'AlertService', '$state', '$location'];

    function ProgramController(programService, editionsService, programVersionService, programSummaryService, programWarningService, assetSetService,
        utilService, videoService, alertService, $state) {
        var pc = this;
        pc.confirm = {};
        pc.collapsed = false;
        pc.program = programService.getProgram;

        pc.data = programSummaryService.getProgramData();
        //create versions
        pc.formatVersion = utilService.formatVersion;
        pc.createWorkingVersion = createWorkingVersion;
        pc.createMajorVersion = createMajorVersion;
        pc.createMinorVersion = createMinorVersion;

        pc.nextMajorVersion = nextMajorVersion;
        pc.nextMinorVersion = nextMinorVersion;
        pc.nextWorkingVersion = nextWorkingVersion;
        pc.getMisMatchedVersionMessage = getWarningMessages;
        pc.isValidVersion = utilService.isValidVersionProgram;
        pc.programSubtypes = {
            'content':[{
                'entity': {
                    'id': 1,
                    'name': 'Long-form'
                }
            },
                {
                    'entity': {
                        'id': 2,
                        'name': 'Short-form'
                    }
                },
                {
                    'entity': {
                        'id': 3,
                        'name': 'Overview'
                    }
                },
                {
                    'entity': {
                        'id': 4,
                        'name': 'Non-Linear'
                    }
                }]
        };

        //set edit to disabled if no permissions or during loading process
        pc.isEditDisabled = isEditDisabled;

        //reset before opening modal dialog
        pc.reset = reset;

        //create copy
        pc.createCopy = createCopy;

        //save form
        pc.save = save;
        pc.cancel = cancel;
        pc.cancelButtonText = cancelButtonText;

        //discard
        pc.discard = discard;
        pc.canBeDiscarded = utilService.canBeDiscarded;
        pc.discardButtonText = discardButtonText;
        pc.discardMessage = utilService.discardMessage;

        //edition
        pc.hasVideoSections = hasVideoSections;
        pc.removeVideoSections = editionsService.removeVideoSections;

        //check if program meta has properties and values
        pc.containsValue = containsValue;
        pc.hasValue = hasValue;
        pc.removeAt = utilService.removeAt;

        pc.setFormModified = setFormModified;
        pc.requiredOnAction = requiredOnAction;
        pc.validateName = validateName;
        pc.parseKeywords = parseKeywords;
        pc.keywords = pc.program().entity.meta.keywords.toString();

        function validateName() {
            pc.submitted = false;
            if (pc.program().entity.name && pc.program().entity.name.match(/\"/g)) {
                pc.nameError = true;
            } else {
                pc.nameError = false;
            }
        }

        function reset(form) {
            alertService.removeAlert();
        }

        function isEditDisabled() {
            return programService.isEditDisabled() || pc.loading;
        }

        function hasVideoSections() {
            var isRequired = false;
            var entity = pc.program().entity;
            if (entity && entity.meta) {
                var editions = entity.meta.editions;
                if (editions) {
                    for (var i = 0; i < editions.length; i++) {
                        if (videoService.hasVideoSections(editions[i])) {
                            isRequired = true;
                            break;
                        }
                    }
                }
            }
            return isRequired;
        }

        function containsValue(prop) {
            var entity = pc.program().entity;
            var containsValue = false;
            if (entity && entity.meta && prop in entity.meta) {
                var property = entity.meta[prop];
                if (property) {
                    containsValue = property.length > 0;
                }
            }
            return containsValue;
        }

        function hasValue(prop) {
            var entity = pc.program().entity;
            if (entity && entity.meta && prop in entity.meta) {
                var obj = entity.meta[prop];
                return obj && 'id' in obj;
            }
            return false;
        }

        function requiredOnAction() {
            var curAction = programService.getCurrentAction();
            if (curAction && (curAction === "Create Major Version" || curAction === "Create Minor Version")) {
                return true;
            }
            return false; 
        }

        function isFormValid(form, action) {
            var isValid = !pc.nameError && form && form.$valid;
            if (!isValid) {
                alertService.error("Validation errors", action);
            } else {
                form.$setPristine();
            }
            form.$setSubmitted();
            $('body').removeClass('modal-open');
            return isValid;
        }

        function save(form) {
            pc.submitted = true;
            if (!pc.nameError) {
                var actionWord = "Save Program";
                if (!pc.program().entity.emmiCode) {
                    actionWord = "Create Program";
                }
                pc.loading = true;
                programService.save()
                    .then(function (response) {
                        form.$setPristine();
                        alertService.success(pc.program().entity.name, actionWord);
                    })
                    .catch(function (response) {
                        alertService.error(response, actionWord);
                    })
                    .finally(function () {
                        delete pc.loading;
                        loadIvrAssetSetsSummary();
                    });
            } else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function cancel() {
            var entity = pc.program().entity;
            var emmiCode = entity.emmiCode;
            var versioned = entity.versionedToLocal || entity.version;
            utilService.navigate(emmiCode, versioned, $state);
        }

        function cancelButtonText() {
            var entity = pc.program().entity;
            var emmiCode = entity.emmiCode;
            var text = "Return to Composer Home";
            if (emmiCode) {
                if (entity.version || entity.versionedToLocal) {
                    text = "Return to Version List Page";
                }
            }
            return text;
        }

        function discardButtonText() {
            var program = pc.program();
            if (program && program.entity) {
                return program.entity.versionedToLocal ? 'REVERT' : 'DELETE';
            } else {
                return 'DELETE';
            }
        }

        function discard() {
            var entity = pc.program().entity;
            var emmiCode = entity.emmiCode;
            var versionedToLocal = entity.versionedToLocal;
            pc.loading = true;
            programService.discard()
                .then(function (response) {
                    utilService.navigate(emmiCode, versionedToLocal, $state);
                })
                .catch(function (response) {
                    alertService.error(response, 'discard program');
                })
                .finally(function () {
                    delete pc.loading;
                });
        }

        function createCopy(form) {
            programService.createCopy(pc.confirm.inputText);
            programService.setEditable(true);
            $('body').removeClass('modal-open');
        }

        function setNotes() {
            var entity = pc.program().entity;
            if (entity) {
                if (!entity.version) {
                    entity.version = utilService.newVersion();
                }
                entity.version.comment = pc.confirm.inputText;
            }
        }

        function setFormModified(form) {
            form.$setDirty();
        }

        function createWorkingVersion(form) {
            pc.submitted = true;
            if (!pc.nameError) {
                setNotes();
                pc.loading = true;
                var operation = 'create working version';
                programVersionService.createWorkingVersion(pc.program())
                    .then(function (response) {
                        var program = pc.program();
                        program.entity.version = response.entity.version;
                        alertService.customSuccess('Working version for program ' + program.entity.name + ' is successfully created in the Composer.');
                        form.$setPristine();
                        programService.setCurrentAction("");
                    })
                    .catch(function (response) {
                        alertService.error(response, operation);
                    })
                    .finally(function () {
                        delete pc.loading;
                    });
                } else {
                    alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
                }
            $('body').removeClass('modal-open');
        }

        function createMajorVersion(form) {
            pc.submitted = true;
            if (isFormValid(form, "Create Major Version")) {
                if (!pc.nameError) {
                    setNotes();
                    pc.loading = true;
                    var operation = 'create major version';
                    programVersionService.createMajorVersion(pc.program())
                        .then(function (response) {
                            var program = pc.program();
                            program.entity.version = response.entity.version;
                            alertService.customSuccess('Major version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            programService.setCurrentAction("");
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete pc.loading;
                        });
                } 
                $('body').removeClass('modal-open');
            }
        }

        function createMinorVersion(form) {
            pc.submitted = true;
            if (isFormValid(form, "Create Minor Version")) {
                if (!pc.nameError) {
                    setNotes();
                    pc.loading = true;
                    var operation = 'create minor version';
                    programVersionService.createMinorVersion(pc.program())
                        .then(function (response) {
                            var program = pc.program();
                            program.entity.version = response.entity.version;
                            alertService.customSuccess('Minor version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            programService.setCurrentAction("");
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete pc.loading;
                        });
                }
                $('body').removeClass('modal-open');
            }
        }

        function getCompleteVersion(obj) {
            return obj.major + '.' + obj.minor + '.' + obj.working
        }

        function nextMajorVersion() {
            programService.setCurrentAction("Create Major Version");
            return programVersionService.nextMajorVersion(pc.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        warningMessages = getWarningMessages(version, verobj.major);
                    } else {
                        version = '1.0.0';
                    }
                    return { resultMessage: version, warningMessages: warningMessages };
                })
        }

        function nextMinorVersion() {
            programService.setCurrentAction("Create Minor Version");
            return programVersionService.nextMinorVersion(pc.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        warningMessages = getWarningMessages(version, verobj.major);
                    } else {
                        version = '0.1.0';
                    }
                    return { resultMessage: version, warningMessages: warningMessages };
                })
        }

        function nextWorkingVersion() {
            programService.setCurrentAction("Create Working Version");
            return programVersionService.nextWorkingVersion(pc.program())
                .then(function (response) {
                    var verobj = response;
                    var version = '0.0.1';
                    if (verobj) {
                        version = verobj.major + '.' + verobj.minor + '.' + verobj.working;
                    }
                    return { resultMessage: version, warningMessages: [] };
                })
        }

        function getWarningMessages(nextProgramVersion, nextProgramMajor) {
            return programWarningService.getWarningMessages(nextProgramVersion, nextProgramMajor);
        }

        function parseKeywords(){
            pc.program().entity.meta.keywords = utilService.parseKeywords(pc).split(',');
            utilService.cleanKeywords(pc.program().entity.meta.keywords);
        }


    }
})(window.angular);
