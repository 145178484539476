(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('deleteEntity', deleteEntity);

    deleteEntity.$inject = ['$modal', '$alert'];

    function deleteEntity($modal, $alert) {
        return {
            restrict: 'A',
            link: link,
            scope: {
                toBeDeleted: '='
            }
        };
        function link(scope, element, attr) {
            element.on('click', function () {
                $modal({
                    templateUrl: 'app/reference/common/delete/delete.modal.html',
                    scope: scope,
                    controller: 'DeleteController',
                    controllerAs: 'dc',
                    resolve: {
                        deleteResource: function () {
                            return scope.toBeDeleted;
                        },
                        successCallback: function () {
                            return function () {
                                var obj = scope.toBeDeleted;
                                obj.deleted = true;
                                $alert({
                                    content: [
                                        obj.entity.name, '\' removed successfully.'
                                    ].join('')
                                });
                            }
                        },
                        errorCallback: function () {
                            return function() {
                                var obj = scope.toBeDeleted;
                                $alert({
                                    type: 'danger',
                                    content: [
                                        obj.entity.name, '\' not removed.'
                                    ].join('')
                                });
                            }
                        }
                    }
                })
            });
        }
    }
})(window.angular);