(function(angular) {
    'use strict';

    angular
        .module('Composer')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('dynaview', {
            parent: 'app',
            url: '/dynaview/:viewId/:entryId',
            data: {
                authorities: ['ROLE_LINKS_USER']
            },
            views: {
                'content@': {
                    templateUrl: 'app/dynaview/dynaview.html',
                    controller: 'DynaviewsController',
                    controllerAs: 'Dynaviews'
                }
            }
        });
    }
})(window.angular);
