(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('ProgramSummaryService', ProgramSummaryService);

    ProgramSummaryService.$inject = ['ProgramVersionService', 'ProgramService', 'ProgramInitService', 'UtilService', 'AlertService', '$stateParams'];

    function ProgramSummaryService(programVersionService, programService, programInitService, utilService, alertService, $stateParams) {

        var pss = this;

        return {
            isEditDisabled: isEditDisabled,

            getProgramData: getProgramData,
            isLoading: isLoading,

            loadProgram: loadProgram,
            getProgram: getProgram,
            loadArticle: loadArticle,

            isVersioned: isVersioned,
        };

        function isVersioned() {           
            var currentProgram = getProgram();
            if(currentProgram.entity.version 
            && !(currentProgram.entity.version.major == "0" 
            && currentProgram.entity.version.minor == "0"
            && currentProgram.entity.version.working == "0")){
                return true;
            }else { 
                return false;
            }
        }

        function isEditDisabled() {
            return programService.isEditDisabled();
        }

        function getProgram(arg) {
            return programService.getProgram(arg);
        }

        function getProgramData() {
            return programInitService.getProgramData();
        }

        function isLoading() {
            return pss.loadingProgram || programInitService.loadingProgramData();
        }

        function loadProgram() {
            var emmiCode = $stateParams.emmiCode;
            var version = $stateParams.version;
            if (emmiCode !== -1) {
                pss.loadingProgram = true;
                if (utilService.isValidVersion(version)) {
                    programVersionService.loadProgramByEmmiCodeAndVersion(emmiCode, version)
                        .catch(function (response) {
                            var resdata = response.data;
                            alertService.error(resdata.errorMessage, 'load program details');

                        })
                        .finally(function () {
                            delete pss.loadingProgram;
                        });
                } else {
                    programService.loadProgramByEmmiCode(emmiCode)
                        .catch(function (response) {
                            var resdata = response.data;
                            alertService.error(resdata.errorMessage, 'load program details');

                        })
                        .finally(function () {
                            delete pss.loadingProgram;
                        });
                }
            }
        }
        function loadArticle() {
            var emmiCode = $stateParams.emmiCode;
            var version = $stateParams.version;
            pss.loadingProgram = true;
            programService.loadArticleByEmmiCode(emmiCode)
                .catch(function (response) {
                    var resdata = response.data;
                    alertService.error(resdata.errorMessage, 'load article details');
                })
                .finally(function () {
                    delete pss.loadingProgram;
                });
        }
    }
})(window.angular);
