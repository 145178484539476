(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('PmtImportController', PmtImportController);

    PmtImportController.$inject = ['PmtImportService', 'UtilService', 'AlertService', 'ProgramVersionService'];

    function PmtImportController(pmtImportService, utilService, alertService, programVersionService) {
        var pc = this;
        pc.reviewData = 'undefined';
        pc.onlyNumbers = /^\d+$/;

        pc.save = save;
        pc.importProgram = importProgram;
        pc.canSave = canSave;
        pc.reset = reset;

        function reset() {
            pc.emmiCode = '';
            var node = clearPreview();
            node.innerText = 'Review the retrieved data here before save';
        }

        function isFormValid(form) {
            var isValid = form && form.$valid;
            if (!isValid) {
                form.$setSubmitted();
            }
            return isValid;
        }

        function importProgram(form) {
            clearPreview();
            if (isFormValid(form)) {
                pc.loading = true;
                pmtImportService.importProgram(pc.emmiCode)
                    .then(function (response) {
                        if (isValidResponse(response)) {
                            displayJSONTree(response.program);
                        }
                    })
                    .catch(function (error) {
                        alertService.error(error, 'import from PMT for emmiCode:[' + pc.emmiCode + ']');
                    })
                    .finally(function () {
                        delete pc.loading;
                    });
            }
        }

        function isValidResponse(response, emmiCode) {
            if (response) {
                if(response.status == 'SUCCESS' || response.status == 'WARNING') {
                    return true;
                } else {
                    alertService.customError(response.message);
                }
            } else {
                alertService.customError("Program with emmi code " + emmiCode + " does not exist in the PMT");
            }
            return false;
        }

        function clearPreview() {
            pc.reviewData = 'undefined';
            // Get DOM-element for inserting json-tree
            var wrapper = angular.element("#preview");
            //clear children
            var myNode = wrapper[0];
            while (myNode.firstChild) {
                myNode.removeChild(myNode.firstChild);
            }
            return myNode;
        }

        function displayJSONTree(data) {
            var myNode = clearPreview();
            pc.reviewData = data;
            // Create json-tree
            var tree = jsonTree.create(data, myNode);

            // Expand first child of Root node
            tree.expand(function (node) {
                return node.parent.isRoot;
            });
        }

        function canSave() {
            return pc.reviewData !== 'undefined';
        }

        function save() {
            pc.loading = true;
            var program = getProgram();

            pmtImportService.saveImportedProgram(program)
                .then(function (response) {
                    if (isValidResponse(response)) {
                        alertService.customSuccess('Program ' + program.entity.emmiCode + ' ' + program.entity.name + ', successfully created in the Composer and checked out to you.');
                    }
                })
                .catch(function (error) {
                    alertService.customError(error.message);
                })
                .finally(function () {
                    delete pc.loading;
                });
        }

        function getProgram() {
            var program = {
                entity: pc.reviewData
            };
            var entity = program.entity;
            if (entity) {
                if (!entity.version) {
                    entity.version = utilService.newVersion();
                }
                entity.version.comment = 'Imported from PMT';
            }
            return program;
        }
    }
})(window.angular);
