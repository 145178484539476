(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('DelDynaviewController', DelDynaviewController);

    //DelDynaviewController.$inject = ['DelDynaviewService', 'deleteResource', 'entityForDeleteResource', 'resourceProviderResource', 'resourceEntryIdResource', 
	DelDynaviewController.$inject = ['DelDynaviewService', 'deleteResource', 'entForDeleteResource', 
			'successCallback', 'errorCallback'];

    //function DelDynaviewController(DelDynaviewService, deleteResource, entityForDeleteResource, resourceProviderResource, resourceEntryIdResource, 
	function DelDynaviewController(DelDynaviewService, deleteResource, entForDeleteResource, 
			successCallback, errorCallback) {
        var delCtrl = this;
        delCtrl.remove = remove;
		
		delCtrl.entityForDelete = angular.copy(entityForDeleteResource);
		//delCtrl.resProvider = getResProviderOf( angular.copy(resourceProviderResource) );
		//delCtrl.theResource = getResourceOf(delCtrl.resProvider.resourceList, angular.copy(resourceEntryIdResource));

        function remove($hide) {
            $hide();
            DelDynaviewService.remove(delCtrl.theResource.uri, deleteResource).then(function (response) {
                successCallback();
            }, errorCallback);
        }
    }
})(window.angular);
