(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('FooterController', FooterController);

    FooterController.$inject = ['Principal', 'API'];

    function FooterController(Principal, api) {
        var vm = this;

        vm.isAuthenticated = Principal.isAuthenticated;
        vm.api = api;

    }
})(window.angular);
