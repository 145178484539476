(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewAssetSetService', NewAssetSetService);

    NewAssetSetService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewAssetSetService($http, Principal, UriTemplate) {

        return {
            create: create,
            newAssetSet: newAssetSet
        };

        function create(newAssetSet) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.assetsets).stringify(),
                    newAssetSet).then(function (response) {
                    return response.data;
                });
            })
        }

        function newAssetSet() {
            return {
                name: ''
            }
        }

    }
})(window.angular);
