(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('TransitionSeriesPathController', TransitionSeriesPathController);

    TransitionSeriesPathController.$inject = ['ProgramService', 'UtilService', '$state'];

    function TransitionSeriesPathController(programService, utilService, $state) {
        var tspc = this;
        tspc.program = programService.getProgram;
        tspc.getQuestionType = utilService.getQuestionType;
        tspc.apiStatus = "nullState";
        tspc.links = [
            { linkName: 'Patient', active: true },
            { linkName: 'Circle of Care', active: false }
        ];
        tspc.seriesList = [];
        tspc.modalObject = {
            modalTitle: '',
            dayFlag: true,
            ivrFlag: false,
            mediaFlag: false,
            conditionFlag: false,
            buttonOne: '',
            buttonTwo: '',
            buttonCancel: '',
            buttonAddClass: '',
            buttonCancelClass: '',
            editFlag: false,
            list: [],
            editItem: '',
            copyFlag: false,
            copyItem: '',
            editTransition: false,
        };
        tspc.itemList = [];
        tspc.circleList = [];
        tspc.changeLink = changeLink;
        tspc.buildSeries = buildSeries;
        tspc.modalList = modalList;
        tspc.toggleList = toggleList;
        tspc.editDayList = editDayList;
        tspc.deleteDayList = deleteDayList;
        tspc.copyDay = copyDay;
        tspc.saveFinal = saveFinal;
        tspc.findLinkName = findLinkName;
        tspc.linkName = 'Patient';
        tspc.findIndex = findIndex;
        tspc.preparePostData = preparePostData;
        tspc.prepareObj = prepareObj;
        tspc.checkForPrecedingIvr = checkForPrecedingIvr;
        tspc.finalObj = {
            daysToEnd: 0,
            components: []
        };
        tspc.seriesObject = [];
        tspc.circleObject = [];
        tspc.reconstructObj = reconstructObj;
        tspc.generateIvrDay = generateIvrDay;
        tspc.removeDuplicate = removeDuplicate;
        tspc.finalReconstructObj = finalReconstructObj;
        tspc.isEditDisabled = isEditDisabled;
        tspc.updateFullList = updateFullList;
        tspc.deleteCondition = deleteCondition;
        tspc.findRemovedIvr = findRemovedIvr;
        tspc.checkPreviousIvrCondition = checkPreviousIvrCondition;
        tspc.prepareCondition = prepareCondition;
        tspc.getCurrentDay = getCurrentDay;
        tspc.generateCondition = generateCondition;
        tspc.generateResponse = generateResponse;
        tspc.getpreviousList = getpreviousList;
        tspc.searchIvr = searchIvr;
        tspc.checkConditionWarning = checkConditionWarning;
        tspc.setFormModified = setFormModified;
        tspc.reconstructMedia = reconstructMedia;
        (function onLoad() {
            tspc.modalList();
            if (tspc.program().entity.meta.components) {
                tspc.reconstructObj(tspc.program().entity.meta.components);
            }
        })();

        function isEditDisabled() {
            return programService.isEditDisabled();
        }

        function setFormModified(form) {
            form.$setDirty();
        }

        //reconstruct the api data to display on the front-end
        function reconstructObj(data) {
            for (var i = 0; i < data.length; i++) {
                if (data[i] && data[i].condition.conjunction.AndCondition.personTypeCd === 1) {
                    if (tspc.seriesObject.length === 0) {
                        if (generateIvrDay(data[i], 1)) {
                            tspc.seriesObject.push(generateIvrDay(data[i], 1));
                        }
                    } else {
                        var dayIndex = tspc.findIndex(tspc.seriesObject, 'day', data[i].condition.conjunction.AndCondition.daysSinceStart);
                        if (!dayIndex) {
                            if (generateIvrDay(data[i], 1)) tspc.seriesObject.push(generateIvrDay(data[i], 1));
                        }
                    }
                } else if (data[i] && data[i].condition.conjunction.AndCondition.personTypeCd === 2) {
                    if (tspc.circleObject.length === 0) {
                        if (generateIvrDay(data[i], 1)) {
                            tspc.circleObject.push(generateIvrDay(data[i], 1));
                        }
                    } else {
                        var dayIndex = tspc.findIndex(tspc.circleObject, 'day', data[i].condition.conjunction.AndCondition.daysSinceStart);
                        if (!dayIndex) {
                            if (generateIvrDay(data[i], 1)) tspc.circleObject.push(generateIvrDay(data[i], 1));
                        }
                    }
                }
            }
            for (var i = 0; i < data.length; i++) {
                if (data[i].condition.programType === 'MULTIMEDIA PROGRAM') {
                    if (data[i] && data[i].condition.conjunction.AndCondition.personTypeCd === 1) {
                        if (tspc.seriesObject.length) {
                            tspc.reconstructMedia(data[i], 'seriesObject');
                        }
                    } else if (data[i] && data[i].condition.conjunction.AndCondition.personTypeCd === 2) {
                        if (tspc.circleObject.length) {
                            tspc.reconstructMedia(data[i], 'circleObject')
                        }
                    }
                }
            }
            angular.forEach(tspc.seriesObject, function (res) {
                if (res.media.length) {
                    res.media = tspc.removeDuplicate(res.media, JSON.stringify);
                }
            });
            angular.forEach(tspc.circleObject, function (res) {
                if (res.media.length) {
                    res.media = tspc.removeDuplicate(res.media, JSON.stringify);
                }
            });
            tspc.finalReconstructObj(tspc.seriesObject, 1);
            tspc.finalReconstructObj(tspc.circleObject, 2);
        }

        //reconstruct media based on seriesobject or  circle of care
        function reconstructMedia(data, type) {
            angular.forEach(tspc[type], function (res) {
                if (res.day === data.condition.conjunction.AndCondition.daysSinceStart) {
                    var obj = {
                        entity: {
                            name: data.condition.programName,
                            emmiCode: data.condition.emmiCode
                        },
                        select: true
                    };
                    if (data.condition.conjunction.AndCondition.OrCondition) {
                        obj['condition'] = tspc.generateCondition(data.condition.conjunction.AndCondition);
                    }
                    res.media.push(obj);
                }
            })
        }

        //remove the duplicate media
        function removeDuplicate(a, key) {
            var index = [];
            return a.filter(function (item) {
                var k = key(item);
                return index.indexOf(k) >= 0 ? false : index.push(k);
            });
        }

        //to get previous day array to identify warning on edit program
        function getpreviousList(data, list) {
            var dataList = [];
            for (var i = 0; i < data.length; i++) {
                if (data[i].day < list.day) {
                    dataList.push(data[i]);
                }
            }
            return dataList;
        }

        //to get previous IVR to identify warning on edit program
        function searchIvr(nameKey, myArray) {
            for (var i = 0; i < myArray.length; i++) {
                if (myArray[i].ivrType && myArray[i].ivrType.entity.name === nameKey) {
                    return myArray[i];
                }
            }
        }

        //to identify warning on condition when edit program
        function checkConditionWarning(list) {
            return angular.forEach(list, function (res) {
                var previousData = tspc.getpreviousList(list, res);
                angular.forEach(list, function (prev) {
                    if (res.ivrType && res.ivrType.condition) {
                        if (previousData.length > 0) {
                            var checkCondition = tspc.searchIvr(res.ivrType.condition.selectedIvr.name, previousData);
                            if (!checkCondition) {
                                res.ivrType.condition.deleteConditionIconFlag = true;
                            }
                        } else {
                            res.ivrType.condition.deleteConditionIconFlag = true;
                        }
                    }
                    if (res.media) {
                        angular.forEach(res.media, function (media) {
                            if (media.condition) {
                                if (previousData.length > 0) {
                                    var checkMedCondition = tspc.searchIvr(media.condition.selectedIvr.name, previousData);
                                    if (!checkMedCondition) {
                                        media.condition.deleteConditionIconFlag = true;
                                    }
                                } else {
                                    media.condition.deleteConditionIconFlag = true;
                                }
                            }
                        })
                    }
                })
            })
        }

        //conctruct the final objects
        function finalReconstructObj(list, type) {
            if (list) {
                list.sort(function (a, b) {
                    return parseFloat(a.day) - parseFloat(b.day);
                });
            }
            if (list.length > 1) {
                list[0].addFlag = true;
                list[0].linkIconFlag = true;
            }
            if (list.length === 1) list[0].linkIconFlag = true;
            list = tspc.checkConditionWarning(list);
            checkForPrecedingIvr(list);

            if (type === 1) {
                dataAssign('itemList', 'finalDayList', 'finalDayList', list);
            } else {
                dataAssign('circleList', 'finalCircleList', 'circleList', list);
            }
        }

        //assign data based on type
        function dataAssign(item, final, met, list) {
            tspc[item] = list;
            tspc.modalObject.editTransition = true;
            tspc.modalObject.list = list;
            tspc[final] = list;
            tspc.program().entity.meta[met] = list;
        }

        function generateCondition(list) {
            var obj = { selectedIvr: {}, selectedQuestion: {}, selectedResponses: [] };
            if (list.OrCondition && list.OrCondition.patientResponses[0].operator) {
                obj.selectedIvr['name'] = list.OrCondition.patientResponses[0].callName;
                obj.selectedOperator = list.OrCondition.patientResponses[0].operator;
                obj.selectedIvr['emmiCode'] = list.OrCondition.patientResponses[0].emmiCode;
                obj.selectedQuestion['questionID'] = list.OrCondition.patientResponses[0].questionId;
                obj.textResponses = list.OrCondition.patientResponses[0].response;
                obj.selectedIvr['meta'] = {};
            } else {
                obj.selectedIvr['name'] = list.OrCondition.patientResponses[0].callName;
                obj.selectedQuestion['questionID'] = list.OrCondition.patientResponses[0].questionId;
                obj.selectedIvr['emmiCode'] = list.OrCondition.patientResponses[0].emmiCode;
                obj.selectedResponses = tspc.generateResponse(list.OrCondition.patientResponses);
                obj.selectedIvr['meta'] = {};
            }
            return obj;
        }

        function generateResponse(response) {
            var res = [];
            response.forEach(function (element) {
                res.push(element.response);
            });
            return res;
        }

        //contrct the day, ivr and initialise media
        function generateIvrDay(list) {
            var obj = {};
            obj.day = list.condition.conjunction.AndCondition.daysSinceStart;
            if (list.condition.programType === 'IVR CALL') {
                obj.ivrType = {
                    entity: {
                        name: list.condition.programName,
                        emmiCode: list.condition.emmiCode
                    }
                };
                if (list.condition.conjunction.AndCondition.OrCondition) {
                    obj.ivrType['condition'] = tspc.generateCondition(list.condition.conjunction.AndCondition);
                }
            }
            obj.toggleFlag = true;
            obj.media = [];
            return obj;
        }

        //change the link
        function changeLink(arg) {
            tspc.links.forEach(function (element) {
                element.active = element.linkName === arg.linkName;
                tspc.linkName = arg.linkName;
            });
        }

        function modalList() {
            var linkName = tspc.findLinkName();
            var list = linkName === 'Patient' ? tspc.finalDayList : tspc.finalCircleList;
            tspc.dayErrorMesg = '';
            tspc.modalObject = {
                modalTitle: 'Add Series Day Number',
                dayFlag: true,
                ivrFlag: false,
                mediaFlag: false,
                conditionFlag: tspc.modalObject.conditionFlag,
                buttonOne: 'ADD MEDIA',
                buttonTwo: 'ADD IVR',
                buttonCancel: 'CANCEL',
                buttonAddClass: 'add-button',
                buttonCancelClass: 'remove-button',
                editFlag: false,
                list: list,
                editItem: '',
                copyFlag: false,
                copyItem: ''
            };
        }

        //build the series
        function buildSeries() {
            tspc.modalList();
            tspc.program().seriesPathValidationMessage = null;
            tspc.program().majorOrMinorVersion = false;
        }

        //edit day
        function editDayList(item) {
            tspc.modalList();
            tspc.modalObject.editItem = item;
            tspc.editCopyItem = angular.copy(item);
            tspc.modalObject.modalTitle = 'Edit Series Day Number';
            tspc.modalObject.buttonOne = 'SAVE & EDIT MEDIA';
            tspc.modalObject.buttonTwo = 'SAVE & EDIT IVR';
            tspc.modalObject.editFlag = true;
        }

        function toggleList(data) {
            data.toggleFlag = !data.toggleFlag;
        }

        function findLinkName() {
            var linkname = '';
            for (var i = 0; i < tspc.links.length; i++) {
                if (tspc.links[i].active) {
                    linkname = tspc.links[i].linkName;
                }
            }
            return linkname;
        }

        //Saving Transition day to the series path/circle of care day array
        function saveFinal(list, form) {
            tspc.finalObj.components = [];
            tspc.program().entity.meta.components = [];
            var item = list[0];
            var linkName = tspc.findLinkName();
            list = linkName === 'Patient' ? tspc.itemList : tspc.circleList;
            if (tspc.modalObject.editFlag) {
                angular.forEach(list, function (res) {
                    if (res.day === tspc.modalObject.editItem.day) {
                        res.day = item.day;
                        if (tspc.modalObject.editItem.ivrType && tspc.modalObject.editItem['ivrType']['condition']) {
                            item.ivrType['condition'] = tspc.modalObject.editItem['ivrType']['condition'];
                        }
                        res.ivrType = item.ivrType;
                        res.media = item.media;
                    }
                });
                if (tspc.modalObject.editFlag) {
                    list = tspc.checkConditionWarning(list);
                    checkForPrecedingIvr(list);
                }
            } else {
                list.push(item);
            }
            list.sort(function (a, b) {
                return parseFloat(a.day) - parseFloat(b.day);
            });
            angular.forEach(list, function (res) {
                res.linkIconFlag = false;
                res.addFlag = false;
            })
            if (list.length > 1) {
                list[0].addFlag = true;
                list[0].linkIconFlag = true;
            }
            if (list.length === 1) list[0].linkIconFlag = true;
            if (linkName === 'Patient') {
                tspc.finalDayList = list;
            } else {
                tspc.finalCircleList = list;
            }
            if (tspc.finalDayList && tspc.finalDayList.length) {
                var last = tspc.finalDayList[tspc.finalDayList.length - 1];
                tspc.finalObj.daysToEnd = last.day;
                tspc.preparePostData(tspc.finalDayList, 1);
            }
            if (tspc.finalCircleList && tspc.finalCircleList.length) {
                tspc.preparePostData(tspc.finalCircleList, 2);
            }
            if (tspc.finalCircleList && tspc.finalCircleList.length || tspc.finalDayList.length) {
                tspc.setFormModified(form);
            }
            tspc.program().entity.meta.components = tspc.finalObj.components;
            tspc.program().entity.meta.daysToEnd = tspc.finalObj.daysToEnd;
        }

        // when we add condition that condition is being added to the saveFinal array
        function updateFullList(fullList, form) {
            tspc.finalObj.components = [];
            tspc.program().entity.meta.components = [];
            if (tspc.findLinkName() === 'Patient') {
                tspc.finalDayList = fullList;
            } else {
                tspc.finalCircleList = fullList;
            }
            if (tspc.finalDayList.length) {
                tspc.preparePostData(tspc.finalDayList, 1);
            }
            if (tspc.finalCircleList.length) {
                tspc.preparePostData(tspc.finalCircleList, 2);
            }
            if (tspc.finalCircleList || tspc.finalDayList) {
                tspc.setFormModified(form);
            }
            if (tspc.finalDayList && tspc.finalDayList.length) {
                var last = tspc.finalDayList[tspc.finalDayList.length - 1];
                tspc.finalObj.daysToEnd = last.day;
            } else {
                tspc.finalObj.daysToEnd = 0;
            }
            tspc.program().entity.meta.components = tspc.finalObj.components;
            tspc.program().entity.meta.daysToEnd = tspc.finalObj.daysToEnd;
        }

        function getCurrentDay(day) {
            tspc.currentDay = day;
        }

        //prepare post object
        function preparePostData(list, type) {
            tspc.program().anyDeleteConditionIconFlag = false;
            angular.forEach(list, function (res) {
                if (res.ivrType && tspc.prepareObj(res.day, res.ivrType, 'ivr')) {
                    tspc.finalObj.components.push(tspc.prepareObj(res.day, res.ivrType, 'ivr', type));
                }
                angular.forEach(res.media, function (media) {
                    if (media && tspc.prepareObj(res.day, media, 'media') !== '') {
                        tspc.finalObj.components.push(tspc.prepareObj(res.day, media, 'media', type));
                    }
                });
            });
            if (type === 1) {
                tspc.program().entity.meta.finalDayList = list;
            } else if (type === 2) {
                tspc.program().entity.meta.circleList = list;
            }
        }

        function prepareObj(day, data, type, link) {
            var object = {};
            var programType = type === 'ivr' ? 'IVR CALL' : 'MULTIMEDIA PROGRAM';
            if (programType && typeof (data) === 'object') {
                object = {
                    condition: {
                        conjunction: {
                            AndCondition: {
                                daysSinceStart: day,
                                personTypeCd: link,
                                OrCondition: tspc.prepareCondition(data)
                            },
                        },
                        emmiCode: data.entity.emmiCode,
                        programName: data.entity.name,
                        programType: programType
                    }
                }
            }
            return object;
        }

        function prepareCondition(data) {
            var patientResponses = [];
            if (data.condition) {
                if (data.condition) {
                    if (data.condition.textResponses) {
                        patientResponses.push(
                            {
                                'callName': data.condition.selectedIvr.name,
                                'emmiCode': data.condition.selectedIvr.emmiCode,
                                'questionId': data.condition.selectedQuestion.questionID,
                                'operator': data.condition.selectedOperator,
                                'response': data.condition.textResponses
                            }
                        );
                    } else if (data.condition.selectedResponses && data.condition.selectedResponses.length) {
                        angular.forEach(data.condition.selectedResponses, function (res) {
                            patientResponses.push(
                                {
                                    'callName': data.condition.selectedIvr.name,
                                    'questionId': data.condition.selectedQuestion.questionID,
                                    'emmiCode': data.condition.selectedIvr.emmiCode,
                                    'response': res
                                }
                            );
                        });
                    }
                }
            }
            if (patientResponses.length) {
                return { patientResponses: patientResponses }
            } else {
                return undefined;
            }
        }

        // find the element
        function findIndex(array, column, val) {
            var obj;
            for (var i = 0; i < array.length; i++) {
                if (array[i][column] === val) {
                    obj = [i];
                }
            }
            return obj;
        }

        function checkConditionFlag(list, deletedIvr) {
            if (list.ivrType && list.ivrType.condition && list.ivrType.condition.selectedIvr.name === deletedIvr) {
                list.ivrType.condition.deleteConditionIconFlag = true;
            }
            if (list.media) {
                angular.forEach(list.media, function (media) {
                    if (media && media.condition && media.condition.selectedIvr.name === deletedIvr) {
                        media.condition.deleteConditionIconFlag = true;
                    }
                });
            }
        }

        function checkPreviousIvrCondition(day, list, deletedIvr) {
            for (var i = 0; i < list.length; i++) {
                if (list[i].ivrType && list[i].ivrType.entity.name === deletedIvr) {
                    checkConditionFlag(list[i], deletedIvr);
                    break;
                } else {
                    checkConditionFlag(list[i], deletedIvr);
                }
                if (!list[i].ivrType && list[i].media) {
                    angular.forEach(list[i].media, function (media) {
                        if (media && media.condition && media.condition.selectedIvr.name === deletedIvr) {
                            media.condition.deleteConditionIconFlag = true;
                        }
                    });
                }
            }
            return list;
        }

        // delete array
        function deleteDayList(list, type, arg, deleteList, form) {
            tspc.program().entity.meta.components = [];
            tspc.finalObj.components = [];
            var linkName = tspc.findLinkName();
            var dayIndex = tspc.findIndex(deleteList, 'day', list.day);
            var deletedIvr = list.ivrType ? angular.copy(list.ivrType.entity.name) : '';
            if (type === 'day') {
                if (dayIndex) {
                    deleteList.splice(dayIndex, 1);
                }
            } else if (type === 'ivr') {
                angular.forEach(deleteList, function (res) {
                    if (res.day === list.day && res.ivrType.entity.name === list.ivrType.entity.name) {
                        delete res.ivrType;
                        deleteDayIndex(res, dayIndex, deleteList);
                    }
                })
            } else if (type === 'media') {
                angular.forEach(deleteList, function (res) {
                    if (res.day === list.day) {
                        var mediaIndex = res.media.indexOf(arg);
                        if (mediaIndex > -1) {
                            res.media.splice(mediaIndex, 1);
                            deleteDayIndex(res, dayIndex, deleteList);
                        }
                    }
                })
            }

            deleteList.sort(function (a, b) {
                return parseFloat(a.day) - parseFloat(b.day);
            });
            angular.forEach(deleteList, function (res) {
                res.addFlag = false;
                res.linkIconFlag = false;
            });
            if (deletedIvr) {
                deleteList = tspc.checkPreviousIvrCondition(list.day, deleteList, deletedIvr);
            }
            tspc.checkForPrecedingIvr(deleteList);
            if (deleteList.length > 1) {
                deleteList[0].addFlag = true;
                deleteList[0].linkIconFlag = true;
            }
            if (deleteList.length === 1) deleteList[0].linkIconFlag = true;
            if (linkName === 'Patient') {
                tspc.finalDayList = deleteList;
            } else {
                tspc.finalCircleList = deleteList;
            }
            if (tspc.finalDayList && tspc.finalDayList.length) {
                var last = tspc.finalDayList[tspc.finalDayList.length - 1];
                tspc.finalObj.daysToEnd = last.day;
                tspc.preparePostData(tspc.finalDayList, 1);
            } else {
                tspc.finalObj.daysToEnd = 0;
            }
            if (tspc.finalCircleList && tspc.finalCircleList.length) {
                tspc.preparePostData(tspc.finalCircleList, 2);
            }
            if (tspc.finalCircleList || tspc.finalDayList) {
                tspc.setFormModified(form);
            }
            tspc.program().entity.meta.components = tspc.finalObj.components;
            tspc.program().entity.meta.daysToEnd = tspc.finalObj.daysToEnd;
        }

        // removes the day when the doesn't have no programs in it
        function deleteDayIndex(deleteDay, dayIndex, deleteList) {
            if (!deleteDay.ivrType) {
                if (deleteDay.media) {
                    if (!deleteDay.media.length) {
                        if (dayIndex) {
                            deleteList.splice(dayIndex, 1);
                        }
                    }
                } else {
                    if (dayIndex) {
                        deleteList.splice(dayIndex, 1);
                    }
                }
            }
        }

        function findRemovedIvr(conditionName, list) {
            angular.forEach(list, function (res) {
                if (res.ivrType && res.ivrType.condition && res.ivrType.condition.selectedIvr.name === conditionName) {
                    res.ivrType.condition.deleteConditionIconFlag = true;
                }
                angular.forEach(res.media, function (media) {
                    if (media && media.condition && media.condition.selectedIvr.name === conditionName) {
                        media.condition.deleteConditionIconFlag = true;
                    }
                });
            })
            return list;
        }

        function copyDay(list) {
            tspc.modalList();
            tspc.modalObject.modalTitle = 'Copy Series Day';
            tspc.modalObject.copyItem = list;
            tspc.modalObject.editFlag = false;
            tspc.modalObject.copyFlag = true;
        }

        function deleteCondition(obj, list, form) {
            tspc.program().entity.meta.components = [];
            tspc.finalObj.components = [];
            var linkName = tspc.findLinkName();
            delete obj['condition'];
            if (linkName === 'Patient') {
                tspc.finalDayList = list;
            } else {
                tspc.finalCircleList = list;
            }
            if (tspc.finalDayList && tspc.finalDayList.length) {
                var last = tspc.finalDayList[tspc.finalDayList.length - 1];
                tspc.finalObj.daysToEnd = last.day;
                tspc.preparePostData(tspc.finalDayList, 1);
            }
            if (tspc.finalCircleList && tspc.finalCircleList.length) {
                var last = tspc.finalDayList[tspc.finalDayList.length - 1];
                tspc.finalObj.daysToEnd = last.day;
                tspc.preparePostData(tspc.finalCircleList, 2);
            }
            if (tspc.finalCircleList.length || tspc.finalDayList.length) {
                tspc.setFormModified(form);
            }
            tspc.program().entity.meta.components = tspc.finalObj.components;
            tspc.program().entity.meta.daysToEnd = tspc.finalObj.daysToEnd;
        }

        function checkForPrecedingIvr(list) {
            var ivrcounter = 0;
            angular.forEach(list, function (res, $index) {
                var thisday = res.day;
                angular.forEach(list, function (resp) {
                    if (thisday > resp.day && resp.ivrType) {
                        ivrcounter++;
                    }
                });
                if (ivrcounter === 0) {
                    list[$index].linkIconFlag = true;
                }
            });
        }
    }
})(window.angular);
