(function (angular) {

    'use strict';

    angular
        .module('Composer')
        .directive('reloadApplicationOnNewVersion', reloadApplicationOnNewVersion);

    reloadApplicationOnNewVersion.$inject = ['$modal', '$window', '$interval', '$http', 'API', '$log', '$q'];

    /**
     * Prompts the user to reload the browser when a new application version has been deployed
     */
    function reloadApplicationOnNewVersion($modal, $window, $interval, $http, api, $log, $q) {

        return {
            restrict: 'A',
            scope: {
                currentFrontEndVersion: '='
            },
            link: Link
        };

        function fetchBackEndVersion() {
            return $http.get(api.links.self).then(function (response) {
                return response.data.version;
            });
        }

        function fetchFrontEndVersion() {
            return $http.get('version.html').then(function (response) {
                return response.data.version;
            });
        }

        function showModal(scope) {
            if (!scope.warningModal) {
                scope.warningModal = $modal({
                    templateUrl: 'app/blocks/services/version-check/version-change-modal.html',
                    animation: 'none',
                    scope: scope,
                    show: true
                });
            } else {
                scope.warningModal.show();
            }
        }

        function Link(scope, element, attrs) {

            scope.initialFrontEndVersion = attrs.content;
            scope.initialBackEndVersion = api.version;
            attrs.$set('data-current-back-end-version', api.version);

            $log.debug('Front-End Version: ' + scope.initialFrontEndVersion);
            $log.debug('Back-End Version: ' + scope.initialBackEndVersion);

            scope.reloadNow = function () {
                $window.location.reload();
            };

            var versionPoll = $interval(function () {
                $q.all([fetchBackEndVersion(), fetchFrontEndVersion()]).then(function (responses) {
                    if (responses[0] !== scope.initialBackEndVersion ||
                        responses[1] !== scope.initialFrontEndVersion) {
                        showModal(scope);
                    }
                });
            }, 600000, 0, false);  // checks every 10 minutes
            element.bind('$destroy', function () {
                $interval.cancel(versionPoll);
            });
        }
    }


})(window.angular);
