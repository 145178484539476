(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('DialectsController', DialectsController);

    DialectsController.$inject = ['DialectService'];

    function DialectsController(DialectService) {
        var Dialects = this;

        (function onControllerLoad() {
            Dialects.nextPage = nextPage;
            Dialects.hasVisible = anyVisibleDialects;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                Dialects.loadingNextPage = true;
                DialectService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    Dialects.loadingNextPage = false;
                } else {
                    Dialects.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                Dialects.loadingNextPage = true;
            }
        }

        function anyVisibleDialects() {
            var hasVisible = false,
                length = Dialects.dialects && Dialects.dialects.content &&
                    Dialects.dialects.content.length;
            for (var i = 0; i < length; i++) {
                if (!Dialects.dialects.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            Dialects.loading = true;
            DialectService.search()
                .then(function (response) {
                    Dialects.dialects = response;
                    //Dialects.dialects = JSON.stringify(response);
                })
                .finally(function () {
                    delete Dialects.loadingNextPage;
                    delete Dialects.loading;
                });
        }

    }
})(window.angular);
