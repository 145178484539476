(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewSourceService', NewSourceService);

    NewSourceService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewSourceService($http, Principal, UriTemplate) {

        return {
            create: create,
            newSource: newSource
        };

        function create(newSource) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.sources).stringify(),
                    newSource).then(function (response) {
                    return response.data;
                });
            })
        }

        function newSource() {
            return {
                name: ''
            }
        }

    }
})(window.angular);
