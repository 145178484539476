(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('ModulesService', ModulesService);

    ModulesService.$inject = [];

    function ModulesService() {
        // Question Variables
        var questionConfiguration = {
            showExistingQId :false,
            questionType : 'New',
            previousQId : null,
            isUniqueQId : false,
            unsetQuestion : null,
            qidDupe : false,
            qformSubmitted : false,
        };
        var activeQuestion = {
                configurable: false,
                defaultQuestion: false,
                questionId: null,
                responseType: 'standard',
                hasClinicalFlag: false,  // Boolean
                clinicalFlagValues: "", // Comma delimited string
                clinicalFlagValuesDict: {},
                clinicalFlagMin: 1,
                clinicalFlagMax: 1,
                hasLogisticalFlag: false, // Boolean
                logisticalFlagValues: "", // Comma delimited String
                logisticalFlagValuesDict: {},
                logisticalFlagMin: 0,
                logisticalFlagMax: 1,
                personTypeCds: [1], // Array - will always be single value 1 for for form editions and can be hard coded
                reportType: "", // Single character F,N or A
                reportName: "",
                reportOrder: 1, // Integer - 1-100
                validResponses: []
        };
        var questionList = [];
        var activeQuestionPreEditState = {};
        // Module Variables
        var moduleConfiguration = {
                moduleType: 'New',
                moduleFlag : true,
                addFlag: true,
                toggleFlag: false,
            },
            activeModule =  {
                moduleRouteName: '',
                moduleTitle: '',
                routing: [],
                defaultNextModuleRoute: ''
            },
            activeModulePreEditState = {},
            moduleList = [];
        // Element Variables
        var elementConfiguration = {
                addFlag: true,
                moduleFlag: false,
                toggleFlag: false,

            },
            activeElement = {
                elementRouteName: '',
                questionId: '',
                displayType: '',
                contextText: '',
                routing: [],
                type: '',
                defaultNextElementRoute: '',
                parentIndex: null
            },
            activeElementPreEditState = {},
            elementList = [];



        return {

            // Question Methods
            setNewQuestion: setNewQuestion,
            setExistingQuestion: setExistingQuestion,
            getActiveQuestion: getActiveQuestion,
            getActiveQuestionPreEditState: getActiveQuestionPreEditState,
            getQuestionConfiguration: getQuestionConfiguration,
            setActiveQuestion: setActiveQuestion,
            removeQuestion: removeQuestion,
            setQuestionList: setQuestionList,
            getQuestionList: getQuestionList,
            addResponse: addResponse,
            removeResponse: removeResponse,
            generateNewQuestion: generateNewQuestion,

            // Module Methods
            addModule: addModule,
            setExistingModule: setExistingModule,
            setActiveModule: setActiveModule,
            setActiveModulePreEditState: setActiveModulePreEditState,
            getActiveModule: getActiveModule,
            getActiveModulePreEditState: getActiveModulePreEditState,
            removeModule: removeModule,
            saveModule: saveModule,
            setModuleList: setModuleList,
            getModuleList: getModuleList,
            getModuleConfiguration: getModuleConfiguration,

            // Element Methods
            addNewElement: addNewElement,
            setExistingElement: setExistingElement,
            getActiveElement: getActiveElement,
            getActiveElementPreEditState: getActiveElementPreEditState,
            saveElement: saveElement,
            setElementList: setElementList,
            getElementList: getElementList,
            getElementConfiguration: getElementConfiguration,
            generateNewElement: generateNewElement,
            prepareModule: prepareModule,
            prepareElement: prepareElement,
            staticRoutingData: staticRoutingData,
            generateNewModule: generateNewModule,
            removeElement: removeElement,

            // Conditions and Routing
            getCondition: getCondition,
            prepareConditions: prepareConditions,
            prepareComponents: prepareComponents,
            getPatientResponses: getPatientResponses,
            routingConfig: routingConfig,
            removeCondition: removeCondition,
            addAndOrCondition: addAndOrCondition,
            addCondition: addCondition,
            addConditionRow: addConditionRow,
            removeAndOrCondition: removeAndOrCondition


        };

        function setNewQuestion() {
            questionConfiguration.showExistingQId = false;
            questionConfiguration.questionType = 'New';
            questionConfiguration.previousQId = null;
            questionConfiguration.unsetQuestion = null;
            questionConfiguration.qidDupe = false;
            questionConfiguration.qformSubmitted = false;
            activeQuestion = {
                configurable: false,
                questionId: null,
                responseType: "standard",
                hasClinicalFlag: false,  // Boolean
                clinicalFlagValues: '', // Comma delimited string
                clinicalFlagValuesDict: {},
                clinicalFlagMin: 0,
                clinicalFlagMax: 1,
                clinicalAnyFlag: 'true',
                hasLogisticalFlag: false, // Boolean
                logisticalFlagValues: '', //Comma delimited String
                logisticalFlagValuesDict: {},
                logisticalFlagMin: 0,
                logisticalFlagMax: 1,
                logisticalAnyFlag: 'true',
                personTypeCds: [1], // Array - will always be single value 1 for for form editions and can be hard coded
                reportType: null, // Single character F,N or A
                reportOrder: null, // Integer - 1-100
                validResponses: []
            };
            activeQuestionPreEditState = JSON.parse(JSON.stringify(activeQuestion));

        }

        function setExistingQuestion() {
            questionConfiguration.showExistingQId = false;
            questionConfiguration.isUniqueQId = true;
            questionConfiguration.questionType = 'Existing';
            questionConfiguration.previousQId = activeQuestion.questionId;
            questionConfiguration.qidDupe = false;
            questionConfiguration.qformSubmitted = false;

            activeQuestion.clinicalAnyFlag = activeQuestion.clinicalAnyFlag ? activeQuestion.clinicalAnyFlag : 'false';
            activeQuestion.logisticalAnyFlag = activeQuestion.logisticalAnyFlag ? activeQuestion.logisticalAnyFlag : 'false';

            if (activeQuestion.clinicalFlagValues === undefined) activeQuestion.clinicalFlagValues = "";
            if (activeQuestion.logisticalFlagValues === undefined) activeQuestion.logisticalFlagValues = "";
            if (activeQuestion.hasClinicalFlag === undefined) activeQuestion.hasClinicalFlag = false;
            if (activeQuestion.hasLogisticalFlag === undefined)activeQuestion.hasLogisticalFlag = false;
            if (activeQuestion.personTypeCds === undefined) activeQuestion.personTypeCds = [1];
            if (activeQuestion.reportType === undefined) activeQuestion.reportType = "F";
            if (activeQuestion.reportOrder === undefined) activeQuestion.reportOrder = 1;

            if (activeQuestion.responseType === "standard") {
                activeQuestion.clinicalFlagValuesDict = {};
                activeQuestion.logisticalFlagValuesDict = {};

                for (var i = 0, isClinicalFlag, isLogisticalFlag, response, value; response = activeQuestion.validResponses[i]; i++) {
                    value = response.response;

                    isClinicalFlag = activeQuestion.clinicalFlagValues.search(value) >= 0;
                    isLogisticalFlag = activeQuestion.logisticalFlagValues.search(value) >= 0;

                    activeQuestion.clinicalFlagValuesDict[value] = isClinicalFlag;
                    activeQuestion.logisticalFlagValuesDict[value] = isLogisticalFlag;
                }
            } else if (activeQuestion.responseType === "number" || activeQuestion.responseType === "decimal") {
                var clinicalFlags = activeQuestion.clinicalFlagValues.split(',');
                var logisticalFlags = activeQuestion.logisticalFlagValues.split(',');

                activeQuestion.clinicalFlagMin = clinicalFlags[0];
                activeQuestion.clinicalFlagMax = clinicalFlags[1];

                activeQuestion.logisticalFlagMin = logisticalFlags[0];
                activeQuestion.logisticalFlagMax = logisticalFlags[1];
            }

        }

        function getActiveQuestion(){
            return activeQuestion;
        }

        function setActiveQuestion(question){
            if(question.reportType && question.reportType.trim().length===0){
                question.reportType = 'F';
            }
            if(!question.reportOrder)
                question.reportOrder = 1;
                question.clinicalAnyFlag = question.clinicalAnyFlag || 'true';
                question.logisticalAnyFlag = question.logisticalAnyFlag || 'true';
                question.reportName = '';
                activeQuestion = JSON.parse(JSON.stringify(question));
                activeQuestionPreEditState = JSON.parse(JSON.stringify(question));
        }

        function getActiveQuestionPreEditState(){
            return activeQuestionPreEditState;
        }

        function removeQuestion(questionId, questions) {
            if (questions) {
                for (var i = 0; questions.length > i; i++) {
                    if (questions[i].questionId === questionId) {
                        questions.splice(i, 1);
                        break;
                    }
                }
            }
            return questions;
        }

        function setQuestionList(questions){
            questionList = questions;
        }

        function getQuestionList(){
            return questionList;
        }

        function addResponse(question) {
            if (!question.validResponses) question.validResponses = [];
            question.validResponses.push({response: null, followUpText: null});
            return question;
        }

        function removeResponse(question, response) {
            var index = question.validResponses.indexOf(response);
            if (index > -1) {
                question.validResponses.splice(index, 1);
            }
            return question;
        }

        function addModule() {
            moduleConfiguration.moduleType = true;
            moduleConfiguration.moduleFlag = true;
            moduleConfiguration.addFlag = true;
            var module = generateNewModule();
            activeModule = routingConfig(module, moduleConfiguration);
            activeModulePreEditState = JSON.parse(JSON.stringify(activeModule));
        }

        function setExistingModule(module) {
            moduleConfiguration.moduleType = 'Edit';
            moduleConfiguration.addFlag = false;
            moduleConfiguration.moduleFlag = true;
            moduleConfiguration.toggleFlag = false;
            module = routingConfig(module, moduleConfiguration);
            activeModule = JSON.parse(JSON.stringify(module));
            activeModulePreEditState = JSON.parse(JSON.stringify(module));
            activeModule.modified = false;
        }
        //delete the module
        function removeModule(selected, form) {
            var index = moduleList.indexOf(selected);
            if (index > -1) {
                for (var i = 0; moduleList[index].formElements.length > i; i++) {
                    questionList = removeQuestion(moduleList[index].formElements[i].questionId, questionList);
                }
                for (i = 0; moduleList[index].formElements.length > i; i++) {
                    elementList = removeElement(moduleList[index].formElements[i], moduleList[index].moduleRouteName, form, moduleList);
                }
                moduleList.splice(index, 1);
                return moduleList;
            }
        }

        function setActiveModule(module){
            activeModule = module;

        }

        function setActiveModulePreEditState(module){
            activeModulePreEditState = module;
        }

        function getActiveModule(){
            return activeModule;
        }

        function getActiveModulePreEditState(){
            return activeModulePreEditState;
        }

        function setModuleList(modules){
            moduleList = modules;
        }

        function getModuleList(){
            return moduleList;
        }

        function getModuleConfiguration(){
            return moduleConfiguration;
        }

        function getElementConfiguration(){
            return elementConfiguration;
        }

        function getQuestionConfiguration(){
            return questionConfiguration;
        }
        // Element Methods

        function addNewElement(parent) {
            elementConfiguration.moduleType = 'New';
            elementConfiguration.moduleFlag = false;
            elementConfiguration.addFlag = true;
            elementConfiguration.parentIndex = parent.moduleRouteName;
            setActiveModule(parent);
            setActiveModulePreEditState(parent);
            generateNewElement();
            generateNewQuestion();
            setElementList(parent);
            activeElement = routingConfig(activeElement, elementConfiguration);
        }

        function setExistingElement(element, parent) {
            activeElement = JSON.parse(JSON.stringify(element));
            activeElementPreEditState = JSON.parse(JSON.stringify(element));
            elementConfiguration.addFlag = false;
            elementConfiguration.moduleFlag = false;
            elementConfiguration.toggleFlag = false;
            elementConfiguration.parentIndex = parent.moduleRouteName;
            setActiveModule(parent);
            setActiveModulePreEditState(parent);
            if (questionList) {
                for (var i = 0; questionList.length > i; i++) {
                    if (questionList[i].questionId === element.questionId) {
                        activeQuestion = JSON.parse(JSON.stringify(questionList[i]));
                        activeQuestionPreEditState = JSON.parse(JSON.stringify(questionList[i]));
                        setExistingQuestion();
                        break;
                    }
                }
            }
        }

        function removeElement(selected, parent, form, modules) {
            activeModule = JSON.parse(JSON.stringify(parent));
            activeModulePreEditState = JSON.parse(JSON.stringify(parent));
            var elementIndex = -1;
            for (var i = 0; modules.length > i; i++) {
                if (modules[i].moduleRouteName === parent.moduleRouteName) {
                    for (var j = 0; modules[i].formElements.length > j; j++) {
                        if (modules[i].formElements[j].elementRouteName === selected.elementRouteName) {
                            elementIndex = j;
                            break;
                        }
                    }
                    if (elementIndex > -1) {
                        if (modules[i].formElements[elementIndex].questionId) {
                           questionList = removeQuestion(modules[i].formElements[elementIndex].questionId, questionList);
                        }
                        modules[i].formElements.splice(elementIndex, 1);
                        setModuleList(modules);
                    }
                    break;
                }
            }
            return modules;
        }

        function getActiveElement(){
            return routingConfig(activeElement, elementConfiguration);
        }

        function getActiveElementPreEditState(){
            return activeModulePreEditState;
        }

        function setElementList(parent){
            elementList = parent.formElements;
        }
        function getElementList(){
            return elementList;
        }

        // Data Prep for re-integration with form edition from modals
        function prepareModule(module) {
            return {
                moduleRouteName: module.moduleRouteName ? module.moduleRouteName.replaceAll('\"', '') : null,
                moduleTitle: module.moduleTitle ? module.moduleTitle.replaceAll('\"', '') : null,
                defaultNextModuleRoute: module.defaultNextModuleRoute,
                modified: module.modified ? module.modified : false,
                conditions: prepareComponents(module.routing),
                formElements: moduleConfiguration.addFlag ? generateNewModule() : module.formElements,
                isGlobalModule: module.isGlobalModule || null,
                globalVersion: module.modified ? module.globalVersion + 1 : ("number" === typeof module.globalVersion ? module.globalVersion : null),
                globalVersionLatest: "number" === typeof module.globalVersionLatest ? module.globalVersionLatest : null,
                linkedToGlobal: module.linkedToGlobal || null,
                globalModuleName: module.globalModuleName || null
            }
        }

        // Reconstructing the data when saving the Element
        function prepareElement(element) {
            return {
                contextText: element.contextText ? element.contextText : null,
                contextTextFormatting: element.contextTextFormatting ? JSON.stringify(element.contextTextFormatting) : null,
                contextTextType: element.contextTextType,
                displayType: element.displayType ? element.displayType.replaceAll('\"', '') : null,
                elementRouteName: element.elementRouteName ? element.elementRouteName.replaceAll('\"', '') : null,
                questionId: element.questionId,
                type: element.type,
                defaultNextElementRoute: element.defaultNextElementRoute,
                conditions: prepareComponents(element.routing)

            }
        }

        function prepareConditions(data) {
            var condition = [];
            if (data) {
                angular.forEach(data, function (res) {
                    condition.push(getCondition(res))
                });
            }
            return condition;
        }

        function prepareComponents(data, typeText) {
            var type = {
                components: [],
                conditions: []
            };
            var conditionType = typeText === 'modal' ? 'conditions' : 'conditions';
            var subCondition = [];
            angular.forEach(data, function (res) {
                subCondition = prepareConditions(res.condition);
                if (subCondition.length) {
                    type[conditionType].push(getPatientResponses(res, subCondition, res.condition[0].btnName[0].name))
                }
            });
            return type[conditionType];
        }

        //preparing complete reconstruction of condition for patient responses JSON for both module and element
        function getPatientResponses(res, subCondition, type) {
            var response = {
                conjunction: {
                    AndCondition: {}
                },
                nextRoute: res.nextRoute
            };
            if (type === 'And') {
                response.conjunction.AndCondition['patientResponses'] = subCondition;
            } else {
                response.conjunction.AndCondition['OrCondition'] = {};
                response.conjunction.AndCondition.OrCondition['patientResponses'] = subCondition;
            }
            return response;
        }

        //preparing the patient response to display
        function getCondition(data) {
            return {
                "question": data.question,
                "operator": data.operator,
                "response": data.response.replace('"', '')
            }
        }
        // Saving Methods

        function saveModule(module, form, elementModified) {
            var isReplacing = false;
           // typeText = 'modal'; // Move to Controller - UI State
            for (var i = 0; i < moduleList.length; i++) {
                if (moduleList[i].moduleRouteName === activeModulePreEditState.moduleRouteName) {
                    if (elementModified) {
                        module.modified = true;
                        if (module.isGlobalModule) {
                            module.globalVersion++;
                        }
                    }
                    moduleList.splice(i, 1, elementModified ? module : prepareModule(module) );
                    isReplacing = true;
                }
            }
            if (!isReplacing) {
                moduleList.push(prepareModule(module));
            }
            setActiveModule(elementModified ? module : prepareModule(module));
        }

        //save Element used to create the module
        function saveElement(element, elements) {
            var isReplacing = false;
            for (var i = 0; moduleList.length > i; i++) {
                if (moduleList[i].moduleRouteName === elementConfiguration.parentIndex) {
                    for (var j = 0; elements.length > j; j++) {
                        if (elements[j].elementRouteName === activeElementPreEditState.elementRouteName) {
                            elements.splice(j, 1, prepareElement(element));
                            isReplacing = true;
                            moduleList[i].formElements = JSON.parse(JSON.stringify(elements));
                            if (moduleList[i].isGlobalModule) moduleList[i].globalVersion++;
                        }
                    }
                }
            }
            if (!isReplacing) {
                for (var i = 0; moduleList.length > i; i++) {
                    if (moduleList[i].moduleRouteName === elementConfiguration.parentIndex) {
                        elements.splice(j, 1, prepareElement(element));
                        moduleList[i].formElements = JSON.parse(JSON.stringify(elements));
                    }
                }
            }
        }

        // Routing and Conditions

        function routingConfig(component, config) {
            if (config.addFlag === true) {
                component.routing = [];
            } else if  (config.addFlag === false) {
                if (component.conditions) component.routing = rebuildRouting(JSON.parse(JSON.stringify(component.conditions)));
                else component.routing = [];
            } else console.log('no match');
            return component;
        }

        function rebuildRouting(components) {
            var routing = [];
            var count = 0;
            angular.forEach(components, function (res) {
                var andCond = res.conjunction.AndCondition;
                if (andCond.patientResponses) {
                    var andCondition = [];
                    for (var i = 0; i < andCond.patientResponses.length; i++) {
                        if (i === 0) {
                            andCondition.push({
                                name: 'If',
                                question: andCond.patientResponses[i].question,
                                operator: andCond.patientResponses[i].operator,
                                response: andCond.patientResponses[i].response,
                                btnName: [{
                                    name: 'And'
                                }]
                            });
                        } else {
                            andCondition.push({
                                name: 'And',
                                question: andCond.patientResponses[i].question,
                                operator: andCond.patientResponses[i].operator,
                                response: andCond.patientResponses[i].response,
                                btnName: []
                            });
                        }
                    }
                    count++;
                    routing.push({
                        id: count,
                        condition: andCondition,
                        nextRoute: res.nextRoute
                    })
                }

                if (andCond.OrCondition) {
                    var orCondition = [];
                    for (var i = 0; i < andCond.OrCondition.patientResponses.length; i++) {
                        if (i === 0) {
                            orCondition.push({
                                name: 'If',
                                question: andCond.OrCondition.patientResponses[i].question,
                                operator: andCond.OrCondition.patientResponses[i].operator,
                                response: andCond.OrCondition.patientResponses[i].response,
                                btnName: [{
                                    name:'Or'
                                }]
                            });
                        } else {
                            orCondition.push({
                                name: 'Or',
                                question: andCond.OrCondition.patientResponses[i].question,
                                operator: andCond.OrCondition.patientResponses[i].operator,
                                response: andCond.OrCondition.patientResponses[i].response,
                                btnName: []
                            });
                        }
                    }
                    count++;
                    routing.push({
                        id: count,
                        condition: orCondition,
                        nextRoute: res.nextRoute

                    })

                }
            });
            return routing;
        }

        function addCondition(component) {
            var last = component.routing.length;
            var next = last + 1;
            component.routing.some(function (value) {
                if (value.id === next) {
                    next = value.id + 1;
                }
            });
            component.routing.push(
                {
                    id: next,
                    condition: addConditionRow(component.questionId),
                    nextRoute: ''
                }
            );

            return component;
        }

        function removeCondition(route, conditionIndex) {

            if (conditionIndex > -1) {
                route.condition.splice(conditionIndex, 1);
            }
            return route;
        }

        function addConditionRow(question) {
            return [
                {
                    name: 'If',
                    question: question ? question : '',
                    operator: '=',
                    response: '',
                    btnName: [
                        {
                            name: 'And',
                        },
                        {
                            name: 'Or',
                        }
                    ],
                    remove: true
                }
            ]
        }

        function addAndOrCondition(routing, condition, btn) {
            if (routing.condition[0].btnName.length > 1) {
                if (btn === 'And') {
                    routing.condition[0].btnName.splice(1, 1);
                } else {
                    routing.condition[0].btnName.splice(0, 1);
                }
            }
            routing['condition'].push({
                name: btn,
                question: null,
                operator: '=',
                response: null,
                btnName: [],
                remove: true,
            });
            return routing;
        }

        function removeAndOrCondition(route, condition) {
            var index = route.condition.indexOf(condition);
            if (index > -1 && route.condition.length > 1) {
                route.condition.splice(index, 1);
                route.condition[0].name = 'If';
            } else {
                route = removeCondition(route, index);
            }
            return route;
        }


        // Static Data for use in predefined elements
        function staticRoutingData() {
            return [
                {
                    condition: {
                        conjunction: {
                            AndCondition: {
                                _define_condition: "",
                                nextRoute: '',
                                patientResponses: [
                                    {
                                        question: '',
                                        operator: '=',
                                        response: ''
                                    }
                                ]
                            }
                        }
                    }
                }
            ]
        }
        function generateNewModule (){
            return [
                {
                    contextText: null,
                    questionId: null,
                    displayType: 'text',
                    elementRouteName: "Intro",
                    type: "Intro",
                    conditions: [],
                    defaultNextElementRoute: "End",
                },
                {
                    elementRouteName: "End",
                    type: "End"
                }
            ]
        }

        function generateNewElement(module) {
           activeElement = {
                elementRouteName: '',
                    questionId: '',
                displayType: '',
                contextText: '',
                routing: [],
                type: '',
                defaultNextElementRoute: ''
            };
            activeElementPreEditState = JSON.parse(JSON.stringify(activeElement));
        }

        function generateNewQuestion() {
            activeQuestion = {
                configurable: false,
                questionId: null,
                responseType: null,
                hasClinicalFlag: false,  // Boolean
                clinicalFlagValues: "", // Comma delimited string
                clinicalFlagValuesDict: {},
                clinicalFlagMin: 1,
                clinicalFlagMax: 1,
                hasLogisticalFlag: false, // Boolean
                logisticalFlagValues: "", // Comma delimited String
                logisticalFlagValuesDict: {},
                logisticalFlagMin: 0,
                logisticalFlagMax: 1,
                personTypeCds: [1], // Array - will always be single value 1 for for form editions and can be hard coded
                reportType: "", // Single character F,N or A
                reportName: "",
                reportOrder: 1, // Integer - 1-100
                validResponses: []
            };
        }

    }
})(window.angular);
