(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('GenderService', GenderService);

    GenderService.$inject = ['$http', 'Principal', 'UriTemplate', 'PaginationService', '$q'];

    function GenderService($http, Principal, UriTemplate, PaginationService, $q) {

        return {
            search: search,
            nextPage: nextPage,
            list: list
        };

        function list(data) {
            search()
                .then(function (response) {
                    var content = response.content;
                    var result = content.map(function(o) {return o.entity;});
                    data.genders = result;
                });
        }

        function search() {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.genders).stringify(), {
                    params: {
                        sort: 'id',
                        size: 10
                    }
                }).then(function (response) {
                    var parsedResponse = PaginationService.handleResponse(response.data);
                    if (!parsedResponse.content) {
                        parsedResponse.content = [];
                    }
                    return parsedResponse;
                });
            })
        }

        function nextPage(linkToFetch) {
            if (linkToFetch) {
                return $http.get(linkToFetch).then(function (response) {
                    return PaginationService.handleResponse(response.data);
                });
            }
            return $q.reject('no link to fetch');
        }

    }
})(window.angular);
