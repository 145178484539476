(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('VersionedProgramDetailController', VersionedProgramDetailController);

    VersionedProgramDetailController.$inject = ['ProgramService', 'EditionsService', 'ProgramVersionService', 'AlertService', '$state', '$stateParams', 'UtilService'];

    function VersionedProgramDetailController(programService, editionsService, programVersionService, alertService, $state, $stateParams, utilService) {
        var vpdc = this;
        vpdc.program = programService.getProgram();
        vpdc.programHistory = programVersionService.getProgramHistory();

        vpdc.collapsedVersionHistory = false;

        (function onControllerLoad() {
            vpdc.nextProgramHistoryPage = nextProgramHistoryPage;
            vpdc.hasProgramHistory = hasProgramHistory;
            vpdc.getProgramDetails = getProgramDetails;
            vpdc.getAssetSets = editionsService.getAssetSets;
            vpdc.editRecentProgram = editRecentProgram;
            vpdc.checkin = checkin;
            vpdc.checkout = checkout;
            vpdc.promote = promote;
            vpdc.getPreview = getPreview;
            vpdc.isCheckedOutByUser = isCheckedOutByUser;
            vpdc.getCheckedOutByUser = getCheckedOutByUser;
            vpdc.loadProgramHistory = loadProgramHistory;
            vpdc.isPreviewLoading = isPreviewLoading;
        })();

        function nextProgramHistoryPage(link) {
            if (vpdc.programHistory && link === vpdc.programHistory.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                vpdc.loadingNextPage = true;
                utilService.fetchPage(link).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    vpdc.loadingNextPage = false;
                    vpdc.programHistory = response;
                } else {
                    vpdc.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                vpdc.loadingNextPage = true;
            }
        }

        function hasProgramHistory() {
            var hasVisible = false,
                length = vpdc.programHistory && vpdc.programHistory.content &&
                    vpdc.programHistory.content.length;
            for (var i = 0; i < length; i++) {
                if (!vpdc.programHistory.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function checkin() {
            vpdc.loading = true;
            var operation = 'check-in';
            programVersionService.checkin(vpdc.program)
                .then(function (response) {
                    vpdc.program = response;
                    alertService.success(vpdc.program.entity.name, operation);
                })
                .catch(function(response) {
                    alertService.error(response, operation);
                })
                .finally(function () {
                    delete vpdc.loading;
                })
        }

        function checkout() {
            vpdc.loading = true;
            var operation = 'check-out';
            programVersionService.checkout(vpdc.program)
                .then(function (response) {
                    vpdc.program = response;
                    alertService.success(vpdc.program.entity.name, operation);
                })
                .catch(function(response) {
                    alertService.error(response, operation);
                })
                .finally(function () {
                    delete vpdc.loading;
                });
        }

        function isCheckedOutByUser() {
            return programVersionService.isCheckedOutByUser();
        }

        function getCheckedOutByUser() {
            return programVersionService.getCheckedOutByUser();
        }

        function promote(program) {
            vpdc.loadingHistory = true;
            var operation = 'promote';
            programVersionService.promote(program)
                .then(function (response) {
                    vpdc.program = response;
                    alertService.success(vpdc.program.entity.name, operation);
                })
                .catch(function(response) {
                    alertService.error(response, operation);
                    delete vpdc.loadingHistory;
                })
                .finally(function () {
                    loadProgramHistory();
                });
        }

        function loadProgramHistory() {
            vpdc.loadingHistory = true;
            programVersionService.loadVersionedProgramHistory($stateParams.emmiCode)
                .then(function (response) {
                    vpdc.programHistory = response; 
                    vpdc.program = programService.getProgram();
                })
                .catch(function(response) {
                    alertService.error(response, 'load program history');
                }).finally(function () {
                    delete vpdc.loadingHistory;
                })
        }

        function getProgramDetails(program) {
            programService.setEditable(false);
            if(program.entity.type === 'MULTIMEDIA PROGRAM') {
                $state.go('edit-program-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: utilService.formatVersion(program.entity)
                });
            } else if(program.entity.type === 'IVR CALL') {
                $state.go('edit-ivr-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: utilService.formatVersion(program.entity)
                });
            } else if(program.entity.type === 'TRANSITION PROGRAM') {
                $state.go('edit-transition-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: utilService.formatVersion(program.entity)
                });
            } else if(program.entity.type === 'FORM EDITION') {
                $state.go('edit-form-edition-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: utilService.formatVersion(program.entity)
                });
            } else if(program.entity.type === 'FORM') {
                $state.go('edit-form-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: utilService.formatVersion(program.entity)
                });
            } else if(program.entity.type === 'CAREPLAN') {
                $state.go('edit-digital-journey-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: utilService.formatVersion(program.entity)
                });
            }  else if(program.entity.type === 'VIDEO') {
                $state.go('edit-video-program-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: utilService.formatVersion(program.entity)
                });
            }
            else if(program.entity.type === 'WEB PROGRAM') {
                $state.go('edit-web-program-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: utilService.formatVersion(program.entity)
                });
            }
        }

        function editRecentProgram() {
            programService.setEditable(true);
            var program = programService.getProgram();
            var initialVersion = 'v0.0.0';
            if(!program.entity.versionedToLocal) {
                initialVersion = utilService.formatVersion(program.entity);
            }
            if(program.entity.type === 'MULTIMEDIA PROGRAM') {
                $state.go('edit-program-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: initialVersion
                });
            } else if(program.entity.type === 'IVR CALL') {
                $state.go('edit-ivr-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: initialVersion
                });
            } else if(program.entity.type === 'TRANSITION PROGRAM') {
                $state.go('edit-transition-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: initialVersion
                });
            } else if(program.entity.type === 'FORM EDITION') {
                $state.go('edit-form-edition-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: initialVersion
                });
            } else if(program.entity.type === 'FORM') {
                $state.go('edit-form-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: initialVersion
                });
            } else if(program.entity.type === 'CAREPLAN') {
                $state.go('edit-digital-journey-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: initialVersion
                });
            }  else if(program.entity.type === 'VIDEO') {
                $state.go('edit-video-program-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: initialVersion
                });
            }
            else if(program.entity.type === 'WEB PROGRAM') {
                $state.go('edit-web-program-details', {
                    emmiCode: $stateParams.emmiCode,
                    version: initialVersion
                });
            }
        }

        function getPreview(program, version) {
            var versionText = version.major + '.' + version.minor + '.' + version.working;
            editionsService.getPreviewData(program.entity.editionId, versionText, program.entity.meta.formEditionMetaData.assetSet.dialect.iso639);
        }

        function isPreviewLoading() {
            return editionsService.getPreviewStatus();
        }

    }
})(window.angular);
