(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewDialectService', NewDialectService);

    NewDialectService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewDialectService($http, Principal, UriTemplate) {

        return {
            create: create,
            newDialect: newDialect
        };

        function create(newDialect) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.dialects).stringify(),
                    newDialect).then(function (response) {
                    return response.data;
                });
            })
        }

        function newDialect() {
            return {
                name: ''
            }
        }

    }
})(window.angular);
