(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewProgramTypeController', NewProgramTypeController);

    NewProgramTypeController.$inject = ['NewProgramTypeService', 'ProgramInitService', '$scope'];

    function NewProgramTypeController(NewProgramTypeService, programInitService, $scope) {
        var npc = this;

        npc.save = save;
        npc.newProgramType = NewProgramTypeService.newProgramType();
        npc.data = programInitService.getProgramTypes();

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewProgramTypeService.create(npc.newProgramType).then(function (response) {
                    $scope.$emit('new-program-type', response);
                    npc.data.programType.content.push(response);
                })
            }
        }
    }

})(window.angular);
