(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('TransitionConditionController', TransitionConditionController);

    TransitionConditionController.$inject = ['ProgramVersionService', 'fullList', 'currentDay', 'programEmmiCd', 'saveCallback', 'UtilService', 'ProgramService', '$state'];

    function TransitionConditionController(ProgramVersionService, fullList, currentDay, programEmmiCd, saveCallback, utilService, programService, $state) {
        var tcc = this;
        tcc.previouIvrCalls = {};
        tcc.questionsLst = [];
        tcc.fullList = fullList;
        tcc.saveCallback = saveCallback;
        tcc.condition = {};
        tcc.cancel = cancel;
        tcc.getQuestions = getQuestions;
        tcc.updateResponses = updateResponses;
        tcc.responsesOpen = false;
        tcc.selectedResponsesString = '';
        tcc.toggleResponsesOpen = toggleResponsesOpen;
        tcc.saveCondition = saveCondition;
        tcc.isValidCondition = isValidCondition;
        tcc.action = "Add";
        tcc.setFormModified = setFormModified;

        (function onLoad() {
            getPreviousIvrCalls(fullList);
            preLoadDataInEditMode(fullList, currentDay, programEmmiCd);
            tcc.action = utilService.isEmpty(tcc.condition) ? "Add" : "Edit";
        })();


        function setFormModified(form) {
            form.$setDirty();
        }
        function preLoadDataInEditMode(fullList, currentDay, programEmmiCd) {
            if (fullList && currentDay && programEmmiCd) {
                var item = utilService.findIndex(fullList, 'day', Number(currentDay));
                if (item && item.ivrType && item.ivrType.condition && item.ivrType.entity.emmiCode === programEmmiCd) {

                    tcc.selectedIvr = tcc.previouIvrCalls[item.ivrType.condition.selectedIvr.name];
                    tcc.condition = item.ivrType.condition;

                    getQuestions(function () {
                        tcc.selectedQuestion = getQuestionsFromLst(tcc.questionsLst, item.ivrType.condition.selectedQuestion);
                        if (tcc.selectedQuestion && tcc.selectedQuestion.responseTypeId === 3) {
                            tcc.selectedOperator = item.ivrType.condition.selectedOperator;
                            tcc.textResponses = item.ivrType.condition.textResponses;
                            tcc.selectedResponses = null;
                        } else {
                            tcc.selectedResponses = item.ivrType.condition.selectedResponses;
                            tcc.selectedOperator = null;
                            tcc.textResponses = null;
                        }
                    });
                } else if (item.media && item.media.length > 0) {
                    item.media.forEach(function (mediaItem) {
                        if (mediaItem.condition && mediaItem.entity.emmiCode === programEmmiCd) {
                            tcc.condition = mediaItem.condition;
                            tcc.selectedIvr = tcc.previouIvrCalls[mediaItem.condition.selectedIvr.name];
                            getQuestions(function () {
                                tcc.selectedQuestion = getQuestionsFromLst(tcc.questionsLst, mediaItem.condition.selectedQuestion);;
                                if (tcc.selectedQuestion && tcc.selectedQuestion.responseTypeId === 3) {
                                    tcc.selectedOperator = mediaItem.condition.selectedOperator;
                                    tcc.textResponses = mediaItem.condition.textResponses;
                                    tcc.selectedResponses = null;
                                } else {
                                    tcc.selectedResponses = mediaItem.condition.selectedResponses;
                                    tcc.selectedOperator = null;
                                    tcc.textResponses = null;
                                }
                            });
                        }
                    });
                }
            }
        }


        function getQuestionsFromLst(questionLst, question) {
            var questionObj = null;
            questionLst.forEach(function (obj) {
                if (obj.questionID === question.questionID) {
                    questionObj = obj;
                    return;
                }
            });
            return questionObj;
        }



        function getPreviousIvrCalls(fullList) {
            fullList.forEach(function (obj) {
                if (obj.ivrType && obj.ivrType.entity && obj.day < currentDay) {
                    tcc.previouIvrCalls[obj.ivrType.entity.name] = obj.ivrType.entity;
                }
            });
        }

        function toggleResponsesOpen() {
            tcc.responsesOpen = !tcc.responsesOpen;
        }
        function addNotAnswered(){
            tcc.questionsLst.forEach(function (question) {
                if (!question.responseParsed && question.responseTypeId === 1 && question.responses) {
                    question.responses = question.responses.indexOf('|') !== -1 ? question.responses.concat('| Not Answered') : question.responses.concat(',Not Answered');
                    question.responseParsed = true;
                }
            });
        }
        function getQuestions(callback) {
            tcc.questionsLst = [];
            tcc.selectedResponses = [];
            if (tcc.selectedIvr && tcc.selectedIvr.meta && tcc.selectedIvr.meta.questions) {
                tcc.questionsLst = tcc.selectedIvr.meta.questions;
                addNotAnswered();
                if (callback) {
                    callback();
                }
            } else if (tcc.selectedIvr) {
                updateSelectedIvr(tcc.selectedIvr)
                    .then(function (response) {
                        if (response.meta) {
                            tcc.selectedIvr.meta = response.meta;
                            tcc.questionsLst = tcc.selectedIvr.meta.questions;
                            addNotAnswered();
                            if (callback) {
                                callback();
                            }
                        }
                    });
            }
        }

        function updateResponses(response) {
            var idx = tcc.selectedResponses.indexOf(response);
            if (idx > -1) {
                tcc.selectedResponses.splice(idx, 1);
            } else {
                tcc.selectedResponses.push(response);
                tcc.selectedResponsesString = tcc.selectedResponses.toString().replace('[', '').replace(']', '');

            }
        }

        function saveCondition($hide, form) {
            tcc.setFormModified(form);
            tcc.condition.selectedIvr = tcc.selectedIvr;
            tcc.condition.selectedQuestion = tcc.selectedQuestion;

            if (tcc.selectedQuestion && tcc.selectedQuestion.responseTypeId === 3) {
                tcc.condition.textResponses = tcc.textResponses;
                tcc.condition.selectedOperator = tcc.selectedOperator;
                tcc.condition.deleteConditionIconFlag = false;
                programService.getProgram().anyDeleteConditionIconFlag = false;

            } else {
                tcc.condition.selectedResponses = tcc.selectedResponses;
                tcc.condition.deleteConditionIconFlag = false;
                programService.getProgram().anyDeleteConditionIconFlag = false;
            }

            fullList.forEach(function (obj) {
                if (obj.day === currentDay) {
                    if (obj.ivrType && obj.ivrType.entity.emmiCode === programEmmiCd) {
                        obj.ivrType.condition = tcc.condition;
                    } else if (obj.media && obj.media.length > 0) {
                        obj.media.forEach(function (mediaItem) {
                            if (mediaItem.entity.emmiCode === programEmmiCd) {
                                mediaItem.condition = tcc.condition;
                            }
                        });
                    }

                }
            });
            tcc.saveCallback({ 'fullList': fullList });
            cancel($hide);
        }

        function updateSelectedIvr(ivrProgram) {
            var config = {
                params: {
                    search: ivrProgram.name,
                    type: 'IVR CALL',
                    size: 10
                }
            }
            return ProgramVersionService.loadProgramByEmmiCode(ivrProgram.emmiCode)
        }

        function isValidCondition() {
            var valid = tcc;
            return !(valid.selectedIvr && valid.selectedQuestion
                && ((valid.selectedResponses && valid.selectedResponses.length > 0)
                    || (RegExp('\\d+').test(valid.textResponses) && valid.selectedOperator)));
        }

        function cancel($hide) {
            $hide();
            $('body').removeClass('modal-open');
        }
    }
})(window.angular);
