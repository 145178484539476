(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewPlaceholderController', NewPlaceholderController);

    NewPlaceholderController.$inject = ['NewPlaceholderService', '$scope'];

    function NewPlaceholderController(NewPlaceholderService, $scope) {
        var npc = this;

        npc.save = save;
        npc.newPlaceholder = NewPlaceholderService.newPlaceholder();

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewPlaceholderService.create(npc.newPlaceholder).then(function (response) {
                    $scope.$emit('new-placeholder', response);
                })
            }
        }
    }

})(window.angular);
