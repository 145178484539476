(function(angular) {
  'use strict';

  angular
    .module('Composer')
    .controller('ConvertFormEdGlobalModalController', ConvertFormEdGlobalModalController);

  ConvertFormEdGlobalModalController.$inject = ['$q', '$scope', 'AlertService', 'ProgramService', 'GlobalModulesService', '$timeout', '$window'];

  function ConvertFormEdGlobalModalController($q, $scope, alertService, programService, globalModulesService, $timeout, $window) {
    var cfg = this;
    cfg.isPatientNameAboveMin = false;
    cfg.isPatientNameBelowMax = true;
    cfg.isPatientNameValid = false;

    cfg.isModuleNameAboveMin = false;
    cfg.isModuleNameBelowMax = true;
    cfg.isModuleNameUnique = true;
    cfg.isModuleNameValid = false;
    cfg.moduleName = "";

    cfg.source = $scope.source;
    cfg.title = cfg.source === 'copy' ? $scope.selectedModule.globalModuleMetaDataParsed.module.moduleTitle || "" : $scope.selectedModule.moduleTitle || "";

    cfg.stageGlobalModule = stageGlobalModule;
    cfg.detachGlobalModule = detachGlobalModule;
    cfg.copyGlobalModule = copyGlobalModule;

    cfg.validatePatientName = validatePatientName;
    cfg.validateModuleName = validateModuleName;

    cfg.validatePatientName();


    function stageGlobalModule(candidate, cb) {
      var moduleName = cfg.moduleName;
      var title = cfg.title;

      return globalModulesService
          .validateModuleName(moduleName)
          .catch(function(errMsg) { 
              cfg.isModuleNameUnique = false;
              alertService.error(errMsg, "validate global module name");
              return $q.reject(errMsg);
          })
          .then(function(isUnique) {
              cfg.isModuleNameUnique = isUnique;

              if (!isUnique) {
                return $q.resolve();
              }
              var editionQuestionSet = programService.getProgram().entity.meta.formEditionMetaData.formParams.formQuestions;
              var moduleQuestionSet = globalModulesService.parseModuleQuestions(editionQuestionSet, candidate);

              return globalModulesService
                  .publishModule(candidate, moduleQuestionSet, moduleName, title)
                  .then(function() {
                      cb();
                      alertService.customSuccess("Successfully created global module " + moduleName + " however this form edition will not be subscribed to further changes until versioned");
                  });

          })
          .catch(function(errMsg) {
              alertService.error(errMsg, "creating global module");
          });
    }


      function copyGlobalModule(sourceModule, cb) {
          var moduleName = cfg.moduleName;
          var title = cfg.title;

          return globalModulesService
              .validateModuleName(moduleName)
              .catch(function(errMsg) {
                  cfg.isModuleNameUnique = false;
                  alertService.error(errMsg, "validate global module name");
                  return $q.reject(errMsg);
              })
              .then(function(isUnique) {
                  cfg.isModuleNameUnique = isUnique;

                  if (!isUnique) {
                      return $q.resolve();
                  }
                  var moduleQuestionSet = sourceModule.globalModuleMetaDataParsed.formQuestions;
                  var existingModule = sourceModule.globalModuleMetaDataParsed.module;
                  existingModule.moduleTitle = title;

                  return globalModulesService
                      .publishModule(existingModule, moduleQuestionSet, moduleName, title)
                      .then(function() {
                          cb();
                          alertService.customSuccess("Successfully created global module " + moduleName + ". The page will now refresh.");
                      });
              })
              .catch(function(errMsg) {
                  alertService.error(errMsg, "creating global module");
              }).finally(function(){
                      $timeout(function(){
                          $window.location.reload();
                      }, 5000);
              });
      }

    function validateModuleName() {
      var input = cfg.moduleName || "",
          inputMeetsMin,
          inputMeetsMax;

      cfg.isModuleNameAboveMin = inputMeetsMin = input.length > 0;

      cfg.isModuleNameBelowMax = inputMeetsMax = input.length < 50;

      cfg.isModuleNameValid =
        inputMeetsMin &&
        inputMeetsMax;
    }

    function validatePatientName() {
      var input = cfg.title|| "",
          inputMeetsMin,
          inputMeetsMax;

      cfg.isPatientNameAboveMin = inputMeetsMin = input.length > 0;

      cfg.isModuleNameBelowMax = inputMeetsMax = input.length < 50;

      cfg.isPatientNameValid =
        inputMeetsMin &&
        inputMeetsMax;
    }

    function detachGlobalModule(module, cb) {
      module.isGlobalModule = false;
      module.globalVersion = -1;
      cb();
    }
  }
})(window.angular);

