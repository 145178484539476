/**
 * Created by hzhang on 3/10/2017.
 */
(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewProductLineController', NewProductLineController);

    NewProductLineController.$inject = ['NewProductLineService', 'ProgramInitService', '$scope'];

    function NewProductLineController(NewProductLineService, programInitService, $scope) {
        var npc = this;

        npc.save = save;
        npc.newProductLine = NewProductLineService.newProductLine();
        npc.data = programInitService.getProductLines();

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewProductLineService.create(npc.newProductLine).then(function (response) {
                    $scope.$emit('new-product-line', response);
                    npc.data.productLine.content.push(response);
                })
            }
        }
    }

})(window.angular);

