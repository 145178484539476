(function(angular) {
    'use strict';

    angular
        .module('Composer')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('importer', {
                parent: 'app',
                url: '/importer',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/importer/pmt/pmt.import.html',
                        controller: 'PmtImportController',
                        controllerAs: 'pc'
                    }
                }
            });
    }
})(window.angular);
