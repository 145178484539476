(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('CarePlanSeriesPathController', CarePlanSeriesPathController);

    CarePlanSeriesPathController.$inject = ['ProgramService', '$state'];

    function CarePlanSeriesPathController(programService, $state) {
        var cpspc = this;
        cpspc.program = programService.getProgram;
        cpspc.apiStatus = "nullState";
        cpspc.links = [
            { linkName: 'Patient', active: true }
        ];
        cpspc.seriesList = [];
        cpspc.modalObject = {
            modalTitle: '',
            dayFlag: true,
            formFlag: false,
            mediaFlag: false,
            conditionFlag: false,
            buttonOne: '',
            buttonTwo: '',
            buttonThree: '',
            buttonCancel: '',
            buttonAddClass: '',
            buttonCancelClass: '',
            editFlag: false,
            list: [],
            editItem: {
                media: [],
                formPrograms: [],
                ivrPrograms: []
            },
            copyFlag: false,
            copyItem: '',
            editTransition: false,
        };
        cpspc.itemList = [];
        cpspc.changeLink = changeLink;
        cpspc.buildSeries = buildSeries;
        cpspc.modalList = modalList;
        cpspc.toggleList = toggleList;
        cpspc.editDayList = editDayList;
        cpspc.deleteDayList = deleteDayList;
        cpspc.copyDay = copyDay;
        cpspc.saveFinal = saveFinal;
        cpspc.findLinkName = findLinkName;
        cpspc.linkName = 'Patient';
        cpspc.findIndex = findIndex;
        cpspc.preparePostData = preparePostData;
        cpspc.prepareObj = prepareObj;
        cpspc.checkForPrecedingForm = checkForPrecedingForm;
        cpspc.finalObj = {
            daysToEnd: 0,
            components: []
        };
        cpspc.seriesObject = [];
        cpspc.reconstructObj = reconstructObj;
        cpspc.generateFormDay = generateFormDay;
        cpspc.removeDuplicate = removeDuplicate;
        cpspc.finalReconstructObj = finalReconstructObj;
        cpspc.isEditDisabled = isEditDisabled;
        cpspc.updateFullList = updateFullList;
        cpspc.deleteCondition = deleteCondition;
        cpspc.findRemovedForm = findRemovedForm;
        cpspc.checkPreviousFormCondition = checkPreviousFormCondition;
        cpspc.prepareCondition = prepareCondition;
        cpspc.getCurrentDay = getCurrentDay;
        cpspc.generateCondition = generateCondition;
        cpspc.generateResponse = generateResponse;
        cpspc.getpreviousList = getpreviousList;
        cpspc.searchForm = searchForm;
        cpspc.checkConditionWarning = checkConditionWarning;
        cpspc.setFormModified = setFormModified;
        cpspc.reconstructMedia = reconstructMedia;
        cpspc.updateOrder = updateOrder;
        cpspc.invalidOrder = invalidOrder;
        cpspc.showOrder = showOrder;

        (function onLoad() {
            cpspc.modalList();
            if (cpspc.program().entity.meta.components) {
                cpspc.reconstructObj(cpspc.program().entity.meta.components);
            }
        })();

        function isEditDisabled() {
            return programService.isEditDisabled();
        }

        function setFormModified(form) {
            form.$setDirty();
        }

        //reconstruct the api data to display on the front-end
        function reconstructObj(data) {
            var dayIndex;
            for (var i = 0; i < data.length; i++) {
                if (data[i] && data[i].condition.conjunction.AndCondition.personTypeCd === 1) {
                    if (cpspc.seriesObject.length === 0) {
                        if (generateFormDay(data[i], 1)) {
                            cpspc.seriesObject.push(generateFormDay(data[i], 1));
                        }
                    } else {
                        dayIndex = cpspc.findIndex(cpspc.seriesObject, 'day', data[i].condition.conjunction.AndCondition.daysSinceStart);
                        if (!dayIndex) {
                            if (generateFormDay(data[i], 1)) cpspc.seriesObject.push(generateFormDay(data[i], 1));
                        }
                    }
                }
            }
            for (i = 0; i < data.length; i++) {
                    if (data[i] && data[i].condition.conjunction.AndCondition.personTypeCd === 1) {
                        if (cpspc.seriesObject.length) {
                            cpspc.reconstructMedia(data[i], 'seriesObject', data[i].condition.programType);
                        }
                    }
            }
            angular.forEach(cpspc.seriesObject, function (res) {
                if (res.media.length) {
                    res.media = cpspc.removeDuplicate(res.media, JSON.stringify);
                }
                buildOrderSet(res);
            });
            cpspc.finalReconstructObj(cpspc.seriesObject, 1);
        }

        function buildOrderSet(obj) {
            var orderCounter = 1;
            obj.orderSet = [];
            if (obj.formPrograms) programListParse(obj.formPrograms);
            if (obj.media) programListParse(obj.media);

            function programListParse(programList) {
                for (var i = 0; i < programList.length; i++) {
                    if (!programList[i].entity.order){
                        programList[i].entity.order = 1;
                    }
                    obj.orderSet.push(orderCounter);
                    orderCounter++;
                }
            }
        }
        //reconstruct media based on series object
        function reconstructMedia(data, type, programType) {
            var ctrlType = cpspc[type];
            for (var i = 0; i < ctrlType.length; i++) {
                if (ctrlType[i].day === data.condition.conjunction.AndCondition.daysSinceStart) {
                    var obj = {
                        entity: {
                            name: data.condition.programName,
                            emmiCode: data.condition.emmiCode,
                            order: data.condition.order
                        },
                        select: true
                    };
                    if (data.condition.conjunction.AndCondition.OrCondition) {
                        obj['condition'] = cpspc.generateCondition(data.condition.conjunction.AndCondition);
                    }
                    if (programType === 'MULTIMEDIA PROGRAM') ctrlType[i].media.push(obj);
                    if (programType === 'FORM') ctrlType[i].formPrograms.push(obj);
                    if (programType === 'IVR CALL') ctrlType[i].ivrPrograms.push(obj);
                }
            }
        }

        //remove the duplicate media
        function removeDuplicate(a, key) {
            var index = [];
            return a.filter(function (item) {
                var k = key(item);
                return index.indexOf(k) >= 0 ? false : index.push(k);
            });
        }

        //to get previous day array to identify warning on edit program
        function getpreviousList(data, list) {
            var dataList = [];
            for (var i = 0; i < data.length; i++) {
                if (data[i].day < list.day) {
                    dataList.push(data[i]);
                }
            }
            return dataList;
        }

        //to get previous FORM to identify warning on edit program
        function searchForm(nameKey, myArray) {
            var match = false;
            for (var i = 0; i < myArray.length; i++) {

                if (myArray[i].formPrograms){
                    for (var j = 0; j < myArray[i].formPrograms.length; j++) {
                        if (myArray[i].formPrograms[j].entity.name === nameKey) match = true;
                    }
                }
                if (myArray[i].ivrPrograms){
                    for (var j = 0; j < myArray[i].ivrPrograms.length; j++) {
                        if (myArray[i].ivrPrograms[j].entity.name === nameKey) match = true;
                    }
                }


            }
            return match;
        }

        //to identify warning on condition when edit program
        function checkConditionWarning(list) {
            for (var i = 0; i < list.length; i++) {
                var previousData = cpspc.getpreviousList(list, list[i]);
                if (list[i].media) {
                    for (var j = 0; j < list[i].media.length; j++) {
                        addDeleteFlag(list[i].media[j]);
                    }
                }
                if (list[i].formPrograms) {
                    for (j = 0; j < list[i].formPrograms.length; j++) {
                        addDeleteFlag(list[i].formPrograms[j]);
                    }
                }
                if (list[i].ivrPrograms) {
                    for (j = 0; j < list[i].ivrPrograms.length; j++) {
                        addDeleteFlag(list[i].ivrPrograms[j]);
                    }
                }
            }
            return list;

            function addDeleteFlag(program) {
                if (program.condition) {
                    if (previousData.length > 0) {
                        var checkMedCondition = cpspc.searchForm(program.condition.selectedForm.name, previousData);
                        if (!checkMedCondition) {
                            program.condition.deleteConditionIconFlag = true;
                        }
                        else {
                            program.condition.deleteConditionIconFlag = false;
                        }
                    } else {
                        program.condition.deleteConditionIconFlag = true;
                    }
                }
            }
        }

        //conctruct the final objects
        function finalReconstructObj(list, type) {
            if (list) {
                list.sort(function (a, b) {
                    return parseFloat(a.day) - parseFloat(b.day);
                });
            }
            if (list.length > 1) {
                list[0].addFlag = true;
                list[0].linkIconFlag = true;
            }
            if (list.length === 1) list[0].linkIconFlag = true;
            list = cpspc.checkConditionWarning(list);
            checkForPrecedingForm(list);

                dataAssign('itemList', 'finalDayList', 'finalDayList', list);
        }

        //assign data based on type
        function dataAssign(item, final, met, list) {
            cpspc[item] = list;
            cpspc.modalObject.editTransition = true;
            cpspc.modalObject.list = list;
            cpspc[final] = list;
            cpspc.program().entity.meta[met] = list;
        }

        function generateCondition(list) {
            // *****************************************
            // Do we need a separate identifier here for if this is IVR or form?
            // *****************************************
            var obj = { selectedForm: {}, selectedQuestion: {}, selectedResponses: [] };
            if (list.OrCondition && list.OrCondition.patientResponses[0].operator) {
                obj.selectedForm['name'] = list.OrCondition.patientResponses[0].callName;
                obj.selectedForm['emmiCode'] = list.OrCondition.patientResponses[0].emmiCode;
                obj.selectedOperator = list.OrCondition.patientResponses[0].operator;
                obj.selectedQuestion['questionID'] = list.OrCondition.patientResponses[0].questionId;
                obj.textResponses = list.OrCondition.patientResponses[0].response;
                obj.selectedForm['meta'] = {};
            } else {
                obj.selectedForm['name'] = list.OrCondition.patientResponses[0].callName;
                obj.selectedForm['emmiCode'] = list.OrCondition.patientResponses[0].emmiCode;
                obj.selectedQuestion['questionID'] = list.OrCondition.patientResponses[0].questionId;
                obj.selectedResponses = cpspc.generateResponse(list.OrCondition.patientResponses);
                obj.selectedForm['meta'] = {};
            }
            return obj;
        }

        function generateResponse(response) {
            var res = [];
            for (var j = 0; j < response.length; j++) {
                res.push(response[j].response);
            }
            return res;
        }

        //contrct the day, form and initialise media
        function generateFormDay(list) {
            var obj = {};
            obj.day = list.condition.conjunction.AndCondition.daysSinceStart;
            obj.toggleFlag = true;
            obj.media = [];
            obj.formPrograms = [];
            obj.ivrPrograms = [];
            return obj;
        }

        //change the link
        function changeLink(arg) {
            cpspc.links.forEach(function (element) {
                element.active = element.linkName === arg.linkName;
                cpspc.linkName = arg.linkName;
            });
        }

        function modalList() {
            var linkName = cpspc.findLinkName();
            var list = cpspc.finalDayList;
            cpspc.dayErrorMesg = '';
            cpspc.modalObject = {
                modalTitle: 'Add Emmi Journey Day Number',
                dayFlag: true,
                formFlag: false,
                mediaFlag: false,
                conditionFlag: cpspc.modalObject.conditionFlag,
                buttonOne: 'ADD MEDIA',
                buttonTwo: 'ADD FORM',
                buttonThree: 'ADD IVR',
                buttonCancel: 'CANCEL',
                buttonAddClass: 'add-button',
                buttonCancelClass: 'remove-button',
                editFlag: false,
                list: list,
                editItem: {
                    media: [],
                    formPrograms: []
                },
                copyFlag: false,
                copyItem: ''
            };
        }

        //build the series
        function buildSeries() {
            cpspc.modalList();
            cpspc.program().seriesPathValidationMessage = null;
            cpspc.program().majorOrMinorVersion = false;
        }

        //edit day
        function editDayList(item) {
            cpspc.modalList();
            cpspc.modalObject.editItem = item;
            cpspc.editCopyItem = angular.copy(item);
            cpspc.modalObject.modalTitle = 'Edit Series Day Number';
            cpspc.modalObject.buttonOne = 'SAVE & EDIT FORM';
            cpspc.modalObject.buttonTwo = 'SAVE & EDIT MEDIA';
            cpspc.modalObject.buttonThree = 'SAVE & EDIT IVR';

            cpspc.modalObject.editFlag = true;
        }

        function toggleList(data) {
            data.toggleFlag = !data.toggleFlag;
        }

        function findLinkName() {
            var linkname = '';
            for (var i = 0; i < cpspc.links.length; i++) {
                if (cpspc.links[i].active) {
                    linkname = cpspc.links[i].linkName;
                }
            }
            return linkname;
        }

        //Saving Transition day to the series path day array
        function saveFinal(list, form) {
            cpspc.finalObj.components = [];
            cpspc.program().entity.meta.components = [];
            var item = list ? list[0] : [];
            list = cpspc.itemList;
            var orderCounter = 1;

            if (cpspc.modalObject.editFlag) {
                for (var i = 0; i < list.length; i++) {
                    if (list[i].day === cpspc.modalObject.editItem.day) {
                        list[i].day = item.day;
                        list[i].media = item.media;
                        list[i].formPrograms = item.formPrograms;
                        list[i].ivrPrograms = item.ivrPrograms;
                        buildOrderSet(list[i]);
                    }
                }
                if (cpspc.modalObject.editFlag) {
                    list = cpspc.checkConditionWarning(list);
                    checkForPrecedingForm(list);
                }
            } else {
                item = programService.setInitialDisplayOrder(item);
                list.push(item);
            }
            list.sort(function (a, b) {
                return parseFloat(a.day) - parseFloat(b.day);
            });
            for (i = 0; i < list.length; i++) {
                list[i].addFlag = false;
                list[i].linkIconFlag = false;
            }
            if (list.length > 1) {
                list[0].addFlag = true;
                list[0].linkIconFlag = true;
            }
            if (list.length === 1) list[0].linkIconFlag = true;
            cpspc.finalDayList = list;

            if (cpspc.finalDayList && cpspc.finalDayList.length) {
                var last = cpspc.finalDayList[cpspc.finalDayList.length - 1];
                cpspc.finalObj.daysToEnd = last.day;
                cpspc.preparePostData(cpspc.finalDayList, 1);
            }
            if (cpspc.finalDayList.length) {
                cpspc.setFormModified(form);
            }
            cpspc.program().entity.meta.components = cpspc.finalObj.components;
            cpspc.program().entity.meta.daysToEnd = cpspc.finalObj.daysToEnd;
        }

        // when we add condition that condition is being added to the saveFinal array
        function updateFullList(fullList, form) {
            cpspc.finalObj.components = [];
            cpspc.program().entity.meta.components = [];
            cpspc.finalDayList = fullList;
            if (cpspc.finalDayList && cpspc.finalDayList.length) {
                cpspc.preparePostData(cpspc.finalDayList, 1);
            }
            if (cpspc.finalDayList) cpspc.setFormModified(form);
            if (cpspc.finalDayList && cpspc.finalDayList.length) {
                var last = cpspc.finalDayList[cpspc.finalDayList.length - 1];
                cpspc.finalObj.daysToEnd = last.day;
            } else {
                cpspc.finalObj.daysToEnd = 0;
            }
            cpspc.program().entity.meta.components = cpspc.finalObj.components;
            cpspc.program().entity.meta.daysToEnd = cpspc.finalObj.daysToEnd;
        }

        function getCurrentDay(day) {
            cpspc.currentDay = day;
        }

        //prepare post object
        function preparePostData(list, type) {
            cpspc.program().anyDeleteConditionIconFlag = false;
            for (var i = 0; i < list.length; i++) {
                if (list[i].media) {
                    for (var j = 0; j < list[i].media.length; j++) {
                        if (list[i].media[j] && cpspc.prepareObj(list[i].day, list[i].media[j], 'media') !== '') {
                            cpspc.finalObj.components.push(cpspc.prepareObj(list[i].day, list[i].media[j], 'media', type));
                        }
                    }
                }
                if (list[i].formPrograms) {
                    for (j = 0; j < list[i].formPrograms.length; j++) {
                        if (list[i].formPrograms[j] && cpspc.prepareObj(list[i].day, list[i].formPrograms[j], 'formProgram') !== '') {
                            cpspc.finalObj.components.push(cpspc.prepareObj(list[i].day, list[i].formPrograms[j], 'formProgram', type));
                        }
                    }
                }
                if (list[i].ivrPrograms) {
                    for (j = 0; j < list[i].ivrPrograms.length; j++) {
                        if (list[i].ivrPrograms[j] && cpspc.prepareObj(list[i].day, list[i].ivrPrograms[j], 'ivrProgram') !== '') {
                            cpspc.finalObj.components.push(cpspc.prepareObj(list[i].day, list[i].ivrPrograms[j], 'ivrProgram', type));
                        }
                    }
                }
            }
            cpspc.program().entity.meta.finalDayList = list;
        }

        function prepareObj(day, data, type, link) {
            var object = {};
            var programType = null;
            if (type === 'form') programType = 'FORM';
            else if (type === 'media') programType = 'MULTIMEDIA PROGRAM';
            else if (type === 'formProgram') programType = 'FORM';
            else if (type === 'ivrProgram') programType = 'IVR CALL';
            if (programType && typeof (data) === 'object') {
                object = {
                    condition: {
                        conjunction: {
                            AndCondition: {
                                daysSinceStart: day,
                                personTypeCd: link,
                                OrCondition: cpspc.prepareCondition(data)
                            },
                        },
                        emmiCode: data.entity.emmiCode,
                        programName: data.entity.name,
                        programType: programType,
                        order: data.entity.order
                    }
                }
            }
            return object;
        }

        function prepareCondition(data) {
            var patientResponses = [];
            if (data.condition) {
                if (data.condition) {
                    if (data.condition.textResponses) {
                        patientResponses.push(
                            {
                                'callName': data.condition.selectedForm.name,
                                'emmiCode': data.condition.selectedForm.emmiCode,
                                'questionId': data.condition.selectedQuestion.questionID,
                                'operator': data.condition.selectedOperator,
                                'response': data.condition.textResponses
                            }
                        );
                    } else if (data.condition.selectedResponses && data.condition.selectedResponses.length) {
                        angular.forEach(data.condition.selectedResponses, function (res) {
                            patientResponses.push(
                                {
                                    'callName': data.condition.selectedForm.name,
                                    'emmiCode': data.condition.selectedForm.emmiCode,
                                    'questionId': data.condition.selectedQuestion.questionID,
                                    'response': res
                                }
                            );
                        });
                    }
                }
            }
            if (patientResponses.length) {
                return { patientResponses: patientResponses }
            } else {
                return undefined;
            }
        }

        // find the element
        function findIndex(array, column, val) {
            var obj;
            for (var i = 0; i < array.length; i++) {
                if (array[i][column] === val) {
                    obj = [i];
                }
            }
            return obj;
        }

        function checkConditionFlag(list, deletedForm) {
            if (list.media) {
                for (i = 0; i < list.media.length; i++) {
                    if (list.media[i].condition && list.media[i].condition.selectedForm.name === deletedForm) {
                        list.media[i].condition.deleteConditionIconFlag = true;
                    }
                }
            }
            if (list.formPrograms) {
                for (var i = 0; i < list.formPrograms.length; i++) {
                    if (list.formPrograms[i].condition && list.formPrograms[i].condition.selectedForm.name === deletedForm) {
                        list.formPrograms[i].condition.deleteConditionIconFlag = true;
                    }
                }
            }
            if (list.ivrPrograms) {
                for (i = 0; i < list.ivrPrograms.length; i++) {
                    if (list.ivrPrograms[i].condition && list.ivrPrograms[i].condition.selectedForm.name === deletedForm) {
                        list.ivrPrograms[i].condition.deleteConditionIconFlag = true;
                    }
                }
            }

        }

        function checkPreviousFormCondition(day, list, deletedForm) {
            for (var i = 0; i < list.length; i++) {
                checkConditionFlag(list[i], deletedForm);


                if (list[i].media) {
                    for (var j = 0; j < list[i].media.length; j++) {
                        if (list[i].media[j].condition && list[i].media[j].condition.selectedForm.name === deletedForm) {
                            list[i].media[j].condition.deleteConditionIconFlag = true;
                        }
                    }
                }
                if (list[i].formPrograms) {
                    for (j = 0; j < list[i].formPrograms.length; j++) {
                        if (list[i].formPrograms[j].condition && list[i].formPrograms[j].condition.selectedForm.name === deletedForm) {
                            list[i].formPrograms[j].condition.deleteConditionIconFlag = true;
                        }
                    }
                }
                if (list[i].ivrPrograms) {
                    for (j = 0; j < list[i].ivrPrograms.length; j++) {
                        if (list[i].ivrPrograms[j].condition && list[i].ivrPrograms[j].condition.selectedForm.name === deletedForm) {
                            list[i].ivrPrograms[j].condition.deleteConditionIconFlag = true;
                        }
                    }
                }
            }
            return list;
        }
// ****************************************************** STOPPED HERE
        // delete array
        function deleteDayList(list, type, arg, deleteList, form) {
            cpspc.program().entity.meta.components = [];
            cpspc.finalObj.components = [];
            var linkName = cpspc.findLinkName();
            var dayIndex = cpspc.findIndex(deleteList, 'day', list.day);
            var deletedForm = type === 'formProgram' || type === 'ivrProgram' ? angular.copy(arg.entity.name) : '';
            if (type === 'day') {
                if (dayIndex) {
                    deleteList.splice(dayIndex, 1);
                }
            } else {
                var index = -1;
                for (var i = 0; i < deleteList.length; i++){
                    if (deleteList[i].day === list.day) {
                        deleteList[i].addFlag = false;
                        deleteList[i].linkIconFlag = false;
                        var dayData = deleteList[i];
                        var itemsInDay = 0;
                        if (deleteList[i].media) itemsInDay = deleteList[i].media.length ? itemsInDay + deleteList[i].media.length : itemsInDay;
                        if (deleteList[i].formPrograms) itemsInDay = deleteList[i].formPrograms.length ? itemsInDay + deleteList[i].formPrograms.length : itemsInDay;
                        if (deleteList[i].ivrPrograms) itemsInDay = deleteList[i].ivrPrograms.length ? itemsInDay + deleteList[i].ivrPrograms.length : itemsInDay;
                        if (type === 'media') index = deleteList[i].media.indexOf(arg);
                        else if (type === 'ivrProgram') index = deleteList[i].ivrPrograms.indexOf(arg);
                        else if (type === 'formProgram') index = deleteList[i].formPrograms.indexOf(arg);
                        if (index > -1) {
                            if (type === 'media') dayData.media.splice(index, 1);
                            else if (type === 'ivrProgram') dayData.ivrPrograms.splice(index, 1);
                            else if (type === 'formProgram') dayData.formPrograms.splice(index, 1);
                            deleteDayIndex(dayData, dayIndex, deleteList, itemsInDay);
                            if (type !== 'ivrProgram') list.orderSet.splice(-1,1);
                        }
                    }
                }
            }
            deleteList.sort(function (a, b) {
                return parseFloat(a.day) - parseFloat(b.day);
            });
            if (deletedForm) {
                deleteList = cpspc.checkPreviousFormCondition(list.day, deleteList, deletedForm);
            }
            cpspc.checkForPrecedingForm(deleteList);
            if (deleteList.length > 1) {
                deleteList[0].addFlag = true;
                deleteList[0].linkIconFlag = true;
            }
            if (deleteList.length === 1) deleteList[0].linkIconFlag = true;
            cpspc.finalDayList = deleteList;

            if (cpspc.finalDayList && cpspc.finalDayList.length) {
                var last = cpspc.finalDayList[cpspc.finalDayList.length - 1];
                cpspc.finalObj.daysToEnd = last.day;
                cpspc.preparePostData(cpspc.finalDayList, 1);
            } else {
                cpspc.finalObj.daysToEnd = 0;
            }
            if (cpspc.finalDayList) cpspc.setFormModified(form);
            cpspc.program().entity.meta.components = cpspc.finalObj.components;
            cpspc.program().entity.meta.daysToEnd = cpspc.finalObj.daysToEnd;
        }

        // removes the day when the doesn't have no programs in it
        function deleteDayIndex(deleteDay, dayIndex, deleteList, itemsInDay) {
            var itemsToDelete = 0;
            if (deleteDay.media) itemsToDelete = deleteDay.media.length ? itemsToDelete + deleteDay.media.length : itemsToDelete;
            if (deleteDay.formPrograms) itemsToDelete = deleteDay.formPrograms.length ? itemsToDelete + deleteDay.formPrograms.length : itemsToDelete;
            if (deleteDay.ivrPrograms) itemsToDelete = deleteDay.ivrPrograms.length ? itemsToDelete + deleteDay.ivrPrograms.length : itemsToDelete;
                if (itemsInDay === itemsToDelete) {
                            deleteList.splice(dayIndex, 1);
                    }
        }

        function findRemovedForm(conditionName, list) {
            angular.forEach(list, function (res) {
                angular.forEach(res.media, function (media) {
                    if (media && media.condition && media.condition.selectedForm.name === conditionName) {
                        media.condition.deleteConditionIconFlag = true;
                    }
                });
            })
            return list;
        }

        function copyDay(list) {
            cpspc.modalList();
            cpspc.modalObject.modalTitle = 'Copy Series Day';
            cpspc.modalObject.copyItem = list;
            cpspc.modalObject.editFlag = false;
            cpspc.modalObject.copyFlag = true;
        }

        function deleteCondition(obj, list, form) {
            cpspc.program().entity.meta.components = [];
            cpspc.finalObj.components = [];
            var linkName = cpspc.findLinkName();
            delete obj['condition'];
                cpspc.finalDayList = list;
            if (cpspc.finalDayList && cpspc.finalDayList.length) {
                var last = cpspc.finalDayList[cpspc.finalDayList.length - 1];
                cpspc.finalObj.daysToEnd = last.day;
                cpspc.preparePostData(cpspc.finalDayList, 1);
            }
            if (cpspc.finalDayList && cpspc.finalDayList.length) cpspc.setFormModified(form);
            cpspc.program().entity.meta.components = cpspc.finalObj.components;
            cpspc.program().entity.meta.daysToEnd = cpspc.finalObj.daysToEnd;
        }

        function checkForPrecedingForm(list) {
            var formCounter = 0;
            angular.forEach(list, function (res, $index) {
                var thisDay = res.day;
                angular.forEach(list, function (resp) {
                    if (thisDay > resp.day && (resp.formPrograms)) {
                        formCounter++;
                    }
                });
                if (formCounter === 0) {
                    list[$index].linkIconFlag = true;
                }
            });
        }

        function updateOrder(element, list){
            var totalItems = (list.formPrograms.length) + (list.media.length);
            if (element.order){
                if (element.order >= 1){
                    element.order = element.order > totalItems ? totalItems : element.order;
                } else element.order = 1;
            }

            cpspc.program().entity.meta.components = [];
            cpspc.finalObj.components = [];
            cpspc.preparePostData(cpspc.finalDayList, 1);
            cpspc.program().entity.meta.components = cpspc.finalObj.components;


        }

        function invalidOrder(list){
            var valArr = [];
            var invalid = false;
            if (list){

                angular.forEach(list.media, function(res){
                    if (res.entity.order){
                        if (valArr.indexOf(res.entity.order) !== -1) { // Are there dupes
                            invalid = true;
                        }
                        valArr.push(res.entity.order);
                        if (res.entity.order > list.orderSet.length) invalid = true; // Any values higher than the total number of programs
                    } else { // Order doesn't exist
                        invalid = true;
                    }
                });
                angular.forEach(list.formPrograms, function(res){
                    if (res.entity.order){
                        if (valArr.indexOf(res.entity.order) !== -1) { // Are there dupes
                            invalid = true;
                        }
                        valArr.push(res.entity.order);
                        if (res.entity.order > list.orderSet.length) invalid = true; // Any values higher than the total number of programs
                    } else { // Order doesn't exist
                        invalid = true;
                    }
                });
                    programService.setCPOrderValid(list.day, invalid);

            }

            return invalid;
        }
        function showOrder(list){
            if(!list) return false;
            if((list.media && list.media.length>0) || (list.formPrograms && list.formPrograms.length>0)) return true;
            return false;
        }




    }
})(window.angular);
