(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('ngEnter', ngEnter);

    function ngEnter() {
        return {
            link: link
        };

        function link(scope, element, attrs) {
            element.bind("keydown keypress", function(event) {
                if(event.which === 13) {
                    scope.$apply(function(){
                        scope.$eval(attrs.ngEnter, {'event': event});
                    });

                    event.preventDefault();
                }
            });
        }
    }
})(window.angular);
