(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('ProgramWarningService', ProgramWarningService);

    ProgramWarningService.$inject = ['ProgramService'];

    function ProgramWarningService(programService) {

        return {
            getWarningMessages : getWarningMessages
        };

        function getWarningMessages(nextProgramVersion, nextProgramMajor) {
            var messages = [];
            var videoSecMsgs = [];
            var videoCCMsgs = [];

            var prevEdMajor = -1;
            var eToEmsg = '';
            var message = '';
            var moreEditions = false;
            var edversions = '';
            var printSummaries = '';
            var formOrIvr = false;

            var entity = programService.getProgram().entity;
            if (entity && entity.meta && entity.meta.editions) {
                var editions = entity.meta.editions;
                editions.forEach(function (edition) {
                    var assetSetPath = edition.assetSet.assetSetPath.toUpperCase();
                    var version = edition.version;
                    if (version) {
                        var major = version.major;
                        if(prevEdMajor >= 0) {
                            if(prevEdMajor != major) {
                                eToEmsg = 'Warning: Major edition versions do not match.';
                            }
                        }
                        prevEdMajor = major;

                        if (nextProgramMajor > -1 && major != nextProgramMajor) {
                            if (edversions.length > 0) {
                                edversions += '; ';
                                moreEditions = true;
                            }
                            edversions += assetSetPath + ', (' + version.major + ')';
                        }
                    }
                    var chapters = edition.meta;
                    var sections = edition.programSections;
                    var videoSecs = '';
                    var videoCCs = '';
                    if(! sections || sections.length == 0) {
                        if(chapters && chapters.length > 0) {
                            videoSecs = 'no section';
                        }
                    }
                    else {
                        sections.forEach(function (section) {
                            var secNumber = section.emmiSctnNmbr;
                            if(! section.sectionUrls || section.sectionUrls.length == 0) {
                                if(videoSecs.length > 0) {
                                    videoSecs += ', ';
                                }
                                videoSecs += secNumber;
                            }
                            else {
                                if(section.sectionUrls.length < 2 || ! section.sectionUrls[1]) {
                                    if(videoCCs.length > 0) {
                                        videoCCs += ', ';
                                    }
                                    videoCCs += secNumber;
                                }
                            }
                        });
                    }

                    if(videoSecs.length > 0) {
                        var msg = "Warning: Asset Set " + assetSetPath + " is missing video section(s): " + videoSecs;
                        videoSecMsgs.push(msg);
                    }
                    if(videoCCs.length > 0) {
                        var msg = "Warning: Asset Set " + assetSetPath + " is missing CC File(s): " + videoCCs;
                        videoCCMsgs.push(msg);
                    }
                    if(edition.printSummaryValidation === false) {
                        if(printSummaries.length > 0) {
                            printSummaries += ', ';
                        }
                        printSummaries += assetSetPath;
                    }
                    if (edition.assetSet.assetSetPath === 'p0' || edition.assetSet.assetSetPath === 'p1') {
                        formOrIvr = true;
                    }
                });
            }

            if(eToEmsg.length > 0) {
                messages.push(eToEmsg);
            }

            if (edversions.length > 0) {
                message = "Warning: the major version number for asset set ";
                if(moreEditions) {
                    message = "Warning: the major version numbers for asset sets ";
                }
                message += edversions + " do not match the major version number (" + nextProgramMajor +
                    ") you are trying to create. ";

                messages.push(message);
            }

            if(videoSecMsgs.length > 0) {
                messages = messages.concat(videoSecMsgs);
            }
            if(videoCCMsgs.length > 0) {
                messages = messages.concat(videoCCMsgs);
            }

            if(printSummaries.length > 0 && !formOrIvr) {
                message = "Warning: The following asset set(s) are missing print summaries: " + printSummaries + ".";
                messages.push(message);
            }

            return messages;
        }
    }
})(window.angular);
