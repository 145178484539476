(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('Principal', Principal);

    Principal.$inject = ['$q', 'Account', '$window', '$state'];

    function Principal($q, Account, $window, $state) {
        var _identity,
            _authenticated = false;

        return {
            authenticate: authenticate,
            hasAnyAuthority: hasAnyAuthority,
            hasAuthority: hasAuthority,
            identity: identity,
            isAuthenticated: isAuthenticated,
            isIdentityResolved: isIdentityResolved
        };

        function authenticate(identity) {
            _identity = identity;
            _authenticated = identity !== null;
        }

        function hasAnyAuthority(authorities) {
            if (!_authenticated || !_identity || !_identity.entity || !_identity.entity.authorities) {
                return false;
            }

            for (var i = 0; i < authorities.length; i++) {
                for (var j = 0; j < _identity.entity.authorities.length; j++) {
                    if (_identity.entity.authorities[j].authority.indexOf(authorities[i]) !== -1) {
                        return true;
                    }
                }
            }

            return false;
        }

        function hasAuthority(authority) {
            if (!_authenticated) {
                return $q.when(false);
            }

            return this.identity().then(function (_id) {
                return _id.entity && _id.entity.authorities && _id.entity.authorities.indexOf(authority) !== -1;
            }, function () {
                return false;
            });
        }

        function identity(force) {

            var deferred = $q.defer();

            if (force === true) {
                _identity = undefined;
            }

            // check and see if we have retrieved the identity data from the server.
            // if we have, reuse it by immediately resolving
            if (angular.isDefined(_identity)) {
                deferred.resolve(_identity);

                return deferred.promise;
            }

            // retrieve the identity data from the server, update the identity object, and then resolve.
            Account.get().then(getAccountThen).catch(getAccountCatch);

            return deferred.promise;

            function getAccountThen(account) {
                _identity = account;
                _authenticated = true;
                deferred.resolve(_identity);
            }

            function getAccountCatch(err) {
                if (err && err.data && err.data.url) {
                    $window.location.href = err.data.url;
                } else {
                    $state.go('error');
                }

            }
        }

        function isAuthenticated() {
            return _authenticated;
        }

        function isIdentityResolved() {
            return angular.isDefined(_identity);
        }
    }
})(window.angular);
