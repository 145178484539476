(function (angular) {
    "use strict";

    angular
        .module("Composer")
        .controller("ArticleController", ArticleController);

    ArticleController.$inject = [
        "ProgramService",
        "ProgramSummaryService",
        "UtilService",
        "$state",
    ];

    function ArticleController(
        programService,
        programSummaryService,
        utilService,
        $state
    ) {
        var artc = this;
        artc.confirm = {};
        artc.collapsed = false;
        artc.program = programService.getProgram;
        artc.data = programSummaryService.getProgramData();
        //create versions
        artc.formatVersion = utilService.formatVersion;
        //set edit to disabled if no permissions or during loading process
        artc.isEditDisabled = isEditDisabled;
        //save form
        artc.cancel = cancel;
        artc.cancelButtonText = cancelButtonText;
        //check if program meta has properties and values
        artc.containsValue = containsValue;
        artc.hasValue = hasValue;
        artc.removeAt = utilService.removeAt;

        function containsValue(prop) {
            var entity = artc.program().entity;
            var containsValue = false;
            if (entity && entity.meta && prop in entity.meta) {
                var property = entity.meta[prop];
                if (property) {
                    containsValue = property.length > 0;
                }
            }
            return containsValue;
        }

        function isEditDisabled() {
            return true;
        }
        function hasValue(prop) {
            var entity = artc.program().entity;
            if (entity && entity.meta && prop in entity.meta) {
                var obj = entity.meta[prop];
                return obj && "id" in obj;
            }
            return false;
        }

        function cancel() {
            var entity = artc.program().entity;
            var emmiCode = entity.emmiCode;
            var versioned = entity.versionedToLocal || entity.version;
            utilService.navigate(emmiCode, versioned, $state);
        }

        function cancelButtonText() {
            var entity = artc.program().entity;
            var emmiCode = entity.emmiCode;
            var text = "Return to Composer Home";
            if (emmiCode) {
                if (entity.version || entity.versionedToLocal) {
                    text = "Return to Version List Page";
                }
            }
            return text;
        }
    }
})(window.angular);
