(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('RadioSelectController', RadioSelectController);

    RadioSelectController.$inject = ['UtilService', 'AlertService', 'fullList', 'selectedItem', 'title', 'programSelection',
        'searchCallback', 'saveCallback', 'enableSearch'];

    function RadioSelectController(utilService, alertService, fullList, selectedItem, title, programSelection,
                                   searchCallback, saveCallback, enableSearch) {
        var sic = this;
        sic.editSelectedItem = {};
        sic.fullList = fullList;
        sic.selectedItem = selectedItem;
        sic.title = title;
        sic.programSelection = programSelection;
        sic.searchCallback = searchCallback;
        sic.saveCallback = saveCallback;
        sic.enableSearch = enableSearch;

        sic.nextPage = nextPage;
        sic.hasVisible = hasVisibleData;
        sic.save = save;
        sic.cancel = cancel;

        var config = {
            params: {
                active: true
            }
        };

        sic.searchEntry = newSearchEntry();
        sic.search = search;

        (function init() {
            if(!selectedItem) return;
            angular.forEach(sic.fullList.content, function (item) {
                if (item.entity.id == selectedItem.id) {
                    sic.editSelectedItem = item;
                }
            });
        })();

        function nextPage(link) {
            if (sic.fullList && link === sic.fullList.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                sic.loadingNextPage = true;
                utilService.fetchPageWithConfig(link, config)
                    .then(ok)
                    .catch(function(response) {
                        alertService.error(response, "Loading next page");
                    })
                    .finally(function () {
                        delete sic.loadingNextPage;
                    })
            }

            function ok(response) {
                if (response) {
                    sic.fullList = response;
                }
            }
        }

        function hasVisibleData() {
            var hasVisible = false,
                length = sic.fullList && sic.fullList.content &&
                    sic.fullList.content.length;

            for (var i = 0; i < length; i++) {
                if (!sic.fullList.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                $('body').removeClass('modal-open');

                    var obj = sic.editSelectedItem.entity;
                    for(var k in obj) {
                        sic.selectedItem[k]=obj[k];
                    }
                    if(sic.saveCallback) {
                        sic.saveCallback();
                    }
                }
        }

        function search(form) {
            if(form) {

                sic.loading = true;
                sic.searchCallback()(sic.searchEntry)
                    .then(function (response) {
                        sic.fullList = response;
                    })
                    .catch(function(response) {
                        alertService.error(response.message, "Search");
                    })
                    .finally(function () {
                        delete sic.loading;
                    });
            }
        }

        function cancel($hide) {
            $hide();
            $('body').removeClass('modal-open');
            if(sic.searchCallback()) {
                sic.searchEntry = "";
                sic.searchCallback()(sic.searchEntry)
                    .then(function (response) {
                        sic.fullList = response;
                    });
            }
        }

        function newSearchEntry() {
            return "";
        }
    }
})(window.angular);
