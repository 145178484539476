/**
 * Created by hzhang on 3/10/2017.
 */
(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewProductLineService', NewProductLineService);

    NewProductLineService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewProductLineService($http, Principal, UriTemplate) {

        return {
            create: create,
            newProductLine: newProductLine
        };

        function create(newProductLine) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.productlines).stringify(),
                    newProductLine).then(function (response) {
                    return response.data;
                });
            })
        }

        function newProductLine() {
            return {
                name: ''
            }
        }

    }
})(window.angular);
