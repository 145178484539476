(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditionsService', EditionsService);

    EditionsService.$inject = ['$http', 'Principal', 'UriTemplate', 'PaginationService', 'UtilService', 'AlertService', 'ProgramService'];

    function EditionsService($http, Principal, UriTemplate, paginationService, utilService, alertService, programService) {

        var es = this;
        es.editions = {};
        es.selectedEdition = {};
        es.editionVersions = {};
        es.selectedVersions = {};
        es.oldSelectedEdition = {};
        es.assetSet = {};
        es.oldAssetSet = {};
        es.loadingPreview = false;
        es.showingForm = '';

        return {
            getEditionsByAssetSetId: getEditionsByAssetSetId,
            getSelectedEdition: getSelectedEdition,
            setSelectedEdition: setSelectedEdition,
            toggleChapterList: toggleChapterList,
            getMetaForChapters: getMetaForChapters,
            hasVisibleEditions: hasVisibleEditions,
            hasEditions: hasEditions,
            addEditions: addEditions,
            getPreviewStatus: getPreviewStatus,
            nextPage: nextPage,
            getEditions: getEditions,
            getAssetSets: getAssetSets,
            getPreviewData: getPreviewData,
            getSelectedVersion: getSelectedVersion,
            setSelectedVersion: setSelectedVersion,
            addEditionVersions: addEditionVersions,
            clearEditionVersions: clearEditionVersions,
            getEditionVersions: getEditionVersions,
            nextVersionPage: nextVersionPage,
            hasVisibleVersions: hasVisibleVersions,
            getEditionVersionsById: getEditionVersionsById,
            removeVideoSections: removeVideoSections,
            removeSelectedEditionVideoSections: removeSelectedEditionVideoSections,
            getOldSelectedEdition: getOldSelectedEdition,
            copySelectedEdition: copySelectedEdition,
            resetEditions: resetEditions,

            getAssetSet: getAssetSet,
            setAssetSet: setAssetSet,
            getOldAssetSet: getOldAssetSet,
            copyAssetSet: copyAssetSet,

            getPrintSummary: getPrintSummary,
            setPrintSummary: setPrintSummary,
            hasPrintSummary: hasPrintSummary,
            getEmmiCodeByEditionId: getEmmiCodeByEditionId
        };

        function removeVideoSections() {
            var entity = programService.getProgram().entity;
            if (entity && entity.meta) {
                var editions = entity.meta.editions;
                if (editions && editions.length > 0) {
                    editions.forEach(function (edition, index, array) {
                        resetVideoSectionAndPrintSummary(edition);
                    });
                }
            }
        }

        function removeSelectedEditionVideoSections() {
            resetVideoSectionAndPrintSummary(es.selectedEdition);
        }

        function resetVideoSectionAndPrintSummary(edition) {
            edition.programSections = [];
            setPrintSummary(edition);
        }

        function setPrintSummary(edition) {
            var program = programService.getProgram().entity;
            var programFolderName = undefined;
            var assetSetPath = undefined;
            if(hasProgramFolderName(program)) {
                programFolderName = program.meta.programFolderName;
            }

            var assetset = edition.assetSet;
            if(assetset && assetset.assetSetPath) {
                assetSetPath = assetset.assetSetPath;
            }

            if(programFolderName && assetSetPath) {
                getPrintSummary(assetSetPath, programFolderName)
                    .then(function (response) {
                        edition.printSummaryLink = response.printSummaryLink;
                        edition.printSummaryValidation = response.linkValidation;
                    });
            }
        }

        function hasPrintSummary() {
            var program = programService.getProgram().entity;
            return hasProgramFolderName(program);
        }

        function hasProgramFolderName(program) {
            return program && program.meta && program.meta.programFolderName;
        }

        function getEditions() {
            return es.editions;
        }

        function getEditionVersions() {
            return es.editionVersions;
        }

        function hasEditions() {
            var entity = programService.getProgram().entity;
            if (entity && entity.meta) {
                var editions = entity.meta.editions;
                if (editions && editions.length > 0) {
                    return true;
                }
            }
            return false;
        }

        function getAssetSets(program) {
            var editions = [];
            if (hasEditions(program)) {
                var entity = program.entity;
                angular.forEach(entity.meta.editions, function (edition) {
                    var version = edition.version;
                    editions.push(edition.assetSet.dialect.description +
                        ' ' + version.major + '.' + version.minor + '.' + version.working);
                });
            }
            return editions.join(', ');
        }

        function clearEditions() {
            for (var prop in es.editions) {
                if (es.editions.hasOwnProperty(prop)) {
                    delete es.editions[prop];
                }
            }
        }

        function clearEditionVersions() {
            for (var prop in es.editionVersions) {
                if (es.editionVersions.hasOwnProperty(prop)) {
                    delete es.editionVersions[prop];
                }
            }
        }

        function hasEditions() {
            var entity = programService.getProgram().entity;
            if (entity && entity.meta) {
                var editions = entity.meta.editions;
                if (editions && editions.length > 0) {
                    return true;
                }
            }
            return false;
        }

        function nextPage(link, editionName, type) {
            if (es.editions && link === es.editions.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                var program = programService.getProgram().entity;
                var config = {
                    params: {
                        emmiCode: program.emmiCode || -1,
                        assetSetId: es.selectedEdition.assetSet.id,
                        name: editionName,
                        size: 10,
                        type: type === 'FORM' ? 'FORM' : ''
                    }
                };
                es.loadingNextPage = true;
                utilService.fetchPageWithConfig(link, config)
                    .then(function (response) {
                        ok(response);
                    })
                    .catch(function (response) {
                        alertService.error(response, 'get editions');
                    })
                    .finally(function () {
                        delete pc.loadingEditions;
                    });
            }

            function ok(response) {
                clearEditions();
                if (response) {
                    es.loadingNextPage = false;
                    addEditions(response);
                } else {
                    es.loadingNextPage = true;
                }
            }
        }

        function nextVersionPage(link) {
            if (es.editionVersions && link === es.editionVersions.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                var config = {
                    params: {
                        editionId: es.selectedEdition.editionId
                    }
                };
                es.loadingNextVersionPage = true;
                utilService.fetchPageWithConfig(link, config)
                    .then(function (response) {
                        ok(response);
                    })
                    .catch(function (response) {
                        alertService.error(response, 'get edition versions');
                    })
                    .finally(function () {
                        delete pc.loadingEditions;
                    });
            }

            function ok(response) {
                clearEditionVersions();
                if (response) {
                    es.loadingNextVersionPage = false;
                    addEditionVersions(response);
                } else {
                    es.loadingNextVersionPage = true;
                }
            }
        }

        function hasVisibleEditions() {
            var hasVisible = false,
                length = es.editions && es.editions.content &&
                    es.editions.content.length;
            for (var i = 0; i < length; i++) {
                if (!es.editions.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function hasVisibleVersions() {
            var hasVisible = false,
                length = es.editionVersions && es.editionVersions.content &&
                    es.editionVersions.content.length;

            for (var i = 0; i < length; i++) {
                if (!es.editionVersions.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function resetEditions() {
            es.editions = {};
        }

        function addEditions(response) {
            for (var k in response) {
                es.editions[k] = response[k];
            }
            es.editions.content.forEach(function (edition, index, array) {
                var entity = edition.entity;
                entity.formattedVersion = utilService.formatVersion(entity);
            });
        }

        function addEditionVersions(response) {
            for (var k in response) {
                es.editionVersions[k] = response[k];
            }
            es.editionVersions.content.forEach(function (editionVersion, index, array) {
                var entity = editionVersion.entity;
                entity.formattedVersion = utilService.formatVersion(entity);

            });
        }

        function getSelectedEdition() {
            return es.selectedEdition;
        }

        function setSelectedEdition(selectedEdition) {
            es.selectedEdition = selectedEdition;
        }

        function getOldSelectedEdition() {
            return angular.copy(es.oldSelectedEdition);
        }

        function copySelectedEdition(selectedEdition) {
            es.oldSelectedEdition = angular.copy(selectedEdition);
        }

        function getSelectedVersion() {
            return es.selectedVersion;
        }

        function setSelectedVersion(selectedVersion) {
            es.selectedVersion = selectedVersion;
        }

        function getAssetSet() {
            return angular.copy(es.assetSet);
        }

        function setAssetSet(assetset) {
            if(assetset) {
                copyAssetSet(es.assetSet);
                es.assetSet = angular.copy(assetset);
            }
        }

        function getOldAssetSet() {
            return angular.copy(es.oldAssetSet);
        }

        function copyAssetSet(assetSet) {
            es.oldAssetSet = angular.copy(assetSet);
        }

        function getEditionsByAssetSetId(editionName, emmiCode, type) {
            var selectedEdition = es.selectedEdition;
            var assetSetId = -1;
            if (selectedEdition && selectedEdition.assetSet) {
                assetSetId = selectedEdition.assetSet.id;
            }

            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.editions  + '/publishable').stringify(), {
                        params: {
                            assetSetId: assetSetId,
                            name: editionName,
                            emmiCode: emmiCode,
                            sort: 'name,asc',
                            size: 10,
                            type: type === 'FORM' ? 'FORM' : null
                        }
                    })
                    .then(function (response) {
                        var parsedResponse = paginationService.handleResponse(response.data);
                        if (!parsedResponse.content) {
                            parsedResponse.content = [];
                        }
                        return parsedResponse;
                    });
            })
        }

        function getMetaForChapters(edition) {
            if (edition) {
                if (edition.meta) {
                    return edition.meta;
                }

                if (edition.version && edition.version.meta) {
                    edition.meta = angular.fromJson(edition.version.meta);
                    return edition.meta;
                }
            }
        }

        function toggleChapterList(edition) {
            if (edition) {
                if (edition.hasOwnProperty("expandedChapter")) {
                    edition.expandedChapter = !edition.expandedChapter;
                } else {
                    edition.expandedChapter = true;
                }
            } else {
                edition = {};
                edition.expandedChapter = true;
            }
        }

        function getEditionVersionsById(editionId) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.editions + '/publishable/{editionId}').stringify({
                    editionId: editionId
                })).then(function (response) {
                    var parsedResponse = paginationService.handleResponse(response.data);
                    if (!parsedResponse.content) {
                        parsedResponse.content = [];
                    }
                    addEditionVersions(parsedResponse);
                    return parsedResponse;
                });
            })
        }

        function getPrintSummary(assetSetPath, fileName) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.editions + '/printsummary/{assetSetPath}/{fileName}').stringify({
                    assetSetPath: assetSetPath,
                    fileName: fileName
                })).then(function (response) {
                    return response.data;
                });
            });
        }

        function getPreviewData(editionId, version, language) {
            return Principal.identity().then(function (user) {
                es.loadingPreview = true;
                return $http.post(UriTemplate.create(user.link.publish + '/edition/preview/{editionId}/{version}').stringify({
                    editionId: editionId,
                    version:version
                })).then(function (response) {
                    console.log('Redirecting Preview to new window at URL: ' + 'https://' + response.data.hostName + '/startemmi2/forms/' + response.data.source + '/' + response.data.viewId + '/' + response.data.emmiCd + '/' + language);
                    window.open ('https://' + response.data.hostName + '/startemmi2/forms/' + response.data.source + '/' + response.data.viewId + '/' + response.data.emmiCd + '/' + language);
                    return response.data;
                }).catch(function (response) {
                    alertService.error(response, 'Load Preview.');
                }).finally(function () {
                        es.loadingPreview = false;
                    });
            });
        }

        function getPreviewStatus() {
            return es.loadingPreview;
        }

        function getEmmiCodeByEditionId(edition) {
            if(es.loadingEmmiCodeByEditionId) return;
            es.loadingEmmiCodeByEditionId = true;
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.editions + '/emmiCode/' + edition.editionId ).stringify())
                    .then(function (response) {
                        edition.emmiCode = response.data;
                    }).catch(function (response) {
                        console.log("Unable to get emmiCode for edition with id "+edition.editionId+ " due to "+response.message);
                    }).finally(function () {
                        es.loadingEmmiCodeByEditionId = false;
                    });
            })
        }
    }
})(window.angular);
