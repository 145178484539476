(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewAssetSetController', NewAssetSetController);

    NewAssetSetController.$inject = ['NewAssetSetService', 'ProgramInitService', '$scope', '$rootScope'];

    function NewAssetSetController(NewAssetSetService, ProgramInitService, $scope, $rootScope) {
        var nbc = this;

        nbc.save = save;
        nbc.newAssetSet = NewAssetSetService.newAssetSet();
        nbc.data = ProgramInitService.getAssetSets(true, true);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewAssetSetService.create(nbc.newAssetSet).then(function (response) {
                    $scope.$emit('new-assetset', response);
                    nbc.data.assetSets.content.push(response);
                });

                $rootScope.$broadcast('updateList');
            }
        }
    }

})(window.angular);
