(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('DeleteService', DeleteService);

    DeleteService.$inject = ['$http', '$q', 'LinkConverter'];

    function DeleteService($http, $q, LinkConverter) {

        return {
            remove: remove
        };

        function remove(resource) {
            LinkConverter.convertResource(resource);
            if (resource && resource.link.self) {
                return $http.delete(resource.link.self);
            }
            return $q.reject('not a persistent object');
        }


    }
})(window.angular);
