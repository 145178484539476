(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditLanguageService', EditLanguageService);

    EditLanguageService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditLanguageService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(languageService) {
            LinkConverter.convertResource(languageService);
            if (languageService && languageService.link.self) {
                return $http.put(languageService.link.self, languageService.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
