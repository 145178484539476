(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewContentSubscriptionController', NewContentSubscriptionController);

    NewContentSubscriptionController.$inject = ['ProgramInitService', 'NewContentSubscriptionService', '$scope'];

    function NewContentSubscriptionController(programInitService, NewContentSubscriptionService, $scope) {
        var npc = this;

        npc.save = save;
        npc.newContentSubscription = NewContentSubscriptionService.newContentSubscription();
        npc.data = programInitService.getContentSubscriptions(true, true);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewContentSubscriptionService.create(npc.newContentSubscription).then(function (response) {
                    $scope.$emit('new-content-subscription', response);
                    npc.data.contentSubscription.content.push(response);
                })
            }
        }
    }

})(window.angular);
