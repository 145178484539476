(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditPlaceholderService', EditPlaceholderService);

    EditPlaceholderService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditPlaceholderService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(placeholderResource) {
            LinkConverter.convertResource(placeholderResource);
            if (placeholderResource && placeholderResource.link.self) {
                return $http.put(placeholderResource.link.self, placeholderResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
