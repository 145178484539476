(function(angular) {
  'use strict';

  angular
    .module('Composer')
    .directive('convertFormEdGlobalModal', convertFormEdGlobalModal);

  convertFormEdGlobalModal.$inject = [ '$modal' ];

  function convertFormEdGlobalModal($modal) {
    return {
      controller: 'ConvertFormEdGlobalModalController',
      controllerAs: "ctrl",
      link: link,
      restrict: 'A',
      scope: {
        selectedModule: "=",
        source: '=',
      }
    };

    function link(scope, element, attrs, ctrl) {
      var modal;

      element.on("click", function(event) {
        setTimeout(function() {
          modal = $modal({
            backdrop: 'static',
            controller: 'ConvertFormEdGlobalModalController',
            controllerAs: 'ctrl',
            keyboard: true,
            scope: scope,
            templateUrl: 'app/program/create/form-edition/convert-global-modal/convert-global.html',
          })
        })
      })
    }
  }
})(window.angular);
