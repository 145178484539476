(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('AssetSetCountryController', AssetSetCountryController);

    AssetSetCountryController.$inject = ['AssetSetCountryService'];

    function AssetSetCountryController(AssetSetCountryService) {
        var AssetSetCountry = this;
        AssetSetCountry.assetsetcountries = [];

        (function onControllerLoad() {
            AssetSetCountry.nextPage = nextPage;
            AssetSetCountry.hasVisible = anyVisibleAssetSetCountry;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                AssetSetCountry.loadingNextPage = true;
                AssetSetCountryService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    AssetSetCountry.loadingNextPage = false;
                } else {
                    AssetSetCountry.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                AssetSetCountry.loadingNextPage = true;
            }
        }

        function anyVisibleAssetSetCountry() {
            var hasVisible = false,
                length = AssetSetCountry.assetsetcountries && AssetSetCountry.assetsetcountries.content &&
                    AssetSetCountry.assetsetcountries.content.length;
            for (var i = 0; i < length; i++) {
                if (!AssetSetCountry.assetsetcountries.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            AssetSetCountry.loading = true;
            AssetSetCountryService.search()
                .then(function (response) {
                    AssetSetCountry.assetsetcountries = response;
                    //AssetSetCountry.assetsetcountries = JSON.stringify(response);
                })
                .finally(function () {
                    delete AssetSetCountry.loadingNextPage;
                    delete AssetSetCountry.loading;
                });
        }

    }
})(window.angular);
