(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewProgramTypeService', NewProgramTypeService);

    NewProgramTypeService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewProgramTypeService($http, Principal, UriTemplate) {

        return {
            create: create,
            newProgramType: newProgramType
        };

        function create(newProgramType) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.programtypes).stringify(),
                    newProgramType).then(function (response) {
                    return response.data;
                });
            })
        }

        function newProgramType() {
            return {
                name: '',
                active: false,
                created: new Date(),
                lastUpdated: new Date()
            }
        }

    }
})(window.angular);
