(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .run(hookToRootScope);

    hookToRootScope.$inject = ['$rootScope', '$modal'];

    /**
     * Hook up an application level listener for http errors submitted by the errorHandlerInterceptor.
     *
     * @param $rootScope on which to listen for 'Composer.httpError' events
     * @param $modal the modal service
     */
    function hookToRootScope($rootScope, $modal) {
        $rootScope.$on('Composer.httpError', function (event, response) {
            popModal($rootScope, $modal, event, response);
        });
    }

    /**
     * This will open a modal based upon the http error code. This functionality can
     * be disabled on a per request basis by including a configuration in the $http
     * request that is in the form of: overrideXXX where XXX is the response status code.
     *
     * e.g.
     * $http.get(url, {override500: true});
     * a call with this configuration would not create a modal for only a 500 error coming back
     * from the GET call
     *
     * $http.get(url, {override409: true});
     * likewise this would skip handling a 409 from the call
     *
     * @param $rootScope the application level scope
     * @param $modal the modal service
     * @param event the $emitted event
     * @param httpResponse the error response
     */
    function popModal($rootScope, $modal, event, httpResponse) {

        if (!httpResponse.config['override' + httpResponse.status]) {
            // show error modal --
            //   the developer has not overridden default error handling for this response status
            event.stopPropagation();
            var alertScope = $rootScope.$new(true);
            alertScope.error = httpResponse.data ?
                (httpResponse.data.detail || httpResponse.data) : angular.toJson(httpResponse);

            switch (httpResponse.status) {
                case 0:
                case 403:
                case 404:
                case 409:
                case 503:
                    $modal({
                        scope: alertScope,
                        templateUrl: httpResponse.status + '.html'
                    });
                    break;
                case 401:
                    // no modal on 401s
                    break;
                case 500:
                    //disabling 500 errors for now
                    break;
                default:
                    $modal({
                        templateUrl: 'app/blocks/components/error/500.html',
                        scope: alertScope
                    });
            }
        }

    }

})(window.angular);
