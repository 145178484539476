(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewWorkflowSubscriptionService', NewWorkflowSubscriptionService);

    NewWorkflowSubscriptionService.$inject = ['$http', 'Principal', 'UriTemplate', 'ProgramVersionService'];

    function NewWorkflowSubscriptionService($http, Principal, UriTemplate, programVersionService) {

        return {
            create: create,
            newWorkflowSubscription: newWorkflowSubscription,
            getProgramData: getProgramData,
            searchProgramData: searchProgramData
        };

        function create(newWorkflowSubscription) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.workflowtemplates).stringify(),
                    newWorkflowSubscription).then(function (response) {
                    return response.data;
                });
            })
        }

        function newWorkflowSubscription() {
            return {
                description: '',
                active: false
            }
        }

        function getProgramData() {
            var config = {
                params: {
                    sort: 'emmiCode',
                    size: 10
                }
            };

            return programVersionService.loadVersionedPrograms(config);
        }

        function searchProgramData(searchValue) {
            var config = {
                params: {
                    search: searchValue,
                    sort: 'emmiCode',
                    size: 10
                }
            };

            return programVersionService.loadVersionedPrograms(config);
        }


    }
})(window.angular);
