(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewUrlTypeController', NewUrlTypeController);

    NewUrlTypeController.$inject = ['NewUrlTypeService', 'ProgramInitService', '$scope'];

    function NewUrlTypeController(NewUrlTypeService, programInitService, $scope) {
        var npc = this;

        npc.save = save;
        npc.newUrlType = NewUrlTypeService.newUrlType();
        npc.data = [];

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewUrlTypeService.create(npc.newUrlType).then(function (response) {
                    $scope.$emit('new-url-type', response);
                    npc.data.urlType.content.push(response);
                })
            }
        }
    }

})(window.angular);
