(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('transitionSelect', transitionSelectModal);

        transitionSelectModal.$inject = ['$modal'];

        function transitionSelectModal($modal) {

            return{
                restrict: 'A',
                link: link,
                controller: function () {
                    var ctrl = this;
                },
                controllerAs: 'ctrl',
                scope: {},
                bindToController: {
                    fullList: '=',
                     saveCallback: '&'
                }
            };
           
            function link(scope, element, attrs, ctrl) {

                var modal = {};
               // debugger;
                element.on('click', function (event) {
                    setTimeout(function(){
                        modal = $modal({
                            backdrop: 'static',
                            keyboard: true,
                            templateUrl: 'app/program/create/transition/transition-modal/transition-modal.html',
                            scope: scope,
                            controller: 'TransitionModalController',
                            controllerAs: 'tmc',
                            resolve: {
                                fullList : function () {
                                    return ctrl.fullList;
                                },
                                saveCallback: function () {
                                    return ctrl.saveCallback;
                                }
                            }
                        })
                    })
                });
    
                element.on('$destroy', function(){
                    $('body').removeClass('modal-open');
                    if(modal.hide !== undefined) {
                        modal.hide();
                    }
                });
            }
        }
           

})(window.angular);