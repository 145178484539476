(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('AssetSetService', AssetSetService);

    AssetSetService.$inject = ['$http', 'Principal', 'UriTemplate', 'PaginationService', '$q'];

    function AssetSetService($http, Principal, UriTemplate, PaginationService, $q) {

        return {
            search: search,
            list: list,
            nextPage: nextPage,
            getSummary: getSummary
        };

        function getSummary(assetSet) {
            if(assetSet) {
                if(!assetSet.summary && assetSet.assetSetPath) {
                     assetSet.summary = assetSet.assetSetPath.toUpperCase()
                        + ' - '
                        + assetSet.dialect.description
                        + ' | '
                        + assetSet.narrator.name
                        + ' | '
                        + assetSet.narrator.gender.description;
                }
                return assetSet.summary;
            }
            return '';
        }

        function search(paths) {
            return Principal.identity().then(function (user) {
                var defaultParams = {
                    sort: 'assetSetOrder,assetSetPath,asc',
                    size: 10
                };
                if (paths) {
                    defaultParams.paths = paths;
                }
                return $http.get(UriTemplate.create(user.link.assetsets).stringify(), {
                    params: defaultParams
                }).then(function (response) {
                    var parsedResponse = PaginationService.handleResponse(response.data);
                    if (!parsedResponse.content) {
                        parsedResponse.content = [];
                    }
                    return parsedResponse;
                });
            })
        }

        function list() {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.assetsets + '/all').stringify())
                    .then(function (response) {
                        return response.data;
                    });
            })
        }

        function nextPage(linkToFetch) {
            if (linkToFetch) {
                return $http.get(linkToFetch).then(function (response) {
                    return PaginationService.handleResponse(response.data);
                });
            }
            return $q.reject('no link to fetch');
        }

    }
})(window.angular);
