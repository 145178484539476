(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('formEdElementModal', formEdElementModal);

    formEdElementModal.$inject = ['$modal'];

    function formEdElementModal($modal) {
        return{
            restrict: 'A',
            link: link,
            controller: function () {
                var ctrl = this;
            },
            controllerAs: 'ctrl',
            scope: {
                fec: '=',
                programForm: "=",
            },
            bindToController: {
                saveCallback: '&',
                fec: '=',
            }
        };

        function link(scope, element, attrs, ctrl) {

            var modal = {};
            // debugger;
            element.on('click', function (event) {
                setTimeout(function(){
                    modal = $modal({
                        backdrop: 'static',
                        keyboard: true,
                        templateUrl: 'app/program/create/form-edition/element-modal/form-ed-element-modal.html',
                        scope: scope,
                        controller: 'FormElementController',
                        controllerAs: 'fel',
                        resolve: {
                            saveCallback: function () {
                                return ctrl.saveCallback;
                            },
                            fec: function () {
                                return ctrl.fec;
                            }
                        }
                    })
                })
            });


            element.on('$destroy', function(){
                $('body').removeClass('modal-open');
                if(modal.hide !== undefined) {
                    modal.hide();
                }
            });
        }

    }
})(window.angular);
