(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('TransitionController', TransitionController);

    TransitionController.$inject = ['ProgramService', 'EditionsService', 'ProgramVersionService', 'ProgramSummaryService',
        'AssetSetService',
        'UtilService', 'AlertService', '$state', '$location', "TransitionWarningService"];

    function TransitionController(programService, editionsService, programVersionService, programSummaryService, assetSetService,
        utilService, alertService, $state, $location, transitionWarningService) {
        var tc = this;
        tc.confirm = {};
        tc.collapsed = false;
        tc.program = programService.getProgram;

        tc.data = programSummaryService.getProgramData();
        //create versions
        tc.formatVersion = utilService.formatVersion;
        tc.createWorkingVersion = createWorkingVersion;
        tc.createMajorVersion = createMajorVersion;
        tc.createMinorVersion = createMinorVersion;

        tc.nextMajorVersion = nextMajorVersion;
        tc.nextMinorVersion = nextMinorVersion;
        tc.nextWorkingVersion = nextWorkingVersion;
        tc.isValidVersion = utilService.isValidVersionProgram;

        //set edit to disabled if no permissions or during loading process
        tc.isEditDisabled = isEditDisabled;

        //reset before opening modal dialog
        tc.reset = reset;

        //create copy
        tc.createCopy = createCopy;

        //save form
        tc.save = save;
        tc.cancel = cancel;
        tc.cancelButtonText = cancelButtonText;

        //discard
        tc.discard = discard;
        tc.canBeDiscarded = utilService.canBeDiscarded;
        tc.discardButtonText = discardButtonText;
        tc.discardMessage = utilService.discardMessage;

        //check if program meta has properties and values
        tc.containsValue = containsValue;
        tc.hasValue = hasValue;
        tc.removeAt = utilService.removeAt;

        tc.setFormModified = setFormModified;
        tc.requiredOnAction = requiredOnAction;
        tc.loadIvrAssetSets = loadIvrAssetSets;
        tc.loadIvrAssetSetsSummary = loadIvrAssetSetsSummary;
        tc.getCocDaysNumber = getCocDaysNumber;
        tc.getMisMatchedVersionMessage = getWarningMessages;
        tc.validateName = validateName;
        tc.parseKeywords = parseKeywords;
        tc.keywords = tc.program().entity.meta.keywords.toString();

        (function onLoad() {
            if (!tc.program().entity.meta) {
                tc.program().entity.meta = {};
            }
            if (tc.data) {
                loadIvrAssetSets();
            } else {
                loadIvrAssetSetsSummary();
            }
        })();

        function validateName() {
            tc.submitted = false;
            if (tc.program().entity.name && tc.program().entity.name.match(/\"/g)) {
                tc.nameError = true;
            } else {
                tc.nameError = false;
            }
        }

        function loadIvrAssetSets() {
            return assetSetService.search(['p0', 'p1']).then(function (data) {
                tc.data.ivrAssetSets = data;
                tc.data.ivrAssetSets.content.forEach(function (assetSet, index, array) {
                    array[index].entity.summary = assetSetService.getSummary(assetSet.entity);
                });
            }).finally(function () {
                //set default value
                if (!tc.program().entity.emmiCode) {
                    tc.program().entity.meta.assetSets = [];
                    if (tc.data.ivrAssetSets && tc.data.ivrAssetSets.total && tc.data.ivrAssetSets.total > 0) {
                        tc.program().entity.meta.assetSets.push(tc.data.ivrAssetSets.content[0].entity);
                    }
                } else {
                    loadIvrAssetSetsSummary();
                }
            });
        }

        function loadIvrAssetSetsSummary() {
            if (containsValue('assetSets')) {
                tc.program().entity.meta.assetSets.forEach(function (assetSet, index, array) {
                    if (!array[index].summary) {
                        array[index].summary = assetSetService.getSummary(assetSet);
                    }
                });
            }
        }

        function reset(form) {
            alertService.removeAlert();
        }

        function isEditDisabled() {
            return programService.isEditDisabled() || tc.loading;
        }

        function containsValue(prop) {
            var entity = tc.program().entity;
            var containsValue = false;
            if (entity && entity.meta && prop in entity.meta) {
                var property = entity.meta[prop];
                if (property) {
                    containsValue = property.length > 0;
                }
            }
            return containsValue;
        }

        function hasValue(prop) {
            var entity = tc.program().entity;
            if (entity && entity.meta && prop in entity.meta) {
                var obj = entity.meta[prop];
                return obj && 'id' in obj;
            }
            return false;
        }

        function requiredOnAction() {
            var curAction = programService.getCurrentAction();
            if (curAction && (curAction === "Create Major Version" || curAction === "Create Minor Version")) {
                return true;
            }
            return false;
        }

        function getCocDaysNumber() {
            var cocDays = 0;
            if (tc.program().entity.meta.components && tc.program().entity.meta.components.length > 0) {
                for (var i = 0; i < tc.program().entity.meta.components.length; i++) {
                    var condition = tc.program().entity.meta.components[i].condition;
                    if (condition && condition.conjunction && condition.conjunction.AndCondition && condition.conjunction.AndCondition.personTypeCd == 2) {
                        cocDays++;
                    }
                }
            }
            return cocDays;
        }

        function isFormValid(form, action) {
            var components = tc.program().entity.meta.components;
            var atLeastOneDayAdded = !!(components && components.length > 0);

            var cocDays = getCocDaysNumber();

            var atLeastOnePatientDayAdded = atLeastOneDayAdded && tc.program().entity.meta.components.length != cocDays;

            tc.program().anyDeleteConditionIconFlag = false;
            programService.checkDeleteWarning(tc.program().entity.meta.finalDayList, 'ivrType');

            if (tc.program().entity.meta.circleList) {
                programService.checkDeleteWarning(tc.program().entity.meta.circleList, 'ivrType');
            }
            var isValid = !tc.nameError && form && form.$valid && atLeastOnePatientDayAdded && !tc.program().anyDeleteConditionIconFlag && tc.program().entity.meta.assetSets.length;

            if (!isValid) {
                var validationErrors = "Validation errors";
                if (!atLeastOnePatientDayAdded) {
                    tc.program().seriesPathValidationMessage = "At least one program must be included in the series schedule to create a major or minor version.";
                } else if (tc.program().anyDeleteConditionIconFlag) {
                    tc.program().seriesPathValidationMessage = "All conditions must be valid in order to create a minor or major version."
                } else {
                    tc.program().seriesPathValidationMessage = null;
                }

                alertService.error(validationErrors, action);
                $('body').removeClass('modal-open');
            } else {
                form.$setPristine();
            }
            form.$setSubmitted();

            return isValid;
        }

        function save(form) {
            tc.submitted = true;
            if (!tc.nameError) {
                var actionWord = "Save Program";
                if (!tc.program().entity.emmiCode) {
                    actionWord = "Create Program";
                }
                tc.loading = true;
                programService.save()
                    .then(function (response) {

                        form.$setPristine();
                        alertService.success(tc.program().entity.name, actionWord);
                    })
                    .catch(function (response) {
                        alertService.error(response, actionWord);
                    })
                    .finally(function () {
                        delete tc.loading;
                        loadIvrAssetSetsSummary();
                    });
            } else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function cancel() {
            var entity = tc.program().entity;
            var emmiCode = entity.emmiCode;
            var versioned = entity.versionedToLocal || entity.version;
            utilService.navigate(emmiCode, versioned, $state);
        }

        function cancelButtonText() {
            var entity = tc.program().entity;
            var emmiCode = entity.emmiCode;
            var text = "Return to Composer Home";
            if (emmiCode) {
                if (entity.version || entity.versionedToLocal) {
                    text = "Return to Version List Page";
                }
            }
            return text;
        }

        function discardButtonText() {
            var program = tc.program();
            if (program && program.entity) {
                return program.entity.versionedToLocal ? 'REVERT' : 'DELETE';
            } else {
                return 'DELETE';
            }
        }

        function discard() {
            var entity = tc.program().entity;
            var emmiCode = entity.emmiCode;
            var versionedToLocal = entity.versionedToLocal;
            tc.loading = true;
            programService.discard()
                .then(function (response) {
                    utilService.navigate(emmiCode, versionedToLocal, $state);
                })
                .catch(function (response) {
                    alertService.error(response, 'discard program');
                })
                .finally(function () {
                    delete tc.loading;
                });
        }

        function createCopy(form) {
            programService.createCopy(tc.confirm.inputText);
            programService.setEditable(true);
            $('body').removeClass('modal-open');
        }

        function setNotes() {
            var entity = tc.program().entity;
            if (entity) {
                if (!entity.version) {
                    entity.version = utilService.newVersion();
                }
                entity.version.comment = tc.confirm.inputText;
            }
        }

        function setFormModified(form) {
            form.$setDirty();
        }

        function createWorkingVersion(form) {
            tc.submitted = true;
            if (!tc.nameError) {
                setNotes();
                tc.loading = true;
                var operation = 'create working version';
                var series = tc.program().entity.meta.finalDayList;
                var circle = tc.program().entity.meta.circleList;
                programVersionService.createWorkingVersion(tc.program())
                    .then(function (response) {
                        var program = tc.program();
                        program.entity.version = response.entity.version;
                        tc.program().entity.meta.finalDayList = series;
                        tc.program().entity.meta.circleList = circle;
                        alertService.customSuccess('Working version for program ' + program.entity.name + ' is successfully created in the Composer.');
                        form.$setPristine();
                        programService.setCurrentAction("");
                    })
                    .catch(function (response) {
                        alertService.error(response, operation);
                    })
                    .finally(function () {
                        delete tc.loading;
                        loadIvrAssetSetsSummary();
                    });
            } else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function createMajorVersion(form) {
            tc.submitted = true;
            tc.program().majorOrMinorVersion = true;
            if (isFormValid(form, "Create Major Version")) {
                if (!tc.nameError) {
                    setNotes();
                    tc.loading = true;
                    var operation = 'create major version';
                    programVersionService.createMajorVersion(tc.program())
                        .then(function (response) {
                            var program = tc.program();
                            program.entity.version = response.entity.version;
                            alertService.customSuccess('Major version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            programService.setCurrentAction("");
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete tc.loading;
                            loadIvrAssetSetsSummary();
                        });
                }
                $('body').removeClass('modal-open');
            }
        }

        function createMinorVersion(form) {
            tc.submitted = true;
            tc.program().majorOrMinorVersion = true;
            if (isFormValid(form, "Create Minor Version")) {
                if (!tc.nameError) {
                    setNotes();
                    tc.loading = true;
                    var operation = 'create minor version';
                    programVersionService.createMinorVersion(tc.program())
                        .then(function (response) {
                            var program = tc.program();
                            program.entity.version = response.entity.version;
                            alertService.customSuccess('Minor version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            programService.setCurrentAction("");
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete tc.loading;
                            loadIvrAssetSetsSummary();
                        });
                }
                $('body').removeClass('modal-open');
            }
        }

        function getCompleteVersion(obj) {
            return obj.major + '.' + obj.minor + '.' + obj.working
        }

        function nextMajorVersion() {
            programService.setCurrentAction("Create Major Version");
            return programVersionService.nextMajorVersion(tc.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        return getWarningMessages(version, verobj.major).then(function (warningMessages) {
                            return { resultMessage: version, warningMessages: warningMessages };
                        });
                    } else {
                        version = '1.0.0';
                    }
                    return { resultMessage: version, warningMessages: warningMessages };
                })
        }

        function nextMinorVersion() {
            programService.setCurrentAction("Create Minor Version");
            return programVersionService.nextMinorVersion(tc.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        return getWarningMessages(version, verobj.major).then(function (warningMessages) {
                            return { resultMessage: version, warningMessages: warningMessages };
                        });
                    } else {
                        version = '0.1.0';
                        return { resultMessage: version, warningMessages: warningMessages };
                    }

                })
        }

        function getWarningMessages(nextProgramVersion, nextProgramMajor) {
            return transitionWarningService.getWarningMessages(nextProgramVersion, nextProgramMajor);
        }

        function nextWorkingVersion() {
            programService.setCurrentAction("Create Working Version");
            return programVersionService.nextWorkingVersion(tc.program())
                .then(function (response) {
                    var verobj = response;
                    var version = '0.0.1';
                    if (verobj) {
                        version = verobj.major + '.' + verobj.minor + '.' + verobj.working;
                    }
                    return { resultMessage: version, warningMessages: [] };
                })
        }

        function parseKeywords(){
            tc.program().entity.meta.keywords = utilService.parseKeywords(tc).split(',');
            utilService.cleanKeywords(tc.program().entity.meta.keywords);
        }
    }
})(window.angular);
