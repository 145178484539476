(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('home', {
                parent: 'app',
                url: '/',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/home/home.html',
                        controller: 'HomeController',
                        controllerAs: 'hc'
                    },
                    'checkedout-partial@home': {
                        templateUrl: 'app/program/checkedout/partial/checkedout-program-partial-list.html',
                        controller: 'CheckedoutProgramController',
                        controllerAs: 'cpc'
                    },
                    'unversioned-partial@home': {
                        templateUrl: 'app/program/unversioned/partial/unversioned-program-partial-list.html',
                        controller: 'UnversionedProgramController',
                        controllerAs: 'upc'
                    },
                    'versioned@home': {
                        templateUrl: 'app/program/versioned/list/versioned-program-list.html',
                        controller: 'VersionedProgramController',
                        controllerAs: 'vpc'
                    }
                }
            })
            .state('logout', {
                parent: 'app',
                url: '/logout',
                views: {
                    'content@': {
                        templateUrl: 'app/home/logout.html'
                    }
                }
            });
    }
})(window.angular);
