(function(angular) {
    'use strict';

    angular
        .module('Composer')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('edit-program-details', {
                parent: 'app',
                url: '/edit-details/:emmiCode/:version',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/program/templates/edit/program-edit-details.html',
                        controller: 'ProgramSummaryController',
                        controllerAs: 'psc'
                    },
                    'version-editor@edit-program-details': {
                        templateUrl: 'app/program/create/program-version.html',
                        controller: 'ProgramController',
                        controllerAs: 'pc'
                    },
                    'metadata@edit-program-details': {
                        templateUrl: 'app/program/create/program.html',
                        controller: 'ProgramController',
                        controllerAs: 'pc'
                    },
                    'content-subscriptions@edit-program-details': {
                        templateUrl: 'app/program/create/content-subscriptions/content-subscriptions.html',
                        controller: 'ProgramController',
                        controllerAs: 'pc'
                    },
                    'editions@edit-program-details': {
                        templateUrl: 'app/program/create/editions/editions.html',
                        controller: 'EditionsController',
                        controllerAs: 'ec'
                    },
                    'chapters-editor@edit-program-details': {
                        templateUrl: 'app/program/create/editions/collapse/edit/chapters.html',
                        controller: 'EditionsController',
                        controllerAs: 'ec'
                    },
                    'videos-editor@edit-program-details': {
                        templateUrl: 'app/program/create/editions/collapse/edit/videos.html',
                        controller: 'EditionsController',
                        controllerAs: 'ec'
                    }
                }
            })
            .state('edit-ivr-details', {
                parent: 'app',
                url: '/edit-ivr-details/:emmiCode/:version',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/program/templates/edit/ivr-edit-details.html',
                        controller: 'ProgramSummaryController',
                        controllerAs: 'psc'
                    },
                    'version-editor@edit-ivr-details': {
                        templateUrl: 'app/program/create/ivr/ivr-version.html',
                        controller: 'IvrController',
                        controllerAs: 'ic'
                    },
                    'metadata@edit-ivr-details': {
                        templateUrl: 'app/program/create/ivr/ivr.html',
                        controller: 'IvrController',
                        controllerAs: 'ic'
                    },
                    'add-module@edit-ivr-details': {
                        templateUrl: 'app/program/create/ivr/ivr-module/ivr-add-module.html',
                        controller: 'IvrController',
                        controllerAs: 'ic'
                    },
                    'editions@edit-ivr-details': {
                        templateUrl: 'app/program/create/editions/editions.html',
                        controller: 'EditionsController',
                        controllerAs: 'ec'
                    },
                    'chapters-editor@edit-ivr-details': {
                        templateUrl: 'app/program/create/editions/collapse/edit/chapters.html',
                        controller: 'EditionsController',
                        controllerAs: 'ec'
                    },
                    'videos-editor@edit-program-details': {
                        templateUrl: 'app/program/create/editions/collapse/edit/videos.html',
                        controller: 'EditionsController',
                        controllerAs: 'ec'
                    }
                }
            })
            .state('edit-web-program-details', {
                parent: 'app',
                url: '/edit-web-program-details/:emmiCode/:version',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/program/templates/edit/web-program-edit-details.html',
                        controller: 'ProgramSummaryController',
                        controllerAs: 'psc'
                    },
                    'version-editor@edit-web-program-details': {
                        templateUrl: 'app/program/create/web-program/web-program-version.html',
                        controller: 'WebProgramController',
                        controllerAs: 'wpc'
                    },
                    'metadata@edit-web-program-details': {
                        templateUrl: 'app/program/create/web-program/web-program.html',
                        controller: 'WebProgramController',
                        controllerAs: 'wpc'
                    }
                }
            })
            .state('edit-transition-details', {
                parent: 'app',
                url: '/edit-transition-details/:emmiCode/:version',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/program/templates/edit/transition-edit-details.html',
                        controller: 'ProgramSummaryController',
                        controllerAs: 'psc'
                    },
                    'version-editor@edit-transition-details': {
                        templateUrl: 'app/program/create/transition/transition-version.html',
                        controller: 'TransitionController',
                        controllerAs: 'tc'
                    },
                    'metadata@edit-transition-details': {
                        templateUrl: 'app/program/create/transition/transition.html',
                        controller: 'TransitionController',
                        controllerAs: 'tc'
                    },
                    'add-module@edit-transition-details': {
                        templateUrl: 'app/program/create/transition/transition-series-path/transition-series-path.html',
                        controller: 'TransitionSeriesPathController',
                        controllerAs: 'tspc'
                    },
                    'transition@edit-transition-details': {
                        templateUrl: 'app/program/create/transition/transition-modal/transition-modal.html',
                        controller: 'TransitionModalController',
                        controllerAs: 'tmc'
                    },
                    'condition@edit-transition-details': {
                        templateUrl: 'app/program/create/transition/transition-condition/transition-condition.html',
                        controller: 'TransitionConditionController',
                        controllerAs: 'tcc'
                    },
                    'chapters-editor@edit-transition-details': {
                        templateUrl: 'app/program/create/editions/collapse/edit/chapters.html',
                        controller: 'EditionsController',
                        controllerAs: 'ec'
                    },
                    'videos-editor@edit-transition-details': {
                        templateUrl: 'app/program/create/editions/collapse/edit/videos.html',
                        controller: 'EditionsController',
                        controllerAs: 'ec'
                    }
                }
            })
            .state('edit-form-edition-details', {
                parent: 'app',
                url: '/edit-form-edition-details/:emmiCode/:version',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/program/templates/edit/form-edition-edit-details.html',
                        controller: 'ProgramSummaryController',
                        controllerAs: 'psc'
                    },
                    'version-editor@edit-form-edition-details': {
                        templateUrl: 'app/program/create/form-edition/form-edition-version.html',
                        controller: 'FormEditionController',
                        controllerAs: 'fec'
                    },

                    'form-content@edit-form-edition-details': {
                        templateUrl: 'app/program/create/form-edition/form-content.html',
                        controller: 'FormEditionController',
                        controllerAs: 'fec'
                    },
                    'form-ed-metadata@edit-form-edition-details': {
                        templateUrl: 'app/program/create/form-edition/form-ed-metadata.html',
                        controller: 'FormEditionController',
                        controllerAs: 'fec'
                    },
                    'form-questions@edit-form-edition-details': {
                        templateUrl: 'app/program/create/form-edition/form-questions.html',
                        controller: 'FormEditionController',
                        controllerAs: 'fec'
                    },
                    'form-module@edit-form-edition-details': {
                        templateUrl: 'app/program/create/form-edition/form-content.html',
                        controller: 'FormEditionController',
                        controllerAs: 'fec'
                    },
                }
            })
            .state('edit-form-details', {
                parent: 'app',
                url: '/edit-form-details/:emmiCode/:version',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/program/templates/edit/form-edit-details.html',
                        controller: 'ProgramSummaryController',
                        controllerAs: 'psc'
                    },
                    'version-editor@edit-form-details': {
                        templateUrl: 'app/program/create/form/form-version.html',
                        controller: 'FormController',
                        controllerAs: 'fc'
                    },
                    'metadata@edit-form-details': {
                        templateUrl: 'app/program/create/form/form.html',
                        controller: 'FormController',
                        controllerAs: 'fc'
                    },
                    'content-subscriptions@edit-form-details': {
                        templateUrl: 'app/program/create/content-subscriptions/content-subscriptions.html',
                        controller: 'FormController',
                        controllerAs: 'pc'
                    },
                    'editions@edit-form-details': {
                        templateUrl: 'app/program/create/editions/editions.html',
                        controller: 'EditionsController',
                        controllerAs: 'ec'
                    }
                }
            })
            .state('edit-digital-journey-details', {
                parent: 'app',
                url: '/edit-digital-journey-details/:emmiCode/:version',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/program/templates/edit/care-plan-edit-details.html',
                        controller: 'ProgramSummaryController',
                        controllerAs: 'psc'
                    },
                    'version-editor@edit-digital-journey-details': {
                        templateUrl: 'app/program/create/care-plan/care-plan-version.html',
                        controller: 'CarePlanController',
                        controllerAs: 'cpc'
                    },
                    'metadata@edit-digital-journey-details': {
                        templateUrl: 'app/program/create/care-plan/care-plan.html',
                        controller: 'CarePlanController',
                        controllerAs: 'cpc'
                    },
                    'content-subscriptions@edit-digital-journey-details': {
                        templateUrl: 'app/program/create/content-subscriptions/content-subscriptions.html',
                        controller: 'CarePlanController',
                        controllerAs: 'pc'
                    },
                    'add-care-plan-module@edit-digital-journey-details': {
                        templateUrl: 'app/program/create/care-plan/care-plan-series-path/care-plan-series-path.html',
                        controller: 'CarePlanSeriesPathController',
                        controllerAs: 'cpspc'
                    },
                    'care-plan@edit-digital-journey-details': {
                        templateUrl: 'app/program/create/care-plan/care-plan-modal/care-plan-modal.html',
                        controller: 'CarePlanModalController',
                        controllerAs: 'cpmc'
                    },
                    'condition@edit-digital-journey-details': {
                        templateUrl: 'app/program/create/care-plan/care-plan-condition/care-plan-condition.html',
                        controller: 'CarePlanConditionController',
                        controllerAs: 'cpcc'
                    },
                }
            })
            .state('edit-video-program-details', {
                parent: 'app',
                url: '/edit-video-program-details/:emmiCode/:version',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/program/templates/edit/video-program-edit-details.html',
                        controller: 'ProgramSummaryController',
                        controllerAs: 'psc'
                    },
                    'version-editor@edit-video-program-details': {
                        templateUrl: 'app/program/create/video-program/video-program-version.html',
                        controller: 'VideoProgramController',
                        controllerAs: 'vpc'
                    },
                    'metadata@edit-video-program-details': {
                        templateUrl: 'app/program/create/video-program/video-program.html',
                        controller: 'VideoProgramController',
                        controllerAs: 'vpc'
                    }
                }
            })
            .state('view-article-details', {
                    parent: 'app',
                    url: '/view-article-details/:emmiCode/:version',
                    data: {
                        authorities: ['ROLE_LINKS_USER']
                    },
                    views: {
                        'content@': {
                            templateUrl: 'app/program/templates/edit/article-view-details.html',
                            controller: 'ProgramSummaryController',
                            controllerAs: 'psc'
                        },
                        'version-editor@view-article-details': {
                            templateUrl: 'app/program/create/article/article-version.html',
                            controller: 'ArticleController',
                            controllerAs: 'artc'
                        },
                        'metadata@view-article-details': {
                            templateUrl: 'app/program/create/article/article.html',
                            controller: 'ArticleController',
                            controllerAs: 'artc'
                        },
                        'content-subscriptions@view-article-details': {
                            templateUrl: 'app/program/create/content-subscriptions/content-subscriptions.html',
                            controller: 'ArticleController',
                            controllerAs: 'pc'
                        }
                    }
                })
    }
})(window.angular);
