(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditSourceService', EditSourceService);

    EditSourceService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditSourceService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(sourceResource) {
            LinkConverter.convertResource(sourceResource);
            if (sourceResource && sourceResource.link.self) {
                return $http.put(sourceResource.link.self, sourceResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
