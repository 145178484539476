(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditPlaceholderController', EditPlaceholderController);

    EditPlaceholderController.$inject = ['EditPlaceholderService', 'savedPlaceholderResource', 'saveCallback', 'errorCallback'];

    function EditPlaceholderController(EditPlaceholderService, savedPlaceholderResource, saveCallback, errorCallback) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedPlaceholderResource);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditPlaceholderService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
