(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('InitializeCampaignController', InitializeCampaignController);

    InitializeCampaignController.$inject = ['ProgramCampaignService', 'AlertService', 'emmiCode', 'callbackFn'];

    function InitializeCampaignController(programCampaignService, alertService, emmiCode, callbackFn) {
        var icc = this;
        icc.emmiCode = emmiCode;
        var campaignFields = {
            custom6: '',
            custom7: ''
        }

        var columnFields = {
            custom6: '',
            custom7:'',
            useDoctorName:false,
            useTransfer:''
        };

        var campaign = {
            emmiCode: icc.emmiCode,
            campaignFields: campaignFields,
            columnFields: columnFields
        }

        var data = programCampaignService.getData();
        icc.cacustom6 = data.cacustom6;
        icc.cacustom7 = data.cacustom7;
        icc.cocustom6 = data.cocustom6;
        icc.cocustom7 = data.cocustom7;
        icc.useTransfer = data.useTransfer;

        initCampaign();

        icc.campaign = campaign;

        icc.callbackFn = callbackFn;
        icc.validateAndUpdateCampaign = validateAndUpdateCampaign;
        icc.errors = '';

        icc.hasErrors = hasErrors;

        function initCampaign() {
            var existingCampaign = programCampaignService.getCampaign();
            campaign.emmiCode = existingCampaign.emmiCode;
            campaign.campaignFields.custom6 = existingCampaign.campaignFields.custom6 ? existingCampaign.campaignFields.custom6 : '';
            campaign.campaignFields.custom7 = existingCampaign.campaignFields.custom7 ? existingCampaign.campaignFields.custom7 : '';

            campaign.columnFields.custom6 = existingCampaign.columnFields.custom6 ? existingCampaign.columnFields.custom6 : '';
            campaign.columnFields.custom7 = existingCampaign.columnFields.custom7 ? existingCampaign.columnFields.custom7 : '';
            campaign.columnFields.useDoctorName = existingCampaign.columnFields.useDoctorName;
            campaign.columnFields.useTransfer = existingCampaign.columnFields.useTransfer ? existingCampaign.columnFields.useTransfer : '';
        }

        function hasErrors() {
            return icc.errors != '';
        }

        function updateCampaign() {
            programCampaignService.updateCampaign(icc.campaign)
                .then(function (response) {
                    alertService.customSuccess('Campaign for program ' + icc.emmiCode + ' is successfully updated');
                })
                .catch(function (error) {
                    alertService.error(error, 'update campaign details');
                })
            return true;
        }

        function validateAndUpdateCampaign() {
            icc.errors = '';
            var campaignData = icc.campaign;
            if(!campaignData.emmiCode) {
                icc.errors = icc.errors + "No valid emmiCode;";
            }
            if(campaignData.columnFields.custom6 != '' && campaignData.columnFields.custom6 == campaignData.columnFields.custom7) {
                icc.errors = icc.errors + "Custom6 and Custom7 cannot be same;";
            }
            if(campaignData.campaignFields.custom6 != '' && campaignData.campaignFields.custom6 == campaignData.campaignFields.custom7) {
                icc.errors = icc.errors + "Custom6 and Custom7 cannot be same;";
            }
            if(campaignData.campaignFields.custom6 != '' && campaignData.columnFields.custom6 != '') {
                icc.errors = icc.errors + "Custom6 cannot be selected at both campaign and custom level;";
            }
            if(campaignData.campaignFields.custom7 != '' && campaignData.columnFields.custom7 != '') {
                icc.errors = icc.errors + "Custom7 cannot be selected at both campaign and custom level;";
            }
            if(icc.errors === '') {
                updateCampaign();
                return true;
            }
            return false;
        }
    }
})(window.angular);
