(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('CarePlanController', CarePlanController);

    CarePlanController.$inject = ['ProgramService', 'EditionsService', 'ProgramVersionService', 'ProgramSummaryService', 'ProgramWarningService',
        'AssetSetService',
        'UtilService', 'VideoService', 'AlertService', '$state', '$location'];

    function CarePlanController(programService, editionsService, programVersionService, programSummaryService, programWarningService, assetSetService,
        utilService, videoService, alertService, $state, $location) {
        var cpc = this;
        cpc.confirm = {};
        cpc.collapsed = false;
        cpc.program = programService.getProgram;

        cpc.data = programSummaryService.getProgramData();
        //create versions
        cpc.formatVersion = utilService.formatVersion;
        cpc.createWorkingVersion = createWorkingVersion;
        cpc.createMajorVersion = createMajorVersion;
        cpc.createMinorVersion = createMinorVersion;

        cpc.nextMajorVersion = nextMajorVersion;
        cpc.nextMinorVersion = nextMinorVersion;
        cpc.nextWorkingVersion = nextWorkingVersion;
        cpc.getMisMatchedVersionMessage = getWarningMessages;
        cpc.isValidVersion = utilService.isValidVersionProgram;


        //set edit to disabled if no permissions or during loading process
        cpc.isEditDisabled = isEditDisabled;

        //reset before opening modal dialog
        cpc.reset = reset;

        //create copy
        cpc.createCopy = createCopy;

        //save form
        cpc.save = save;
        cpc.cancel = cancel;
        cpc.cancelButtonText = cancelButtonText;

        //discard
        cpc.discard = discard;
        cpc.canBeDiscarded = utilService.canBeDiscarded;
        cpc.discardButtonText = discardButtonText;
        cpc.discardMessage = utilService.discardMessage;

        //edition
        cpc.hasVideoSections = hasVideoSections;
        cpc.removeVideoSections = editionsService.removeVideoSections;

        //check if program meta has properties and values
        cpc.containsValue = containsValue;
        cpc.hasValue = hasValue;
        cpc.removeAt = utilService.removeAt;

        cpc.setFormModified = setFormModified;
        cpc.requiredOnAction = requiredOnAction;
        cpc.validateName = validateName;
        cpc.parseKeywords = parseKeywords;
        cpc.keywords = cpc.program().entity.meta.keywords.toString();
        cpc.getMissingCampaignData = programService.getMissingCampaignData;
        function validateName() {
            cpc.submitted = false;
            if (cpc.program().entity.name && cpc.program().entity.name.match(/\"/g)) {
                cpc.nameError = true;
            } else {
                cpc.nameError = false;
            }
        }

        function reset(form) {
            alertService.removeAlert();
        }

        function isEditDisabled() {
            return programService.isEditDisabled() || cpc.loading;
        }

        function hasVideoSections() {
            var isRequired = false;
            var entity = cpc.program().entity;
            if (entity && entity.meta) {
                var editions = entity.meta.editions;
                if (editions) {
                    for (var i = 0; i < editions.length; i++) {
                        if (videoService.hasVideoSections(editions[i])) {
                            isRequired = true;
                            break;
                        }
                    }
                }
            }
            return isRequired;
        }

        function containsValue(prop) {
            var entity = cpc.program().entity;
            var containsValue = false;
            if (entity && entity.meta && prop in entity.meta) {
                var property = entity.meta[prop];
                if (property) {
                    containsValue = property.length > 0;
                }
            }
            return containsValue;
        }

        function hasValue(prop) {
            var entity = cpc.program().entity;
            if (entity && entity.meta && prop in entity.meta) {
                var obj = entity.meta[prop];
                return obj && 'id' in obj;
            }
            return false;
        }

        function requiredOnAction() {
            var curAction = programService.getCurrentAction();
            if (curAction && (curAction === "Create Major Version" || curAction === "Create Minor Version")) {
                return true;
            }
            return false; 
        }

        function isFormValid(form, action) {
            var components = cpc.program().entity.meta.components;
            var atLeastOneDayAdded = !!(components && components.length > 0);
            cpc.program().anyDeleteConditionIconFlag = false;
            var daysOrderInvalid = programService.getCPOrderValid().length > 0;
            programService.setMissingCampaignData(false);
            for (var i = 0; i < cpc.program().entity.meta.components.length; i++){
                if (cpc.program().entity.meta.components[i].condition.programType === 'IVR CALL') {
                    if (!cpc.program().entity.meta.patientCampaignId || !cpc.program().entity.meta.patientCampaignName) {
                        programService.setMissingCampaignData(true);
                    }
                }
            }


            var isValid = !cpc.nameError && atLeastOneDayAdded && !cpc.program().anyDeleteConditionIconFlag && form && form.$valid && !daysOrderInvalid && cpc.program().entity.meta.productLines.length > 0 && cpc.program().entity.meta.contentSubscriptions.length > 0 && !cpc.getMissingCampaignData();
            if (!isValid) {
                var validationErrors = "Validation errors";
                if (!atLeastOneDayAdded) {
                    validationErrors = "At least one program must be included in the series schedule to create a major or minor version.";
                } else if (cpc.program().anyDeleteConditionIconFlag) {
                    validationErrors = "All conditions must be valid in order to create a minor or major version."
                } else if (daysOrderInvalid) {
                    validationErrors = "Validation errors exist in the display order of one or more days below."
                } else if (cpc.getMissingCampaignData()) {
                    validationErrors = "Campaign Name and ID are required for Emmi Journeys that use IVR calls."
                }
                else {
                    cpc.program().seriesPathValidationMessage = null;
                }
                alertService.error(validationErrors, action);
            } else {
                form.$setPristine();
            }

            form.$setSubmitted();
            $('body').removeClass('modal-open');
            return isValid;
        }

        function save(form) {
            cpc.submitted = true;
            if (!cpc.nameError) {
                var actionWord = "Save Program";
                if (!cpc.program().entity.emmiCode) {
                    actionWord = "Create Program";
                }
                cpc.loading = true;
                programService.save()
                    .then(function (response) {
                        form.$setPristine();
                        alertService.success(cpc.program().entity.name, actionWord);
                    })
                    .catch(function (response) {
                        alertService.error(response, actionWord);
                    })
                    .finally(function () {
                        delete cpc.loading;
                        loadFormAssetSetsSummary();
                    });
            } else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function cancel() {
            var entity = cpc.program().entity;
            var emmiCode = entity.emmiCode;
            var versioned = entity.versionedToLocal || entity.version;
            utilService.navigate(emmiCode, versioned, $state);
        }

        function cancelButtonText() {
            var entity = cpc.program().entity;
            var emmiCode = entity.emmiCode;
            var text = "Return to Composer Home";
            if (emmiCode) {
                if (entity.version || entity.versionedToLocal) {
                    text = "Return to Version List Page";
                }
            }
            return text;
        }

        function discardButtonText() {
            var program = cpc.program();
            if (program && program.entity) {
                return program.entity.versionedToLocal ? 'REVERT' : 'DELETE';
            } else {
                return 'DELETE';
            }
        }

        function discard() {
            var entity = cpc.program().entity;
            var emmiCode = entity.emmiCode;
            var versionedToLocal = entity.versionedToLocal;
            cpc.loading = true;
            programService.discard()
                .then(function (response) {
                    utilService.navigate(emmiCode, versionedToLocal, $state);
                })
                .catch(function (response) {
                    alertService.error(response, 'discard program');
                })
                .finally(function () {
                    delete cpc.loading;
                });
        }

        function createCopy(form) {
            programService.createCopy(cpc.confirm.inputText);
            programService.setEditable(true);
            $('body').removeClass('modal-open');
        }

        function setNotes() {
            var entity = cpc.program().entity;
            if (entity) {
                if (!entity.version) {
                    entity.version = utilService.newVersion();
                }
                entity.version.comment = cpc.confirm.inputText;
            }
        }

        function setFormModified(form) {
            form.$setDirty();
        }

        function createWorkingVersion(form) {
            cpc.submitted = true;
            if (!cpc.nameError) {
                setNotes();
                cpc.loading = true;
                var operation = 'create working version';
                programVersionService.createWorkingVersion(cpc.program())
                    .then(function (response) {
                        var program = cpc.program();
                        program.entity.version = response.entity.version;
                        alertService.customSuccess('Working version for program ' + program.entity.name + ' is successfully created in the Composer.');
                        form.$setPristine();
                        programService.setCurrentAction("");
                    })
                    .catch(function (response) {
                        alertService.error(response, operation);
                    })
                    .finally(function () {
                        delete cpc.loading;
                    });
                } else {
                    alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
                }
            $('body').removeClass('modal-open');
        }

        function createMajorVersion(form) {
            cpc.submitted = true;
            cpc.program().majorOrMinorVersion = true;
            if (isFormValid(form, "Create Major Version")) {
                if (!cpc.nameError) {
                    setNotes();
                    cpc.loading = true;
                    var operation = 'create major version';
                    programVersionService.createMajorVersion(cpc.program())
                        .then(function (response) {
                            var program = cpc.program();
                            program.entity.version = response.entity.version;
                            alertService.customSuccess('Major version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            programService.setCurrentAction("");
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete cpc.loading;
                        });
                } 
                $('body').removeClass('modal-open');
            }
        }

        function createMinorVersion(form) {
            cpc.submitted = true;
            cpc.program().majorOrMinorVersion = true;
            if (isFormValid(form, "Create Minor Version")) {
                if (!cpc.nameError) {
                    setNotes();
                    cpc.loading = true;
                    var operation = 'create minor version';
                    programVersionService.createMinorVersion(cpc.program())
                        .then(function (response) {
                            var program = cpc.program();
                            program.entity.version = response.entity.version;
                            alertService.customSuccess('Minor version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            programService.setCurrentAction("");
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete cpc.loading;
                        });
                }
                $('body').removeClass('modal-open');
            }
        }

        function getCompleteVersion(obj) {
            return obj.major + '.' + obj.minor + '.' + obj.working
        }

        function nextMajorVersion() {
            programService.setCurrentAction("Create Major Version");
            return programVersionService.nextMajorVersion(cpc.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        warningMessages = getWarningMessages(version, verobj.major);
                    } else {
                        version = '1.0.0';
                    }
                    return { resultMessage: version, warningMessages: warningMessages };
                })
        }

        function nextMinorVersion() {
            programService.setCurrentAction("Create Minor Version");
            return programVersionService.nextMinorVersion(cpc.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        warningMessages = getWarningMessages(version, verobj.major);
                    } else {
                        version = '0.1.0';
                    }
                    return { resultMessage: version, warningMessages: warningMessages };
                })
        }

        function nextWorkingVersion() {
            programService.setCurrentAction("Create Working Version");
            return programVersionService.nextWorkingVersion(cpc.program())
                .then(function (response) {
                    var verobj = response;
                    var version = '0.0.1';
                    if (verobj) {
                        version = verobj.major + '.' + verobj.minor + '.' + verobj.working;
                    }
                    return { resultMessage: version, warningMessages: [] };
                })
        }

        function getWarningMessages(nextProgramVersion, nextProgramMajor) {
            return programWarningService.getWarningMessages(nextProgramVersion, nextProgramMajor);
        }

        function parseKeywords(){
            cpc.program().entity.meta.keywords = utilService.parseKeywords(cpc).split(',');
            utilService.cleanKeywords(cpc.program().entity.meta.keywords);
        }
    }
})(window.angular);
