(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditPerspectiveController', EditPerspectiveController);

    EditPerspectiveController.$inject = ['EditPerspectiveService', 'savedPerspectiveResource', 'saveCallback', 'errorCallback'];

    function EditPerspectiveController(EditPerspectiveService, savedPerspectiveResource, saveCallback, errorCallback) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedPerspectiveResource);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditPerspectiveService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
