(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('selectVersionModal', selectVersionModal);

    selectVersionModal.$inject = ['$modal'];

    function selectVersionModal($modal) {

        return {
            restrict: 'A',
            link: link,
            scope: {
                selectedEdition: '='
            }
        };

        function link(scope, element) {
            var modal = {};

            element.on('click', function () {
                modal = $modal({
                    templateUrl: 'app/program/create/editions/select/version/versions.select.modal.html',
                    scope: scope
                })
            });

            scope.$on('selected-version', function (event, selectedEdition) {
                scope.selectedEdition.version = selectedEdition.version;
                scope.selectedEdition.formattedVersion = selectedEdition.formattedVersion;

            });

            element.on('$destroy', function(){
                if(modal.hide !== undefined) {
                    modal.hide();
                }
            });
        }


    }
})(window.angular);
