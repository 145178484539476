(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewLanguageService', NewLanguageService);

    NewLanguageService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewLanguageService($http, Principal, UriTemplate) {

        return {
            create: create,
            newLanguage: newLanguage
        };

        function create(newLanguage) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.languages).stringify(),
                    newLanguage).then(function (response) {
                    return response.data;
                });
            })
        }

        function newLanguage() {
            return {
                description: '',
                active: false,
                created: new Date(),
                lastUpdated: new Date()
            }
        }

    }
})(window.angular);
