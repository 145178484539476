(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('multiSelect', multiSelectModal);

    multiSelectModal.$inject = ['$modal'];

    function multiSelectModal($modal) {

        return {
            restrict: 'A',
            link: link,
            controller: function () {
                var ctrl = this;
                ctrl.clearSelected = function () {
                    if (ctrl.fullList) {
                        angular.forEach(ctrl.fullList.content, function (item) {
                            if (item) {
                                item.select = undefined;
                            }
                        })
                    }
                }
            },
            controllerAs: 'ctrl',
            scope: {},
            bindToController: {
                fullList: '=',
                items: '=',
                title: '@title',
                saveCallback: '&'
            }
        };

        function link(scope, element, attrs, ctrl) {
            var modal = {};

            element.on('click', function () {
                modal = $modal({
                    backdrop: 'static',
                    keyboard: true,
                    templateUrl: 'app/modal/select/select.items.modal.html',
                    scope: scope,
                    controller: 'MultiSelectController',
                    controllerAs: 'sic',
                    resolve: {
                        fullList: function () {
                            return ctrl.fullList;
                        },
                        selectedItems: function () {
                            if (!ctrl.items) {
                                ctrl.items = [];
                            }
                            return ctrl.items;
                        },
                        title: function () {
                            return ctrl.title;
                        },
                        saveCallback: function () {
                            return ctrl.saveCallback;
                        }
                    }
                })
            });

            element.on('$destroy', function () {
                ctrl.clearSelected();
                $('body').removeClass('modal-open');
                if (modal.hide !== undefined) {
                    modal.hide();
                }
            });
        }
    }
})(window.angular);
