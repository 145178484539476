(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('view-program-details', {
                parent: 'app',
                url: '/view-details/:emmiCode',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/program/templates/view/details/program-view-details.html',
                        controller: 'VersionedProgramDetailController',
                        controllerAs: 'vpdc'
                    },
                    'status@view-program-details': {
                        templateUrl: 'app/program/versioned/details/versioned-program-status.html'
                    },
                    'version-summary@view-program-details': {
                        templateUrl: 'app/program/versioned/details/versioned-program-summary.html'
                    },
                    'publish-history@view-program-details': {
                        templateUrl: 'app/program/versioned/publish/versioned-program-publish-history.html',
                        controller: 'VersionedProgramPublishController',
                        controllerAs: 'vppc'
                    },

                    'campaign-status@view-program-details': {
                        templateUrl: 'app/program/versioned/campaign/versioned-program-campaign.html',
                        controller: 'VersionedProgramCampaignController',
                        controllerAs: 'vpcc'
                    },
                    'version-history@view-program-details': {
                        templateUrl: 'app/program/versioned/details/versioned-program-version-history.html'
                    }
                }
            })
            .state('view-recent-programs', {
                parent: 'app',
                url: '/recent-programs',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/program/templates/view/recent/program-view-recent.html'
                    },
                    'checkedout@view-recent-programs': {
                        templateUrl: 'app/program/checkedout/list/checkedout-program-list.html',
                        controller: 'CheckedoutProgramController',
                        controllerAs: 'cpc'
                    },
                    'unversioned@view-recent-programs': {
                        templateUrl: 'app/program/unversioned/list/unversioned-program-list.html',
                        controller: 'UnversionedProgramController',
                        controllerAs: 'upc'
                    }
                }
            })
    }
})(window.angular);
