(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('ProgramPublisherService', ProgramPublisherService);

    ProgramPublisherService.$inject = ['$http', 'Principal', 'UriTemplate', 'UtilService'];

    function ProgramPublisherService($http, Principal, UriTemplate, utilService) {

        var programPublished = false;
        var loadingPublishHistory = false;

        return {
            getPublishablePrograms: getPublishablePrograms,
            publishProgram: publishProgram,
            getPublishHistory: getPublishHistory,
            activateDeactivateProgram: activateDeactivateProgram,
            onPublishCheck: onPublishCheck,
            setProgramPublished: setProgramPublished,
            isProgramPublished: isProgramPublished,
            isLoadingPublishHistory: isLoadingPublishHistory
        };

        function isLoadingPublishHistory() {
            return loadingPublishHistory;
        }

        function setProgramPublished(isPublished) {
            programPublished = isPublished;
            loadingPublishHistory = false;
        }

        function isProgramPublished() {
            return programPublished;
        }

        function getPublishablePrograms(emmiCode, config) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.publish + '/programs/publishable/{emmiCode}')
                    .stringify({emmiCode: emmiCode}), config
                ).then(function (response) {
                    return utilService.paginateResponse(response);
                });
            })
        }

        function getPublishHistory(emmiCode, systemName, config) {
            loadingPublishHistory = true;
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.published + '/history/{emmiCode}/{systemName}')
                    .stringify({emmiCode: emmiCode, systemName: systemName}), config
                ).then(function (response) {
                    return utilService.paginateResponse(response);
                });
            })
        }

        function publishProgram(publishRequest, config) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.publish + '/program')
                    .stringify(), publishRequest, config
                ).then(function (response) {
                    return utilService.paginateResponse(response);
                });
            })
        }

        function activateDeactivateProgram(programActivationRequest, config) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.publish + '/program/activation/')
                    .stringify(), programActivationRequest, config
                ).then(function (response) {
                    return response;
                });
            })
        }

        function onPublishCheck(emmiCode, version, systemName, config) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.publish + '/on-publish-check/{emmiCode}/{version}/{systemName}')
                    .stringify({emmiCode: emmiCode, version: version, systemName: systemName}), config
                ).then(function (response) {
                    return response;
                });
            })
        }
    }
})(window.angular);
