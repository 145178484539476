(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditSystemController', EditSystemController);

    EditSystemController.$inject = ['EditSystemService', 'savedSystemResource', 'saveCallback', 'errorCallback'];

    function EditSystemController(EditSystemService, savedSystemResource, saveCallback, errorCallback) {
        var esysc = this;

        esysc.save = save;
        esysc.copy = angular.copy(savedSystemResource);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditSystemService.update(esysc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
