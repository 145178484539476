(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewUrlTypeService', NewUrlTypeService);

    NewUrlTypeService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewUrlTypeService($http, Principal, UriTemplate) {

        return {
            create: create,
            newUrlType: newUrlType
        };

        function create(newUrlType) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.urltypes).stringify(),
                    newUrlType).then(function (response) {
                    return response.data;
                });
            })
        }

        function newUrlType() {
            return {
                name: '',
                active: false,
                created: new Date(),
                lastUpdated: new Date()
            }
        }

    }
})(window.angular);
