/**
 * Created by hzhang on 3/13/2017.
 */
(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditProductLineController', EditProductLineController);

    EditProductLineController.$inject = ['EditProductLineService', 'savedProductLineResource', 'saveCallback', 'errorCallback'];

    function EditProductLineController(EditProductLineService, savedProductLineResource, saveCallback, errorCallback) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedProductLineResource);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditProductLineService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
