(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditTypeAssetSetService', EditTypeAssetSetService);

    EditTypeAssetSetService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditTypeAssetSetService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(typeAssetSetResource) {
            LinkConverter.convertResource(typeAssetSetResource);
            if (typeAssetSetResource && typeAssetSetResource.link.self) {
                return $http.put(typeAssetSetResource.link.self, typeAssetSetResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
