(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('ProgramSummaryController', ProgramSummaryController);

    ProgramSummaryController.$inject = ['ProgramSummaryService', '$state', '$scope', '$modal', '$rootScope', '$timeout'];

    function ProgramSummaryController(programSummaryService, $state, $scope, $modal, $rootScope, $timeout) {
        var psc = this;
        psc.program = programSummaryService.getProgram($state.current.name);
        psc.isVersioned = programSummaryService.isVersioned;
        psc.isLoading = programSummaryService.isLoading;
        psc.isPrevent = isPrevent;
        psc.go = go;

        (function initialize() {
            if($state.current.name ==='view-article-details') {
                programSummaryService.loadArticle();
            }
            else {
                programSummaryService.loadProgram();
            }
        })();

        function isPrevent() {
            return psc.program.entity.type === 'IVR CALL' &&
                    (psc.program.entity.meta.ivrType.name === 'Outreach'
                        || psc.program.entity.meta.ivrType.name === 'Prevent');
        }

        function go(toState, emmiCode) {
            if (emmiCode) {
                $state.go(toState, {emmiCode: emmiCode});
            } else {
                $state.go(toState);
            }
        }

        function isInEditState(currentState) {
            return currentState.name;
        }

        function isFormDirty() {
            return $scope.programForm && $scope.programForm.$dirty;
        }

        $scope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams, options) {
            if (isInEditState(fromState) && isFormDirty()) {

                event.preventDefault();

                $modal({
                    templateUrl: 'app/modal/confirm/confirm.modal.html',
                    scope: $scope,
                    controller: 'ConfirmController',
                    controllerAs: 'cc',
                    resolve: {
                        title: function () {
                            return 'Exit without Saving?';
                        },
                        confirmText: function () {
                            return 'If you choose to continue, the unsaved changes to the original program will be removed.';
                        },
                        isWarning: function () {
                            return true;
                        },
                        warningText: function () {
                            return '';
                        },
                        confirmButtonText: function () {
                            return 'OK';
                        },
                        confirmed: function () {
                            return function () {
                                if(psc.program && psc.program.entity) {
                                    $state.go(toState.name, {emmiCode: psc.program.entity.emmiCode}, {
                                        notify: false
                                    }).then(function (state) {
                                        broadcastSuccess(state);
                                    });
                                } else {
                                    $state.go(toState.name, {}, {
                                        notify: false
                                    }).then(function (state) {
                                        broadcastSuccess(state);
                                    });
                                }
                            }
                        }
                    }
                });
            }
        });

        function broadcastSuccess(state) {
            $rootScope.$broadcast('$stateChangeSuccess', state, null);
        }

        window.onbeforeunload = function () {
            if (isFormDirty()) {
                return 'If you choose to continue, the unsaved changes to the original program will be removed.';
            }
        };
    }
})(window.angular);
