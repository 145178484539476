(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewConfigService', NewConfigService);

    NewConfigService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewConfigService($http, Principal, UriTemplate) {

        return {
            create: create,
            newConfig: newConfig
        };

        function create(newConfig) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.configs).stringify(),
                    newConfig).then(function (response) {
                    return response.data;
                });
            })
        }

        function newConfig() {
            return {
                name: ''
            }
        }

    }
})(window.angular);
