(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditCountryService', EditCountryService);

    EditCountryService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditCountryService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(countryResource) {
            LinkConverter.convertResource(countryResource);
            if (countryResource && countryResource.link.self) {
                return $http.put(countryResource.link.self, countryResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
