(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditDynaviewController', EditDynaviewController);

    EditDynaviewController.$inject = ['EditDynaviewService', 'savedResource', 'entityForEditResource', 'resourceProviderResource', 'resourceEntryIdResource', 
					'saveCallback', 'errorCallback'];
	
    function EditDynaviewController(EditDynaviewService, savedResource, entityForEditResource, resourceProviderResource, resourceEntryIdResource, 
			saveCallback, errorCallback) {
	
        var EditCtrl = this;

        EditCtrl.save = save;
		
		if(savedResource == null)
			EditCtrl.copy = EditDynaviewService.newDataResource();
		else
			EditCtrl.copy = angular.copy(savedResource);
		
		EditCtrl.entityForEdit = angular.copy(entityForEditResource);
		EditCtrl.entityDef = getEntityDefOf(EditCtrl.entityForEdit);
		
		//EditCtrl.resProviderName = angular.copy(resourceProviderResource);
		//EditCtrl.theResEntryId = angular.copy(resourceEntryIdResource);
		
		EditCtrl.resProvider = getResProviderOf( angular.copy(resourceProviderResource) );
		EditCtrl.theResource = getResourceOf(EditCtrl.resProvider.resourceList, angular.copy(resourceEntryIdResource));
		
        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditDynaviewService.save(EditCtrl.theResource.uri, EditCtrl.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
		
		EditCtrl.formFieldType = function (entityFieldType) {
			var fieldtype = "text";
			var constobj = getConstantOf(entityFieldType);
			if(constobj != null && constobj.hasOwnProperty("values") && constobj.values.constructor === Array) 
				fieldtype = "select";
			else
				fieldtype = "text";
			
			return fieldtype;
		};
		
		EditCtrl.formSelectOptions = function (entityFieldType) {
			var options = [];
			var constobj = getConstantOf(entityFieldType);
			if(constobj != null && constobj.values.constructor === Array) {
				options = constobj.values;
			}
			
			return options;
		};
    }
})(window.angular);
