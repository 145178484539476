(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewTypeAssetSetService', NewTypeAssetSetService);

    NewTypeAssetSetService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewTypeAssetSetService($http, Principal, UriTemplate) {

        return {
            create: create,
            newTypeAssetSet: newTypeAssetSet
        };

        function create(newTypeAssetSet) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.typeassetsets).stringify(),
                    newTypeAssetSet).then(function (response) {
                    return response.data;
                });
            })
        }

        function newTypeAssetSet() {
            return {}
        }

    }
})(window.angular);
