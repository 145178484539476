(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewSystemController', NewSystemController);

    NewSystemController.$inject = ['NewSystemService', 'ProgramInitService', '$scope'];

    function NewSystemController(NewSystemService, programInitService, $scope) {
        var nsysc = this;

        nsysc.save = save;
        nsysc.newSystem = NewSystemService.newSystem();
        nsysc.data = programInitService.getSystems();

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewSystemService.create(nsysc.newSystem).then(function (response) {
                    $scope.$emit('new-system', response);
                    nsysc.data.systems.content.push(response);
                })
            }
        }
    }

})(window.angular);
