(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('PublishController', PublishController);

    PublishController.$inject = ['PublishService', 'publishResource', 'successCallback', 'errorCallback'];

    function PublishController(publishService, publishResource, successCallback, errorCallback) {
        var pc = this;
        pc.publishReferences = publishReferences;

        function publishReferences($hide) {
            $hide();
            publishService.publishReferences(publishResource).then(function (response) {
                successCallback();
            }, errorCallback);
        }
    }
})(window.angular);
