/**
 * Created by hzhang on 3/13/2017.
 */
(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditProductLineService', EditProductLineService);

    EditProductLineService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditProductLineService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(productLineResource) {
            LinkConverter.convertResource(productLineResource);
            if (productLineResource && productLineResource.link.self) {
                return $http.put(productLineResource.link.self, productLineResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
