(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('VersionedProgramController', VersionedProgramController);

    VersionedProgramController.$inject = ['ProgramService', 'ProgramVersionService', '$state', 'AlertService', 'UtilService', '$sessionStorage'];

    function VersionedProgramController(programService, programVersionService, $state, alertService, utilService, $sessionStorage) {
        var vpc = this;
        vpc.searchProgram = null;

        vpc.createNewNavigation = createNewNavigation;
        (function onControllerLoad() {
            vpc.apiStatus = "nullState";
            vpc.search = search;
            vpc.nextPage = nextPage;
            vpc.nextEditionsPage = nextEditionsPage;
            vpc.hasVisible = hasVisibleData;
            vpc.createNewProgram = createNewProgram;
            vpc.showProgramHistory = showProgramHistory;
            vpc.showArticle = showArticle;
            vpc.restoreSearch = restoreSearch;
            vpc.clearSearch = clearSearch;

            vpc.collapseVersions = false;
            vpc.alphaNumeric = /^[a-zñáéíóúü0-9%&_+\-\[\] "]+$/i;
            vpc.createTypes = [
                {name:'Emmi Journey',value:'edit-digital-journey-details'},
                {name:'Form',value:'edit-form-details'},
                {name:'Form Edition',value:'edit-form-edition-details'},
                {name:'IVR Call', value: 'edit-ivr-details'},
                {name:'Multimedia Program', value:'edit-program-details'},
                {name:'Transition Program', value: 'edit-transition-details'},
                {name:'Video',value:'edit-video-program-details'},
                {name:'Web Program',value:'edit-web-program-details'}
            ];
            restoreSearch();

        })();

        function restoreSearch(){
            var sort = $sessionStorage.programSortParam || 'emmiCode';
                vpc.searchProgram = $sessionStorage.programSearchTerm;
                vpc.type = $sessionStorage.programType || 'All';
                if ($sessionStorage.currentPage){
                    if (vpc.type === 'Form Edition'){
                        nextEditionsPage($sessionStorage.currentPage);
                    } else {
                        nextPage($sessionStorage.currentPage);
                    }
                } else {
                    search(sort);
                }


        }
        function clearSearch(){
            vpc.searchProgram = '';
            $sessionStorage.currentPage = null;
            search('emmiCode');
        }

        function nextPage(link) {
            if (vpc.programs && link === vpc.programs.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                vpc.loading = true;
                utilService.fetchPage(link).then(ok, finalize)
                    .finally(function(){
                    $sessionStorage.currentPage = link;
                });
            }

            function ok(response) {
                if (response) {
                    vpc.programs = response;
                }
                finalize();
            }
        }

        function finalize() {
            vpc.loading = false;
        }

        function nextEditionsPage(link) {
            if (vpc.editions && link === vpc.editions.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                vpc.loading = true;
                utilService.fetchPage(link).then(ok, finalize)
                    .finally(function(){
                        $sessionStorage.currentPage = link;
                });
            }

            function ok(response) {
                if (response) {
                    vpc.editions = response;
                }
                finalize();
            }
        }

        function hasVisibleData() {
            var hasVisible = false,
                length = vpc.programs && vpc.programs.content &&
                    vpc.programs.content.length;
            for (var i = 0; i < length; i++) {
                if (!vpc.programs.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }
        function createNewNavigation(arg) {
            programService.createNewProgram(arg);
            $state.go(arg, {emmiCode: -1, version: 'v0.0.0'});
        }

        function search(sort) {
            vpc.apiStatus = (vpc.type + '-fail').toLowerCase();
            vpc.loading = true;
            // UPDATE HERE WITH VERSIONED EDITIONS API
            if (vpc.type === 'Form Edition') {
                var sortParam = sort || 'editionId';
                programVersionService.loadVersionedEditions(getConfig(sort))
                    .then(function (response) {
                        vpc.editions = response;
                        vpc.apiStatus = (vpc.type + '-pass').toLowerCase();
                    })
                    .catch(function(response) {
                        alertService.error(response, "Search");
                    })
                    .finally(function () {
                        $sessionStorage.currentPage = null;
                        $sessionStorage.programSortParam = sortParam;
                        $sessionStorage.programSearchTerm = vpc.searchProgram;
                        $sessionStorage.programType = vpc.type;
                        delete vpc.loading;
                    });
            } else {
                var sortParam = sort || 'emmiCode';
                programVersionService.loadVersionedPrograms(getConfig(sortParam))
                    .then(function (response) {
                        vpc.programs = response;
                        vpc.apiStatus = (vpc.type + '-pass').toLowerCase();
                    })
                    .catch(function(response) {
                        alertService.error(response, "Search");
                    })
                    .finally(function () {
                        $sessionStorage.currentPage = null;
                        $sessionStorage.programSortParam = sortParam;
                        $sessionStorage.programSearchTerm = vpc.searchProgram;
                        $sessionStorage.programType = vpc.type;
                        delete vpc.loading;
                    });
            }
        }

        function getConfig(sort) {
            var config = {
                params: {
                    search: vpc.searchProgram,
                    sort: sort,
                    type: vpc.type,
                    size: 10
                }
            };
            return config;
        }

        function createNewProgram() {
            programService.createNewProgram();
            $state.go('edit-program-details', {emmiCode: -1, version: 'v0.0.0'});
        }

        function showProgramHistory(program) {
            var emmiCode = program.entity.emmiCode;
            $state.go('view-program-details', {emmiCode: emmiCode});
        }

        function showArticle(article) {
            var emmiCode = article.entity.emmiCode;
            $state.go('view-article-details', {emmiCode: emmiCode});
        }
    }
})(window.angular);
