(function (angular) {
    'use strict';

    angular.module('Composer')
        .factory('UtilService', UtilService)
        .directive('svgImage', svgImage)
        .filter('titleCase', titleCase)
        .filter('trustedHtml', trustedHtml);

    svgImage.$inject = ['$http'];

    UtilService.$inject = ['$http', '$q', 'PaginationService','$sce'];

    function titleCase() {
        return function(input) {
            input = input || '';
            return input.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        };
    }

    function UtilService($http, $q, paginationService) {

        return {
            removeAt : removeAt,
            formatVersion : formatVersion,
            defaultIfEmptyContent: defaultIfEmptyContent,
            fetchPage : fetchPage,
            fetchPageWithConfig: fetchPageWithConfig,
            paginateResponse: paginateResponse,
            newProgram: newProgram,
            newVersion: newVersion,
            isValidVersion: isValidVersion,
            openInNewTab: openInNewTab,
            isArrayNotEmpty: isArrayNotEmpty,
            deDupeAndClean: deDupeAndClean,
            prettyPrint: prettyPrint,
            findIndex:findIndex,
            isEmpty: isEmpty,
            validURL: validURL,
            parseKeywords: parseKeywords,
            cleanKeywords: cleanKeywords,
            navigate: navigate,
            discardMessage: discardMessage,
            isValidVersionProgram: isValidVersionProgram,
            canBeDiscarded: canBeDiscarded,
            getQuestionType: getQuestionType,
            removeInvalid: removeInvalid,
            httpFailureMessage: httpFailureMessage,
        };

        function httpFailureMessage(status) {
          var msg = "";

          switch(status) {
            case 500:
              msg = "We were unable to complete this operation because of an error on the application server, try again later or open a bug ticket with the Editorial team (status 500)";
              break;
            case 400:
              msg = "The data sent to the application server to complete this operation was malformed for some reason, open a bug ticket if this persists (status 400)";
              break;
            case 401: case 402: case 407:
              msg = "You were denied network authorization needed to complete this operation, open a bug ticket if this issue persists (status " + status + ")";
              break;
            case 404: case 410:
              msg = "The network location of the application server needed to complete this operation has been moved, open a bug ticket if this issue persists (status " + status + ")";
              break;
            case 405:
              msg = "The wrong kind of network request was made needed to complete this operation, open a bug ticket if this issue persists (status 405)";
              break;
            case 408:
              msg = "The application server needed to complete this operation failed to respond to your network request, open a bug ticket if this issue persists (status 408)";
              break;
            default:
              msg = "We encountered an issue processing the network request needed to complete this operation, try again later or open a bug ticket if this issue persists" + (status ? "(status " + status + ")" : "");
          }

          return msg
        }

        function getQuestionType(selectedQuestion) {
            if (selectedQuestion){
                var responseTypeId = selectedQuestion.responseTypeId || 1;
                if(responseTypeId === 1) {
                    return 'selection';
                } else if(responseTypeId === 2) {
                    return 'text';
                } else if(responseTypeId === 3) {
                    return 'numeric';
                } else if(responseTypeId === 4) {
                    return 'decimal'
                }
            }
            return 'unspecified';
        }

        function isArrayNotEmpty(array) {
            // the array is defined and has at least one element
            return (typeof array !== 'undefined' && array.length > 0);
        }

        function openInNewTab(url) {
            var win = window.open(url, '_blank');
            win.focus();
        }

        function isValidVersion(version) {
            return version && version !== 'v0.0.0' && version !== 'not-versioned';
        }

        function defaultIfEmptyContent(response) {
            var parsedResponse = response.data;
            if (!parsedResponse.content) {
                parsedResponse.content = [];
            }
            return parsedResponse;
        }

        function paginateResponse(response) {
            var parsedResponse = paginationService.handleResponse(response.data);
            if (!parsedResponse.content) {
                parsedResponse.content = [];
            }
            return parsedResponse;
        }

        function removeAt(idx, arr) {
            arr.splice(idx, 1);
        }

        function formatVersion(entity) {
            if (entity && entity.version) {
                return 'v'
                    + entity.version.major
                    + '.'
                    + entity.version.minor
                    + '.'
                    + entity.version.working;
            } else {
                return 'not-versioned';
            }
        }

        function fetchPage(linkToFetch) {
            if (linkToFetch) {
                return $http.get(linkToFetch).then(function (response) {
                    return paginationService.handleResponse(response.data);
                });
            }
            return $q.reject('no link to fetch');
        }

        function fetchPageWithConfig(linkToFetch, config) {
            if (linkToFetch) {
                return $http.get(linkToFetch, config).then(function (response) {
                    return paginationService.handleResponse(response.data);
                });
            }
            return $q.reject('no link to fetch');
        }

        function newProgram(programType) {
            var meta = {
                brand: {},
                source: {},
                specialties: [],
                productLines: [],
                contentSubscriptions: [],
                workflowSubscriptions: [],
                patientFacingNames: [],
                keywords: []
                };
            var typeName = '';

            if(programType) {
                meta.typeId = programType.id;
                typeName = programType.type;
                if(programType.type === 'MULTIMEDIA PROGRAM') {
                    meta.editions = [];
                    meta.eplayerAvailable = true;
                    meta.eplayerMobileEnabled = true;
                }
                if(programType.type === 'IVR CALL') {
                    meta.ivrType = {};
                    meta.questions = [];
                }
                if(programType.type === 'TRANSITION PROGRAM') {
                    meta.components = [];
                    meta.finalDayList = [];
                    meta.circleList = [];
                    meta.daysToEnd = 0;
                }
                if(programType.type === 'FORM EDITION') {
                    meta.editions = [];
                    meta.formEditionMetaData = {
                        formParams: {
                            firstModuleRoute: '',
                            formTitle: '',
                            formQuestions: []
                        },
                        formModules: [],
                        assetSet: {},
                        wipGlobalModules: null
                    }
                }
                if(programType.type === 'FORM'){
                    meta.editions = [];
                }
                if(programType.type === 'CAREPLAN'){
                    meta.editions = [];
                }
                if(programType.type === 'VIDEO'){
                    meta.editions = [];
                }
                if (programType.type === 'WEB PROGRAM') {
                    meta.trackingStart = {
                        code: 1,
                        description: 'When the URL is opened'
                    }
                    meta.trackingComplete = {
                        code: 1,
                        description: 'When the URL is opened'
                    }
                }
            }

            return {
                entity: {
                    name: '',
                    type: typeName,
                    meta: meta
                }
            }
        }

        function newVersion() {
            return {
                major: 0,
                minor: 0,
                working: 0,
                comment: ''
            }
        }
    }

    function prettyPrint(json) {
        return JSON.stringify(json, undefined, 2);
    }

    function svgImage($http) {
        return {
            restrict: 'E',
            link: function(scope, element) {
                var imgURL = element.attr('src');
                // if you want to use ng-include, then
                // instead of the above line write the bellow:
                // var imgURL = element.attr('ng-include');
                var request = $http.get(
                    imgURL,
                    {'Content-Type': 'application/xml'}
                );

                scope.manipulateImgNode = function(data, elem){
                    var $svg = angular.element(data)[2];
                    var imgClass = elem.attr('class');
                    if(typeof(imgClass) !== 'undefined') {
                        var classes = imgClass.split(' ');
                        for(var i = 0; i < classes.length; ++i){
                            $svg.classList.add(classes[i]);
                        }
                    }
                    $svg.removeAttribute('xmlns:a');
                    return $svg;
                };

                request.success(function(data){
                    element.replaceWith(scope.manipulateImgNode(data, element));
                });
            }
        };
    }

    // find the element
    function findIndex(array, column, val) {
        var obj;
        for (var i = 0; i < array.length; i++) {
            if (array[i][column] === val) {
                obj = array[i]
            }
        }
        return obj;
    }

    function isEmpty(obj) {
        for(var key in obj) {
            if(obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    
    function deDupeAndClean(original, removeSpaces, caseSensitive){
            var parsedList = '';
            if (original !== undefined && original !== null){
                if (caseSensitive){
                    parsedList = original.replace(/\s\s+/g, ' '); // Removes Double Spaces
                } else {
                    parsedList = original.replace(/\s\s+/g, ' ').toLowerCase(); // Removes Double Spaces and forces all values to lowercase
                }

                parsedList = parsedList.replace(/,+/g, ','); // Removes Excess Commas
                // Removes double+ commas
                if (parsedList.charAt(parsedList.length - 1) === ','){  // Removes comma at the end
                    parsedList = parsedList.slice(0, -1);
                }
                if (parsedList.charAt(0) === ','){  // Removes comma at the beginning
                    parsedList = parsedList.slice(1);
                }
                parsedList = parsedList.replace(/,+/g, ','); // Removes Excess Commas
                parsedList = parsedList.replace(/\s,/g, ','); // Removes Extra space before a comma
                parsedList = parsedList.replace(/,\s/g, ','); // Removes Extra space after a comma


                if (removeSpaces) {
                    parsedList = parsedList.replace(/\s/g, ''); // removes all spaces
                }
                    var parsedArray = parsedList.split(',');
                    if (parsedArray) {
                        for (var i = 0; i < parsedArray.length; i++) {

                            for (var j = 0; j < parsedArray.length; j++) {
                                if (i !== j){
                                    if (parsedArray[i] === parsedArray[j]){
                                    parsedArray.splice(j, 1); // Dedupes list
                                    }
                                }
                            }
                        }
                    }
                    parsedList = parsedArray.toString();
                return parsedList;
            } else {
                parsedList = null;
                return parsedList;
            }
        }

    function validURL(URL) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

        var isValid = pattern.test(URL);
        if (URL === '') {
            isValid = true;
        }
        return isValid;
    }

    function parseKeywords(ctrl){
        ctrl.keywords = ctrl.keywords.replace(', ', ',');
        ctrl.keywords = ctrl.keywords.replace(' ,', ',');
        ctrl.keywords = ctrl.keywords.replace(',,', ',');
        return ctrl.keywords;
    }

    function navigate(emmiCode, versionedToLocal, state) {
        if (versionedToLocal) {
            state.go('view-program-details', { emmiCode: emmiCode });
        }
        else {
            state.go('home');
        }
    }
    function cleanKeywords(keywords){
        var blank = keywords.indexOf('');
        if (blank !== -1){
            keywords.splice(blank,1);
        }
        return keywords;
    }

    function discardMessage(ctrl) {
        var message = "Delete this program? You will no longer be able to access it once you discard it.";
        if (ctrl.program().entity.versionedToLocal) {
            message = "Revert the local save of this program? This will not affect previously created versions of the program.";
        }
        return message;
    }
    function canBeDiscarded(ctrl) {
        var entity = ctrl.program().entity;
        if (!isValidVersionProgram(entity.version)) {
            if (!entity.emmiCode) {
                return false;
            }
            return true;
        }
        return false;
    }

    function isValidVersionProgram(version) {
        return version && !(version.major == "0" && version.minor == "0" && version.working == "0");
    }

    function removeInvalid(type,activeQuestion, metaField){
        var value = activeQuestion[metaField];
        value =  value.replace( /[^\d\.]*/g, '');
        if (type === 'numbersOnly') {
            value =  value.replace( /[^\d]*/g, '');
        } else if (type === 'decimal') {
            var firstIndex = value.indexOf('.');
            if (firstIndex !== -1){
                value =  value.replace(/([.])/g, '');
                var valueTemp = value.slice(0, firstIndex) + '.' + value.slice(firstIndex);
                value = valueTemp;
            }
        }
        return value;
    }

    function trustedHtml($sce){
        return function(input) {
            return $sce.trustAsHtml(input);
        };
    }
    
})(window.angular);
