(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditSpecialtyController', EditSpecialtyController);

    EditSpecialtyController.$inject = ['EditSpecialtyService', 'savedSpecialtyResource', 'saveCallback', 'errorCallback'];

    function EditSpecialtyController(EditSpecialtyService, savedSpecialtyResource, saveCallback, errorCallback) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedSpecialtyResource);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditSpecialtyService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
