(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('PerspectiveController', PerspectiveController);

    PerspectiveController.$inject = ['PerspectiveService'];

    function PerspectiveController(PerspectiveService) {
        var Perspectives = this;

        (function onControllerLoad() {
            Perspectives.nextPage = nextPage;
            Perspectives.hasVisible = anyVisiblePerspectives;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                Perspectives.loadingNextPage = true;
                PerspectiveService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    Perspectives.loadingNextPage = false;
                } else {
                    Perspectives.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                Perspectives.loadingNextPage = true;
            }
        }

        function anyVisiblePerspectives() {
            var hasVisible = false,
                length = Perspectives.perspectives && Perspectives.perspectives.content &&
                    Perspectives.perspectives.content.length;
            for (var i = 0; i < length; i++) {
                if (!Perspectives.perspectives.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            Perspectives.loading = true;
            PerspectiveService.search()
                .then(function (response) {
                    Perspectives.perspectives = response;
                    //Perspectives.perspectives = JSON.stringify(response);
                })
                .finally(function () {
                    delete Perspectives.loadingNextPage;
                    delete Perspectives.loading;
                });
        }

    }
})(window.angular);
