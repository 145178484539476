(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('ImportGlobalModuleController', ImportGlobalModuleController);

    ImportGlobalModuleController.$inject = ['UtilService', 'fullList', 'AlertService', 'FormEditionService', 'saveCallback', 'fec', 'GlobalModulesService', 'existingModule'];

    function ImportGlobalModuleController(utilService, fullList, alertService, formEditionService, saveCallback, fec, globalModulesService, existingModule) {
        var igmc = this;
        igmc.save = save;
        igmc.saveCallback = saveCallback;
        igmc.closeModal = closeModal;
        igmc.formSubmitted = false;
        igmc.globalModulesList = [];
        igmc.selectedGlobalModule = null;
        igmc.selectedGlobalModuleParsed = {};
        igmc.isExistingModule = false;
        igmc.setSelectedGM = setSelectedGM;
        igmc.globalModulesList = formEditionService.getExistingGlobalModules();
        igmc.isDupe = false;
        (function init() {
            if (existingModule){
                igmc.isExistingModule = true;
                setSelectedGM();
            }
        })();

        function setSelectedGM() {
            if (igmc.isExistingModule){
                globalModulesService.getModuleConfig(existingModule.globalModuleName).then(function(data){
                    igmc.selectedGlobalModuleParsed = data;
                });
            }
            else if (igmc.selectedGlobalModule){
                globalModulesService.getModuleConfig(JSON.parse(igmc.selectedGlobalModule).name).then(function(data){
                    igmc.selectedGlobalModuleParsed = data;
                    isDupe();
                });
            }
        }
        //Save the modal for module and element
        function save($hide) {
            igmc.formSubmitted = true;
            igmc.isFormValid = igmc.selectedGlobalModuleParsed.name !== undefined;
            if (igmc.isFormValid) addGlobalModule();
            igmc.closeModal($hide);


        }

        function addGlobalModule() {
            var globalModuleQuestions = igmc.selectedGlobalModuleParsed.globalModuleMetaDataParsed.formQuestions;
            var globalModule = igmc.selectedGlobalModuleParsed.globalModuleMetaDataParsed.module;
            globalModule.globalModuleName = igmc.selectedGlobalModuleParsed.name;
            globalModule.globalVersion = igmc.selectedGlobalModuleParsed.version;
            var existingFormEdition = fec.program().entity.meta.formEditionMetaData;

            // Removes existing questions that are included in the global module.
            for (var i = 0; i < globalModuleQuestions.length; i++){
                if (existingFormEdition.formParams.formQuestions){
                    for (var j = 0; j < existingFormEdition.formParams.formQuestions.length; j++){
                        if (globalModuleQuestions[i].questionId === existingFormEdition.formParams.formQuestions[j].questionId) {
                            existingFormEdition.formParams.formQuestions.splice(j, 1);

                        }
                    }
                } else existingFormEdition.formParams.formQuestions = [];
            }

            // pushes global module questions to the form edition
            for (i = 0; i < globalModuleQuestions.length; i++) {
              existingFormEdition.formParams.formQuestions.push(globalModuleQuestions[i]);
            }

            if (!existingFormEdition.formModules) existingFormEdition.formModules = [];

            // Pushes deletes the outgoing module route the global module to the form edition
            if(igmc.isExistingModule) {
                var existingModuleIndex = existingFormEdition.formModules.indexOf(existingModule);
                globalModule.defaultNextModuleRoute = existingModule.defaultNextModuleRoute ? existingModule.defaultNextModuleRoute.toString() : null ;
                globalModule.isGlobalModule = true;
                globalModule.linkedToGlobal = true;
                globalModule.globalVersionLatest = igmc.selectedGlobalModuleParsed.version;
                existingFormEdition.formModules[existingModuleIndex] = Object.assign({}, globalModule);
                existingFormEdition.formModules[existingModuleIndex] = globalModule;
                removeOrphanQuestions(existingFormEdition);
                alertService.customSuccess('Successfully updated ' + globalModule.globalModuleName + ' to the latest version.');
                formEditionService.setModuleConfig(Object.assign({}, globalModule));
                igmc.saveCallback();
            } else {
                delete globalModule.defaultNextModuleRoute;
                globalModule.isGlobalModule = true;
                globalModule.linkedToGlobal = false;
                globalModule.globalVersionLatest = igmc.selectedGlobalModuleParsed.version;
                globalModule.globalVersion = 0;
                existingFormEdition.formModules.push(Object.assign({}, globalModule));
                alertService.customSuccess('Successfully imported ' + globalModule.globalModuleName + ' to the form edition.');
            }

        }

        //close modal while clicking cancel
        function closeModal($hide) {
            $hide();

        }
        function isDupe(){
            igmc.isDupe = false;
            var existingFormEdition = fec.program().entity.meta.formEditionMetaData;
            var selectedModule = igmc.selectedGlobalModuleParsed.globalModuleMetaDataParsed.module;
            existingFormEdition.formModules.map(function(module){
               if (module.moduleRouteName === selectedModule.moduleRouteName|| (module.globalModuleName && module.globalModuleName === igmc.selectedGlobalModuleParsed.name)){
                   igmc.isDupe = true;
               }
            })
        }

      function removeOrphanQuestions(existingFormEdition) {
          const existingQuestionIds = existingFormEdition.formModules.reduce(function(questionIds, module) {
            return module.formElements.reduce(function(questionIds, element) {
              const questionId = element.questionId;

              if (!questionId) return questionIds;
              questionIds.push(questionId);
              return questionIds;
            },
            questionIds);
          },
          []);

          const orphanQuestions = existingFormEdition.formParams.formQuestions.reduce(function(orphanQuestions, question) {
            const qID = question.questionId;
            const isOrphan = !existingQuestionIds.find(function(existingQID) {
              return qID === existingQID
            });

            if (isOrphan) {
              orphanQuestions.push(question);
            }

            return orphanQuestions;
          },
          []);

          // Remove orphan Form Question instances
          for (var i = 0, orphanIdx, orphanQuestion; orphanQuestion = orphanQuestions[i]; i++) {
            orphanIdx = existingFormEdition.formParams.formQuestions.indexOf(orphanQuestion);
            existingFormEdition.formParams.formQuestions.splice(orphanIdx, 1);
          }
      }

    }
})(window.angular);
