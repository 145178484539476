(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('addNewNarratorModal', addNewNarratorModal);

    addNewNarratorModal.$inject = ['$modal', '$alert'];

    function addNewNarratorModal($modal, $alert) {

        return {
            restrict: 'A',
            link: link,
            scope: {
                fullList: '='
            }
        };

        function link(scope, element) {
            element.on('click', function () {
                $modal({
                    templateUrl: 'app/reference/narrator/new/new.narrator.modal.html',
                    scope: scope
                })
            });
            scope.$on('new-narrator', function (event, data) {
                newNarratorAdded(event, {
                    added: data,
                    fullList: scope.fullList
                })
            });
        }

        function newNarratorAdded(event, data) {
            $alert({
                content: [
                    'Narrator \'', data.added.entity.name, '\' created successfully.'
                ].join('')
            });
            data.fullList.push(data.added);
        }
    }
})(window.angular);
