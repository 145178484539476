(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('delEntity', delEntity);

    delEntity.$inject = ['$modal', '$alert'];

    function delEntity($modal, $alert) {
        return {
            restrict: 'A',
            link: link,
            scope: {
				//resourceProvider: '@resourceProvider', 
				//resourceEntryId: '@resourceEntryId', 
				entForDelete: '@entForDelete', 
                toBeDeleted: '=toBeDeleted'
            }
        };
        function link(scope, element, attr) {
            element.on('click', function () {
                $modal({
                    templateUrl: 'app/dynaview/delete.dynaview.modal.html',
                    scope: scope,
                    controller: 'DelDynaviewController',
                    controllerAs: 'delCtrl',
                    resolve: {
                        deleteResource: function () {
                            return scope.toBeDeleted;
                        }, 
						
						entForDeleteResource : function () {
							return scope.entForDelete
						}, 
						/*
						resourceProviderResource: function() {
							return scope.resourceProvider
						}, 
						resourceEntryIdResource: function() {
							return scope.resourceEntryId
						}, */
                        successCallback: function () {
                            return function () {
                                var obj = scope.entityForDelete;
                                $alert({
                                    content: [
                                        obj, '\' removed successfully.'
                                    ].join('')
                                });
                            }
                        },
                        errorCallback: function () {
                            return function() {
                                var obj = scope.entityForDelete;
                                $alert({
                                    type: 'danger',
                                    content: [
                                        obj, '\' not removed.'
                                    ].join('')
                                });
                            }
                        }
                    }
                })
            });
        }
    }
})(window.angular);