(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('ConfigController', ConfigController);

    ConfigController.$inject = ['ConfigService'];

    function ConfigController(ConfigService) {
        var ConfigCtrl = this;

        (function onControllerLoad() {
            ConfigCtrl.nextPage = nextPage;
            ConfigCtrl.hasVisible = anyVisibleConfigs;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                ConfigCtrl.loadingNextPage = true;
              ConfigService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    ConfigCtrl.loadingNextPage = false;
                } else {
                    ConfigCtrl.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                ConfigCtrl.loadingNextPage = true;
            }
        }

        function anyVisibleConfigs() {
            var hasVisible = false,
                length = ConfigCtrl.configs && ConfigCtrl.configs.content &&
                    ConfigCtrl.configs.content.length;
            for (var i = 0; i < length; i++) {
                if (!ConfigCtrl.configs.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            ConfigCtrl.loading = true;
            ConfigService.search()
                .then(function (response) {
                    ConfigCtrl.configs = response;
                })
                .finally(function () {
                    delete ConfigCtrl.loadingNextPage;
                    delete ConfigCtrl.loading;
                });
        }

    }
})(window.angular);
