(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('AlertService', AlertService);

    AlertService.$inject = ['$alert'];

    function AlertService($alert) {

        return {
            error: error,
            success: success,

            customError: customError,
            customSuccess: customSuccess,

            removeAlert: removeAlert
        };

        function removeElement(element) {
            if(element) {
                angular.element(element).remove();
            }
        }

        function removeAlert() {
            var myalert = document.getElementsByClassName('alert')[0];
            removeElement(myalert);
        }

        function customError(errorMessage) {
            removeAlert();
            $alert({
                type: 'danger',
                duration: 30,
                content: 'ERROR! '+errorMessage
            });
        }

        function customSuccess(msg) {
            removeAlert();
            $alert({
                duration: 15,
                content: msg
            });
        }

        function error(error, operation) {
            removeAlert();
            var errorMessage = error.message;
            if (error.data && error.data.errorMessage) {
                errorMessage = error.data.errorMessage;
            } else if (error.errorMessage) {
                errorMessage = error.errorMessage;
            } else if(error.statusText) {
                errorMessage = error.statusText;
            } else if(error) {
                errorMessage = error;
            }
            var message = 'ERROR! Unable to ' + operation;
            if(!errorMessage) {
                errorMessage = "Unknown: Consult the Support team for more details."
            }
            message = message + ', Reason: ' + errorMessage;
            $alert({
                type: 'danger',
                duration: 30,
                content: message
            });
        }

        function success(msg, operation) {
            removeAlert();
            $alert({
                duration: 15,
                content: [
                    'SUCCESS! '+ msg + ' ' + operation + ' successful.'
                ].join('')
            });
        }
    }
})(window.angular);
