(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('ivrQuestionModal', ivrQuestionModal);

    ivrQuestionModal.$inject = ['$modal'];

    function ivrQuestionModal($modal) {

        return {
            restrict: 'A',
            link: link,
            scope: {
                 params: '=',
                 item: "=",
                 index: "="

            }
        };

        function link(scope, element) {
            var modal = {};

            element.on('click', function () {
                modal = $modal({
                    templateUrl: 'app/program/create/ivr/ivr-question-modal/ivr-question-modal.html',
                    scope: scope
                })
            });

            element.on('$destroy', function(){
                if(modal.hide !== undefined) {
                    modal.hide();
                }
            });
        }



    }
})(window.angular);
