(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('FormElementController', FormElementController);

    FormElementController.$inject = ['UtilService', 'AlertService', 'FormEditionService', 'saveCallback', 'fec', 'ModulesService', 'ProgramService','$scope'];

    function FormElementController(utilService, alertService, formEditionService, saveCallback, fec, modulesService, programService,$scope) {
        var fel = this;

        // Retrieve Module/Element/Question states
        fel.activeQuestion = modulesService.getActiveQuestion();
        fel.activeQuestionPreEditState = modulesService.getActiveQuestionPreEditState();
        fel.questionConfiguration = modulesService.getQuestionConfiguration();
        fel.activeElement = modulesService.getActiveElement();
        fel.activeElementPreEditState = modulesService.getActiveElementPreEditState();
        fel.elementConfiguration = modulesService.getElementConfiguration();
        fel.activeModule = modulesService.getActiveModule();
        fel.activeModulePreEditState = modulesService.getActiveModulePreEditState();
        fel.moduleConfiguration = modulesService.getModuleConfiguration();
        fel.moduleList = modulesService.getModuleList();
        fel.elementList = modulesService.getElementList();
        fel.questionList = modulesService.getQuestionList();
        programService.loadExistingQuestions();
        fel.tempQuestions = fel.questionList ? JSON.parse(JSON.stringify(fel.questionList)) : [];
        // Handling Methods
        fel.setQuestion = modulesService.setQuestion;
        fel.saveQuestion = saveQuestion;
        fel.loadBlankQuestion = modulesService.setNewQuestion;
        fel.loadExistingQuestion = modulesService.setExistingQuestion;
        fel.addResponse = modulesService.addResponse;
        fel.removeResponse = modulesService.removeResponse;
        fel.routingConfig = modulesService.routingConfig;
        fel.addCondition = addCondition;
        fel.removeConditon = removeCondition;
        fel.addConditionRow = addConditionRow;
        fel.addAndOrCondition = addAndOrCondition;
        fel.validateForm = formEditionService.validateForm;
        fel.rebuildRouting = rebuildRouting;
        fel.removeAndOrCondition = removeAndOrCondition;
        fel.applyQuestion = applyQuestion;
        fel.isDupeQuestion = isDupeQuestion;
        fel.missingResponses = missingResponses;
        fel.isQIdChanged = isQIdChanged;
        fel.copyExistingQId = copyExistingQId;
        fel.toggleExistingQId = toggleExistingQId;
        fel.isItQuestion = isItQuestion;
        fel.type = 'element';
        fel.editingQuestion = fel.questionConfiguration.addFlag; // Sets UI active/inactive default state for Question
        fel.save = save;
        fel.saveCallback = saveCallback;
        fel.closeModal = closeModal;
        fel.isFormValid = false;
        fel.formSubmitted = false;
        fel.isDupe = false;
        fel.validateName = validateName;
        fel.setDisplayType = setDisplayType;
        fel.editingQuestion = false;
        fel.editingQuestionToggle = editingQuestionToggle;
        fel.missingQuestion = false;
        fel.validateQuestion = validateQuestion;
        fel.removeInvalid = utilService.removeInvalid;
        fel.errors = {};

        function editingQuestionToggle(state){
            fel.editingQuestion = !fel.editingQuestion;

            if (state === 'cancel') fel.activeQuestion = JSON.parse(JSON.stringify(fel.activeQuestionPreEditState));
            if (state === 'blockText') {
                fel.editingQuestion = false;
                fel.activeQuestion = [];
            }
            if (state === 'question' || state === 'edit') {
                fel.existingQuestionIds = programService.getExistingQuestions();
                fel.editingQuestion = true;
            }
            if (state === 'save') fel.activeElement.elementRouteName = fel.activeQuestion.questionId;
        }

        //conditions
        function addCondition() {
            fel.activeElement = modulesService.addCondition(fel.activeElement);
        }
        // remove entire condition
        function removeCondition(response) {
            fel.activeElement = modulesService.removeCondition(fel.activeElement);
        }

        function addConditionRow() {
           fel.activeElement.routing =  modulesService.addConditionRow(fel.activeElement.routing);
        }
        function addAndOrCondition(routeIndex, condition, btn) {
            var i = fel.activeElement.routing.indexOf(routeIndex);
            fel.activeElement.routing[i] = modulesService.addAndOrCondition(routeIndex, condition, btn);
        }
        function validateForm() {
            formEditionService.validateForm();
        }
        function rebuildRouting() {
          fel.activeElement.routing = modulesService.rebuildRouting(fel.activeElement.routing);
        }
        function removeAndOrCondition(routeIndex, condition) {
            var i = fel.activeElement.routing.indexOf(routeIndex);
            fel.activeElement.routing[i] = modulesService.removeAndOrCondition(routeIndex, condition);
            if(fel.activeElement.routing[i].condition.length === 0)
                fel.activeElement.routing.splice(i,1);
        }
        function validateName() {
            fel.isDupe = formEditionService.validateName(fel.activeElement.elementRouteName, fel.elementList, fel.type, fel.elementConfiguration.addFlag);
        }

        //Save the modal for module and element
        function save($hide, form) {
            var question = fel.activeQuestion;
            if (question.responseType === "standard") {
                var clinicalDict = question.clinicalFlagValuesDict;
                var logisticalDict = question.logisticalFlagValuesDict;
                question.clinicalAnyFlag = 'false';
                question.logisticalAnyFlag = 'false';
                var clinicalFlagValues = Object.keys(clinicalDict).reduce(function(valuesStr, response) {
                  var isFlagged = clinicalDict[response];
                  return isFlagged ? valuesStr + response + "," : valuesStr;
                }, "");

                var logisticalFlagValues = Object.keys(logisticalDict).reduce(function(valuesStr, response) {
                  var isFlagged = logisticalDict[response];
                  return isFlagged ? valuesStr + response + "," : valuesStr;
                  }, "");

                question.clinicalFlagValues = clinicalFlagValues;
                question.logisticalFlagValues = logisticalFlagValues;
            } else if (question.responseType === "number" || question.responseType === 'decimal') {

                if (question.clinicalAnyFlag === 'true') {
                    question.clinicalFlagMin ='9999';
                    question.clinicalFlagMax = '';
                }
                if (question.logisticalAnyFlag === 'true') {
                    question.logisticalFlagMin = '9999';
                    question.logisticalFlagMax = '';
                }
                question.clinicalFlagValues = "" + question.clinicalFlagMin + "," + question.clinicalFlagMax;
                question.logisticalFlagValues = "" + question.logisticalFlagMin + "," + question.logisticalFlagMax;
            }
            
            fel.missingQuestion = false;
            if (fel.activeElement.type === 'question') {
                if (fel.activeQuestion && fel.activeQuestion.questionId &&
                    (fel.activeQuestion.responseType === 'number' || (fel.activeQuestion.validResponses && fel.activeQuestion.validResponses.length > 0) || fel.activeQuestion.responseType === 'decimal')) {
                    fel.activeElement.questionId = fel.activeQuestion.questionId;
                }
                else {
                    fel.missingQuestion = true;
                    return false;
                }
            }
            fel.formSubmitted = true;
            fel.isFormValid = fel.validateForm(fel.activeElement, fel.elementConfiguration, fel.type, form, fel.elementList);
            if (fel.isFormValid){
                fel.activeElement.displayType = fel.activeElement.displayType ? fel.activeElement.displayType : 'text';
                fel.activeElement.type === 'question' && fel.tempQuestions ?  modulesService.setQuestionList(fel.tempQuestions) : modulesService.setQuestionList(fel.questionList);
                modulesService.saveElement(fel.activeElement, fel.elementList);
                fel.saveCallback();
                fel.closeModal($hide);
            }
        }

        //close modal while clicking cancel
        function closeModal($hide) {
            $hide();
            fel.activeElement = { // Is this needed anymore?
                elementRouteName: '',
                questionId: '',
                displayType: '',
                contextText: '',
                contextTextType: '',
                routing: [],
                type: '',
                defaultNextElementRoute: ''
            }
        }

        function setDisplayType(question) {
            if (question.responseType === 'number') {
                fel.activeElement.displayType = 'numeric';
            } else if (question.responseType === 'decimal') {
                fel.activeElement.displayType = 'decimal';
            } else {
                fel.activeElement.displayType = 'selection';
            }
            if (question.responseType === 'number' || question.responseType === 'decimal') {
                question.clinicalAnyFlag = 'true';
                question.logisticalAnyFlag = 'true';
            } else {
                question.clinicalAnyFlag = 'false';
                question.logisticalAnyFlag = 'false';
            }
        }

        function validateQuestion(activeQuestion) {
            var question = activeQuestion;
            var reportName = question.reportName;
            var reportType = question.reportType;
            var hasValidType =
                reportType &&
                "A" === reportType ||
                "F" === reportType ||
                "N" === reportType;

            var hasValidName =
                reportName &&
                reportName.length > 0;

            var hasFlag = question.hasLogisticalFlag || question.hasClinicalFlag;

            var hasValidReportOrder =
                question.reportOrder >= 1 &&
                question.reportOrder <= 100;
            var clinicalFlagValid = true;
            var logisticalFlagValid = true;
            if ((parseInt(question.clinicalFlagMin) >= parseInt(question.clinicalFlagMax) && question.clinicalAnyFlag !== 'true') && question.responseType === 'number') clinicalFlagValid = false;
            if ((parseInt(question.logisticalFlagMin) >= parseInt(question.logisticalFlagMax) && question.logisticalAnyFlag !== 'true') && question.responseType === 'number') logisticalFlagValid = false;

            return hasFlag ?
                hasValidName &&
                hasValidType &&
                hasValidReportOrder &&
                clinicalFlagValid &&
                logisticalFlagValid :
                true;

        }

        function isItQuestion(type) {
            if (type === 'blockText') {
                for (var i = 0; i < fel.tempQuestions.length; i++) {
                    if (fel.tempQuestions[i].questionId === fel.activeQuestionPreEditState.questionId) {
                        fel.tempQuestions.splice(i, 1);
                        fel.unsetQuestion = [];
                        fel.activeQuestion = [];
                        return false;
                    }
                }

            } else if (type === 'question') {
                fel.activeQuestion = modulesService.getActiveQuestion();
            }
        }

        function validateFlag(responseType, flagValuesDict, flagMin, flagMax, flagError, errorType) {
            flagMin = parseInt(flagMin);
            flagMax = parseInt(flagMax);
            if (responseType === 'standard') {
                flagError[errorType] = true;
                for (var k in flagValuesDict) {
                    if (flagValuesDict[k]) {
                        flagError[errorType] = false;
                        break;
                    }
                }
            }
            if (responseType === 'number' || responseType === 'decimal') {
                if (!(flagMin >= 0 && flagMax)) return flagError[errorType] = true;
                if (flagMin > flagMax) return flagError[errorType] = true;
            }
        }

        function validateSubmittedQuestion(activeQuestion) {
            if (activeQuestion.hasClinicalFlag || activeQuestion.hasLogisticalFlag || activeQuestion.reportName) {
                if (activeQuestion.hasClinicalFlag) {
                    fel.errors.clinicalFlagError = false;
                    if (activeQuestion.clinicalAnyFlag !== 'true') {
                        validateFlag(activeQuestion.responseType, activeQuestion.clinicalFlagValuesDict,
                            activeQuestion.clinicalFlagMin, activeQuestion.clinicalFlagMax,
                            fel.errors, 'clinicalFlagError');
                    } else {
                        fel.errors.clinicalFlagError = false;
                    }
                }
                if (activeQuestion.hasLogisticalFlag) {
                    fel.errors.logisticalFlagError = false;
                    if (activeQuestion.logisticalAnyFlag !== 'true') {
                        validateFlag(activeQuestion.responseType, activeQuestion.logisticalFlagValuesDict,
                            activeQuestion.logisticalFlagMin, activeQuestion.logisticalFlagMax,
                            fel.errors, 'logisticalFlagError');
                    } else {
                        fel.errors.clinicalFlagError = false;
                    }
                }
                if (activeQuestion.reportName && !activeQuestion.reportType) return false;
                if (fel.errors.clinicalFlagError || fel.errors.logisticalFlagError) return false;
                if (!(activeQuestion.reportType && activeQuestion.reportType.trim())) return false;
                if (!(activeQuestion.reportName && activeQuestion.reportName.trim())) return false;
                if (!activeQuestion.reportOrder) return false;

            }
            return true;
        }

        function saveQuestion(form) {
            var qType = fel.questionConfiguration.questionType;
            fel.errors = {};
            if (!validateSubmittedQuestion(fel.activeQuestion)) {
                fel.errors.hasFormErrors = true;
                return false;
            }
            if (fel.activeQuestion.reportName === undefined || fel.activeQuestion.reportName.length <= 0){
                fel.activeQuestion.reportType = null;
                fel.activeQuestion.reportOrder = null;
            }
            fel.showExistingQId = false;
            fel.qformSubmitted = true;
            fel.tempQuestions = fel.questionList ? fel.questionList.slice() : [];
            if (fel.activeQuestion.responseType === 'number' || fel.activeQuestion.responseType === 'decimal') {
                fel.activeQuestion.validResponses = [];
            }

            var qidChanged = fel.isQIdChanged(fel.activeQuestion, qType);
            var missingResponses = fel.missingResponses(fel.activeQuestion);
            var qidDupe = fel.isDupeQuestion(fel.activeQuestion, qType, qidChanged);
            if (form.questionId.$valid) {
                if (((qidChanged && !qidDupe) || (!qidChanged && qType === 'Existing')) && !missingResponses) { // If QID has changed, and it's not registering as a dupe, splice out the old version and add in the new version
                    for (var i = 0; i < fel.tempQuestions.length; i++) {
                        if (fel.tempQuestions[i].questionId === fel.activeQuestionPreEditState.questionId) {
                            fel.tempQuestions.splice(i, 1);
                            fel.tempQuestions.push(fel.activeQuestion);
                            fel.unsetQuestion = fel.activeQuestion;
                            editingQuestionToggle('save', fel.activeQuestion);
                            return false;
                        }
                    }
                } else if (!qidChanged && !qidDupe && fel.activeQuestion.questionId && ((!missingResponses && fel.activeQuestion.responseType === 'standard') || (fel.activeQuestion.responseType === 'number') || (fel.activeQuestion.responseType === 'decimal'))) { // If it wasn't changed and it's not a dupe, push the new question
                    if (fel.tempQuestions) {
                        fel.tempQuestions.push(fel.activeQuestion);
                        fel.unsetQuestion = fel.activeQuestion;
                        editingQuestionToggle('save', fel.activeQuestion);
                    } else {
                        fel.tempQuestions = [];
                        fel.tempQuestions.push(fel.activeQuestion);
                        fel.unsetQuestion = fel.activeQuestion;
                        editingQuestionToggle('save', fel.activeQuestion);
                    }

                } else {
                    // Fails Validation
                    fel.qidDupe = qidDupe;
                }
                formEditionService.setBrokenComponents([]);
            }
        }

        function applyQuestion() {
            fec.program().entity.meta.formEditionMetaData.formParams.formQuestions = modulesService.getQuestionList();
        }

        function isDupeQuestion(activeQuestion, modalType, qidChanged) {
            if (fel.tempQuestions) { // If questions exist, look for dupes
                var qidCount = 0;
                for (var i = 0; i < fel.tempQuestions.length; i++) {
                    if (fel.questionList[i].questionId === activeQuestion.questionId) {
                        qidCount++;
                    }
                }
                if (modalType === 'New' && qidCount > 0) {
                    return true;
                } else if (modalType === 'Existing' && qidCount > 0 && qidChanged) {
                    return true;
                } else if (modalType === 'Existing' && !qidChanged) {
                    return false;
                }
            }
            return false;
        }

        function missingResponses(activeQuestion) {
            if (activeQuestion.validResponses && activeQuestion.validResponses.length > 0) {
                for (var k = 0; k < activeQuestion.validResponses.length; k++) {
                    if (!activeQuestion.validResponses[k].response || activeQuestion.validResponses[k].response === '') {
                        return true;
                    }
                }
            } else if ((!activeQuestion.validResponses || activeQuestion.validResponses.length === 0) && activeQuestion.responseType === 'standard') return true;
            return false;
        }

        function isQIdChanged(activeQuestion, modalType) {
            return activeQuestion.questionId !== fel.activeQuestionPreEditState.questionId && modalType === 'Existing';

        }

        function copyExistingQId(activeQuestion) {
            activeQuestion.questionId = fel.selectedExistingQId;
            fel.toggleExistingQId();
        }

        function toggleExistingQId() {
            fel.showExistingQId = !fel.showExistingQId;
            fel.selectedExistingQId = null;
        }
        $scope.$watch('$viewContentLoaded', function() {

            var toolbarOptions = [
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['link'],
                [{ 'align': [] }],
                ['clean']
            ];
            var quill = new Quill('#editor-container', {
                modules: {
                    toolbar: toolbarOptions
                },
                //placeholder: 'Compose an epic...',
                theme: 'snow'  // or 'bubble'
            });
            if(fel.activeElement.contextTextFormatting){
                fel.activeElement.contextTextFormatting = JSON.parse(fel.activeElement.contextTextFormatting);
                quill.setContents(fel.activeElement.contextTextFormatting);
                fel.activeElement.contextTextType = 'html';
            }else{
                quill.setText(fel.activeElement.contextText ? fel.activeElement.contextText : '');
            }

            quill.on('text-change', function() {
                fel.activeElement.contextTextFormatting = quill.getContents();
                fel.activeElement.contextText = quill.root.innerHTML;
                fel.activeElement.contextTextType = 'html';
            });
        });
    }
})(window.angular);
