(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditSourceController', EditSourceController);

    EditSourceController.$inject = ['EditSourceService', 'savedSourceResource', 'saveCallback', 'errorCallback'];

    function EditSourceController(EditSourceService, savedSourceResource, saveCallback, errorCallback) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedSourceResource);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditSourceService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
