(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditContentSubscriptionController', EditContentSubscriptionController);

    EditContentSubscriptionController.$inject = ['ProgramInitService', 'EditContentSubscriptionService', 'savedContentSubscriptionResource', 'saveCallback', 'errorCallback', '$timeout'];

    function EditContentSubscriptionController(programInitService, EditContentSubscriptionService, savedContentSubscriptionResource, saveCallback, errorCallback, $timeout) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedContentSubscriptionResource);
        epc.data = programInitService.getContentSubscriptions(true, true);
        epc.searchFn = searchFn;
        epc.searchEntry = '';
        epc.enableSearch = true;

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditContentSubscriptionService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }

        function searchFn(searchEntry) {
            return $timeout(function () {
                if (!searchEntry) {
                    return epc.data.configs;
                } else if (searchEntry == '') {
                    return epc.data.configs;
                } else {
                    var configs = {'content': [], 'total': 0};
                    for (var i = 0; i < epc.data.configs.content.length; i++) {
                        if (epc.data.configs.content[i].entity &&
                            epc.data.configs.content[i].entity.description &&
                            epc.data.configs.content[i].entity.description.toUpperCase().indexOf(searchEntry.toUpperCase()) > -1) {
                            configs.content.push(epc.data.configs.content[i]);
                        }
                    }
                    configs.total = configs.content.length;
                    return configs;
                }
            }, 0);
        }
    }
})(window.angular);
