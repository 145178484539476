(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('Auth', Auth);

    Auth.$inject = ['$rootScope', '$state', '$sessionStorage', 'Principal'];

    function Auth($rootScope, $state, $sessionStorage, Principal) {
        return {
            authorize: authorize,
            getPreviousState: getPreviousState,
            logout: logout,
            resetPreviousState: resetPreviousState,
            storePreviousState: storePreviousState
        };

        function authorize(force) {
            return Principal.identity(force).then(authThen);

            function authThen() {
                var isAuthenticated = Principal.isAuthenticated();

                // an authenticated user can't access to login and register pages
                if (isAuthenticated && $rootScope.toState.parent === 'account' && ($rootScope.toState.name === 'login' || $rootScope.toState.name === 'register' || $rootScope.toState.name === 'social-auth')) {
                    $state.go('home');
                }

                // recover and clear previousState after external login redirect (e.g. oauth2)
                if (isAuthenticated && !$rootScope.fromState.name && getPreviousState()) {
                    var previousState = getPreviousState();
                    resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                }

                if ($rootScope.toState.data.authorities && $rootScope.toState.data.authorities.length > 0 && !Principal.hasAnyAuthority($rootScope.toState.data.authorities)) {
                    if (isAuthenticated) {
                        // user is signed in but not authorized for desired state
                        $state.go('accessdenied');
                    }
                    else {
                        // user is not authenticated. stow the state they wanted before you
                        // send them to the login service, so you can return them when you're done
                        storePreviousState($rootScope.toState.name, $rootScope.toStateParams);

                        // now, send them to the signin state so they can log in
                        $state.go('accessdenied');
                    }
                }
            }
        }

        function logout() {
            Principal.authenticate(null);
        }


        function getPreviousState() {
            return $sessionStorage.previousState;
        }

        function resetPreviousState() {
            delete $sessionStorage.previousState;
        }

        function storePreviousState(previousStateName, previousStateParams) {
            $sessionStorage.previousState = {"name": previousStateName, "params": previousStateParams};
        }
    }
})(window.angular);
