(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('ConfirmController', ConfirmController);

    ConfirmController.$inject = ['confirmed', 'title', 'confirmText', 'isWarning', 'warningText', 'confirmButtonText'];

    function ConfirmController(confirmed, title, confirmText, isWarning, warningText, confirmButtonText) {
        var cc = this;
        cc.confirm = confirm;
        cc.title = title;
        cc.confirmText = confirmText;
        cc.isWarning = isWarning;
        cc.warningText = warningText;
        cc.confirmButtonText = confirmButtonText;
        cc.confirmButtonClass = (confirmButtonText.toUpperCase() === 'DELETE') ? 'btn-color-red' : '';
        cc.cancel =cancel;

        function confirm($hide) {
            $('body').removeClass('modal-open');
            $hide();
            confirmed();
        }
         function cancel() {
            $('body').removeClass('modal-open');
         }
    }
})(window.angular);
