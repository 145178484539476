(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('FormEditionController', FormEditionController);

    FormEditionController.$inject = ['ProgramService', 'EditionsService', 'ProgramVersionService', 'ProgramSummaryService', 'ProgramWarningService',
        'AssetSetService', 'FormEditionService',
        'UtilService', 'VideoService', 'AlertService', '$state', '$location', '$scope', 'GlobalModulesService','ModulesService'];

    function FormEditionController(programService, editionsService, programVersionService, programSummaryService, programWarningService, assetSetService, formEditionService, utilService, videoService, alertService, $state, $location, $scope, globalModulesService, modulesService) {
        var fec = this;
        fec.confirm = {};
        fec.collapsed = false;
        fec.existingQuestions = {};
        fec.program = programService.getProgram;
        fec.data = programSummaryService.getProgramData();
        programService.loadExistingWIP(fec.program());
        fec.isLoadingAssetSets = isLoadingAssetSets;
        fec.assetSetSelection = '';
        //create versions
        fec.formatVersion = utilService.formatVersion;
        fec.isSubscribed = globalModulesService.isSubscribed;
        fec.createWorkingVersion = createWorkingVersion;
        fec.createMajorVersion = createMajorVersion;
        fec.createMinorVersion = createMinorVersion;
        fec.nextMajorVersion = nextMajorVersion;
        fec.nextMinorVersion = nextMinorVersion;
        fec.nextWorkingVersion = nextWorkingVersion;
        fec.getMisMatchedVersionMessage = getWarningMessages;
        fec.isValidVersion = utilService.isValidVersionProgram;
        fec.loadIvrAssetSets = loadIvrAssetSets;
        //set edit to disabled if no permissions or during loading process
        fec.isEditDisabled = isEditDisabled;
        fec.setAssetSet = setAssetSet;
        //reset before opening modal dialog
        fec.reset = reset;
        //create copy
        fec.createCopy = createCopy;

        //save form
        fec.save = save;
        fec.cancel = cancel;
        fec.cancelButtonText = cancelButtonText;
        fec.modulesParsed = false;

        //discard
        fec.discard = discard;
        fec.canBeDiscarded = utilService.canBeDiscarded;
        fec.discardButtonText = discardButtonText;
        fec.discardMessage = utilService.discardMessage;
        fec.existingAssetSet = existingAssetSet;
        //check if program meta has properties and values
        fec.containsValue = containsValue;
        fec.hasValue = hasValue;
        fec.removeAt = utilService.removeAt;
        fec.scrollToComponent = scrollToComponent;
        fec.setFormModified = setFormModified;
        fec.requiredOnAction = requiredOnAction;
        fec.validateName = validateName;
        fec.doIExist = doIExist;
        fec.isAnythingBroken = isAnythingBroken;
        fec.resetBrokenComponentValidation = resetBrokenComponentValidation;
        fec.addModule = addModule;
        fec.setExistingModule = setExistingModule;
        fec.saveModule = saveModule;
        fec.removeModule = removeModule;
        fec.setExistingModule = setExistingModule;
        fec.addElement = addElement;
        fec.saveElement = saveElement;
        fec.openElement = setExistingElement;
        fec.removeElement = removeElement;
        fec.closeModal = closeModal;
        fec.editModule = setExistingModule;
        fec.parseQuestion = parseQuestion;
        fec.questionsToRemove = [];
        fec.toggleAnyValue = toggleAnyValue;
        fec.modifiedGlobalModules = [];
        fec.stageUpdatedModule = stageUpdatedModule;
        fec.validateGlobalModulesSyncStatus = validateGlobalModulesSyncStatus;
        fec.pushSyncedModule = pushSyncedModule;

        fec.globalModuleSource = 'convert';

        // TODO Why is FormEditionController is initialized multiple times?
        (function onLoad() {

            if (!fec.program().entity) {
                fec.program().entity = {
                    formEditionMetaData: {}
                };
            }

            if (!fec.program().entity.meta) {
                fec.program().entity.meta = {
                    formEditionMetaData: {}
                };
            }

            if (!fec.program().entity.meta.formEditionMetaData.formParams) {
                fec.program().entity.meta.formEditionMetaData.formParams = {
                    firstModuleRoute: '',
                    formTitle: '',
                    formQuestions: []
                };
            }
            if (!fec.program().entity.meta.formEditionMetaData.formParams.formQuestions) {
                fec.program().entity.meta.formEditionMetaData.formParams.formQuestions = [];
            }
            if (!fec.program().entity.meta.formEditionMetaData.formModules) {
                fec.program().entity.meta.formEditionMetaData.formModules = [];
            }
            loadIvrAssetSets();

            fec.program().entity.meta.formEditionMetaData.formModules.length > 0 ? fec.program().entity.meta.formEditionMetaData.formParams.firstModuleRoute = fec.program().entity.meta.formEditionMetaData.formModules[0].moduleRouteName : null;
        })();

        function isLoadingAssetSets() {
            var loadingAssetSets = formEditionService.isLoadingAssetSets();
            if (!loadingAssetSets && !fec.data.ivrAssetSets) {
                var assetSetData = formEditionService.getIvrAssetSets();
                fec.data.ivrAssetSets = assetSetData.assetSets;
            }
            return loadingAssetSets;
        }

        function loadIvrAssetSets() {
            formEditionService.loadIvrAssetSets();
            //set current value
            fec.assetSetSelection = fec.program().entity.meta.formEditionMetaData.assetSet.id;
        }

        function validateName() {
            fec.submitted = false;
            if (fec.program().entity.name && fec.program().entity.name.match(/\"/g)) {
                fec.nameError = true;
            } else {
                fec.nameError = false;
            }
        }

        function reset() {
            alertService.removeAlert();
        }

        function isEditDisabled() {
            return programService.isEditDisabled() || fec.loading;
        }

        function containsValue(prop) {
            var entity = fec.program().entity;
            var containsValue = false;
            if (entity && entity.meta && prop in entity.meta) {
                var property = entity.meta[prop];
                if (property) {
                    containsValue = property.length > 0;
                }
            }
            return containsValue;
        }

        function hasValue(prop) {
            var entity = fec.program().entity;
            if (entity && entity.meta && prop in entity.meta) {
                var obj = entity.meta[prop];
                return obj && 'id' in obj;
            }
            return false;
        }

        function requiredOnAction() {
            var curAction = programService.getCurrentAction();
            if (curAction && (curAction === "Create Major Version" || curAction === "Create Minor Version")) {
                return true;
            }
            return false;
        }

        function isFormValid(form, action) {
            var isValid = !fec.nameError && form && form.$valid;
            var hasModules = fec.program().entity.meta.formEditionMetaData.formModules ? fec.program().entity.meta.formEditionMetaData.formModules.length > 0 : false;
            if (!isValid || !hasModules) {
                if (!hasModules) alertService.error("At least one module is required.", action);
                else alertService.error("Validation errors", action);
                form.$setSubmitted();
                return false;
            } else {
                form.$setPristine();
                form.$setSubmitted();
                $('body').removeClass('modal-open');
                return true;
            }
        }

        function save(form) {

            fec.submitted = true;
            if (!fec.nameError) {
                var actionWord = "Save Program";
                if (!fec.program().entity.emmiCode) {
                    actionWord = "Create Program";
                }
                fec.loading = true;

                programService.save()
                    .then(function (response) {
                        var programName = fec.program().entity.name;
                        form.$setPristine();
                        alertService.customSuccess("SUCCESS! " + actionWord + " " + programName + " successful, however any newly created global modules will not be linked until versioned")
                    })
                    .catch(function (response) {
                        alertService.error(response, actionWord);
                    })
                    .finally(function () {
                        validateGlobalModulesSyncStatus();
                        delete fec.loading;
                    });
            } else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function cancel() {
            var entity = fec.program().entity;
            var emmiCode = entity.emmiCode;
            var versioned = entity.versionedToLocal || entity.version;
            utilService.navigate(emmiCode, versioned, $state);
        }

        function cancelButtonText() {
            var entity = fec.program().entity;
            var emmiCode = entity.emmiCode;
            var text = "Return to Composer Home";
            if (emmiCode) {
                if (entity.version || entity.versionedToLocal) {
                    text = "Return to Version List Page";
                }
            }
            return text;
        }

        function discardButtonText() {
            var program = fec.program();
            if (program && program.entity) {
                return program.entity.versionedToLocal ? 'REVERT' : 'DELETE';
            } else {
                return 'DELETE';
            }
        }

        function discard() {
            var entity = fec.program().entity;
            var emmiCode = entity.emmiCode;
            var versionedToLocal = entity.versionedToLocal;
            fec.loading = true;
            programService.discard()
                .then(function (response) {
                    utilService.navigate(emmiCode, versionedToLocal, $state);
                })
                .catch(function (response) {
                    alertService.error(response, 'discard program');
                })
                .finally(function () {
                    delete fec.loading;
                });
        }

        function createCopy(form) {
            programService.createCopy(fec.confirm.inputText);
            modulesService.setModuleList(fec.program().entity.meta.formEditionMetaData.formModules);
            programService.setEditable(true);
            $('body').removeClass('modal-open');

        }

        function setNotes() {
            var entity = fec.program().entity;
            if (entity) {
                if (!entity.version) {
                    entity.version = utilService.newVersion();
                }
                entity.version.comment = fec.confirm.inputText;
            }
        }

        function setFormModified(form) {
            form.$setDirty();
        }

        function createWorkingVersion(form) {
            fec.submitted = true;
            if (!fec.nameError) {
                setNotes();
                fec.loading = true;
                var operation = 'create working version';
                var program = fec.program();
                setGlobalVersionToLatest();
                globalModulesService.pushUpdatedGlobalModules();
                programVersionService.createWorkingVersion(program)
                    .then(function (response) {
                        var program = fec.program();
                        program.entity.version = response.entity.version;
                        alertService.customSuccess('Working version for program ' + program.entity.name + ' is successfully created in the Composer.');
                        form.$setPristine();
                        programService.setCurrentAction("");
                    })
                    .then(function () {
                        if (program.entity.meta.formEditionMetaData.formModules){
                            validateGlobalModulesSyncStatus();
                            return globalModulesService.addSubscription(program);
                        }

                    })
                    .catch(function (response) {
                        alertService.error(response, operation);
                    })
                    .finally(function () {
                        delete fec.loading;
                    });
            } else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function createMajorVersion(form) {
            fec.submitted = true;
            if (isFormValid(form, "Create Major Version")) {
                if (!fec.nameError) {
                    setNotes();
                    fec.loading = true;
                    var operation = 'create major version';
                    var program = fec.program();
                    setGlobalVersionToLatest();
                    globalModulesService.pushUpdatedGlobalModules();
                    programVersionService.createMajorVersion(program)
                        .then(function (response) {
                            var program = fec.program();
                            program.entity.version = response.entity.version;
                            alertService.customSuccess('Major version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            programService.setCurrentAction("");
                        })
                        .then(function () {
                            if (program.entity.meta.formEditionMetaData.formModules){
                                validateGlobalModulesSyncStatus();
                                return globalModulesService.addSubscription(program);
                            }
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete fec.loading;
                        });
                }
                $('body').removeClass('modal-open');
            }
        }

        function createMinorVersion(form) {
            fec.submitted = true;
            if (isFormValid(form, "Create Minor Version")) {
                if (!fec.nameError) {
                    setNotes();
                    fec.loading = true;
                    var operation = 'create minor version';
                    var program = fec.program();
                    setGlobalVersionToLatest();
                    globalModulesService.pushUpdatedGlobalModules();
                    programVersionService.createMinorVersion(fec.program())
                        .then(function (response) {
                            var program = fec.program();
                            program.entity.version = response.entity.version;
                            alertService.customSuccess('Minor version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            programService.setCurrentAction("");
                        })
                        .then(function () {
                            if (program.entity.meta.formEditionMetaData.formModules){
                                validateGlobalModulesSyncStatus();
                                return globalModulesService.addSubscription(program);
                            }
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete fec.loading;
                        });
                }
                $('body').removeClass('modal-open');
            }
        }

        function getCompleteVersion(obj) {
            return obj.major + '.' + obj.minor + '.' + obj.working
        }

        function nextMajorVersion() {
            var brokenComponents = formEditionService.getBrokenComponents();
            getLatestGlobalVersion();
            if (fec.isAnythingBroken(brokenComponents) === false) {
                programService.setCurrentAction("Create Major Version");
                return programVersionService.nextMajorVersion(fec.program())
                    .then(function (response) {
                        var verobj = response;
                        var version;
                        var warningMessages = [];
                        if (verobj) {
                            version = getCompleteVersion(verobj);
                            warningMessages = getWarningMessages(version, verobj.major);
                        } else {
                            version = '1.0.0';
                        }
                        return {resultMessage: version, warningMessages: warningMessages};
                    })
            }
        }

        function nextMinorVersion() {
            var brokenComponents = formEditionService.getBrokenComponents();
            getLatestGlobalVersion();
            if (fec.isAnythingBroken(brokenComponents) === false) {
                programService.setCurrentAction("Create Minor Version");
                return programVersionService.nextMinorVersion(fec.program())
                    .then(function (response) {
                        var verobj = response;
                        var version;
                        var warningMessages = [];
                        if (verobj) {
                            version = getCompleteVersion(verobj);
                            warningMessages = getWarningMessages(version, verobj.major);
                        } else {
                            version = '0.1.0';
                        }
                        return {resultMessage: version, warningMessages: warningMessages};
                    })
            }
        }

        function nextWorkingVersion() {
            programService.setCurrentAction("Create Working Version");
            getLatestGlobalVersion();
            return programVersionService.nextWorkingVersion(fec.program())
                .then(function (response) {
                    var verobj = response;
                    var version = '0.0.1';
                    if (verobj) {
                        version = verobj.major + '.' + verobj.minor + '.' + verobj.working;
                    }
                    return {resultMessage: version, warningMessages: []};
                })
        }

        function getWarningMessages(nextProgramVersion, nextProgramMajor) {
            return programWarningService.getWarningMessages(nextProgramVersion, nextProgramMajor);
        }

        function setAssetSet(form) {
            for (var i = 0; i < fec.data.ivrAssetSets.content.length; i++) {
                if (fec.data.ivrAssetSets.content[i].entity.id === fec.assetSetSelection) {
                    fec.program().entity.meta.formEditionMetaData.assetSet = fec.data.ivrAssetSets.content[i].entity;
                    fec.setFormModified(form);
                }
            }
        }

        function existingAssetSet(id) {
            return !!(fec.program().entity && fec.program().entity.meta.formEditionMetaData.assetSet.id === id);
        }

//  ONLY USED IN UI
        function parseQuestion(question, datatype) {
            if(fec.program().entity.meta.formEditionMetaData.formParams.formQuestions) {
                for (var i = 0; fec.program().entity.meta.formEditionMetaData.formParams.formQuestions.length > i; i++) {

                    if (fec.program().entity.meta.formEditionMetaData.formParams.formQuestions[i].questionId === question) {

                        if (datatype === 'responseType') {
                            return fec.program().entity.meta.formEditionMetaData.formParams.formQuestions[i].responseType;
                        } else if (datatype === 'responses') {
                            return fec.program().entity.meta.formEditionMetaData.formParams.formQuestions[i].validResponses;
                        }

                    }
                }
            }
        }

        function closeModal() {
            $hide();

        }

        function doIExist(component, type, secondaryComponent) {
            var brokenComponents = formEditionService.getBrokenComponents();
            var doesNotExist = true;
            var secondaryIndex;
            var moduleList = modulesService.getModuleList();
            if (type === 'QID') {
                for (var i = 0; i < fec.program().entity.meta.formEditionMetaData.formParams.formQuestions.length; i++) {
                    if (fec.program().entity.meta.formEditionMetaData.formParams.formQuestions[i].questionId === component) {
                        doesNotExist = false;
                        break;
                    }
                }
            }
            if (type === 'Module') {
                for (var i = 0; i < moduleList.length; i++) {
                    if (moduleList[i].moduleRouteName === component) {
                        doesNotExist = false;
                        break;
                    }
                }
            }
            if (type === 'Element') {
                for (var i = 0; i < moduleList.length; i++) {
                    if (moduleList[i].moduleRouteName === secondaryComponent) {
                        secondaryIndex = i;
                        break;
                    }
                }
                if (secondaryIndex >= 0) {
                    for (var i = 0; i < moduleList[secondaryIndex].formElements.length; i++) {
                        if (moduleList[secondaryIndex].formElements[i].elementRouteName === component) {
                            doesNotExist = false;
                            break;
                        }
                    }
                }
            }
            var comp = JSON.stringify({component: component, type: type});
            var existingIndex = brokenComponents.indexOf(comp);
            if (doesNotExist === true) {
                if (existingIndex === -1) {
                    brokenComponents.push(comp);
                }
            }
            if (doesNotExist === false) {
                if (existingIndex !== -1) {
                    brokenComponents = brokenComponents.splice(existingIndex, 0);
                }
            }
            formEditionService.setBrokenComponents(brokenComponents);
            return doesNotExist;
        }

        function isAnythingBroken(brokenComponents) {
            var isBroken = brokenComponents.length > 0;
            if (isBroken) {
                alertService.error("Broken components or routes must be corrected or removed.", "Create Major or Minor Version");
            }
            return isBroken;
        }

        function resetBrokenComponentValidation() {
            formEditionService.setBrokenComponents([]);
        }

        function scrollToComponent(module, element) {
            var id;
            if (element) {
                id = '#module_' + module + '_element_' + element;
            } else {
                id = '#module_' + module;
            }
            $([document.documentElement, document.body]).animate({
                scrollTop: $(id).offset().top - 60
            }, 1000);

        }

        function toggleAnyValue(activeQuestion, flagType) { // DOES THIS NEED TO MOVE TO EDITION CONTROLLER?
            if (flagType === 'clinical' && activeQuestion.clinicalAnyFlag === 'true') {
                activeQuestion.clinicalFlagMin = "9999";
                activeQuestion.clinicalFlagMax = "";
            } else if (flagType === 'clinical') {
                activeQuestion.clinicalFlagMin = "0";
                activeQuestion.clinicalFlagMax = "1";
            }
            if (flagType === 'logistical' && activeQuestion.logisticalAnyFlag === 'true') {
                activeQuestion.logisticalFlagMin = "9999";
                activeQuestion.logisticalFlagMax = "";
            } else if (flagType === 'logistical') {
                activeQuestion.logisticalFlagMin = "0";
                activeQuestion.logisticalFlagMax = "1";
            }
        }

        function stageUpdatedModule(parentModuleParsed) {
            var editionQuestions =  fec.program().entity.meta.formEditionMetaData.formParams.formQuestions;
            var eId = fec.program().entity.editionId;
            globalModulesService.setUpdatedModule(parentModuleParsed, editionQuestions, eId);
        }

        function validateGlobalModulesSyncStatus() {
            modulesService.setModuleList(fec.program().entity.meta.formEditionMetaData.formModules);
            formEditionService.setExistingGlobalModules();
            getLatestGlobalVersion();
        }

        function getLatestGlobalVersion(){
            var modules = fec.program().entity.meta.formEditionMetaData.formModules;
            var eId = fec.program().entity.editionId;
            if (modules) {
                modules.map(function (module) {
                    if (module.isGlobalModule) {
                        globalModulesService.getLatestVersion(eId, module).then(function (response) {
                            module.globalVersionLatest = response;
                        });
                    }
                })
            }
         }
         function setGlobalVersionToLatest() {
             var modules = fec.program().entity.meta.formEditionMetaData.formModules;
             if (modules) {
                 modules.map(function (module) {
                     if (module.isGlobalModule && module.globalVersion < module.globalVersionLatest) {
                         module.globalVersion = module.globalVersionLatest;
                     }
                 })
             }
         }

         function pushSyncedModule(){
             var moduleConfig = modulesService.getActiveModule();
             fec.stageUpdatedModule(moduleConfig);
         }

        function addModule(){
            modulesService.setModuleList(fec.program().entity.meta.formEditionMetaData.formModules);
            modulesService.setQuestionList(fec.program().entity.meta.formEditionMetaData.formParams.formQuestions);
            modulesService.addModule();

        }

        function removeModule(selected, form) {
            modulesService.setModuleList(fec.program().entity.meta.formEditionMetaData.formModules);
            fec.setFormModified(form);
            fec.resetBrokenComponentValidation();
            fec.program().entity.meta.formEditionMetaData.formModules = modulesService.removeModule(selected, form);
            fec.program().entity.meta.formEditionMetaData.formParams.formQuestions = modulesService.getQuestionList();
        }

        function setExistingModule(module){
            modulesService.setModuleList(fec.program().entity.meta.formEditionMetaData.formModules);
            modulesService.setQuestionList(fec.program().entity.meta.formEditionMetaData.formParams.formQuestions);
            modulesService.setExistingModule(module);

        }

        //save module used to create the module
        function saveModule(form) {
            fec.setFormModified(form);
            fec.resetBrokenComponentValidation();
            fec.program().entity.meta.formEditionMetaData.formModules = modulesService.getModuleList();
            fec.program().entity.meta.formEditionMetaData.formParams.firstModuleRoute = fec.program().entity.meta.formEditionMetaData.formModules[0].moduleRouteName;
            pushSyncedModule();
        }

        function addElement(parentModule){
            modulesService.setModuleList(fec.program().entity.meta.formEditionMetaData.formModules);
            modulesService.setQuestionList(fec.program().entity.meta.formEditionMetaData.formParams.formQuestions);
            modulesService.setElementList(parentModule);
            modulesService.addNewElement(parentModule);
            modulesService.setNewQuestion();

        }

        function removeElement(selected, module, form) {
            modulesService.setModuleList(fec.program().entity.meta.formEditionMetaData.formModules);
            modulesService.setQuestionList(fec.program().entity.meta.formEditionMetaData.formParams.formQuestions);
            fec.setFormModified(form);
            fec.resetBrokenComponentValidation();
            fec.program().entity.meta.formEditionMetaData.formModules = modulesService.removeElement(selected, module, form, fec.program().entity.meta.formEditionMetaData.formModules);
            fec.program().entity.meta.formEditionMetaData.formParams.formQuestions = modulesService.getQuestionList();
        }

        function setExistingElement(element, parentModule){
            modulesService.setModuleList(fec.program().entity.meta.formEditionMetaData.formModules);
            modulesService.setQuestionList(fec.program().entity.meta.formEditionMetaData.formParams.formQuestions);
            modulesService.setElementList(parentModule);
            modulesService.setExistingElement(element, parentModule);

        }

        //save module used to create the module
        function saveElement(form) {
            fec.setFormModified(form);
            fec.resetBrokenComponentValidation();
            fec.program().entity.meta.formEditionMetaData.formModules = modulesService.getModuleList();
            fec.program().entity.meta.formEditionMetaData.formParams.formQuestions = modulesService.getQuestionList();
            pushSyncedModule();
        }
    }

})(window.angular);
