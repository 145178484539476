(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('SystemsController', SystemsController);

    SystemsController.$inject = ['SystemService'];

    function SystemsController(SystemService) {
        var SystemsCtrl = this;

        (function onControllerLoad() {
            SystemsCtrl.nextPage = nextPage;
            SystemsCtrl.hasVisible = anyVisible;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                SystemsCtrl.loadingNextPage = true;
                SystemService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    SystemsCtrl.loadingNextPage = false;
                } else {
                    SystemsCtrl.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                SystemsCtrl.loadingNextPage = true;
            }
        }

        function anyVisible() {
            var hasVisible = false,
                length = SystemsCtrl.systems && SystemsCtrl.systems.content &&
                    SystemsCtrl.systems.content.length;
            for (var i = 0; i < length; i++) {
                if (!SystemsCtrl.systems.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            SystemsCtrl.loading = true;
            SystemService.search()
                .then(function (response) {
                    SystemsCtrl.systems = response;
                    //SystemsCtrl.systems = JSON.stringify(response);
                })
                .finally(function () {
                    delete SystemsCtrl.loadingNextPage;
                    delete SystemsCtrl.loading;
                });
        }

    }
})(window.angular);
