(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditPerspectiveService', EditPerspectiveService);

    EditPerspectiveService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditPerspectiveService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(perspectiveResource) {
            LinkConverter.convertResource(perspectiveResource);
            if (perspectiveResource && perspectiveResource.link.self) {
                return $http.put(perspectiveResource.link.self, perspectiveResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
