(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('BrandsController', BrandsController);

    BrandsController.$inject = ['BrandService'];

    function BrandsController(BrandService) {
        var Brands = this;

        (function onControllerLoad() {
            Brands.nextPage = nextPage;
            Brands.hasVisible = anyVisibleBrands;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                Brands.loadingNextPage = true;
                BrandService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    Brands.loadingNextPage = false;
                } else {
                    Brands.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                Brands.loadingNextPage = true;
            }
        }

        function anyVisibleBrands() {
            var hasVisible = false,
                length = Brands.brands && Brands.brands.content &&
                    Brands.brands.content.length;
            for (var i = 0; i < length; i++) {
                if (!Brands.brands.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            Brands.loading = true;
            BrandService.search()
                .then(function (response) {
                    Brands.brands = response;
                    //Brands.brands = JSON.stringify(response);
                })
                .finally(function () {
                    delete Brands.loadingNextPage;
                    delete Brands.loading;
                });
        }

    }
})(window.angular);
