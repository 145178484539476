(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditWorkflowSubscriptionController', EditWorkflowSubscriptionController);

    EditWorkflowSubscriptionController.$inject = ['ProgramInitService', 'EditWorkflowSubscriptionService', 'NewWorkflowSubscriptionService', 'savedWorkflowSubscriptionResource', 'saveCallback', 'errorCallback'];

    function EditWorkflowSubscriptionController(programInitService, EditWorkflowSubscriptionService, NewWorkflowSubscriptionService, savedWorkflowSubscriptionResource, saveCallback, errorCallback) {
        var ebc = this;

        ebc.save = save;
        ebc.copy = angular.copy(savedWorkflowSubscriptionResource);
        ebc.data = programInitService.getWorkflowSubscriptions(true, true);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                ebc.copy.entity.targetEmmiCode = ebc.copy.entity.targetProgram['emmiCode'];
                EditWorkflowSubscriptionService.update(ebc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }

        (function initPrograms() {
            NewWorkflowSubscriptionService.getProgramData()
                .then(function (response) {
                    ebc.programs = response;
                });
            EditWorkflowSubscriptionService.findProgramByEmmiCode(ebc.copy.entity.targetEmmiCode)
                .then(function (response) {
                    ebc.copy.entity.targetProgram = response.entity;
                })
        })();

        ebc.searchProgram = function(searchFor) {

            return NewWorkflowSubscriptionService.searchProgramData(searchFor)
                .then(function (response) {
                    ebc.programs = response;
                    return ebc.programs;
                });
        }
    }
})(window.angular);
