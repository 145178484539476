(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('VersionedProgramCampaignController', VersionedProgramCampaignController);

    VersionedProgramCampaignController.$inject = ['ProgramService', 'EditionsService', 'ProgramCampaignService', 'ProgramPublisherService'];

    function VersionedProgramCampaignController(programService, editionsService, programCampaignService, programPublisherService) {
        var vpcc = this;
        vpcc.program = programService.getProgram();
        vpcc.data = programCampaignService.getData();
        vpcc.isCheckedOutByUser = programService.isCheckedOutByUser;
        vpcc.confirm = {};
        vpcc.isLoadingPublishHistory = programPublisherService.isLoadingPublishHistory;
        vpcc.isPublishedProgram = programPublisherService.isProgramPublished;
        vpcc.campaign = programCampaignService.getCampaign();
        vpcc.campaignInitialized = isCampaignInitialized;
        vpcc.isLoading = programCampaignService.isLoading;
        vpcc.getCampaignCustom6Label = getCampaignCustom6Label;
        vpcc.getCampaignCustom7Label = getCampaignCustom7Label;
        vpcc.getCustom6Label = getCustom6Label;
        vpcc.getCustom7Label = getCustom7Label;
        vpcc.getUseTransferLabel = getUseTransferLabel;

        function isCampaignInitialized() {
            if(vpcc.isLoading) return false;
            return programCampaignService.isCampaignInitialized();
        }

        function getCampaignCustom6Label() {
            return programCampaignService.getLabelByValue(vpcc.data.cacustom6, vpcc.campaign.campaignFields.custom6);
        }

        function getCampaignCustom7Label() {
            return programCampaignService.getLabelByValue(vpcc.data.cacustom7, vpcc.campaign.campaignFields.custom7);
        }

        function getCustom6Label() {
            return programCampaignService.getLabelByValue(vpcc.data.cocustom6, vpcc.campaign.columnFields.custom6);
        }

        function getCustom7Label() {
            return programCampaignService.getLabelByValue(vpcc.data.cocustom7, vpcc.campaign.columnFields.custom7);
        }

        function getUseTransferLabel() {
            return programCampaignService.getLabelByValue(vpcc.data.useTransfer, vpcc.campaign.columnFields.useTransfer);
        }
    }
})(window.angular);
