(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditTypeAssetSetController', EditTypeAssetSetController);

    EditTypeAssetSetController.$inject = ['EditTypeAssetSetService', 'ProgramInitService', 'savedTypeAssetSetResource', 'saveCallback', 'errorCallback'];

    function EditTypeAssetSetController(EditTypeAssetSetService, ProgramInitService, savedTypeAssetSetResource, saveCallback, errorCallback) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedTypeAssetSetResource);
        epc.data = ProgramInitService.getTypeAssetSets(true, true);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditTypeAssetSetService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
