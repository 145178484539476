(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewPlaceholderService', NewPlaceholderService);

    NewPlaceholderService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewPlaceholderService($http, Principal, UriTemplate) {

        return {
            create: create,
            newPlaceholder: newPlaceholder
        };

        function create(newPlaceholder) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.placeholders).stringify(),
                    newPlaceholder).then(function (response) {
                    return response.data;
                });
            })
        }

        function newPlaceholder() {
            return {
                name: ''
            }
        }

    }
})(window.angular);
