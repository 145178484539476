(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('ProgramService', ProgramService);

    ProgramService.$inject = ['ProgramInitService', '$http', 'Principal', 'UriTemplate', 'UtilService', '$sessionStorage', '$timeout', 'GlobalModulesService'];

    function ProgramService(programInitService, $http, Principal, UriTemplate, utilService, $sessionStorage, $timeout, globalModulesService) {

        var currentProgram = {};
        var existingQuestions = {};
        var editable = false;
        var loadingQuestions = false;
        var CPOrderInvalidDays = [];
        var currentAction = '';
        var gettingCallFlows = false;
        var missingCampaignData = false;
        var programTypes = {'edit-program-details':
                                {type: 'MULTIMEDIA PROGRAM', id: 1 },
                            'edit-ivr-details':
                                {type: 'IVR CALL', id: 2 },
                            'edit-transition-details':
                                {type: 'TRANSITION PROGRAM', id: 3 },
                            'edit-form-edition-details':
                                {type: 'FORM EDITION', id: 15 },
                            'edit-form-details':
                                {type: 'FORM', id: 11 },
                            'edit-digital-journey-details':
                                {type: 'CAREPLAN', id: 12 },
                            'edit-video-program-details':
                                {type: 'VIDEO', id: 7 },
                            'edit-web-program-details':
                                {type: 'WEB PROGRAM', id: 8 },
                            'view-article-program-details':
                                {type: 'ARTICLE', id: 4 }
                        };

        return {
            getProgram: getProgram,
            setProgram: setProgram,

            getCurrentAction: getCurrentAction,
            setCurrentAction: setCurrentAction,

            getExistingQuestions: getExistingQuestions,
            setExistingQuestions: setExistingQuestions,
            setEditable: setEditable,
            isEditDisabled: isEditDisabled,

            loadProgramByEmmiCode: loadProgramByEmmiCode,

            loadLocalProgram: loadLocalProgram,
            loadLocalPrograms: loadLocalPrograms,

            loadCheckedOutPrograms: loadCheckedOutPrograms,
            isCheckedOutByUser: isCheckedOutByUser,
            loadCheckedOutEditions: loadCheckedOutEditions,

            loadExistingQuestions: loadExistingQuestions,

            getCheckedOutByUser: getCheckedOutByUser,
            getCPOrderValid: getCPOrderValid,
            setCPOrderValid: setCPOrderValid,
            setInitialDisplayOrder: setInitialDisplayOrder,

            getCallFlows: getCallFlows,
            getCallFlowVersions: getCallFlowVersions,
            getCallFlowData: getCallFlowData,

            save: save,
            reset: reset,
            createNewProgram: createNewProgram,
            discard: discard,

            //create copy
            createCopy: createCopy,
            loadQuestion: loadQuestion,
            selectQuestion: selectQuestion,
            loadExistingWIP: loadExistingWIP,

            //read program, but not set-up it as current program
            getProgramByEmmiCode: getProgramByEmmiCode,

            //check delete program warnings on series where there is a condition
            checkDeleteWarning: checkDeleteWarning,
            loadArticleByEmmiCode: loadArticleByEmmiCode,
            setMissingCampaignData: setMissingCampaignData,
            getMissingCampaignData: getMissingCampaignData,
            setGlobalModulesToUnversioned: setGlobalModulesToUnversioned

        };

        function reset() {
            currentProgram = {};
        }

        function createNewProgram(arg) {
            var programType = programTypes[arg];
            currentProgram = utilService.newProgram(programType);
            setEditable(true);
            setCurrentAction("");
        }

        function loadProgramByEmmiCode(emmiCode) {
            if (emmiCode == -1) {
                setEditable(true);
                return $timeout(function () {
                    return getProgram();
                }, 0);
            }
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/{emmiCode}').stringify({
                    emmiCode: emmiCode
                })).then(function (response) {
                    return setProgram(response.data);
                });
            })
        }

        function getProgramByEmmiCode(emmiCode) {
            if (emmiCode == -1) {
                return null;
            }
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/{emmiCode}').stringify({
                    emmiCode: emmiCode
                })).then(function (response) {
                    return response.data;
                });
            })
        }

        function loadArticleByEmmiCode(emmiCode) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/article/{emmiCode}').stringify({
                    emmiCode: emmiCode
                })).then(function (response) {
                    return setProgram(response.data);
                });
            })
        }
        function getArticleByEmmiCode(emmiCode) {
            if (emmiCode == -1) {
                return null;
            }
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/article/{emmiCode}').stringify({
                    emmiCode: emmiCode
                })).then(function (response) {
                    return response.data;
                });
            })
        }
        function selectQuestion() {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.questionmodules).stringify(), {
                }).then(function (response) {
                    return utilService.paginateResponse(response);
                });
            })
        }
        function loadQuestion(id) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.questions + '/all/' + id).stringify(), {
                }).then(function (response) {
                    return response;
                });
            })
        }
        function loadLocalPrograms() {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/local/all').stringify(), {
                    params: {
                        sort: 'lastUpdated,desc',
                        size: 10
                    }
                }).then(function (response) {
                    return utilService.paginateResponse(response);
                });
            })
        }

        function loadLocalProgram(emmiCode) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/local/{emmiCode}').stringify({
                    emmiCode: emmiCode
                })).then(function (response) {
                    setProgram(response.data);
                    currentProgram.entity.isLatestVersion = true;
                    return currentProgram;
                });
            })
        }

        function loadCheckedOutPrograms() {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/coprograms').stringify(), {
                    params: {
                        sort: 'emmiCode',
                        size: 10
                    }
                }).then(function (response) {
                    return utilService.paginateResponse(response);
                });
            })
        }
        function loadCheckedOutEditions() {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.editions + '/coeditions').stringify(), {
                    params: {
                        sort: 'created,desc',
                        size: 10
                    }
                }).then(function (response) {
                    return utilService.paginateResponse(response);
                });
            })
        }

        function loadExistingQuestions() {
            if(loadingQuestions || !angular.equals(existingQuestions, {})) return;
            loadingQuestions = true;
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/questions').stringify(), {
                }).then(function (response) {
                    return setExistingQuestions(response);
                }).finally(function () {
                    loadingQuestions = false;
                });
            })
        }

        function save() {
            var program = getProgram();
            return Principal.identity().then(function (user) {
                program.entity.createdBy = user.entity.username;
                program.entity.updatedBy = user.entity.username;
                program.entity.meta.finalDayList = [];
                program.entity.meta.circleList = [];
                if (program.entity.type === 'FORM EDITION'){
                    program.entity.meta.formEditionMetaData.wipGlobalModules = JSON.stringify({wip: globalModulesService.getUpdatedGlobalModules()});
                }
                return $http.post(UriTemplate.create(user.link.content + '/save').stringify(),
                    program.entity).then(function (response) {
                        return setProgram(response.data);
                    });
            })
        }

        function discard() {
            var program = getProgram();
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.content + '/discard').stringify(),
                    program.entity).then(function (response) {
                        return response.status;
                    });
            })
        }

        function createCopy(name) {
            var program = getProgram();
            var copy = angular.copy(program);
            if (copy.entity.type === 'FORM EDITION'){
                copy = setGlobalModulesToUnversioned(copy);
            }
            copy.entity.name = name;
            copy.entity.emmiCode = null;
            copy.entity.editionId = null;
            copy.entity.created = null;
            copy.entity.createdBy = null;
            copy.entity.lastUpdated = null;
            copy.entity.updatedBy = null;
            copy.entity.version = null;
            copy.entity.versionedToLocal = false;
            copy.entity.versionedToLocal = false;
            copy.entity.meta.editions = null;

            copy.entity.meta.patientFacingNames.forEach(function (assetSet, index, array) {
                copy.entity.meta.patientFacingNames[index].description = '';
            });

            return setProgram(copy);
        }

        function getProgram(arg) {
            if (!currentProgram.entity) {
                var programType = null;
                if(arg) {
                    programType = programTypes[arg];
                }
                currentProgram = utilService.newProgram(programType);
            }
            if (!currentProgram.entity.meta) {
                currentProgram.entity.meta = {};
            } else {
                if (currentProgram.entity.meta.formEditionMetaData) loadExistingWIP(currentProgram);
            }

            if (currentProgram.entity.meta.programCategory &&
                currentProgram.entity.meta.programCategory === 'Minor') {
                currentProgram.entity.meta.programCategory = 'Pediatric';
            }

            return currentProgram;
        }

        function setProgram(program) {
            currentProgram.entity = program.entity;
            return currentProgram;
        }
        function getExistingQuestions() {
            return existingQuestions;
        }

        function setExistingQuestions(questions) {
            existingQuestions = questions.data.questions;
            return existingQuestions;
        }

        function loadExistingWIP(selectedProgram){
            var wipGM = selectedProgram.entity.meta.formEditionMetaData.wipGlobalModules ? JSON.parse(selectedProgram.entity.meta.formEditionMetaData.wipGlobalModules) : {wip: []};
             globalModulesService.setUpdatedGlobalModules = wipGM.wip.length > 0 ? wipGM.wip[0] : [];
        }
        function getCPOrderValid() {
            return CPOrderInvalidDays;
        }

        function setCPOrderValid(day, invalidState) {

            if (invalidState) {
                if (CPOrderInvalidDays.indexOf(day) === -1) {
                    CPOrderInvalidDays.push(day);
                }
            } else {
                var existingIndex = CPOrderInvalidDays.indexOf(day);
                if (existingIndex !== -1) {
                    CPOrderInvalidDays.splice(existingIndex, 1)
                }
            }
            return CPOrderInvalidDays;
        }

        function isCheckedOutByUser() {
            var currentUser = $sessionStorage.userName;
            if (currentProgram && currentProgram.entity) {
                var entity = currentProgram.entity;
                var status = entity.status;
                if (status) {
                    var checkedOutBy = status.checkedOutBy;
                    if (checkedOutBy) {
                        return checkedOutBy == currentUser;

                    } else { //Program is not checked out.
                        return false;
                    }
                } else if (entity.versionedToLocal) { //versioned to local
                    return entity.lastUpdatedBy == currentUser;
                } else if (entity.name) { //Program is not versioned
                    return true;
                }
            }
            return false;
        }

        function getCheckedOutByUser() {
            if (currentProgram && currentProgram.entity) {
                var entity = currentProgram.entity;
                var status = entity.status;
                if (status) {
                    var checkedOutBy = status.checkedOutBy;
                    return checkedOutBy;

                }
            }
            return "";
        }

        function isEditDisabled() {
            return !editable;
        }

        function setEditable(canEdit) {
            editable = canEdit;
            if(currentProgram && currentProgram.entity && currentProgram.entity.type === "FORM EDITION") {
                //metadata not required for editions
                return;
            } else {
                var isLoading = programInitService.loadingProgramData();
                if (canEdit && !isLoading) {
                    programInitService.loadProgramData();
                }
            }
        }
        function setMissingCampaignData(arg){
            missingCampaignData = arg;
        }

        function getMissingCampaignData(){
            return missingCampaignData;
        }
        function checkDeleteWarning(series, type) {
            if(!series || series.length == 0) return;
            for (var i = 0; i < series.length; i++) {
                if (series[i][type] && series[i][type].condition && series[i][type].condition.deleteConditionIconFlag) {
                    currentProgram.anyDeleteConditionIconFlag = true;
                    return;
                }
                if (series[i].media) {
                    for (var j = 0; j < series[i].media.length; j++) {
                        if (series[i].media[j] && series[i].media[j].condition && series[i].media[j].condition.deleteConditionIconFlag) {
                            currentProgram.anyDeleteConditionIconFlag = true;
                            //return;
                        }
                    }
                }
                if (series[i].formPrograms) {
                    for (var j = 0; j < series[i].formPrograms.length; j++) {
                        if (series[i].formPrograms[j] && series[i].formPrograms[j].condition && series[i].formPrograms[j].condition.deleteConditionIconFlag) {
                            currentProgram.anyDeleteConditionIconFlag = true;
                            //return;
                        }
                    }
                }
                if (series[i].ivrPrograms) {
                    for (var j = 0; j < series[i].ivrPrograms.length; j++) {
                        if (series[i].ivrPrograms[j] && series[i].ivrPrograms[j].condition && series[i].ivrPrograms[j].condition.deleteConditionIconFlag) {
                            currentProgram.anyDeleteConditionIconFlag = true;
                            //return;
                        }
                    }
                }
            }
        }

        function getCurrentAction() {
            return currentAction;
        }

        function setCurrentAction(action) {
            currentAction = action;
        }
        
        function setInitialDisplayOrder(item){
            item.orderSet = [];
            var orderCounter = 1;
            // Add order for new program days
            if (item.formType) {
                item.formType.entity.order = orderCounter;
                item.orderSet.push(orderCounter);
                orderCounter++;
            }
            angular.forEach(item.formPrograms, function(res){
                res.entity.order = orderCounter;
                item.orderSet.push(orderCounter);
                orderCounter++;
            });
            angular.forEach(item.media, function(res){
                res.entity.order = orderCounter;
                item.orderSet.push(orderCounter);
                orderCounter++;
            });

            return item;
        }

        function getCallFlows(searchTerm){
            return Principal.identity().then(function (user) {
                gettingCallFlows = true;
                var params = {
                    sort: 'displayName,asc',
                    size: 10
                };

                if(searchTerm) {
                    return $http.get(UriTemplate.create(user.link.content + '/ivr/callFlows/' + searchTerm).stringify(), {
                        params: params
                    }).then(ok);
                } else {
                    return $http.get(UriTemplate.create(user.link.content + '/ivr/callFlows').stringify(), {
                        params: params
                    }).then(ok);
                }

                function ok(response) {
                    gettingCallFlows = false;
                    return utilService.paginateResponse(response);
                }
            })
        }

        function getCallFlowVersions(callFlow){
            gettingCallFlows = true;
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/ivr/callFlowVersions/' + callFlow.id).stringify())
                    .then(function (response) {
                    gettingCallFlows = false;
                    return response.data;
                });
            })
        }
        function  getCallFlowData(id, version){
            gettingCallFlows = true;
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/ivr/callFlowData/' + id + '/' + version).stringify())
                    .then(function (response) {
                    return response.data;

                });
            })
        }
        function setGlobalModulesToUnversioned(program) {
            var formModules = program.entity.meta.formEditionMetaData.formModules;
            if (formModules) {
                for (var i = 0; i < formModules.length; i++){
                    if (formModules[i].isGlobalModule) {
                        formModules[i].linkedToGlobal = false;
                        formModules[i].globalVersion = 0;
                    }
                }
            }
            return program;
        }
    }
})(window.angular);
