(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('MultiSelectController', MultiSelectController);

    MultiSelectController.$inject = ['UtilService', 'fullList', 'selectedItems', 'title', 'AlertService', 'saveCallback'];

    function MultiSelectController(utilService, fullList, selectedItems, title, alertService, saveCallback) {
        var sic = this;
        var editSelectedItems = selectedItems.slice();
        sic.fullList = fullList;
        sic.selectedItems = selectedItems;
        sic.title = title;
        sic.saveCallback = saveCallback;
        sic.onSelectChange = onSelectChange;

        sic.nextPage = nextPage;
        sic.save = save;
        sic.cancel = cancel;
        var config = {
            params: {
                active: true
            }
        };

        function setSelectedItems() {
            
            angular.forEach(sic.fullList.content, function (item) {
                item.select = false;
                angular.forEach(editSelectedItems, function (selectedItem) {
                    if (item.entity.id == selectedItem.id) {
                        item.select = true;
                        return;
                    }
                });
            });
        }

        (function init() {
            setSelectedItems();
        })();

        function nextPage(link) {
            if (sic.fullList && link === sic.fullList.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                sic.loadingNextPage = true;
                utilService.fetchPageWithConfig(link, config)
                    .then(ok)
                    .catch(function(response) {
                        alertService.error(response, "Loading next page");
                    })
                    .finally(function () {
                        delete sic.loadingNextPage;
                    })
            }

            function ok(response) {
                if (response) {
                    sic.fullList = response;
                    setSelectedItems();
                }
            }
        }

        function onSelectChange(item) {
            if(item.hasOwnProperty('select')) {
                if(item.select) {
                    item.select = false;
                } else {
                    item.select = true;
                }
            } else {
                item.select = true;
            }
            if(item.select) {
                editSelectedItems.push(item.entity);
            } else {
                angular.forEach(editSelectedItems, function(selectedItem, idx) {
                   if(selectedItem.id == item.entity.id) {
                       editSelectedItems.splice(idx, 1);
                       return;
                   }
                });
            }
        }

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                $('body').removeClass('modal-open');
                sic.selectedItems.splice(0, sic.selectedItems.length);
                angular.forEach(editSelectedItems, function (selectedItem) {
                    sic.selectedItems.push(selectedItem);
                });

                if(sic.saveCallback) {
                    sic.saveCallback();
                }
            }
        }
        function cancel($hide) {
            $hide();
            $('body').removeClass('modal-open');
        }
    }
})(window.angular);