(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewTypeAssetSetController', NewTypeAssetSetController);

    NewTypeAssetSetController.$inject = ['NewTypeAssetSetService', 'ProgramInitService', '$scope'];

    function NewTypeAssetSetController(NewTypeAssetSetService, ProgramInitService, $scope) {
        var nbc = this;

        nbc.save = save;
        nbc.newTypeAssetSet = NewTypeAssetSetService.newTypeAssetSet();
        nbc.data = ProgramInitService.getTypeAssetSets(true, true);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewTypeAssetSetService.create(nbc.newTypeAssetSet).then(function (response) {
                    $scope.$emit('new-typeAssetSet', response);
                    nbc.data.typeAssetSets.content.push(response);
                })
            }
        }
    }

})(window.angular);
