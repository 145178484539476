(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('WorkflowSubscriptionController', WorkflowSubscriptionController);

    WorkflowSubscriptionController.$inject = ['WorkflowSubscriptionService'];

    function WorkflowSubscriptionController(WorkflowSubscriptionService) {
        var WorkflowSubscriptionCtrl = this;

        (function onControllerLoad() {
            WorkflowSubscriptionCtrl.nextPage = nextPage;
            WorkflowSubscriptionCtrl.hasVisible = hasVisibleWorkflows;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                WorkflowSubscriptionCtrl.loadingNextPage = true;
                WorkflowSubscriptionService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    WorkflowSubscriptionCtrl.loadingNextPage = false;
                } else {
                    WorkflowSubscriptionCtrl.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                WorkflowSubscriptionCtrl.loadingNextPage = true;
            }
        }

        function hasVisibleWorkflows() {
            var hasVisible = false,
                length = WorkflowSubscriptionCtrl.workflowSubscriptions && WorkflowSubscriptionCtrl.workflowSubscriptions.content &&
                    WorkflowSubscriptionCtrl.workflowSubscriptions.content.length;
            for (var i = 0; i < length; i++) {
                if (!WorkflowSubscriptionCtrl.workflowSubscriptions.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            WorkflowSubscriptionCtrl.loading = true;
            WorkflowSubscriptionService.search()
                .then(function (response) {
                    WorkflowSubscriptionCtrl.workflowSubscriptions = response;
                })
                .finally(function () {
                    delete WorkflowSubscriptionCtrl.loadingNextPage;
                    delete WorkflowSubscriptionCtrl.loading;
                });
        }

    }
})(window.angular);
