(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewPerspectiveController', NewPerspectiveController);

    NewPerspectiveController.$inject = ['NewPerspectiveService', 'ProgramInitService', '$scope'];

    function NewPerspectiveController(NewPerspectiveService, programInitService, $scope) {
        var nbc = this;

        nbc.save = save;
        nbc.newPerspective = NewPerspectiveService.newPerspective();
        nbc.data = programInitService.getPerspectives();

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewPerspectiveService.create(nbc.newPerspective).then(function (response) {
                    $scope.$emit('new-perspective', response);
                    nbc.data.perspectives.content.push(response);
                })
            }
        }
    }

})(window.angular);
