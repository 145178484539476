(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('CarePlanConditionController', CarePlanConditionController);

    CarePlanConditionController.$inject = ['ProgramVersionService', 'fullList', 'currentDay', 'programEmmiCd', 'saveCallback', 'UtilService', 'ProgramService', '$state'];

    function CarePlanConditionController(ProgramVersionService, fullList, currentDay, programEmmiCd, saveCallback, utilService, programService, $state) {
        var cpcc = this;
        cpcc.previousForms = {};
        cpcc.questionsLst = [];
        cpcc.fullList = fullList;
        cpcc.saveCallback = saveCallback;
        cpcc.condition = {};
        cpcc.cancel = cancel;
        cpcc.getQuestions = getQuestions;
        cpcc.updateResponses = updateResponses;
        cpcc.responsesOpen = false;
        cpcc.selectedResponsesString = '';
        cpcc.toggleResponsesOpen = toggleResponsesOpen;
        cpcc.saveCondition = saveCondition;
        cpcc.isValidCondition = isValidCondition;
        cpcc.action = "Add";
        cpcc.setFormModified = setFormModified;
        // cpcc.notAnsweredResponsePresent = addNotAnsweredResponse;

        (function onLoad() {
            getPreviousForms(fullList);
            preLoadDataInEditMode(fullList, currentDay, programEmmiCd);
            cpcc.action = utilService.isEmpty(cpcc.condition) ? "Add" : "Edit";
        })();


        function setFormModified(form) {
            form.$setDirty();
        }
        function preLoadDataInEditMode(fullList, currentDay, programEmmiCd) {
            if (fullList && currentDay && programEmmiCd) {
                var item = utilService.findIndex(fullList, 'day', Number(currentDay));
                if (isValidArray(item.formPrograms)) findAndSetExistingCondition(item.formPrograms, programEmmiCd);
                if (isValidArray(item.ivrPrograms)) findAndSetExistingCondition(item.ivrPrograms, programEmmiCd);
                if (isValidArray(item.media)) findAndSetExistingCondition(item.media, programEmmiCd);
            }
        }

        function findAndSetExistingCondition(programs, programEmmiCd){
            programs.forEach(function (program) {
                if (program && program.condition && program.entity.emmiCode === programEmmiCd) {
                    var name = program.condition.selectedForm.name;
                    cpcc.selectedForm = cpcc.previousForms[name];
                    cpcc.condition = program.condition;
                    getQuestions(function () {
                        cpcc.selectedQuestion = getQuestionsFromLst(cpcc.questionsLst, program.condition.selectedQuestion);
                        if (cpcc.selectedQuestion && (cpcc.selectedQuestion.questionResponseType === 3 || cpcc.selectedQuestion.questionResponseType === 4)) {
                            cpcc.selectedOperator = program.condition.selectedOperator;
                            cpcc.textResponses = program.condition.textResponses;
                            cpcc.selectedResponses = null;
                        } else {
                            cpcc.selectedResponses = program.condition.selectedResponses;
                            cpcc.selectedOperator = null;
                            cpcc.textResponses = null;
                        }
                    });
                }
            });
        }

        function isValidArray(array){
            return array && array.length>0
        }

        function getQuestionsFromLst(questionLst, question) {
            var questionObj = null;
            questionLst.forEach(function (obj) {
                if (obj.questionID === question.questionID) {
                    questionObj = obj;
                    return;
                }
            });
            return questionObj;
        }

        function getPreviousForms(fullList) {
            fullList.forEach(function (obj) {
                if (obj.formType && obj.formType.entity && obj.day < currentDay) {
                    obj.formType.entity.dispName = obj.formType.entity.name;
                    cpcc.previousForms[obj.formType.entity.dispName] = obj.formType.entity;
                }
                if (obj.formPrograms && obj.formPrograms.length && obj.day < currentDay) {
                    obj.formPrograms.forEach(function (formProgram){
                        formProgram.entity.dispName = formProgram.entity.name;
                        formProgram.entity.type = 'FORM';
                        cpcc.previousForms[formProgram.entity.dispName] = formProgram.entity;
                    });
                }
                if (obj.ivrPrograms && obj.ivrPrograms.length && obj.day < currentDay) {
                    obj.ivrPrograms.forEach(function (ivrProgram){
                        ivrProgram.entity.dispName = ivrProgram.entity.name;
                        ivrProgram.entity.type = 'IVR CALL';
                        cpcc.previousForms[ivrProgram.entity.dispName] = ivrProgram.entity;
                    });
                }

            });
        }

        function toggleResponsesOpen() {
            cpcc.responsesOpen = !cpcc.responsesOpen;
        }
        function getQuestions(callback) {
            cpcc.questionsLst = [];
            cpcc.selectedResponses = [];
            if (cpcc.selectedForm && cpcc.selectedForm.meta) {
                cpcc.questionsLst = [];
                parseQuestionsResponses();
                if (callback) {
                    callback();
                }
            } else if (cpcc.selectedForm) {
                updateSelectedForm(cpcc.selectedForm)
                    .then(function (response) {
                        if (response.meta) {
                            cpcc.selectedForm.meta = response.meta;
                            cpcc.questionsLst = [];
                            parseQuestionsResponses();
                            if (callback) {
                                callback();
                            }
                        }
                    });
            }
        }

        function addNotAnsweredResponse(responses) {
            if(!responses) responses = [];
            var notAnsweredResponsePresent = false;
            for (var i = 0; i < responses.length; i++) {
                if (responses[i] && responses[i].response === 'Not Answered') {
                    notAnsweredResponsePresent = true;
                }
            }
            if(!notAnsweredResponsePresent) {
                responses.push({response: 'Not Answered'});
            }
        }

        function parseQuestionsResponses(){
            if (cpcc.selectedForm.type === 'FORM') {
                cpcc.selectedForm.meta.editions[0].version.metaParsed = JSON.parse(cpcc.selectedForm.meta.editions[0].version.meta);
                cpcc.selectedForm.meta.editions[0].version.metaParsed.forEach(function (question) {
                    if (question.questionResponseType === 1) {
                        addNotAnsweredResponse(question.validResponses);
                        cpcc.questionsLst.push({
                            questionID: question.questionId,
                            validResponses: question.validResponses,
                            questionResponseType: question.questionResponseType
                        });
                    }
                });
            }
            else if (cpcc.selectedForm.type === 'IVR CALL') {
                cpcc.selectedForm.meta.questions.forEach(function (question) {
                    question.parsedResponses = question.parsedResponses ? question.parsedResponses : [];
                    if (typeof question.responses === 'string' || question.responses instanceof String) {
                        if(question.responses.includes('|')){
                            question.responses = question.responses.split('|');
                        } else {
                            question.responses = question.responses.split(',');
                        }


                        for(var i = 0; i < question.responses.length; i++){
                            question.parsedResponses.push({response: question.responses[i]});
                        }
                    }
                    if (question.questionResponseType === 1) {
                        addNotAnsweredResponse(question.parsedResponses);
                        cpcc.questionsLst.push({
                            questionID: question.questionID,
                            validResponses: question.parsedResponses,
                            questionResponseType: question.questionResponseType
                        });
                    }
                });
            }
        }

        function updateResponses(response) {
            var idx = cpcc.selectedResponses.indexOf(response.response);
            if (idx > -1) {
                cpcc.selectedResponses.splice(idx, 1);
            } else {
                cpcc.selectedResponses.push(response.response);
                cpcc.selectedResponsesString = cpcc.selectedResponses.toString().replace('[', '').replace(']', '');

            }
        }
        function saveCondition($hide, form) {
            cpcc.setFormModified(form);
            cpcc.condition.selectedForm = cpcc.selectedForm;
            cpcc.condition.selectedQuestion = cpcc.selectedQuestion;
            if (cpcc.selectedQuestion && (cpcc.selectedQuestion.questionResponseType === 3 || cpcc.selectedQuestion.questionResponseType === 4)) {
                cpcc.condition.textResponses = cpcc.textResponses;
                cpcc.condition.selectedOperator = cpcc.selectedOperator;
                cpcc.condition.selectedResponses = null;
                cpcc.condition.deleteConditionIconFlag = false;
                programService.getProgram().anyDeleteConditionIconFlag = false;

            } else {
                cpcc.condition.selectedResponses = cpcc.selectedResponses;
                cpcc.condition.textResponses = null;
                cpcc.condition.selectedOperator = null;
                cpcc.condition.deleteConditionIconFlag = false;
                programService.getProgram().anyDeleteConditionIconFlag = false;
            }

            fullList.forEach(function (obj) {
                if (obj.day === currentDay) {
                    if (obj.formType && obj.formType.entity.emmiCode === programEmmiCd) {
                        obj.formType.condition = cpcc.condition;
                    }
                    if (obj.media && obj.media.length > 0) {
                        obj.media.forEach(function (mediaItem) {
                            if (mediaItem.entity.emmiCode === programEmmiCd) {
                                mediaItem.condition = cpcc.condition;
                            }
                        });
                    }
                   if (obj.formPrograms && obj.formPrograms.length > 0) {
                        obj.formPrograms.forEach(function (formProgramItem) {
                            if (formProgramItem.entity.emmiCode === programEmmiCd) {
                                formProgramItem.condition = cpcc.condition;
                            }
                        });
                    }
                    if (obj.ivrPrograms && obj.ivrPrograms.length > 0) {
                        obj.ivrPrograms.forEach(function (ivrProgramItem) {
                            if (ivrProgramItem.entity.emmiCode === programEmmiCd) {
                                ivrProgramItem.condition = cpcc.condition;
                            }
                        });
                    }


                }
            });
            cpcc.saveCallback({ 'fullList': fullList });
            cancel($hide);
        }
        function updateSelectedForm(formProgram) {
            if (formProgram.type === 'IVR CALL') return ProgramVersionService.loadProgramByEmmiCode(formProgram.emmiCode);
            if (formProgram.type === 'FORM') return ProgramVersionService.loadFormByEmmiCode(formProgram.emmiCode);

        }
        function isValidCondition() {
            var valid = cpcc;
            return !(valid.selectedForm && valid.selectedQuestion
                && ((valid.selectedResponses && valid.selectedResponses.length > 0)
                    || (RegExp('\\d+').test(valid.textResponses) && valid.selectedOperator)));
        }
        function cancel($hide) {
            $hide();
            $('body').removeClass('modal-open');
        }
    }
})(window.angular);
