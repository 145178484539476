(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('Account', Account);

    Account.$inject = ['$http', 'API'];

    function Account($http, api) {

        return {
            get: get
        };

        function get() {
            return $http.get(api.links.user).then(function (response) {
                return response.data;
            });
        }

    }
})(window.angular);
