(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditionsController', EditionsController);

    EditionsController.$inject = ['ProgramService', 'EditionsService', 'AssetSetService', 'UtilService',
        'VideoService', 'AlertService', '$scope'];

    function EditionsController(programService, editionsService, assetSetService, utilService,
                                videoService, alertService, $scope) {
        var ec = this;
        ec.collapsed = false;
        ec.editionsCollapsed = true;
        ec.editEditionsCollapsed = true;
        ec.newEditionsCollapsed = true;
        ec.editionName = null;

        //initialize data
        ec.program = program;
        ec.editions = editionsService.getEditions();
        ec.formatVersion = utilService.formatVersion;

        //editions
        //working with editions
        ec.createNewEdition = createNewEdition;
        ec.saveEdition = saveEdition;
        ec.deleteEdition = deleteEdition;
        ec.cancelEditions = clearSelectedEdition;
        ec.resetEdition = resetEdition;

        ec.setSelectedEdition = setSelectedEdition;
        ec.editEdition = editEdition;
        ec.getEditionsByAssetSetId = getEditionsByAssetSetId;

        //show editions
        ec.hasVisibleEditions = editionsService.hasVisibleEditions;
        ec.hasVisibleVersions = editionsService.hasVisibleVersions;
        ec.hasEditions = editionsService.hasEditions;
        ec.nextEditionsPage = nextEditionsPage;


        //asset set on editions
        ec.getAssetSetSummary = getAssetSetSummary;
        ec.isAssetSetDisabled = isAssetSetDisabled;

        ec.setEditionVersions = setEditionVersions;
        ec.setSelectedVersion = setSelectedVersion;

        ec.initEditionFormData = initEditionFormData;
        ec.canSearchVersion = canSearchVersion;
        ec.searchEditions = searchEditions;

        ec.cancelEditionSelect = cancelEditionSelect;

        //chapters and video sections
        ec.hasMetaForChapters = hasMetaForChapters;
        ec.getMetaForChapters = editionsService.getMetaForChapters;
        ec.toggleChapterList = editionsService.toggleChapterList;
        ec.getVideoSections = getVideoSections;
        ec.removeVideoSections = editionsService.removeSelectedEditionVideoSections;
        ec.hasVideoSections = hasVideoSections;
        ec.openInNewTab = utilService.openInNewTab;
        ec.getCCUrl = getCCUrl;
        ec.hasCCSection = hasCCSection;

        //check errors
        ec.checkErrors = checkErrors;
        ec.savedEdition = true;
        ec.validEdition = true;

        ec.selectedEdition = editionsService.getSelectedEdition();
        ec.selectedEdition.editionVersions = [];
        ec.selectedVersion = {};
        ec.hasMetaInEdition = hasMetaInEdition;
        ec.hasNonMatchedVersion = hasNonMatchedVersion;
        ec.checkEditionVersion = checkEditionVersion;

        ec.hasEditionWithoutChapter = hasEditionWithoutChapter;
        ec.checkEditionChapters = checkEditionChapters;

        ec.setPrintSummary = editionsService.setPrintSummary;
        ec.hasPrintSummary = editionsService.hasPrintSummary;

        (function init() {
            ec.loadingAssetSets = true;
            assetSetService.list()
                .then(function (data) {
                    ec.assetSets = data;
                    ec.assetSets.forEach(function (assetSet, index, array) {
                        assetSet.summary = assetSetService.getSummary(assetSet);
                    });
                })
                .finally(function () {
                    delete ec.loadingAssetSets;
                });
            initEditionFormData();
        })();

        function getCCUrl(sectionUrls) {
            var sectionUrl = sectionUrls.filter(isCCSectionUrl);
            return sectionUrl.length !== 0 ? sectionUrl[0].url : '';
        }

        function hasCCSection(sectionUrls) {
            return sectionUrls.filter(isCCSectionUrl).length !== 0;
        }

        function isCCSectionUrl(sectionUrl) {
           return sectionUrl.urlType.name === 'CC';
        }

        function program() {
            return programService.getProgram();
        }

        function checkErrors() {
            return ec.savedEdition === false;
        }

        function canSearchVersion() {
            return ec.validEdition === true;
        }

        ec.isEditDisabled = function () {
            return programService.isEditDisabled() || ec.loading;
        };

        ec.backToEdition = function () {
            $scope.$emit('back-to-edition');
        };

        ec.getEditionVersions = function () {
            var edVersPage = editionsService.getEditionVersions();
            return edVersPage.content;
        };

        ec.editCollapsed = function (idx) {
            if (idx === ec.selectedEdition.index)
                return ec.editEditionsCollapsed;
            return true;
        };

        function createNewEdition() {
            ec.editEditionsCollapsed = true;
            ec.newEditionsCollapsed = false;
            ec.selectedEdition.assetSet = null;
            ec.selectedEdition = {};
            ec.newChaptersExpended = true;

            ec.savedEdition = true;
            ec.validEdition = true;
            resetEdition();
        }

        function getAssetSetSummary(assetSet) {
            return assetSetService.getSummary(assetSet);
        }

        function isFormValid(form) {
            var isValid = form && form.$valid;
            if (!isValid) {
                form.$setSubmitted();
                alertService.error("Validation errors", "Add Edition");
            }
            return isValid;
        }

        function isAssetSetDisabled(assetSet) {
            var disabled = false;
            if (editionsService.hasEditions()) {
                var entity = ec.program().entity;
                angular.forEach(entity.meta.editions, function (edition) {
                    if (edition.assetSet.id == assetSet.id) {
                        disabled = true;
                        return;
                    }
                });
            }
            return disabled;
        }

        function resetEdition() {
            ec.selectedEdition.name = null;
            ec.selectedEdition.version = null;
            ec.selectedEdition.formattedVersion = null;
            ec.selectedEdition.editionId = null;

            ec.selectedEdition.editionVersions = [];
            ec.selectedEdition.selectedVersion = null;

            editionsService.setSelectedEdition(ec.selectedEdition);

            editionsService.clearEditionVersions();

            if (ec.selectedEdition.assetSet) {
                editionsService.setAssetSet(ec.selectedEdition.assetSet);
                getEditionsByAssetSetId();
            }
        }

        function clearSelectedEdition() {
            ec.selectedEdition = {};
            editionsService.setSelectedEdition(ec.selectedEdition);
            ec.editEditionsCollapsed = true;
            ec.newEditionsCollapsed = true;

            editionsService.copySelectedEdition(ec.selectedEdition);
        }

        function initEditionFormData() {
            var curEd = editionsService.getSelectedEdition();
            var editions = editionsService.getEditions();

            if (editions && editions.content) {
                editions.content.forEach(function (obj) {
                    var edition = obj.entity;
                    if (edition.editionId == curEd.editionId) {
                        ec.editSelectedEdition = obj;
                    }
                });
            }

            var curEv = editionsService.getSelectedVersion();
            var editionVersions = editionsService.getEditionVersions();
            if (curEv && curEv.editionId && editionVersions && editionVersions.content) {
                editionVersions.content.forEach(function (obj) {
                    var editionVersion = obj.entity;
                    if (editionVersion.formattedVersion == curEv.formattedVersion) {
                        ec.editSelectedVersion = obj;
                    }
                });
            }
        }

        function saveEdition(editionForm) {
            if (isFormValid(editionForm)) {
                var index = ec.selectedEdition.index;
                if (typeof index != "undefined") {
                    updateEdition(index);
                } else {
                    addEdition();
                }
                clearSelectedEdition();
                ec.savedEdition = true;

                checkEditionVersion();
                editionForm.$setDirty();
            }
            else {
                ec.savedEdition = false;
            }
        }

        function deleteEdition(index, form) {
            var editions = ec.program().entity.meta.editions;
            editions.splice(index, 1);
            clearSelectedEdition();
            checkEditionVersion();

            form.$setDirty();
        }

        function addEdition() {
            var meta = ec.program().entity.meta;
            if (!meta.editions) {
                meta.editions = [];
            }

            editionsService.setPrintSummary(ec.selectedEdition);
            if (ec.program().entity.type !== 'MULTIMEDIA PROGRAM') editionsService.getEmmiCodeByEditionId(ec.selectedEdition);


            meta.editions.push(ec.selectedEdition);
            editionsService.setSelectedEdition(ec.selectedEdition);
        }

        function updateEdition(index) {
            var existingEdition = ec.program().entity.meta.editions[index];
            existingEdition.assetSet = ec.selectedEdition.assetSet;
            existingEdition.assetSetId = ec.selectedEdition.assetSet.id;
            existingEdition.name = ec.selectedEdition.name;
            existingEdition.meta = ec.selectedEdition.meta;
            existingEdition.version = ec.selectedEdition.version;
            existingEdition.formattedVersion = ec.selectedEdition.formattedVersion;
            existingEdition.patientFacingName = ec.selectedEdition.patientFacingName;
            existingEdition.editionId = ec.selectedEdition.editionId;
            existingEdition.programSections = ec.selectedEdition.programSections;

            if (ec.program().entity.type !== 'MULTIMEDIA PROGRAM')  editionsService.getEmmiCodeByEditionId(existingEdition);
            editionsService.setPrintSummary(existingEdition);
        }

        function nextEditionsPage(link) {
            editionsService.nextPage(link, ec.editionName, ec.program().entity.type);
        }

        function addEditions(response) {
            editionsService.addEditions(response);
            ec.editions = editionsService.getEditions();
        }

        function searchEditions() {
            editionsService.clearEditionVersions();
            editionsService.resetEditions();

            getEditionsByAssetSetId();
        }

        function getEditionsByAssetSetId() {
            ec.loadingEditions = true;
            var emmiCode = ec.program().entity.emmiCode || -1;
            editionsService.getEditionsByAssetSetId(ec.editionName, emmiCode, ec.program().entity.type)
                .then(function (response) {
                    addEditions(response);
                })
                .catch(function (response) {
                    alertService.error(response, 'get editions');
                })
                .finally(function () {
                    delete ec.loadingEditions;
                });
        }

        function editEdition(idx) {
            clearSelectedEdition();
            ec.editEditionsCollapsed = !ec.editEditionsCollapsed;
            if (ec.editEditionsCollapsed || idx < 0) {
                return;
            }
            ec.newEditionsCollapsed = true;

            ec.selectedEdition.index = idx;
            ec.selectedEdition.editionVersions = [];

            var edition = ec.program().entity.meta.editions[idx];
            ec.selectedEdition.version = edition.version;
            ec.selectedEdition.formattedVersion = utilService.formatVersion(edition);
            ec.selectedEdition.meta = JSON.parse(edition.version.meta);
            ec.selectedEdition.programSections = edition.programSections;
            ec.selectedEdition.programSectionUrls = edition.programSectionUrls;

            setSelectedEditionProps(edition, false);
            getEditionsByAssetSetId();

            editionsService.copySelectedEdition(ec.selectedEdition);
        }

        function setAssetSet(edition) {
            if (edition.assetSet) {
                ec.selectedEdition.assetSet = edition.assetSet;
            } else if (edition.assetSetId && editionsService.hasEditions()) {
                var assetSetId = edition.assetSetId;
                //ec.data.assetSets.forEach(function (assetSet, index, array) {
                if(ec.assetSets) {
                    ec.assetSets.forEach(function (assetSet, index, array) {
                        if (assetSet.id == assetSetId) {
                            ec.selectedEdition.assetSet = assetSet;
                            ec.selectedEdition.assetSetId = assetSet.id;
                        }
                    });
                }
            }
            editionsService.setAssetSet(ec.selectedEdition.assetSet);
        }

        function setSelectedEdition(form, $hide) {
            ec.validEdition = form && form.$valid;
            if (ec.validEdition) {
                var selectedEdition = ec.editSelectedEdition;
                if (selectedEdition && selectedEdition.entity) {
                    var edition = selectedEdition.entity;
                    ec.selectedEdition.formattedVersion = null;
                    setSelectedEditionProps(edition, true);
                }
            }
        }

        function setSelectedEditionProps(edition, toNextState) {
            if (edition) {
                setAssetSet(edition);
                ec.selectedEdition.name = edition.name;
                ec.selectedEdition.patientFacingName = edition.patientFacingName;
                ec.selectedEdition.editionId = edition.editionId;

                ec.selectedEdition.editionVersions = [];
                editionsService.setSelectedEdition(ec.selectedEdition);
                ec.loadingEditions = true;

                editionsService.getEditionVersionsById(edition.editionId)
                    .then(function (response) {
                        setEditionVersions(response, toNextState);
                    })
                    .catch(function (response) {
                        alertService.error(response, "Versions");
                    })
                    .finally(function () {
                        delete ec.loadingEditions;
                    });
            }
        }

        function setEditionVersions(response, toNextState) {
            var i = 0;
            response.content.forEach(function (obj) {
                var editionVersion = obj.entity;
                editionVersion.formattedVersion = utilService.formatVersion(editionVersion);

                ec.selectedEdition.editionVersions.push(editionVersion);
                if (ec.selectedEdition.formattedVersion &&
                    ec.selectedEdition.formattedVersion === editionVersion.formattedVersion) {
                    editionsService.setSelectedVersion(editionVersion);
                }
                else if (i === 0) {
                    ec.selectedVersion.version = editionVersion.version;
                    ec.selectedVersion.formattedVersion = utilService.formatVersion(editionVersion);
                    editionsService.setSelectedVersion(editionVersion);
                    ec.editSelectedVersion = obj;
                }
                i++;
            });

            if (toNextState) {
                $scope.$emit('selected-edition', ec.selectedEdition);
            }
        }

        function setSelectedVersion(form, $hide) {
            if (isFormValid(form)) {
                $hide();
                var selectedVersion = ec.editSelectedVersion;
                if (selectedVersion && selectedVersion.entity) {
                    var editionVersion = selectedVersion.entity;
                    ec.selectedEdition = editionsService.getSelectedEdition();
                    ec.selectedEdition.version = editionVersion.version;
                    ec.selectedEdition.formattedVersion = editionVersion.formattedVersion;

                    var chapters = JSON.parse(editionVersion.version.meta);
                    ec.selectedEdition.meta = null;
                    if (chapters && chapters.length > 0) {
                        ec.selectedEdition.meta = chapters;
                    }
                    ec.selectedEdition.programSections = [];

                    editionsService.copySelectedEdition(ec.selectedEdition);

                    editionsService.setSelectedVersion(editionVersion);
                    editionsService.setSelectedEdition(ec.selectedEdition);

                    $scope.$emit('selected-version', ec.selectedEdition);
                }
            }
        }

        function cancelEditionSelect($hide) {
            $hide();

            ec.selectedEdition.assetSet = editionsService.getOldAssetSet();
            editionsService.setAssetSet(ec.selectedEdition.assetSet);
            ec.getEditionVersions();

            var curEditionId = ec.selectedEdition.editionId;
            ec.selectedEdition = editionsService.getOldSelectedEdition();

            if (ec.selectedEdition.assetSet && ec.editionName) {
                ec.editionName = null;
                editionsService.setSelectedEdition(ec.selectedEdition);
                searchEditions();
            }

            if (ec.selectedEdition.editionId && ec.selectedEdition.editionId != curEditionId) {
                setSelectedEditionProps(ec.selectedEdition, false);
            }

            $scope.$emit('restore-edition', ec.selectedEdition);
        }

        function hasMetaInEdition(edition) {
            var hasMetaInEdition = edition && edition.version && edition.version.meta != '{}';
            if(hasMetaInEdition) {
                if(!edition.emmiCode) {
                    if (ec.program().entity.type !== 'MULTIMEDIA PROGRAM') editionsService.getEmmiCodeByEditionId(edition);

                }
            }
            return hasMetaInEdition;
        }

        function hasMetaForChapters(edition) {
            return edition && edition.version && edition.version.meta && edition.version.meta != '{}';
        }

        function getVideoSections() {
            var program = ec.program().entity;
            var programFolderName = program.meta.programFolderName;
            var edition = ec.selectedEdition;
            if (!programFolderName) {
                alertService.error("Enter video equivalent location", "retrieve video sections");
                return;
            } else if(!edition.assetSet) {
                alertService.error("Select assetSet", "retrieve video sections");
                return;
            }
            var assetSetPath = edition.assetSet.assetSetPath;
            var version = edition.formattedVersion.replace(/v/, "");
            var chapters = [{number: 0, name: "Full Video"}];
            editionsService.removeSelectedEditionVideoSections();
            ec.loadingSections = true;
            angular.forEach(edition.meta, function (chapter) {
                chapters.push(chapter);
            });
            angular.forEach(chapters, function (chapter) {
                videoService.getVideoSections(programFolderName, assetSetPath, version, chapter.number)
                    .then(function (response) {
                        if (response && response != "") {
                            videoService.setSectionInformation(chapter, edition, response);
                        } else {
                            videoService.setNoSectionInformation(chapter, edition);
                        }
                        if (ec.selectedEdition.programSections.length == chapters.length) {
                            videoService.sortSections(ec.selectedEdition);
                            delete ec.loadingSections;
                        }
                    })
                    .catch(function (response) {
                        // alertService.error(response, "Loading Video Sections");
                        delete ec.loadingSections;
                    })
            });
        }

        function hasVideoSections() {
            return videoService.hasVideoSections(ec.selectedEdition);
        }

        function hasNonMatchedVersion() {
            if (!editionsService.hasEditions()) {
                return;
            }
            if (!ec.nonMatchedVersion) {
                checkEditionVersion();
            }
            return ec.nonMatchedVersion;
        }

        function checkEditionVersion() {
            var meta = ec.program().entity.meta;
            ec.nonMatchedVersion = false;
            if (!meta.editions || meta.editions.length < 2) {
                return;
            }

            var editions = meta.editions;
            var lastMajor = -1;
            editions.forEach(function (edition) {
                var version = edition.version;
                if (version) {
                    var major = version.major;
                    if (lastMajor > -1 && major != lastMajor) {
                        ec.nonMatchedVersion = true;
                    }
                    lastMajor = major;
                }
            });
        }

        function hasEditionWithoutChapter() {
            if (!editionsService.hasEditions()) {
                return false;
            }
            if(!ec.editionWithoutChapter) {
                checkEditionChapters();
            }
            return (ec.editionWithoutChapter && ec.editionWithoutChapter.length > 0);
        }

        function checkEditionChapters() {
            var meta = ec.program().entity.meta;
            ec.editionWithoutChapter = '';
            if (!meta.editions || meta.editions.length < 1) {
                return;
            }

            var editions = meta.editions;
            editions.forEach(function (edition) {
                if(hasMetaInEdition(edition) == false) {
                    if(ec.editionWithoutChapter.length > 0) {
                        ec.editionWithoutChapter += ', ';
                    }
                    ec.editionWithoutChapter += edition.name;
                }
                if ((edition.assetSet.id === 10 || edition.assetSet.id === 11) && edition.version.metaParsed === undefined) {
                    edition.version.metaParsed = JSON.parse(edition.version.meta);
                }

            });
        }
    }
})(window.angular);
