(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NavbarController', NavbarController);

    NavbarController.$inject = ['$state', '$http', 'Auth', 'UriTemplate', 'Principal'];

    function NavbarController($state, $http, Auth, UriTemplate, Principal) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.$state = $state;

        function logout() {
            collapseNavbar();
            Principal.identity().then(function (user) {
                $http.post(UriTemplate.create(user.link.logout).stringify({
                    sort: 'id'
                })).then(function (response) {
                    Auth.logout();
                    $state.go('logout');
                });
            })
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

    }
})(window.angular);
