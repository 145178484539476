(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditAssetSetController', EditAssetSetController);

    EditAssetSetController.$inject = ['EditAssetSetService', 'ProgramInitService', 'savedAssetSetResource', 'saveCallback', 'errorCallback', '$rootScope'];

    function EditAssetSetController(EditAssetSetService, ProgramInitService, savedAssetSetResource, saveCallback, errorCallback, $rootScope) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedAssetSetResource);
        epc.data = ProgramInitService.getAssetSets(true, true);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditAssetSetService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                    $rootScope.$broadcast('updateList');
                }, errorCallback);
            }
        }
    }
})(window.angular);
