(function(angular) {
  'use strict';

  angular
    .module('Composer')
    .directive('detachFormEdGlobalModal', detachFormEdGlobalModal);

  detachFormEdGlobalModal.$inject = [ '$modal' ];

  function detachFormEdGlobalModal($modal) {
    return {
      controller: 'ConvertFormEdGlobalModalController',
      controllerAs: "ctrl",
      link: link,
      restrict: 'A',
      scope: {
        selectedModule: "="
      }
    };

    function link(scope, element, attrs, ctrl) {
      var modal;

      element.on("click", function(event) {
        setTimeout(function() {
          modal = $modal({
            backdrop: 'static',
            controller: 'ConvertFormEdGlobalModalController',
            controllerAs: 'ctrl',
            keyboard: true,
            scope: scope,
            templateUrl: 'app/program/create/form-edition/detach-global-modal/detach-global.html',
          })
        })
      })
    }
  }
})(window.angular);

