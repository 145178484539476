(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('GlobalModulesController', GlobalModulesController);

    GlobalModulesController.$inject = ['GlobalModulesService', '$state', 'UtilService', 'AlertService', '$timeout'];

    function GlobalModulesController(GlobalModulesService, $state, UtilService, alertService, $timeout) {
        var globalModules = this;
        globalModules.currentPage = 0;
        globalModules.sort = 'name,asc';
        globalModules.getList = getList;
        globalModules.setSort = setSort;
        globalModules.goToGlobalModule = goToGlobalModule;
        globalModules.newGlobalModule = newGlobalModule;
        globalModules.parseModule = parseModule;
        globalModules.routeToEdition = routeToEdition;
        globalModules.deleteGlobalModule = deleteGlobalModule;
        globalModules.canSyncAll = canSyncAll;
        globalModules.syncAll = syncAll;
        globalModules.source = 'copy';
        globalModules.searchTerm = '';
        globalModules.pageList = [];
        globalModules.pageListNav = [];
        globalModules.loadingNextPage = true;
        globalModules.modules = null;
        globalModules.syncInProgress = false;
        globalModules.getList(globalModules.sort, globalModules.currentPage, globalModules.searchTerm);
        (function onControllerLoad() {
            if (globalModules.modules === null) globalModules.getList(globalModules.sort, globalModules.currentPage, globalModules.searchTerm);

        })();

        function getList(sort, currentPage, searchTerm) {
            if (currentPage === -1) return false;
            globalModules.modules = [];
            globalModules.currentPage = null;
            globalModules.totalPages = null;
            globalModules.loading = true;
            return GlobalModulesService.fetchPage(currentPage, sort, 40, searchTerm).then(function(response) {
                globalModules.modules = response;
                globalModules.currentPage = currentPage;
                globalModules.totalPages = response.totalPages;
                globalModules.loadingNextPage = false;
                generatePaginationList(currentPage, globalModules.totalPages);
                globalModules.loading = false;
                return globalModules.modules;
            }).catch(function(response){
                alertService.error(response, "Unable to load modules");
                });

        }
        function setSort(sort) {
            globalModules.sort = sort;
            globalModules.loadingNextPage = true;
            globalModules.getList(globalModules.sort, globalModules.currentPage, globalModules.searchTerm);
        }

        function goToGlobalModule(gmName, gmId) {
            $state.go('edit-global-module', {
                gmName: gmName,
                type: 'edit',
                gmId: gmId
            });
        }

        function newGlobalModule() {
            $state.go('new-global-module', {
                gmName: '',
                type: 'new'
            });
        }

        function parseModule(module){
            module.globalModuleMetaDataParsed = JSON.parse(module.globalModuleMetaData);
            return module;
        }

        function generatePaginationList(currentPage, totalPages){
            var i;
            globalModules.pageList = [];
            globalModules.pageListNav = {
                first: (currentPage !== 0 ? 0 : -1),
                prev: (currentPage !== 0 ? (currentPage - 1) : -1),
                next: (currentPage + 1 <= totalPages - 1 ? currentPage + 1 : -1),
                last: (currentPage + 1 <= totalPages - 1 ? totalPages - 1 : -1)
            };
            if (currentPage <= 5) {
                for (i = 0; i < (totalPages > 10 ? 10 : totalPages); i++) {
                        globalModules.pageList.push(i);
                }

            } else if (currentPage > 5) {
                for (i = totalPages > 10 ? currentPage - 5 : 0; i < ((currentPage + 5) > totalPages ? totalPages : (currentPage + 5)); i++) {
                    globalModules.pageList.push(i);
                }
            }
        }

        function routeToEdition(edition){
            GlobalModulesService.getEditionEmmiCode(edition).then(function(response){
                window.open('#/view-details/' + response.data,'_blank');
            }).catch(function(response){
                alertService.error(response, "Unable to open form edition");
            })

            ;
        }

        function deleteGlobalModule(selectedModule, $hide){
            GlobalModulesService.deleteGlobalModule(selectedModule.id).then(function(response){
                if (response.status !== 200){
                   alertService.error("Unable to delete the specific module. ", response.statusText);
                } else {
                    alertService.customSuccess('The global module has been deleted. Refreshing page...');
                }
            }).catch(function(response){
                alertService.error("Unable to delete the specific module.", response.statusText);

            }).finally(function(){
                $hide();
                $timeout(function(){
                    window.location.reload();
                }, 3000);
            })
        }

        function canSyncAll(module, index){
            var canSync = false;
            if (module.globalModulesSubscriptions || module.globalModulesSubscriptions.length > 0){
                for (var i = 0; i < module.globalModulesSubscriptions.length; i++){
                    if (module.globalModulesSubscriptions[i].version !== module.version){
                        canSync = true;
                        globalModules.modules.content[index].canSync = canSync;
                        break;
                    }
                }
            }
            return canSync;
        }

        function syncAll(module, index){
            globalModules.syncInProgress = true;
            globalModules.modules.content[index].syncInProgress = true;
            GlobalModulesService.syncAllEditions(module).then(function (response){
                globalModules.syncInProgress = false;
                if (response.status === 200){
                    alertService.customSuccess("All editions were synced for global module " + module.name + '.');
                } else if (response.status === 206){
                    alertService.customSuccess("Available editions for global module " + module.name + ' were synced.');
                } else {
                    alertService.customError('Unable to sync editions for global module ' + module.name + '.');
                }
            }).catch(function(){
                alertService.customError('Unable to sync editions for global module ' + module.name + '.');
            }).finally(function(){
                globalModules.syncInProgress = false;
                globalModules.getList(globalModules.sort, globalModules.currentPage, globalModules.searchTerm);
            })



        }
    }
})(window.angular);
