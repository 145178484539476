(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('editExistingTypeAssetSet', editExistingTypeAssetSet);

    editExistingTypeAssetSet.$inject = ['$modal', '$alert'];

    function editExistingTypeAssetSet($modal, $alert) {

        return {
            restrict: 'A',
            link: link,
            scope: {
                toBeEdited: '='
            }
        };

        function link(scope, element) {

            element.on('click', function () {
                $modal({
                    templateUrl: 'app/reference/typeassetset/edit/edit.typeassetset.modal.html',
                    scope: scope,
                    controller: 'EditTypeAssetSetController',
                    controllerAs: 'ebc',
                    resolve: {
                        savedTypeAssetSetResource: function () {
                            return scope.toBeEdited
                        },
                        saveCallback: function () {
                            return function (data) {
                                updatedExistingTypeAssetSet(data, scope.toBeEdited)
                            }
                        },
                        errorCallback: function () {
                            return errorUpdating;
                        }
                    }
                })
            });

        }

        function errorUpdating() {
            $alert({
                type: 'danger',
                content: 'Error: ' + err.data.errorMessage + '. Please try again.'
            });
        }

        function updatedExistingTypeAssetSet(newValue, existingValue) {
            $alert({
                content: [
                    'TypeAssetSet edited successfully.'
                ].join('')
            });
            angular.extend(existingValue, newValue);
        }
    }
})(window.angular);
