/**
 * Created by hzhang on 3/9/2017.
 */
(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('ProductLineService', ProductLineService);

    ProductLineService.$inject = ['$http', 'Principal', 'UriTemplate', 'PaginationService', '$q'];

    function ProductLineService($http, Principal, UriTemplate, PaginationService, $q) {

        return {
            search: search,
            list: list,
            nextPage: nextPage
        };

        function search(active) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.productlines).stringify(), {
                    params: {active: active, sort: 'id', size: 10}
                }).then(function (response) {
                    var parsedResponse = PaginationService.handleResponse(response.data);
                    if (!parsedResponse.content) {
                        parsedResponse.content = [];
                    }
                    return parsedResponse;
                });
            })
        }

        function list() {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.productlines).stringify(), {
                    params: {size: 10}
                }).then(function (response) {
                    return response.data;
                });
            })
        }

        function nextPage(linkToFetch) {
            if (linkToFetch) {
                return $http.get(linkToFetch).then(function (response) {
                    return PaginationService.handleResponse(response.data);
                });
            }
            return $q.reject('no link to fetch');
        }

    }
})(window.angular);
