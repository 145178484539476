(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('deletePlaceholder', deletePlaceholder);

    deletePlaceholder.$inject = ['$alert', 'DeletePlaceholderService'];

    function deletePlaceholder($alert, DeletePlaceholderService) {

        return {
            restrict: 'A',
            link: link,
            scope: {
                toBeDeleted: '='
            }
        };

        function link(scope, element) {
            element.on('click', function () {
                remove(scope.toBeDeleted);
            });
        }

        function remove(placeholder) {
            DeletePlaceholderService.remove(placeholder).then(ok, err);

            function ok() {
                placeholder.deleted = true;
                $alert({
                    content: [
                        'Placeholder \'', placeholder.entity.name, '\' removed successfully.'
                    ].join('')
                });
            }

            function err() {
                $alert({
                    type: 'danger',
                    content: [
                        'Placeholder \'', placeholder.entity.name, '\' not removed.'
                    ].join('')
                });
            }
        }
    }
})(window.angular);
