(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditNarratorController', EditNarratorController);

    EditNarratorController.$inject = ['EditNarratorService', 'GenderService', 'savedNarratorResource', 'saveCallback', 'errorCallback'];

    function EditNarratorController(EditNarratorService, GenderService, savedNarratorResource, saveCallback, errorCallback) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedNarratorResource);
        epc.data = {};

        (function onControllerLoad() {
            GenderService.list(epc.data);
        })();

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditNarratorService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
