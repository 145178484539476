(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewCountryController', NewCountryController);

    NewCountryController.$inject = ['NewCountryService', 'ProgramInitService', '$scope'];

    function NewCountryController(NewCountryService, programInitService, $scope) {
        var nbc = this;

        nbc.save = save;
        nbc.newCountry = NewCountryService.newCountry();
        nbc.data = programInitService.getCountries();

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewCountryService.create(nbc.newCountry).then(function (response) {
                    $scope.$emit('new-country', response);
                    nbc.data.countries.content.push(response);
                })
            }
        }
    }

})(window.angular);
