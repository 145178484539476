(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('UrlTypeController', UrlTypeController);

    UrlTypeController.$inject = ['UrlTypeService'];

    function UrlTypeController(UrlTypeService) {
        var UrlTypeCtrl = this;

        (function onControllerLoad() {
            UrlTypeCtrl.nextPage = nextPage;
            UrlTypeCtrl.hasVisible = anyVisibleAssetSet;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                UrlTypeCtrl.loadingNextPage = true;
                UrlTypeService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    UrlTypeCtrl.loadingNextPage = false;
                } else {
                    UrlTypeCtrl.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                UrlTypeCtrl.loadingNextPage = true;
            }
        }

        function anyVisibleAssetSet() {
            var hasVisible = false,
                length = UrlTypeCtrl.urlType && UrlTypeCtrl.urlType.content &&
                    UrlTypeCtrl.urlType.content.length;
            for (var i = 0; i < length; i++) {
                if (!UrlTypeCtrl.urlType.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            UrlTypeCtrl.loading = true;
            UrlTypeService.search()
                .then(function (response) {
                    UrlTypeCtrl.urlType = response;
                })
                .finally(function () {
                    delete UrlTypeCtrl.loadingNextPage;
                    delete UrlTypeCtrl.loading;
                });
        }

    }
})(window.angular);
