(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .service('GlobalModulesService', GlobalModulesService);

    GlobalModulesService.$inject = [
      '$q',
      '$http',
      'Principal',
      'UriTemplate',
      'UtilService',
      '$timeout'
    ];

    function GlobalModulesService($q, $http, principal, uri, utils, $timeout) {
        this.addSubscription = addSubscription;
        this.fetchAll = fetchAll;
        this.fetchPage = fetchPage;
        this.fetchByName = fetchByName;
        this.publishModule = publishModule;
        this.validateModuleName = validateModuleName;
        this.getModuleConfig = getModuleConfig;
        this.parseModuleQuestions = parseModuleQuestions;
        this.setUpdatedModule = setUpdatedModule;
        this.getUpdatedGlobalModules = getUpdatedGlobalModules;
        this.setUpdatedGlobalModules = setUpdatedGlobalModules;
        this.pushUpdatedGlobalModules = pushUpdatedGlobalModules;
        this.getLatestVersion = getLatestVersion;
        this.isSubscribed = isSubscribed;
        this.resetUpdatedGlobalModules = resetUpdatedGlobalModules;
        this.reviseGlobalModule = reviseGlobalModule;
        this.getEditionEmmiCode = getEditionEmmiCode;
        this.deleteGlobalModule = deleteGlobalModule;
        this.syncAllEditions = syncAllEditions;
        var updatedGlobalModules = [];

        function publishModule(module, questions, moduleName, patientName) {
            var preparedModule = Object.assign({}, module);
            preparedModule.defaultNextModuleRoute = "";

            var globalModule = {
                globalModuleMetaData: JSON.stringify({
                    formQuestions: questions,
                    module: preparedModule
                }),
                name: moduleName,
                patientFacingName: patientName,
                version: 1
            };

            module.isGlobalModule = true;
            module.globalModuleName = moduleName;

            return createModule(globalModule).then(function (response) {
                return Promise.resolve(response);
            }).catch(function (errMsg) {
                return Promise.reject(errMsg);
            }).finally(function () {
                module.globalVersion = 0;
            })

        }

        function syncSubscription(globalModule, modulesSubscribed, formEditionId) {
            var subs = globalModule.globalModulesSubscriptions || (globalModule.globalModulesSubscriptions = []),
                subExists = false,
                shouldSubscribe;

            subExists = subs.find(function (sub) {
                return formEditionId === sub.edition.editionId;
            });

            if (subExists) {
                return $q.resolve();
            }

            subs.push({edition: {editionId: formEditionId}, version: globalModule.version});

            shouldSubscribe =
                reviseGlobalModule(globalModule)
                    .then(function (response) {
                        modulesSubscribed.push(globalModule);
                        return response;
                    });

            return shouldSubscribe;
        }

        function addSubscription(formEdition) {
            var eId = formEdition.entity.editionId,
                formModules = formEdition.entity.meta.formEditionMetaData.formModules,
                modulesSubscribed = [],
                unsyncedModules = formModules.filter(function (formModule) {
                    return formModule.isGlobalModule;
                });

            var setSubscriptions =
                unsyncedModules
                    .map(function (formModule) {
                        return fetchByName(formModule.globalModuleName).then(function (globalModuleList) {
                            var globalModule = globalModuleList[0];
                            return syncSubscription(globalModule, modulesSubscribed, eId)
                                .catch(function (errMsg) {
                                    formModule.globalVersion = 0;
                                    return $q.reject(errMsg);
                                });
                        })
                    });

            return $q.all(setSubscriptions);
        }

        function isSubscribed(eId, formModule) {
            return fetchByName(formModule.globalModuleName).then(function (globalModuleList) {
                var globalModule = globalModuleList[0];
                for (var i = 0; i < globalModule.globalModulesSubscriptions.length; i++) {
                    if (globalModule.globalModulesSubscriptions[i].edition.editionId === eId) {
                        var isLinked = globalModule.globalModulesSubscriptions[i].version;
                        return isLinked;
                    }
                }

            });


        }

        function getLatestVersion(eId, formModule) {
            return fetchByName(formModule.globalModuleName).then(function (globalModuleList) {
                return globalModuleList[0].version;
            });
        }

        function createModule(formModule) {
            return principal.identity().then(function (user) {
                var endpoint = uri.create(user.link.globalModules).stringify();
                return $http.post(endpoint, formModule);
            })
                .catch(function (response) {
                    var status = response.status;
                    var msg = 409 === status ?
                        "A module with this module's name already exists" :
                        utils.httpFailureMessage(status);

                    return Promise.reject(msg);
                })
        }

        function reviseGlobalModule(formModule) {
            var body = Object.assign({}, formModule);

            body.globalModuleMetaData = formModule.globalModuleMetaDataParsed ? JSON.stringify(formModule.globalModuleMetaDataParsed) : formModule.globalModuleMetaData;

            return principal.identity().then(function (user) {
                var endpoint = uri.create(user.link.globalModules + '/' + formModule.id).stringify();
                return $http.put(endpoint, body);
            }).then(function (response) {
                return response;
            })
                .catch(function (response) {
                    var status = response.status;
                    var msg = utils.httpFailureMessage(status);

                    return Promise.reject(msg);
                })
        }

        function validateModuleName(candidate) {
            var isValid =
                fetchByName(candidate)
                    .then(function (results) {
                        return !Array.isArray(results);
                    });

            return isValid;
        }

        function getModuleConfig(moduleName) {
            var retrievedModule =
                fetchPage(0, 'name,desc', 1, moduleName)
                    .then(function (data) {
                        var retData = data.content[0];
                        retData.globalModuleMetaDataParsed = JSON.parse(retData.globalModuleMetaData);

                        return retData;
                    });
            return retrievedModule;
        }

        function fetchAll() {
            var globalModuleList =
                fetchPage(0, 'name,desc', 10000)
                    .then(function (data) {
                        return data.content;
                    });

            return globalModuleList
        }

        function fetchByName(moduleName) {
            var globalModule =
                fetchPage(0, 'name,desc', 1, moduleName)
                    .then(function (data) {
                        return data.content;
                    });

            return globalModule;
        }

        function fetchPage(page, sort, size, partialName) {
            sort = sort || "name,asc";
            size = size || 40;
            partialName = partialName || "";

            return principal.identity().then(function (user) {
                return $http.get(uri.create(user.link.globalModules).stringify(), {
                    params: {
                        name: partialName,
                        page: page,
                        sort: sort,
                        size: size,
                    }
                })
                    .then(function (response) {
                        return response.data;
                    }).catch(function (response) {
                        var msg = utils.httpFailureMessage(response.status);
                        return Promise.reject(msg);
                    });
            })
        }

        function parseModuleQuestions(editionQuestionSet, candidate) {

            var moduleQuestionSet = [];

            var intersectQuestions = function (moduleQuestions, formElement) {
                var questionMatch;

                if (!formElement.questionId) {
                    return moduleQuestions;
                }

                questionMatch = editionQuestionSet.find(function (question) {
                    return question.questionId === formElement.questionId;
                });

                if (!questionMatch) {
                    return moduleQuestions;
                }
                moduleQuestions.push(questionMatch);
                return moduleQuestions;
            };

            candidate.formElements.reduce(intersectQuestions, moduleQuestionSet);

            return moduleQuestionSet;
        }

        function setUpdatedModule(module, editionQuestionSet, formEditionId) {
            if (module.isGlobalModule) {
                var updatedModule = {};
                var newVersion = 0;

                var moduleQuestionSet = parseModuleQuestions(editionQuestionSet, module);
                    fetchByName(module.globalModuleName).then(function (response) {
                        newVersion = module.globalVersion >= response[0].version ? module.globalVersion : response[0].version + 1;
                        module.globalVersion = newVersion;
                        updatedModule = Object.assign({}, response[0]);
                        updatedModule.globalModuleMetaData = JSON.stringify({
                            formQuestions: moduleQuestionSet,
                            module: module
                        });
                        updatedModule.patientFacingName = module.moduleRouteName;
                        updatedModule.version = newVersion;
                        var subs = updatedModule.globalModulesSubscriptions;
                        var subsEnd = subs.length;

                        for (var i = 0, sub; i < subsEnd; i++) {
                            sub = subs[i];
                            if (sub.edition.editionId === formEditionId) {
                                sub.version = updatedModule.version;
                                break;
                            }
                        }
                        updatedGlobalModules.push(updatedModule);

                    })
            }
            return module;
        }

        function resetUpdatedGlobalModules() {
            updatedGlobalModules = [];
        }

        function pushUpdatedGlobalModules() {
            var allReturned = 0;
            for (var i = 0; i < updatedGlobalModules.length; i++) {
                reviseGlobalModule(updatedGlobalModules[i]).then(function () {
                    allReturned++;
                    if (allReturned === updatedGlobalModules.length) {
                        resetUpdatedGlobalModules();
                    }

                });

            }
        }

        function getUpdatedGlobalModules() {
            return updatedGlobalModules;
        }

        function setUpdatedGlobalModules(updatedModules) {
            updatedGlobalModules = updatedModules;
        }

        function deleteGlobalModule(id){
            return principal.identity().then(function(user) {
                var endpoint = uri.create(user.link.globalModules + '/' + id).stringify();
                return $http.delete(endpoint);
            })
        }

        function getEditionEmmiCode(edition){
            return principal.identity().then(function(user) {
                return $http.get(uri.create(user.link.editions + 'emmiCode/' + edition).stringify(), {
                    })
                    .then(function (response) {
                        return response;
                    }).catch(function (response) {
                        var msg  = utils.httpFailureMessage(response.status);
                        return Promise.reject(msg);
                    }
                )
            });
        }

        function syncAllEditions(module){

            return principal.identity().then(function(user) {
                return $http.put(uri.create(user.link.globalModules + '/' + module.id + '/sync').stringify(), {
                })
                    .then(function (response) {
                        return response;
                    }).catch(function (response) {
                            var msg  = utils.httpFailureMessage(response.status);
                            return Promise.reject(msg);
                        }
                    )
            });

        }
    }

})(window.angular);
