(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .config(angularStrapConfig);

    angularStrapConfig.$inject = ['$alertProvider'];

    function angularStrapConfig($alertProvider) {
        angular.extend($alertProvider.defaults, {
            animation: 'am-fade-and-slide-top',
            duration: 2,
            placement: 'top',
            type: 'info',
            container: 'body'
        });
    }

})(window.angular);
