(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewConfigController', NewConfigController);

    NewConfigController.$inject = ['NewConfigService', 'ProgramInitService', '$scope'];

    function NewConfigController(NewConfigService, programInitService, $scope) {
        var ncc = this;

        ncc.save = save;
        ncc.newConfig = NewConfigService.newConfig();
        ncc.data = programInitService.getConfigs();

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewConfigService.create(ncc.newConfig).then(function (response) {
                    $scope.$emit('new-config', response);
                    ncc.data.configs.content.push(response);
                })
            }
        }
    }

})(window.angular);
