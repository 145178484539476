(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewContentSubscriptionService', NewContentSubscriptionService);

    NewContentSubscriptionService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewContentSubscriptionService($http, Principal, UriTemplate) {

        return {
            create: create,
            newContentSubscription: newContentSubscription
        };

        function create(newContentSubscription) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.contentsubscriptions).stringify(),
                    newContentSubscription).then(function (response) {
                    return response.data;
                });
            })
        }

        function newContentSubscription() {
            return {
                description: '',
                active: false,
                created: new Date(),
                lastUpdated: new Date()
            }
        }

    }
})(window.angular);
