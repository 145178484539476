(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('CountryController', CountryController);

    CountryController.$inject = ['CountryService'];

    function CountryController(CountryService) {
        var Country = this;

        (function onControllerLoad() {
            Country.nextPage = nextPage;
            Country.hasVisible = anyVisibleCountry;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                Country.loadingNextPage = true;
                CountryService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    Country.loadingNextPage = false;
                } else {
                    Country.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                Country.loadingNextPage = true;
            }
        }

        function anyVisibleCountry() {
            var hasVisible = false,
                length = Country.countries && Country.countries.content &&
                    Country.countries.content.length;
            for (var i = 0; i < length; i++) {
                if (!Country.countries.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            Country.loading = true;
            CountryService.search()
                .then(function (response) {
                    Country.countries = response;
                    //Country.countries = JSON.stringify(response);
                })
                .finally(function () {
                    delete Country.loadingNextPage;
                    delete Country.loading;
                });
        }

    }
})(window.angular);
