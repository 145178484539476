(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('ivrCallFlowModal', ivrCallFlowModal);

    ivrCallFlowModal.$inject = ['$modal'];

    function ivrCallFlowModal($modal) {

        return {
            restrict: 'A',
            link: link,
            scope: {
                 params: '='
            }
        };

        function link(scope, element) {
            var modal = {};

            element.on('click', function () {
                modal = $modal({
                    templateUrl: 'app/program/create/ivr/ivr-call-flow-modal/ivr-call-flow-modal.html',
                    scope: scope
                })
            });

            element.on('$destroy', function(){
                if(modal.hide !== undefined) {
                    modal.hide();
                }
            });
        }



    }
})(window.angular);
