(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('DelDynaviewService', DelDynaviewService);

    DelDynaviewService.$inject = ['$http', '$q', 'LinkConverter'];

    function DelDynaviewService($http, $q, LinkConverter) {

        return {
            remove: remove
        };

        function remove(theLinkUri, resource) {
            return $http.delete(theLinkUri, dataResource).then(function (response) {
				return response.data;
			});
        }

    }
})(window.angular);
