(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('errorHandlerInterceptor', errorHandlerInterceptor);

    errorHandlerInterceptor.$inject = ['$q', '$rootScope'];

    function errorHandlerInterceptor($q, $rootScope) {
        return {
            responseError: responseError
        };

        function responseError(response) {
            $rootScope.$emit('Composer.httpError', response);
            return $q.reject(response);
        }
    }
})(window.angular);
