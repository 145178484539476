(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('FormModuleController', FormModuleController);

    FormModuleController.$inject = ['UtilService', 'AlertService', 'FormEditionService', 'saveCallback', 'fec', 'ModulesService', '$state'];

    function FormModuleController(utilService, alertService, formEditionService, saveCallback, fec, modulesService, $state) {
        var fm = this;


        // Retrieve Module/Element/Question states
        fm.activeModule = modulesService.getActiveModule();
        fm.activeModulePreEditState = modulesService.getActiveModulePreEditState();
        fm.moduleConfiguration = modulesService.getModuleConfiguration();
        fm.moduleList = modulesService.getModuleList();
        fm.questionList = modulesService.getQuestionList();
        fm.type = 'module';
        fm.addCondition = addCondition;
        fm.removeConditon = removeCondition;
        fm.validateForm = formEditionService.validateForm;
        fm.save = save;
        fm.saveCallback = saveCallback;
        fm.closeModal = closeModal;
        fm.isFormValid = false;
        fm.formSubmitted = false;
        fm.isDupe = false;
        fm.validateName = validateName;
        fm.flagAsModified = flagAsModified;
        fm.addConditionRow = addConditionRow;
        fm.addAndOrCondition = addAndOrCondition;
        fm.rebuildRouting = rebuildRouting;
        fm.removeAndOrCondition = removeAndOrCondition;
        fm.sourcePage = $state.params.type;


        //conditions
        function addCondition() {
            fm.activeModule = modulesService.addCondition(fm.activeModule);
        }
        // remove entire condition
        function removeCondition() {
            fm.activeModule = modulesService.removeCondition(fm.activeModule);
        }

            function addConditionRow() {
            fm.activeModule.routing =  modulesService.addConditionRow(fm.activeModule.routing);
        }
        function addAndOrCondition(routeIndex, condition, btn) {
            var i = fm.activeModule.routing.indexOf(routeIndex);
            fm.activeModule.routing[i] = modulesService.addAndOrCondition(routeIndex, condition, btn);
        }
        function validateForm() {
            formEditionService.validateForm();
        }
        function rebuildRouting() {
            fm.activeModule.routing = modulesService.rebuildRouting(fm.activeModule.routing);
        }
        function removeAndOrCondition(routeIndex, condition) {
            var i = fm.activeModule.routing.indexOf(routeIndex);
            fm.activeModule.routing[i] = modulesService.removeAndOrCondition(routeIndex, condition);
            if (!fm.activeModule.routing[i].condition.length) fm.activeModule.routing.splice(i,1);
        }

        function validateName() {
           fm.isDupe = formEditionService.validateName(fm.activeModule.moduleRouteName,fm.moduleList,'module', fm.moduleConfiguration.addFlag);
        }
        //Save the modal for module and element
        function save($hide, form) {
            fm.formSubmitted = true;
            fm.isFormValid = fm.validateForm(fm.activeModule, fm.moduleConfiguration, fm.type, form, fm.moduleList);
            if (fm.isFormValid){
                modulesService.saveModule(fm.activeModule);
                fm.saveCallback();
                fm.closeModal($hide);
            }

        }
        //close modal while clicking cancel
        function closeModal($hide) {
            $hide();
            // fm.routingConfig();
            fm.moduleConfiguration[fm.type] = {
                moduleRouteName: '',
                moduleTitle: '',
                routing: [],
                elementRouteName: '',
                modified: false,
                questionId: '',
                displayType: '',
                contentText: '',
                type: '',
                defaultNextModuleRoute: ''

            }
        }
        function flagAsModified(){
            fm.activeModule.modified = true;
        }
    }
})(window.angular);
