(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('ProgramVersionService', ProgramVersionService);

    ProgramVersionService.$inject = ['$http', 'Principal', 'UriTemplate', 'ProgramService', 'ProgramCampaignService', 'UtilService'];

    function ProgramVersionService($http, Principal, UriTemplate, programService, programCampaignService, utilService) {

        var programHistory = {};

        return {
            getProgram: programService.getProgram,
            isCheckedOutByUser: programService.isCheckedOutByUser,

            getCheckedOutByUser: programService.getCheckedOutByUser,

            getProgramHistory: getProgramHistory,

            createWorkingVersion: createWorkingVersion,
            createMajorVersion: createMajorVersion,
            createMinorVersion: createMinorVersion,
            nextMajorVersion: nextMajorVersion,
            nextMinorVersion: nextMinorVersion,
            nextWorkingVersion: nextWorkingVersion,
            promote: promote,

            checkout: checkout,
            checkin: checkin,

            loadVersionedPrograms: loadVersionedPrograms,
            loadVersionedProgramHistory: loadVersionedProgramHistory,
            loadVersionedEditions: loadVersionedEditions,

            loadProgramByEmmiCodeAndVersion: loadProgramByEmmiCodeAndVersion,
            loadProgramByEmmiCode: loadProgramByEmmiCode,
            loadFormByEmmiCode: loadFormByEmmiCode
        };

        function isValidProgram(program) {
            return program && program.entity;
        }

        function isFormEdition(program) {
            return program.entity.type === 'FORM EDITION';
        }

        function getUrlBase(program, user) {
            return isFormEdition(program) ? user.link.editions : user.link.content;
        }

        function loadProgramByEmmiCodeAndVersion(emmiCode, formattedVersion) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/{version}/{emmiCode}').stringify({
                    emmiCode: emmiCode,
                    version: hyphenateVersion(formattedVersion)
                })).then(function (response) {
                    return programService.setProgram(response.data);
                });
            })
        }

        function loadProgramByEmmiCode(emmiCode){
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/{emmiCode}').stringify({
                    emmiCode: emmiCode
                })).then(function (response) {
                    return response.data.entity;
                });
            })
        }

        function loadFormByEmmiCode(emmiCode){
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/form/{emmiCode}').stringify({
                    emmiCode: emmiCode
                })).then(function (response) {
                    return response.data.entity;
                });
            })
        }

        function loadVersionedPrograms(config) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content).stringify(), config)
                    .then(function (response) {
                        return utilService.paginateResponse(response);
                    });
            })
        }

        function loadVersionedEditions(config) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.editions + "/form").stringify(), config)
                    .then(function (response) {
                        return utilService.paginateResponse(response);
                    });
            })
        }

        function setLatestVersion(response) {
            var content = response.data.content;
            if (content && content.length > 0) {
                var entity = content[0].entity;
                entity.isLatestVersion = true;
                programService.setProgram(content[0]);
            }
        }

        function getProgramHistory() {
            return programHistory;
        }

        function setProgramHistory(response) {
            if (response) {
                programHistory = utilService.paginateResponse(response);
            } else {
                programHistory = {};
            }
        }

        function loadVersionedProgramHistory(emmiCode) {
            setProgramHistory();
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/history/{emmiCode}').stringify({
                    emmiCode: emmiCode
                }), {
                    params: {
                        size: 10
                    }
                }).then(function (response) {
                    setLatestVersion(response);
                    setProgramHistory(response);
                    programCampaignService.loadCampaign(emmiCode);
                    return getProgramHistory();
                });
            })
        }



        function checkin(program) {
            if (!isValidProgram(program)) {
                return;
            }
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(getUrlBase(program, user) + '/ci/{emmiCode}').stringify({
                    emmiCode: program.entity.emmiCode,
                    version: getJsonVersion(program.entity.version)
                })).then(function (response) {
                    programService.setProgram(response.data);
                    return utilService.defaultIfEmptyContent(response);
                });
            })
        }

        function checkout(program) {
            if (!isValidProgram(program)) {
                return;
            }
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(getUrlBase(program, user) + '/co/{emmiCode}').stringify({
                    emmiCode: program.entity.emmiCode,
                    version: getJsonVersion(program.entity.version)
                })).then(function (response) {
                    var program = response.data;
                    program.entity.isLatestVersion = true;
                    programService.setProgram(program);
                    return utilService.defaultIfEmptyContent(response);
                });
            })
        }

        function defaultIfNull(str) {
            return str || -1;
        }

        function createWorkingVersion(program) {
            if (!isValidProgram(program)) {
                return;
            }
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(getUrlBase(program, user) + '/createworkingversion/').stringify(),
                    program.entity).then(function (response) {
                    programService.setProgram(response.data);
                    return utilService.defaultIfEmptyContent(response);
                });
            })
        }

        function createMajorVersion(program) {
            if (!isValidProgram(program)) {
                return;
            }
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(getUrlBase(program, user) + '/createmajorversion/').stringify(),
                    program.entity).then(function (response) {
                    programService.setProgram(response.data);
                    return utilService.defaultIfEmptyContent(response);
                });
            })
        }

        function createMinorVersion(program) {
            if (!isValidProgram(program)) {
                return;
            }
            return Principal.identity().then(function (user) {
                console.log('create minor: url = ' + user.link.content);
                return $http.post(UriTemplate.create(getUrlBase(program, user) + '/createminorversion/').stringify(),
                    program.entity).then(function (response) {
                    programService.setProgram(response.data);
                    return utilService.defaultIfEmptyContent(response);
                });
            })
        }

        function nextMajorVersion(program) {
            if(isFormEdition(program)) {
                return nextMajorEditionVersion(program);
            } else {
                return Principal.identity().then(function (user) {
                    return $http.get(UriTemplate.create(user.link.content + '/nextmajorversion/{emmiCode}').stringify({
                        emmiCode: defaultIfNull(program.entity.emmiCode)
                    })).then(function (response) {
                        return response.data;
                    });
                })
            }
        }

        function nextMinorVersion(program) {
            if(isFormEdition(program)) {
                return nextMinorEditionVersion(program);
            } else {
                return Principal.identity().then(function (user) {
                    return $http.get(UriTemplate.create(user.link.content + '/nextminorversion/{emmiCode}').stringify({
                        emmiCode: defaultIfNull(program.entity.emmiCode)
                    })).then(function (response) {
                        return response.data;
                    });
                })
            }
        }

        function nextWorkingVersion(program) {
            if(isFormEdition(program)) {
                return nextWorkingEditionVersion(program);
            } else {
                return Principal.identity().then(function (user) {
                    return $http.get(UriTemplate.create(user.link.content + '/nextworkingversion/{emmiCode}').stringify({
                        emmiCode: defaultIfNull(program.entity.emmiCode)
                    })).then(function (response) {
                        return response.data;
                    });
                })
            }
        }

        function nextMajorEditionVersion(program) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.editions + '/nextmajorversion/{editionId}').stringify({
                    editionId: defaultIfNull(program.entity.editionId)
                })).then(function (response) {
                    return response.data;
                });
            })
        }

        function nextMinorEditionVersion(program) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.editions + '/nextminorversion/{editionId}').stringify({
                    editionId: defaultIfNull(program.entity.editionId)
                })).then(function (response) {
                    return response.data;
                });
            })
        }

        function nextWorkingEditionVersion(program) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.editions + '/nextworkingversion/{editionId}').stringify({
                    editionId: defaultIfNull(program.entity.editionId)
                })).then(function (response) {
                    return response.data;
                });
            })
        }

        function promote(program) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(getUrlBase(program, user) + '/promoteprogram/{version}/{emmiCode}').stringify({
                    emmiCode: program.entity.emmiCode,
                    version: getJsonVersion(program.entity.version)
                })).then(function (response) {
                    return response.data;
                });
            })
        }

        function getJsonVersion(version) {
            return version.major + "." + version.minor + "." + version.working;
        }

        function hyphenateVersion(version) {
            return version.replace(/v/, "").replace(/\./g, "-");
        }
    }
})(window.angular);
