(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('UnversionedProgramController', UnversionedProgramController);

    UnversionedProgramController.$inject = ['ProgramService', 'Principal', '$state', 'UtilService'];

    function UnversionedProgramController(programService, principal, $state, utilService) {
        var upc = this;
        upc.authenticated = principal.isAuthenticated();
        upc.collapse = false;

        (function onLoad() {
            upc.hasVisible = hasVisibleData;
            upc.nextPage = nextPage;
            upc.getProgramDetails = getProgramDetails;
            loadLocalPrograms();
        })();

        function hasVisibleData() {
            var hasVisible = false,
                length = upc.programs && upc.programs.content &&
                    upc.programs.content.length;
            for (var i = 0; i < length; i++) {
                if (!upc.programs.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function nextPage(link) {
            if (upc.programs && link === upc.programs.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                upc.loadingNextPage = true;
                utilService.fetchPage(link).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    upc.loadingNextPage = false;
                    upc.programs = response;
                } else {
                    upc.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                upc.loadingNextPage = true;
            }
        }

        function getProgramDetails(program) {
            upc.loadingDetails = true;
            var emmiCode = program.entity.emmiCode;
            programService.setEditable(true);
            var state;
            if (program.entity.type === 'IVR CALL'){
                state = 'edit-ivr-details';
            }
            else if (program.entity.type === 'MULTIMEDIA PROGRAM'){
                state = 'edit-program-details';
            } else if (program.entity.type === 'TRANSITION PROGRAM'){
                state = 'edit-transition-details';
            } else if (program.entity.type === 'FORM EDITION'){
                state = 'edit-form-edition-details';
            } else if (program.entity.type === 'FORM'){
                state = 'edit-form-details';
            } else if (program.entity.type === 'CAREPLAN'){
                state = 'edit-digital-journey-details';
            } else if (program.entity.type === 'VIDEO') {
                state = 'edit-video-program-details';
            } else if (program.entity.type === 'WEB PROGRAM') {
                state = 'edit-web-program-details';
            }
            $state.go(state, {emmiCode: emmiCode, version: 'v0.0.0'});
        }

        function loadLocalPrograms() {
            upc.loading = true;
            programService.loadLocalPrograms()
                .then(function (response) {
                    upc.programs = response;
                })
                .finally(function () {
                    delete upc.loading;
                });
        }
    }
})(window.angular);
