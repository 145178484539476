(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewAssetSetCountryController', NewAssetSetCountryController);

    NewAssetSetCountryController.$inject = ['NewAssetSetCountryService', 'ProgramInitService', '$scope'];

    function NewAssetSetCountryController(NewAssetSetCountryService, ProgramInitService, $scope) {
        var nbc = this;

        nbc.save = save;
        nbc.newAssetSetCountry = NewAssetSetCountryService.newAssetSetCountry();
        nbc.data = ProgramInitService.getAssetSetCountries(true, true);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewAssetSetCountryService.create(nbc.newAssetSetCountry).then(function (response) {
                    $scope.$emit('new-assetsetcountry', response);
                    nbc.data.assetsetcountries.content.push(response);
                })
            }
        }
    }

})(window.angular);
