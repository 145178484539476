/**
 * Created by hzhang on 3/9/2017.
 */
(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('ProductLineController', ProductLineController);

    ProductLineController.$inject = ['ProductLineService'];

    function ProductLineController(ProductLineService) {
        var ProductLineCtrl = this;

        (function onControllerLoad() {
            ProductLineCtrl.nextPage = nextPage;
            ProductLineCtrl.hasVisible = anyVisible;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                ProductLineCtrl.loadingNextPage = true;
                ProductLineService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    ProductLineCtrl.loadingNextPage = false;
                } else {
                    ProductLineCtrl.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                ProductLineCtrl.loadingNextPage = true;
            }
        }

        function anyVisible() {
            var hasVisible = false,
                length = ProductLineCtrl.productLine && ProductLineCtrl.productLine.content &&
                    ProductLineCtrl.productLine.content.length;
            for (var i = 0; i < length; i++) {
                if (!ProductLineCtrl.productLine.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            ProductLineCtrl.loading = true;
            ProductLineService.search()
                .then(function (response) {
                    ProductLineCtrl.productLine = response;
                })
                .finally(function () {
                    delete ProductLineCtrl.loadingNextPage;
                    delete ProductLineCtrl.loading;
                });
        }

    }
})(window.angular);
