(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('ProgramPublishVersionController', ProgramPublishVersionController);

    ProgramPublishVersionController.$inject = ['ProgramPublisherService', 'EditionsService', 'UtilService', 'AlertService',
        'emmiCode', 'systemDisplayName', 'systemName', 'callbackFn', 'programType'];

    function ProgramPublishVersionController(programPublisherService, editionsService, utilService, alertService,
                                             emmiCode, systemDisplayName, systemName, callbackFn, programType) {
        var pvc = this;
        pvc.emmiCode = emmiCode;
        pvc.programType = programType;
        pvc.systemDisplayName = systemDisplayName;
        pvc.systemName = systemName;
        pvc.selectedProgram = null;
        pvc.confirm = {};
        pvc.publish = publish;
        pvc.hasData = hasVisibleData;
        pvc.nextPage = nextPage;
        pvc.getAssetSets = editionsService.getAssetSets;
        pvc.callbackFn = callbackFn;
        pvc.loadPublishablePrograms = loadPublishablePrograms;
        pvc.onPublishCheck = onPublishCheck;

        var config = {
            params: {
                sort: 'created',
                size: 10
            }
        };

        function loadPublishablePrograms(sortBy) {
            config.params.sort = sortBy || 'created';
            pvc.loading = true;
            programPublisherService.getPublishablePrograms(pvc.emmiCode, config)
                .then(function (response) {
                    pvc.publishablePrograms = response;
                })
                .finally(function () {
                    delete pvc.loading;
                })
        }

        function nextPage(link) {
            if (link) {
                pvc.loadingNextPage = true;
                utilService.fetchPage(link).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    pvc.loadingNextPage = false;
                    pvc.publishablePrograms = response;
                } else {
                    pvc.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                pvc.loadingNextPage = true;
            }
        }

        function hasVisibleData() {
            var hasVisible = false,
                length = pvc.publishablePrograms && pvc.publishablePrograms.content &&
                    pvc.publishablePrograms.content.length;
            for (var i = 0; i < length; i++) {
                if (!pvc.publishablePrograms.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function publish($hide) {
            if (pvc.selectedProgram) {
                var publishRequest = {};
                publishRequest.emmiCode = pvc.emmiCode;
                publishRequest.systemName = pvc.systemName;
                var version = pvc.selectedProgram.version;
                publishRequest.version = version.major + '.' + version.minor + '.' + version.working;
                publishRequest.note = pvc.confirm.inputText;
                pvc.publishing = true;
                programPublisherService.publishProgram(publishRequest, config)
                    .then(function (response) {
                        pvc.callbackFn();
                        alertService.success(pvc.selectedProgram.name, 'publish');
                    })
                    .catch(function (response) {
                        var resdata = response.data;
                        alertService.error(resdata.errorMessage, 'publish');                        
                        
                    })
                    .finally(function() {
                        delete pvc.publishing;
                        $hide();
                    })
            }
        }

        function onPublishCheck() {
            if (pvc.selectedProgram) {
                var version = pvc.selectedProgram.version;
                var verString = version.major + '.' + version.minor + '.' + version.working;
                return programPublisherService.onPublishCheck(pvc.emmiCode, verString, pvc.systemName, config)
                    .then(function (response) {
                        var warningMessages = [];
                        var resmsg = response.data;
                        if(resmsg.length > 0) {
                            warningMessages.push(resmsg);
                        }
                        return { resultMessage: '', warningMessages: warningMessages };
                    })
            }
        }
    }
})(window.angular);
