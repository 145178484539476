(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditGlobalModuleController', EditGlobalModuleController);

    EditGlobalModuleController.$inject = ['$state', 'UtilService', 'GlobalModulesService', 'ModulesService', 'FormEditionService', 'AlertService'];

    function EditGlobalModuleController($state, UtilService, globalModulesService, modulesService, formEditionService, alertService) {
        var egm = this;
        egm.moduleName = $state.params.gmName;

        egm.loading = false;
        egm.saveModule = saveModule;
        egm.addElement = addElement;
        egm.removeElement = removeElement;
        egm.setExistingElement = setExistingElement;
        egm.saveElement = saveElement;
        egm.resetBrokenComponentValidation = resetBrokenComponentValidation;
        egm.setFormModified = setFormModified;
        egm.setExistingModule = setExistingModule;
        egm.saveGlobalModule = saveGlobalModule;
        egm.startModule = startModule;
        egm.goToList = goToList;
        egm.modified = false;
        egm.newModule = $state.params.type === 'new';
        egm.newGlobalModuleName = '';
        egm.newGlobalModulePFName = '';
        egm.newGlobalModuleTitle = '';
        egm.hideModule = $state.params.type === 'new';


        loadModule();



        function loadModule(){
            if (!egm.newModule){
                egm.loading = true;
                return globalModulesService.getModuleConfig($state.params.gmName).then(function(response){
                    response.globalModuleMetaDataParsed = JSON.parse(response.globalModuleMetaData);
                    egm.module = response;
                    egm.loading = false;
                    egm.module.globalModuleMetaDataParsed.module.globalVersionLatest = JSON.parse(JSON.stringify(egm.module.globalModuleMetaDataParsed.module.globalVersion));
                    modulesService.setQuestionList(egm.module.globalModuleMetaDataParsed.formQuestions);
                    return Promise.resolve(response);
                }).catch(function(errMsg) {
                    alertService.error(errMsg, "Load Global Module");
                    return Promise.reject(errMsg);
                })
            } else {

                egm.module = {
                    name: '',
                    patientFacingName: '',
                    globalModuleMetaData: '',
                    globalModuleMetaDataParsed: {
                        module: {
                            defaultNextModuleRoute: '',
                            formElements: modulesService.generateNewModule(),
                            globalVersion: 0,
                            globalVersionLatest: 0,
                            moduleRouteName: '',
                            moduleTitle: ''
                        },
                        formQuestions: [],
                        version: 0,
                    }
                };
                modulesService.setQuestionList(egm.module.globalModuleMetaDataParsed.formQuestions);
            }
        }

        function setExistingModule(module){
            modulesService.setModuleList([egm.module.globalModuleMetaDataParsed.module]);
            modulesService.setQuestionList(egm.module.globalModuleMetaDataParsed.formQuestions);
            modulesService.setExistingModule(module);

        }
        //save module used to create the module
        function saveModule(form) {
            egm.setFormModified(form);
            egm.resetBrokenComponentValidation();
            var modules = modulesService.getModuleList();
            egm.module.globalModuleMetaDataParsed.module = JSON.parse(JSON.stringify(modules[0]));

        }

        function addElement(parentModule){
            modulesService.setModuleList([egm.module.globalModuleMetaDataParsed.module]);
            modulesService.setQuestionList(egm.module.globalModuleMetaDataParsed.formQuestions);
            modulesService.setElementList(parentModule);
            modulesService.addNewElement(parentModule);
            modulesService.setNewQuestion();

        }

        function removeElement(selected, module, form) {
            modulesService.setModuleList([egm.module.globalModuleMetaDataParsed.module]);
            egm.setFormModified(form);
            egm.resetBrokenComponentValidation();
            var modules = modulesService.removeElement(selected, module, form, [egm.module.globalModuleMetaDataParsed.module]);
            egm.module.globalModuleMetaDataParsed.module = JSON.parse(JSON.stringify(modules[0]));
            egm.module.globalModuleMetaDataParsed.formQuestions = modulesService.getQuestionList();
        }

        function setExistingElement(element, parentModule){
            modulesService.setQuestionList(egm.module.globalModuleMetaDataParsed.formQuestions);
            modulesService.setModuleList([egm.module.globalModuleMetaDataParsed.module]);
            modulesService.setElementList(parentModule);
            modulesService.setExistingElement(element, parentModule);

        }

        //save module used to create the module
        function saveElement(form) {
            egm.setFormModified(form);
            egm.resetBrokenComponentValidation();
            var modules = modulesService.getModuleList();
            egm.module.globalModuleMetaDataParsed.module = JSON.parse(JSON.stringify(modules[0]));
            egm.module.globalModuleMetaDataParsed.formQuestions = modulesService.getQuestionList();
        }

        function resetBrokenComponentValidation() {
            formEditionService.setBrokenComponents([]);
        }

        function setFormModified(form) {
           form.$setDirty();
           egm.modified = true;
        }

        function startModule(){
            egm.module.globalModuleMetaDataParsed.module.moduleTitle = egm.newGlobalModuleTitle;
            egm.module.globalModuleMetaDataParsed.module.moduleRouteName = egm.newGlobalModulePFName;
            egm.module.name = egm.newGlobalModuleName;
            egm.module.patientFacingName = egm.newGlobalModulePFName;
            egm.hideModule = false;
            egm.modified = true;
        }

        function saveGlobalModule(){
            egm.module.globalModuleMetaDataParsed.module.globalVersion++;
            egm.module.globalModuleMetaDataParsed.module.globalVersionLatest = JSON.parse(JSON.stringify(egm.module.globalModuleMetaDataParsed.module.globalVersion));
            if (egm.newModule){
                globalModulesService.publishModule(egm.module.globalModuleMetaDataParsed.module,egm.module.globalModuleMetaDataParsed.formQuestions,egm.module.name,egm.module.patientFacingName, 'manage').then(function(response){
                        $state.go('edit-global-module', {
                            gmName: response.data.name,
                            type: 'edit',
                            gmId: response.data.id
                    });
                });
            } else {
                egm.module.version++;
                globalModulesService.reviseGlobalModule(egm.module).then(function(response){
                    alertService.customSuccess('Changes to ' + egm.module.name + ' have been saved. Existing form edition links will need to be re-synced.');
                    egm.modified = false;
                    return Promise.resolve(response);
                }).catch(function(errMsg) {
                    alertService.error(errMsg, "Update Global Module");
                    return Promise.reject(errMsg);
                });
            }

        }

        function goToList(){
            $state.go('global-modules', {});
        }




    }
})(window.angular);
