(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('VideoProgramController', VideoProgramController);

    VideoProgramController.$inject = ['ProgramService', 'EditionsService', 'ProgramVersionService', 'ProgramSummaryService', 'ProgramWarningService',
        'AssetSetService',
        'UtilService', 'VideoService', 'AlertService', '$state', '$location'];

    function VideoProgramController(programService, editionsService, programVersionService, programSummaryService, programWarningService, assetSetService,
                                    utilService, videoService, alertService, $state, $location) {
        var vpc = this;
        vpc.confirm = {};
        vpc.collapsed = false;
        vpc.program = programService.getProgram;

        vpc.data = programSummaryService.getProgramData();
        //create versions
        vpc.formatVersion = utilService.formatVersion;
        vpc.createWorkingVersion = createWorkingVersion;
        vpc.createMajorVersion = createMajorVersion;
        vpc.createMinorVersion = createMinorVersion;

        vpc.nextMajorVersion = nextMajorVersion;
        vpc.nextMinorVersion = nextMinorVersion;
        vpc.nextWorkingVersion = nextWorkingVersion;
        vpc.getMisMatchedVersionMessage = getWarningMessages;
        vpc.isValidVersion = utilService.isValidVersionProgram;
        vpc.getLimelightInfo = getLimelightInfo;
        vpc.parseKeywords = parseKeywords;
        vpc.resetAssetSets = resetAssetSets;
        //set edit to disabled if no permissions or during loading process
        vpc.isEditDisabled = isEditDisabled;
        vpc.validEndLinksURL = validEndLinksURL;
        vpc.validateVideoLocation = videoService.validateVideoLocation;
        //reset before opening modal dialog
        vpc.reset = reset;

        //create copy
        vpc.createCopy = createCopy;

        //save form
        vpc.save = save;
        vpc.cancel = cancel;
        vpc.cancelButtonText = cancelButtonText;

        //discard
        vpc.discard = discard;
        vpc.canBeDiscarded = utilService.canBeDiscarded;
        vpc.discardButtonText = discardButtonText;
        vpc.discardMessage = utilService.discardMessage;
        //edition
        vpc.hasVideoSections = hasVideoSections;
        vpc.removeVideoSections = editionsService.removeVideoSections;

        //check if program meta has properties and values
        vpc.containsValue = containsValue;
        vpc.hasValue = hasValue;
        vpc.removeAt = utilService.removeAt;
        vpc.setFormModified = setFormModified;
        vpc.requiredOnAction = requiredOnAction;
        vpc.validateName = validateName;
        vpc.keywords = vpc.program().entity.meta.keywords.toString();
        (function onLoad() {
            if (!vpc.program().entity.meta) {
                vpc.program().entity.meta = {};
                // vpc.program().entity.meta.assetSets = [];
                vpc.program().entity.meta.programFolderName = '';
            }
            if (vpc.data) {
                loadVideoAssetSets();
            }
            setAssetSetSummaries();
        })();

        function loadVideoAssetSets() {
            return assetSetService.list().then(function (data) {
                vpc.data.ivrAssetSets = data;
                vpc.data.ivrAssetSets.forEach(function (assetSet, index, array) {
                    array[index].summary = assetSetService.getSummary(assetSet);
                });
            })
        }

        function setAssetSetSummary(edition) {
            edition.assetSetSummary = assetSetService.getSummary(edition.assetSet);
        }

        function setAssetSetToEdition(assetSetPath, edition){
            for (var i = 0; i < vpc.data.ivrAssetSets.length; i++){
                if (assetSetPath === vpc.data.ivrAssetSets[i].assetSetPath){
                    edition.assetSet = vpc.data.ivrAssetSets[i];
                    setAssetSetSummary(edition);
                    break;
                }
            }
            edition.endLinksURLValid = true;
        }

        function validateName() {
            vpc.submitted = false;
            if (vpc.program().entity.name && vpc.program().entity.name.match(/\"/g)) {
                vpc.nameError = true;
            } else {
                vpc.nameError = false;
            }
        }

        function reset(form) {
            alertService.removeAlert();
        }

        function isEditDisabled() {
            return programService.isEditDisabled() || vpc.loading;
        }

        function hasVideoSections() {
            var isRequired = false;
            var entity = vpc.program().entity;
            if (entity && entity.meta) {
                var editions = entity.meta.editions;
                if (editions) {
                    for (var i = 0; i < editions.length; i++) {
                        if (videoService.hasVideoSections(editions[i])) {
                            isRequired = true;
                            break;
                        }
                    }
                }
            }
            return isRequired;
        }

        function containsValue(prop) {
            var entity = vpc.program().entity;
            var containsValue = false;
            if (entity && entity.meta && prop in entity.meta) {
                var property = entity.meta[prop];
                if (property) {
                    containsValue = property.length > 0;
                }
            }
            return containsValue;
        }

        function hasValue(prop) {
            var entity = vpc.program().entity;
            if (entity && entity.meta && prop in entity.meta) {
                var obj = entity.meta[prop];
                return obj && 'id' in obj;
            }
            return false;
        }

        function requiredOnAction() {
            var curAction = programService.getCurrentAction();
            if (curAction && (curAction === "Create Major Version" || curAction === "Create Minor Version")) {
                return true;
            }
            return false;
        }

        function isFormValid(form, action) {
            var isValid = !vpc.nameError && form && form.$valid;
            if (!isValid) {
                alertService.error("Validation errors", action);
            } else {
                form.$setPristine();
            }
            form.$setSubmitted();
            $('body').removeClass('modal-open');
            return isValid;
        }

        function setAssetSetSummaries() {
            var editions = vpc.program().entity.meta.editions;
            if (editions) {
                for (var i = 0; i < editions.length; i++) {
                    setAssetSetSummary(editions[i]);
                }
            }
        }

        function save(form) {
            vpc.submitted = true;
            vpc.program().entity.type='VIDEO';

            if (!vpc.nameError) {
                var actionWord = "Save Program";
                if (!vpc.program().entity.emmiCode) {
                    actionWord = "Create Program";
                }
                vpc.loading = true;
                programService.save()
                    .then(function (response) {
                        setAssetSetSummaries();
                        form.$setPristine();
                        alertService.success(vpc.program().entity.name, actionWord);
                    })
                    .catch(function (response) {
                        alertService.error(response, actionWord);
                    })
                    .finally(function () {
                        delete vpc.loading;
                    });
            } else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function cancel() {
            var entity = vpc.program().entity;
            var emmiCode = entity.emmiCode;
            var versioned = entity.versionedToLocal || entity.version;
            utilService.navigate(emmiCode, versioned, $state);
        }

        function cancelButtonText() {
            var entity = vpc.program().entity;
            var emmiCode = entity.emmiCode;
            var text = "Return to Composer Home";
            if (emmiCode) {
                if (entity.version || entity.versionedToLocal) {
                    text = "Return to Version List Page";
                }
            }
            return text;
        }

        function discardButtonText() {
            var program = vpc.program();
            if (program && program.entity) {
                return program.entity.versionedToLocal ? 'REVERT' : 'DELETE';
            } else {
                return 'DELETE';
            }
        }

        function discard() {
            var entity = vpc.program().entity;
            var emmiCode = entity.emmiCode;
            var versionedToLocal = entity.versionedToLocal;
            vpc.loading = true;
            programService.discard()
                .then(function (response) {
                    utilService.navigate(emmiCode, versionedToLocal, $state);
                })
                .catch(function (response) {
                    alertService.error(response, 'discard program');
                })
                .finally(function () {
                    delete vpc.loading;
                });
        }

        function createCopy(form) {
            programService.createCopy(vpc.confirm.inputText);
            programService.setEditable(true);
            $('body').removeClass('modal-open');
        }

        function setNotes() {
            var entity = vpc.program().entity;
            if (entity) {
                if (!entity.version) {
                    entity.version = utilService.newVersion();
                }
                entity.version.comment = vpc.confirm.inputText;
            }
        }

        function setFormModified(form) {
            form.$setDirty();
        }

        function createWorkingVersion(form) {
            vpc.submitted = true;
            if (!vpc.nameError) {
                setNotes();
                vpc.loading = true;
                var operation = 'create working version';
                programVersionService.createWorkingVersion(vpc.program())
                    .then(function (response) {
                        var program = vpc.program();
                        program.entity.version = response.entity.version;
                        setAssetSetSummaries();
                        alertService.customSuccess('Working version for program ' + program.entity.name + ' is successfully created in the Composer.');
                        form.$setPristine();
                        programService.setCurrentAction("");
                    })
                    .catch(function (response) {
                        alertService.error(response, operation);
                    })
                    .finally(function () {
                        delete vpc.loading;
                    });
            } else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function createMajorVersion(form) {
            vpc.submitted = true;
            if (!vpc.program().entity.meta.editions.length > 0) {
                alertService.error("The Limelight Video Asset Identifier is missing or invalid", "create Major Version");
                form.$setSubmitted();
                return false;
            }
            var endLinksValid = validateEndLinks(vpc.program().entity.meta.editions, alertService);
            if (!endLinksValid) {
                form.$setSubmitted();
                return false;
            }
            if (isFormValid(form, "Create Major Version")) {
                if (!vpc.nameError) {
                    setNotes();
                    vpc.loading = true;
                    var operation = 'create major version';
                    programVersionService.createMajorVersion(vpc.program())
                        .then(function (response) {
                            var program = vpc.program();
                            program.entity.version = response.entity.version;
                            setAssetSetSummaries();
                            alertService.customSuccess('Major version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            form.$setPristine();
                            programService.setCurrentAction("");
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete vpc.loading;
                        });
                }
                $('body').removeClass('modal-open');
            }
        }

        function createMinorVersion(form) {
            vpc.submitted = true;
            if (!vpc.program().entity.meta.editions.length > 0) {
                alertService.error("The Limelight Video Asset Identifier is missing or invalid", "create Major Version");
                form.$setSubmitted();
                return false;
            }
            var endLinksValid = validateEndLinks(vpc.program().entity.meta.editions, alertService);
            if (!endLinksValid) {
                form.$setSubmitted();
                return false;
            }
            if (isFormValid(form, "Create Minor Version")) {
                if (!vpc.nameError) {
                    setNotes();
                    vpc.loading = true;
                    var operation = 'create minor version';
                    programVersionService.createMinorVersion(vpc.program())
                        .then(function (response) {
                            var program = vpc.program();
                            program.entity.version = response.entity.version;
                            setAssetSetSummaries();
                            alertService.customSuccess('Minor version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            form.$setPristine();
                            programService.setCurrentAction("");
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete vpc.loading;
                        });
                }
                $('body').removeClass('modal-open');
            }
        }

        function getCompleteVersion(obj) {
            return obj.major + '.' + obj.minor + '.' + obj.working
        }

        function nextMajorVersion() {
            programService.setCurrentAction("Create Major Version");
            return programVersionService.nextMajorVersion(vpc.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        warningMessages = getWarningMessages(version, verobj.major);
                    } else {
                        version = '1.0.0';
                    }
                    return { resultMessage: version, warningMessages: warningMessages };
                })
        }

        function nextMinorVersion() {
            programService.setCurrentAction("Create Minor Version");
            return programVersionService.nextMinorVersion(vpc.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        warningMessages = getWarningMessages(version, verobj.major);
                    } else {
                        version = '0.1.0';
                    }
                    return { resultMessage: version, warningMessages: warningMessages };
                })
        }

        function nextWorkingVersion() {
            programService.setCurrentAction("Create Working Version");
            return programVersionService.nextWorkingVersion(vpc.program())
                .then(function (response) {
                    var verobj = response;
                    var version = '0.0.1';
                    if (verobj) {
                        version = verobj.major + '.' + verobj.minor + '.' + verobj.working;
                    }
                    return { resultMessage: version, warningMessages: [] };
                })
        }

        function getWarningMessages(nextProgramVersion, nextProgramMajor) {
            return programWarningService.getWarningMessages(nextProgramVersion, nextProgramMajor);
        }

        function getLimelightInfo(folder, form) {
            var videoCheck = [];
            for (var i = 0; i < vpc.data.ivrAssetSets.length; i++){
                videoCheck.push({
                    folder: folder,
                    chapters: [{number: 0, name: 'Full Video'},{number:2, name: 'Chapter 2'}],
                    assetSetPath: vpc.data.ivrAssetSets[i].assetSetPath,
                    versionId: '1.0'
                })
            }
            vpc.program().entity.meta.editions = [];
            var videosChecked = 0;
            angular.forEach(videoCheck, function (lang) {
                vpc.loadingSections = true;
                angular.forEach(lang.chapters, function (chapter) {
                    videoService.getVideoSections(lang.folder, lang.assetSetPath, lang.versionId, chapter.number)
                        .then(function (response) {
                            var selectedEdition = null;
                            if (vpc.program().entity.meta.editions) {
                                var foundEdition = false;
                                angular.forEach(vpc.program().entity.meta.editions, function (edition) {
                                    if (edition.language === lang.assetSetPath) {
                                        foundEdition = true;
                                        selectedEdition = edition;
                                    }
                                });
                                if (!foundEdition && response !== '' && response.videoUrl !== undefined) {
                                    vpc.program().entity.meta.editions.push({
                                        language: lang.assetSetPath,
                                        programSections: []
                                    });
                                    selectedEdition = vpc.program().entity.meta.editions[vpc.program().entity.meta.editions.length - 1];
                                    setAssetSetToEdition(lang.assetSetPath, selectedEdition);
                                }
                            }
                            if (response && response !== "" && response.videoUrl !== undefined) {
                                videoService.setSectionInformation(chapter, selectedEdition, response);
                            }
                            if (response !== '' && response.videoUrl !== undefined && selectedEdition.programSections.length === lang.chapters.length) {
                                videoService.sortSections(selectedEdition);
                            }
                            if (chapter.number === 0) videosChecked++;
                            if (videosChecked === videoCheck.length) vpc.loadingSections = false;
                        })
                        .catch(function (response) {
                            console.log('No video found for ' + lang.assetSetPath + 'for Chapter ' + chapter.number)
                            vpc.loadingSections = false;
                        })
                });
            })

            form.$setDirty();
        }
        function resetAssetSets(){
            if (vpc.program().entity.meta.editions.length > 0) {
                vpc.program().entity.meta.editions = [];
            }
        }

        function parseKeywords(){
            vpc.program().entity.meta.keywords = utilService.parseKeywords(vpc).split(',');
            utilService.cleanKeywords(vpc.program().entity.meta.keywords);
        }

        function validEndLinksURL(edition) {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

            var isValid = pattern.test(edition.endLinksURL);
            if (edition.endLinksURL === '') {
                isValid = true;
            }
            edition.endLinksURLValid = isValid;
            return isValid;
        }

        function validateEndLinks(editions, alertService){
            var invalidURLs = false;
            var haveBothEndLinksValues = true;
            angular.forEach(editions, function(edition){
                if (edition.endLinksDisplayText || edition.endLinksURL) {
                    vpc.validEndLinksURL(edition);
                    haveBothEndLinksValues = (edition.endLinksDisplayText && edition.endLinksURL && edition.endLinksDisplayText.length > 0 && edition.endLinksURL.length > 0);
                }
                if (!haveBothEndLinksValues) alertService.error("End Links URLs and Display Text must be included together.", "create Major Version");
                if (!(edition.endLinksURLValid === true || edition.endLinksURLValid === undefined)) {
                    alertService.error("One or more end links URLs are malformed", "create Major Version");
                    invalidURLs = true;
                }
            });

            if (invalidURLs || !haveBothEndLinksValues) return false;
                else return true;
        }
    }
})(window.angular);
