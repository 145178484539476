(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditBrandController', EditBrandController);

    EditBrandController.$inject = ['EditBrandService', 'savedBrandResource', 'saveCallback', 'errorCallback'];

    function EditBrandController(EditBrandService, savedBrandResource, saveCallback, errorCallback) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedBrandResource);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditBrandService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
