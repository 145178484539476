(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('confirmWithCommentModal', confirmWithCommentModal);

    confirmWithCommentModal.$inject = ['$modal'];

    function confirmWithCommentModal($modal) {
        return {
            restrict: 'A',
            link: link,
            scope: {
                action: '&callbackFn',
                confirmHeader: '@',
                confirmLabel: '@',
                confirmText: '@',
                warningText:'@',
                isWarning: '@',
                inputLabel: '@',
                maxLength: '@',
                confirm: '=',
                forOnAction: '@',
                onActionCallback: '&',
                okButtonValue: '@',
                okButtonClass: '@',
                validate: '=',
                isValid: '&',
                program: '=' //Only pass program for publishing instance
            }
        };

        function link(scope, element) {
            var modal = {};
            element.on('click', function () {
                modal = $modal({
                    backdrop: 'static',
                    keyboard: true,
                    templateUrl: 'app/modal/confirm-with-comment/confirm-with-comment.modal.html',
                    scope: scope,
                    show: function() {
                        if(scope.validate === true) {
                            return scope.isValid ? scope.isValid() : true;
                        }
                        return true;
                    }(),
                    controller: 'ConfirmWithCommentController',
                    controllerAs: 'cwc',
                    resolve: {
                        title: function () {
                            return scope.confirmHeader;
                        },
                        confirmLabel: function () {
                            return scope.confirmLabel;
                        },
                        confirmText: function () {
                            return scope.confirmText;
                        },
                        isWarning: function () {
                            return scope.isWarning;
                        },
                        warningText: function () {
                            return scope.warningText;
                        },
                        maxLength: function () {
                            return scope.maxLength;
                        },
                        inputLabel: function () {
                            return scope.inputLabel;
                        },
                        confirmed: function () {
                            return function (data) {
                                scope.confirm.inputText = data;
                                scope.action();
                            }
                        },
                        forOnAction: function () {
                            return scope.forOnAction;
                        },
                        onActionCallback: function () {
                            return function () {
                                return scope.onActionCallback();
                            }
                        },
                        okButtonValue: function () {
                            return scope.okButtonValue;
                        },
                        okButtonClass: function () {
                            return scope.okButtonClass;
                        },
                        program: function () {
                            return scope.program;
                        }
                    }
                })
            });

            element.on('$destroy', function() {
                if(modal.hide !== undefined) {
                    modal.hide();
                }
            });
        }
    }
})(window.angular);
