(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('editExistingBrand', editExistingBrand);

    editExistingBrand.$inject = ['$modal', '$alert'];

    function editExistingBrand($modal, $alert) {

        return {
            restrict: 'A',
            link: link,
            scope: {
                toBeEdited: '='
            }
        };

        function link(scope, element) {

            element.on('click', function () {
                $modal({
                    templateUrl: 'app/reference/brands/edit/edit.brand.modal.html',
                    scope: scope,
                    controller: 'EditBrandController',
                    controllerAs: 'ebc',
                    resolve: {
                        savedBrandResource: function () {
                            return scope.toBeEdited
                        },
                        saveCallback: function () {
                            return function (data) {
                                updatedExistingBrand(data, scope.toBeEdited)
                            }
                        },
                        errorCallback: function () {
                            return errorUpdating;
                        }
                    }
                })
            });

        }

        function errorUpdating() {
            $alert({
                type: 'danger',
                content: 'Error: ' + err.data.errorMessage + '. Please try again.'
            });
        }

        function updatedExistingBrand(newValue, existingValue) {
            $alert({
                content: [
                    'Brand ', newValue.entity.description, ' edited successfully.'
                ].join('')
            });
            angular.extend(existingValue, newValue);
        }
    }
})(window.angular);
