(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('PaginationService', PaginationService);


    function PaginationService() {
        return {
            handleResponse: handleResponse
        };

        function addPageLinks(responsePage, pageData) {
            if (responsePage.linkList && responsePage.linkList.length > 0) {
                var totalPages = responsePage.page.totalPages;
                var hasPrev = false;
                var hasNext = false;
                for (var i = 0, l = responsePage.linkList.length; i < l; i++) {
                    var aLink = responsePage.linkList[i];
                    if (aLink.rel.indexOf('self') === -1) {
                        var linkValue = aLink.rel.substring(5),
                            linkClass = '';
                        if (linkValue === 'next') {
                            linkValue = 'Next >';
                            linkClass = 'next';
                            hasNext = true;
                        }
                        else if (linkValue === 'prev') {
                            linkValue = '< Previous';
                            linkClass = 'previous';
                            hasPrev = true;
                            addFirstPageLink(aLink, pageData, false); // << First
                        }
                        addPageLink(pageData, i, linkValue, aLink, linkClass, false); //rest of pages
                        if (linkValue === 'Next >') {
                            addLastPageLink(aLink, totalPages - 1, pageData, false); // Last >>
                        }
                    }
                }

                //add disabled links
                if (!hasNext) {
                    var idx = responsePage.linkList.length - 1;
                    var aLink = responsePage.linkList[idx];
                    addPageLink(pageData, idx, 'Next >', aLink, 'next', true); //Next >
                    addLastPageLink(aLink, totalPages - 1, pageData, true); // Last >>
                } else if (!hasPrev) {
                    var idx = 1;
                    var aLink = responsePage.linkList[idx];
                    addPageLinkAtBeginning(pageData, idx, '< Previous', aLink, 'prev', true); // < Previous
                    addFirstPageLink(aLink, pageData, true); // << First
                }
            }
        }

        function handleResponse(responsePage) {
            var pageData = {};

            if (responsePage && responsePage.content) {
                // sort the rows the way they exist on the response page
                for (var sort = 0, size = responsePage.content.length; sort < size; sort++) {
                    var content = responsePage.content[sort];
                    content.sortIdx = sort;
                }

                // put the content in scope
                pageData.content = responsePage.content;

                // set the total
                pageData.total = responsePage.page && responsePage.page.totalElements ?
                    responsePage.page.totalElements : 0;

                // create links in scope
                pageData.link = responsePage.link;
                pageData.linksDisplay = [];

                addPageLinks(responsePage, pageData);

                // create current loading plus page
                pageData.load = responsePage.link && (responsePage.link['page-self'] || responsePage.link.self);

                // page numbers
                if (responsePage.page) {
                    pageData.currentPage = responsePage.page.number + 1;
                    pageData.currentPageSize = responsePage.page.size;
                }

                // status filter on response
                if (responsePage.filter) {
                    pageData.status = responsePage.filter.status;
                }

                // handle sort response object
                if (responsePage.sort) {
                    pageData.sortProperty = {
                        property: responsePage.sort[0].property,
                        ascending: responsePage.sort[0].direction === 'ASC'
                    };
                }
            } else {
                pageData.total = 0;
            }

            return pageData;
        }

        function createPageLink(linkhref, pagenbr) {
            var urlpars = linkhref.split('?');
            var baseurl = urlpars[0];
            var params = urlpars[1];
            var newparams = '';
            var i = 0;
            params.split("&").forEach(function(part) {
                if(i > 0) {
                    newparams += '&';
                }
                var item = part.split("=");
                if(item[0] == 'page') {
                    newparams += item[0] + '=' + pagenbr;
                }
                else {
                    newparams += item[0] + '=' + item[1];
                }
                i++;
            });
            return baseurl + '?' + newparams;
        }

        function addFirstPageLink(aLink, pageData, disabled) {
            var firstlinkhref = createPageLink(aLink.href, 0);
            pageData.linksDisplay.unshift({
                order: 0,
                name: '<< First',
                href: firstlinkhref,
                className: '',
                disabled: disabled
            });
        }

        function addLastPageLink(aLink, totalPages, pageData, disabled) {
            var lastlinkhref = createPageLink(aLink.href, totalPages);
            pageData.linksDisplay.push({
                order: 0,
                name: 'Last >>',
                href: lastlinkhref,
                className: '',
                disabled: disabled
            });
        }

        function addPageLink(pageData, idx, linkValue, aLink, linkClass, disabled) {
            pageData.linksDisplay.push({
                order: idx,
                name: linkValue,
                href: aLink.href,
                className: linkClass,
                disabled: disabled
            });
        }

        function addPageLinkAtBeginning(pageData, idx, linkValue, aLink, linkClass, disabled) {
            pageData.linksDisplay.unshift({
                order: idx,
                name: linkValue,
                href: aLink.href,
                className: linkClass,
                disabled: disabled
            });
        }
    }

})(window.angular);
