(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('VideoService', VideoService);

    VideoService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function VideoService($http, Principal, UriTemplate) {

        return {
            getVideoSections: getVideoSections,
            hasVideoSections: hasVideoSections,
            setSectionInformation: setSectionInformation,
            setNoSectionInformation: setNoSectionInformation,
            sortSections: sortSections
        };

        function getVideoSections(folderName, assetSetPath, versionId, chapterNumber) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.editions + 'video/{folderName}/{chapterNumber}/{versionId}?assetPath={assetSetPath}').stringify({
                    assetSetPath: assetSetPath,
                    folderName: folderName,
                    chapterNumber: chapterNumber,
                    versionId: versionId
                })).then(function (response) {
                    return response.data;
                });
            })
        }

        function hasVideoSections(edition) {
            var programSections = edition.programSections;
            return programSections && programSections.length > 1;
        }

        function setProgramSection(chapter, edition) {
            var programSection = {
                sectionUrls: []
            };
            programSection.emmiSctnNmbr = chapter.number;
            programSection.emmiSctnNm = chapter.name;

            edition.programSections.push(programSection);
            return programSection;
        }

        function setSectionInformation(chapter, edition, response) {
            var programSection = setProgramSection(chapter, edition);
            programSection.emmiSctnMnts = response.duration > 0 ? response.duration : 1;
            programSection.mediaId = response.mediaId;
            programSection.sectionUrls = getSectionUrls(response);
        }

        function getSectionUrls(response) {
            var sectionUrls = [];
            var videoSectionUrl = newSectionUrl(response.videoUrl, response.videoUrlType);
            var captionSectionUrl = newSectionUrl(response.captionUrl, response.captionUrlType);
            var thumbnailSectionUrl = newSectionUrl(response.thumbnailUrl, response.thumbnailUrlType);
            var masterListSectionUrl = newSectionUrl(response.masterListUrl, response.masterListUrlType);
            sectionUrls.push(videoSectionUrl);
            sectionUrls.push(captionSectionUrl);
            sectionUrls.push(thumbnailSectionUrl);
            sectionUrls.push(masterListSectionUrl);
            return sectionUrls;
        }

        function newSectionUrl(url, urlTypeName) {
            var sectionUrl = {
                urlType: {}
            };
            sectionUrl.url = url;
            sectionUrl.urlType.name = urlTypeName;
            return sectionUrl;
        }

        function setNoSectionInformation(chapter, edition) {
            setProgramSection(chapter, edition);
        }

        function sortSections(edition) {
            edition.programSections.sort(function (a, b) {
                return a.emmiSctnNmbr - b.emmiSctnNmbr;
            });
        }
    }
})(window.angular);
