(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditBrandService', EditBrandService);

    EditBrandService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditBrandService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(brandResource) {
            LinkConverter.convertResource(brandResource);
            if (brandResource && brandResource.link.self) {
                return $http.put(brandResource.link.self, brandResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
