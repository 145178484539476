(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditDialectService', EditDialectService);

    EditDialectService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditDialectService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(dialectResource) {
            LinkConverter.convertResource(dialectResource);
            if (dialectResource && dialectResource.link.self) {
                return $http.put(dialectResource.link.self, dialectResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
