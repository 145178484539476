(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditAssetSetCountryController', EditAssetSetCountryController);

    EditAssetSetCountryController.$inject = ['EditAssetSetCountryService', 'ProgramInitService', 'savedAssetSetCountryResource', 'saveCallback', 'errorCallback'];

    function EditAssetSetCountryController(EditAssetSetCountryService, ProgramInitService, savedAssetSetCountryResource, saveCallback, errorCallback) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedAssetSetCountryResource);
        epc.data = ProgramInitService.getAssetSetCountries(true, true);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditAssetSetCountryService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
