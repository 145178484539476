(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('CarePlanModalController', CarePlanModalController);

    CarePlanModalController.$inject = ['ProgramService', 'ProgramVersionService', '$state', 'AlertService', 'UtilService', '$scope', 'fullList', 'saveCallback'];

    function CarePlanModalController(ProgramService, ProgramVersionService, state, AlertService, utilService, $scope, fullList, saveCallback) {
        var cpmc = this;

        cpmc.fullList = fullList;
        cpmc.saveCallback = saveCallback;
        cpmc.dayErrorMesg = '';
        cpmc.selectedFormType = '';
        cpmc.selectedMedia = [];
        cpmc.selectedFormPrograms = [];
        cpmc.selectedIvrPrograms = [];
        cpmc.searchMediaFlag = false;
        cpmc.dayList = [];
        cpmc.addList = {
            day: "",
            formType: "",
            media: [],
            formPrograms: [],
            ivrPrograms: [],
        };
        cpmc.editFlag = false;
        cpmc.dayNumber = '';
        cpmc.save = save;
        cpmc.buttonAction = buttonAction;
        cpmc.cancel = cancel;
        cpmc.loadTypes = loadTypes;
        cpmc.search = search;
        cpmc.nextPage = nextPage;
        cpmc.onSelectMedia = onSelectMedia;
        cpmc.onSelectFormPrograms = onSelectFormPrograms;
        cpmc.onSelectIvrPrograms = onSelectIvrPrograms;
        cpmc.addObject = addObject;
        cpmc.editDayList = editDayList;
        cpmc.dataPopulation = dataPopulation;
        cpmc.copyDay = copyDay;
        cpmc.mediaList = mediaList;
        cpmc.formProgramsList = formProgramsList;
        cpmc.ivrProgramsList = ivrProgramsList;
        cpmc.saveAndClose = saveAndClose;
        cpmc.dayValidation = dayValidation;
        cpmc.removeDuplicate = removeDuplicate;
        cpmc.dayErrorAlert = dayErrorAlert;
        cpmc.addDay = addDay;
        cpmc.modalFlagDefault = modalFlagDefault;
        cpmc.assignModalFlag = assignModalFlag;
        cpmc.addOrder = addOrder;

        // assigining data while onloading
        (function onLoad() {
            cpmc.modalObject = cpmc.fullList;
            cpmc.selectedMedia = [];
            cpmc.selectedFormPrograms = [];
            cpmc.selectedIvrPrograms = [];
            if (cpmc.modalObject.editFlag) {
                cpmc.editDayList(cpmc.modalObject.editItem);
            } else if (cpmc.modalObject.copyFlag) {
                cpmc.copyDay(cpmc.modalObject.list);
            }
            cpmc.loadTypes('FORM');
            cpmc.loadTypes('IVR CALL');
            cpmc.loadTypes('MULTIMEDIA PROGRAM');
        })();

        // add Day button action
        function addDay() {
            if (cpmc.day) cpmc.day = Number(cpmc.day);
        }

        //default modal 
        function modalFlagDefault() {
            cpmc.dayErrorMesg = '';
            cpmc.modalObject.dayFlag = false;
            cpmc.modalObject.formFlag = false;
            cpmc.modalObject.mediaFlag = false;
            cpmc.modalObject.ivrFlag = false;
            cpmc.modalObject.modalTitle = '';
            cpmc.modalObject.buttonOne = '';
            cpmc.modalObject.buttonTwo = '';
            cpmc.modalObject.buttonThree = '';
        }

        //assiging the modal data based on the modal event
        function assignModalFlag(title, btnOne, btnTwo, btnThree, flag) {
            cpmc.modalObject[flag] = true;
            cpmc.modalObject.modalTitle = title;
            cpmc.modalObject.buttonOne = btnOne;
            cpmc.modalObject.buttonTwo = btnTwo;
            cpmc.modalObject.buttonThree = btnThree;
        }
        
        //common button for all types from modal 
        function buttonAction(arg, $hide) {
            cpmc.dayError = false;
            cpmc.searchProgram = '';
            cpmc.mediaErrorFlag = false;
            var addedList = cpmc.modalObject.list ? cpmc.modalObject.list : [];
            if (arg === 'ADD MEDIA') {
                if (cpmc.dayValidation(addedList)) {
                    cpmc.modalFlagDefault();
                    cpmc.assignModalFlag('Add Programs','ADD FORM','SAVE MEDIA', 'ADD IVR', 'mediaFlag' );
                    if (cpmc.modalObject.editFlag && cpmc.modalObject.editItem.media) cpmc.selectedMultimediaList = cpmc.modalObject.editItem.media;
                    if (cpmc.modalObject.editFlag && cpmc.modalObject.editItem.formPrograms) {
                        cpmc.selectedFormProgramsList = cpmc.selectedFormProgramsList ? cpmc.selectedFormProgramsList : cpmc.modalObject.editItem.formPrograms;
                    }
                }
            } else if (arg === 'ADD FORM' && !cpmc.modalObject.editFlag) {
                if (cpmc.dayValidation(addedList)) {
                    cpmc.modalFlagDefault();
                    cpmc.assignModalFlag(cpmc.modalObject.editFlag ? 'Edit FORM Program' : 'Add FORM Program', 'ADD MEDIA', 'SAVE FORM', 'ADD IVR','formFlag');
                }
            }
                else if (arg === 'ADD IVR' && !cpmc.modalObject.editFlag) {
                if (cpmc.dayValidation(addedList)) {
                    cpmc.modalFlagDefault();
                    cpmc.assignModalFlag(cpmc.modalObject.editFlag ? 'Edit IVR Program' : 'Add IVR Program', 'ADD MEDIA', 'ADD FORM', 'SAVE IVR', 'ivrFlag');
                }
            } else if (arg === 'SAVE FORM') {
                if (cpmc.formProgramsList()) {
                    cpmc.save($hide);
                    cpmc.selectedMedia = [];
                    cpmc.selectedFormPrograms = [];
                    cpmc.selectedIvrPrograms = [];
                } else {
                    cpmc.dayErrorAlert('select');
                }
            } else if (arg === 'SAVE IVR') {
            if (cpmc.ivrProgramsList()) {
                cpmc.save($hide);
                cpmc.selectedMedia = [];
                cpmc.selectedFormPrograms = [];
                cpmc.selectedIvrPrograms = [];
            } else {
                cpmc.dayErrorAlert('select');
            }
        }
                else if (arg === 'SAVE MEDIA') {
                if (cpmc.mediaList()) {
                    cpmc.save($hide);
                    cpmc.selectedMedia = [];
                    cpmc.selectedFormPrograms = [];
                    cpmc.selectedIvrPrograms = [];
                } else {
                    cpmc.dayErrorAlert('select');
                }
            }
            else if (arg === 'SAVE & EDIT FORM') {
                if (cpmc.dayValidation(addedList)) {
                    cpmc.modalFlagDefault();
                    cpmc.assignModalFlag(cpmc.modalObject.editFlag ? 'Edit FORM Program' : 'Add FORM Program','SAVE FORM','SAVE & EDIT MEDIA', 'SAVE & EDIT IVR', 'formFlag' );
                    if (cpmc.modalObject.editFlag && cpmc.modalObject.editItem.media) {
                        cpmc.selectedMultimediaList = cpmc.selectedMultimediaList ? cpmc.selectedMultimediaList : cpmc.modalObject.editItem.media;
                    }
                    if (cpmc.modalObject.editFlag && cpmc.modalObject.editItem.formPrograms) {
                        cpmc.selectedFormProgramsList = cpmc.selectedFormProgramsList ? cpmc.selectedFormProgramsList : cpmc.modalObject.editItem.formPrograms;
                    }
                    if (cpmc.modalObject.editFlag && cpmc.modalObject.editItem.ivrPrograms) {
                        cpmc.selectedIvrProgramsList = cpmc.selectedIvrProgramsList ? cpmc.selectedIvrProgramsList : cpmc.modalObject.editItem.ivrPrograms;
                    }
                    cpmc.addObject();
                }
            } else if (arg === 'SAVE & EDIT MEDIA') {
                if (cpmc.modalObject.editFlag && cpmc.modalObject.editItem.media) {
                    cpmc.selectedMultimediaList = cpmc.selectedMultimediaList ? cpmc.selectedMultimediaList : cpmc.modalObject.editItem.media;
                }
                if (cpmc.modalObject.editFlag && cpmc.modalObject.editItem.formPrograms) {
                    cpmc.selectedFormProgramsList = cpmc.selectedFormProgramsList ? cpmc.selectedFormProgramsList : cpmc.modalObject.editItem.formPrograms;
                }
                if (cpmc.modalObject.editFlag && cpmc.modalObject.editItem.ivrPrograms) {
                    cpmc.selectedIvrProgramsList = cpmc.selectedIvrProgramsList ? cpmc.selectedIvrProgramsList : cpmc.modalObject.editItem.ivrPrograms;
                }
                if (cpmc.dayValidation(addedList)) {
                    cpmc.modalFlagDefault();
                    cpmc.assignModalFlag(cpmc.modalObject.editFlag ? 'Edit Multimedia Programs' : 'Add Multimedia Programs','SAVE & EDIT FORM','SAVE MEDIA', 'SAVE & EDIT IVR', 'mediaFlag' );
                    cpmc.addObject();
                }
            } else if (arg === 'SAVE & EDIT IVR') {
                if (cpmc.modalObject.editFlag && cpmc.modalObject.editItem.media) {
                    cpmc.selectedMultimediaList = cpmc.selectedMultimediaList ? cpmc.selectedMultimediaList : cpmc.modalObject.editItem.media;
                }
                if (cpmc.modalObject.editFlag && cpmc.modalObject.editItem.formPrograms) {
                    cpmc.selectedFormProgramsList = cpmc.selectedFormProgramsList ? cpmc.selectedFormProgramsList : cpmc.modalObject.editItem.formPrograms;
                }
                if (cpmc.modalObject.editFlag && cpmc.modalObject.editItem.ivrPrograms) {
                    cpmc.selectedIvrProgramsList = cpmc.selectedIvrProgramsList ? cpmc.selectedIvrProgramsList : cpmc.modalObject.editItem.ivrPrograms;
                }
                if (cpmc.dayValidation(addedList)) {
                    cpmc.modalFlagDefault();
                    cpmc.assignModalFlag(cpmc.modalObject.editFlag ? 'Edit IVR Programs' : 'Add IVR Programs','SAVE & EDIT FORM','SAVE & EDIT MEDIA', 'SAVE IVR', 'ivrFlag' );
                    cpmc.addObject();
                }
            }

            else if (arg === 'copy') {
                if (cpmc.dayValidation(addedList)) {
                    if (cpmc.modalObject.copyFlag) {
                        var list = [];
                        var index = utilService.findIndex(cpmc.modalObject, 'day', cpmc.day);
                        if (index) {
                            cpmc.dayErrorAlert('inuse');
                        } else {
                            list.push({ day: cpmc.day, formType: angular.copy(cpmc.modalObject.copyItem.formType), media: angular.copy(cpmc.modalObject.copyItem.media), formPrograms: angular.copy(cpmc.modalObject.copyItem.formPrograms), ivrPrograms: angular.copy(cpmc.modalObject.copyItem.ivrPrograms), toggleFlag: true });
                            cpmc.saveCallback({ final: list });
                            $hide();
                            $('body').removeClass('modal-open');
                        }
                    }
                }
            } else if (arg === 'CANCEL') {
                cpmc.day = cpmc.day ? cpmc.day : cpmc.modalObject.editItem.day;
                cpmc.cancel(arg, $hide);
                $(".modal").modal("hide");
                $('body').removeClass('modal-open');
            }
        }

        // day validation to check duplicate days 
        function dayValidation(list) {
            var regex = /^\d+$/;
            if (cpmc.day) {
                if (regex.test(cpmc.day)) {
                    var index = utilService.findIndex(list, 'day', Number(cpmc.day));
                    if (index && !cpmc.modalObject.editFlag) {
                        cpmc.dayErrorAlert('inuse');
                        return false;
                    } else if (cpmc.modalObject.editFlag && cpmc.day !== cpmc.modalObject.editItem.day) {
                        var modalIndex = utilService.findIndex(cpmc.modalObject.list, 'day', cpmc.day);
                        if (modalIndex) {
                            cpmc.dayErrorAlert('inuse');
                            return false;
                        } else {

                            return true;
                        }
                    } else {
                        return true;
                    }
                } else {
                    cpmc.dayError = true;
                    cpmc.dayErrorAlert('numbers');
                    return false;
                }
            } else {
                cpmc.dayError = true;
                cpmc.dayErrorAlert('req');
                return false;
            }
        }

        //day validation errors
        function dayErrorAlert(type) {
            if (type === 'numbers') {
                cpmc.dayErrorMesg = 'Use numbers only.';
            } else if (type === 'inuse') {
                cpmc.dayErrorMesg = 'This day is already in use.';
            } else if (type === 'req') {
                cpmc.dayErrorMesg = 'Day is required.';
            } else if (type === 'select') {
                cpmc.dayErrorMesg = 'Please select a program.';
            }
        }

        // search programs on FORM and Multimedia
        function search(arg, type) {
            if (type === 'form' && arg !== '') {
                cpmc.searchProgram = arg;
                cpmc.loadTypes('FORM');
            }
            if (type === 'ivr' && arg !== '') {
                cpmc.searchProgram = arg;
                cpmc.loadTypes('IVR CALL');
            }
            if (type === 'media' && arg !== '') {
                cpmc.searchProgram = arg;
                cpmc.searchMediaFlag = true;
                cpmc.loadTypes('MULTIMEDIA PROGRAM');
            } else {
                cpmc.searchMediaFlag = false;
            }
        }

        // pagination for FORM and Multimedia
        function nextPage(link, type) {
            cpmc.dataPopulation();
            if (cpmc.formList && link === cpmc.formList.load && type === 'form') {
                return; //if it the same link as the current page, return
            } else if (cpmc.multimediaList && link === cpmc.multimediaList.load && type === 'media') {
                return; //if it the same link as the current page, return
            }
            else if (cpmc.ivrList && link === cpmc.ivrList.load && type === 'ivr') {
                    return; //if it the same link as the current page, return
            } else if (link) {
                cpmc.loadingNextPage = true;
                utilService.fetchPage(link).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    cpmc.loadingNextPage = false;
                    if (type === 'form') cpmc.formList = response;
                    if (type === 'media') cpmc.multimediaList = response;
                    if (type === 'ivr') cpmc.ivrList = response;
                    cpmc.dataPopulation();
                } else {
                    cpmc.loadingNextPage = true;
                }
            }

            function err() {
                vpc.loadingNextPage = true;
            }
        }

        function mediaList() {
            var list = [];
            if (cpmc.selectedMedia) {
                angular.forEach(cpmc.selectedMedia, function (res) {
                    if (res.select) {
                        list.push(res);
                    }
                });
                return list;
            }
        }
        function formProgramsList() {
            var list = [];
            if (cpmc.selectedFormPrograms) {
                angular.forEach(cpmc.selectedFormPrograms, function (res) {
                    if (res.select) {
                        list.push(res);
                    }
                });
                return list;
            }
        }
        function ivrProgramsList() {
            var list = [];
            if (cpmc.selectedIvrPrograms) {
                angular.forEach(cpmc.selectedIvrPrograms, function (res) {
                    if (res.select) {
                        list.push(res);
                    }
                });
                return list;
            }
        }

        // to remove duplicate programs
        function removeDuplicate(a, key) {
            var index = [];
            return a.filter(function (item) {
                var k = key(item);
                return index.indexOf(k) >= 0 ? false : index.push(k);
            });
        }

        //selected media is available or not 
        function onSelectMedia(item) {
            item.select = !item.select;
            var found = false;
            angular.forEach( cpmc.selectedMedia, function (res,index) {
                if (item.entity.emmiCode === res.entity.emmiCode) {
                  found = true;
                  if (!item.select) {
                    cpmc.selectedMedia.splice(index, 1);
                  }
                }
              });
              if (!found) {
                cpmc.selectedMedia.push(item);
              }
            cpmc.selectedMultimediaList = cpmc.selectedMedia;
        }

        //selected media is available or not
        function onSelectFormPrograms(item) {
            item.select = !item.select;
            var found = false;
            angular.forEach( cpmc.selectedFormPrograms, function (res,index) {
                if (item.entity.emmiCode === res.entity.emmiCode) {
                    found = true;
                    if (!item.select) {
                        cpmc.selectedFormPrograms.splice(index, 1);
                    }
                }
            });
            if (!found) {
                cpmc.selectedFormPrograms.push(item);
            }
            cpmc.selectedFormProgramsList = cpmc.selectedFormPrograms;
        }

        //selected media is available or not
        function onSelectIvrPrograms(item) {
            cpmc.selectedIvrPrograms[0] = item;
            for (var i = 0; i < cpmc.ivrList.content.length; i++){
                cpmc.ivrList.content[i].select = false;

                if (cpmc.ivrList.content[i].entity.name === item.entity.name) cpmc.ivrList.content[i].select = true;
            }
            cpmc.selectedIvrProgramsList = cpmc.selectedIvrPrograms;
        }

        //load types/search types like form/multimedia (api call)
        function loadTypes(arg) {
            cpmc.apiStatus = (cpmc.type + '-fail').toLowerCase();
            var config = {
                params: {
                    search: cpmc.searchProgram,
                    type: arg,
                    size: 10,
                    publishable: true
                }
            };
            cpmc.loading = true;
            ProgramVersionService.loadVersionedPrograms(config)
                .then(function (response) {
                    if (arg === 'FORM') {

                        angular.forEach(response.content, function (res) {
                            res.select = false;
                        });
                        cpmc.formList = response;
                        if (cpmc.modalObject.editFlag) {
                            cpmc.dataPopulation();
                        }
                    } else if (arg === 'IVR CALL'){
                        angular.forEach(response.content, function (res) {
                            res.select = false;
                        });
                        cpmc.ivrList = response;
                        if (cpmc.modalObject.editFlag) {
                            cpmc.dataPopulation();
                        }
                    } else {
                        angular.forEach(response.content, function (res) {
                            res.select = false;
                        });
                        cpmc.multimediaList = response;
                        if (cpmc.modalObject.editFlag) {
                            cpmc.dataPopulation();
                        }
                    }
                })
                .catch(function (response) {
                    AlertService.error(response, "Search");
                })
                .finally(function () {
                    delete cpmc.loading;
                });
        }

        //edit day modal
        function editDayList() {
            cpmc.selectedMedia = [];
            cpmc.selectedFormPrograms = [];
            cpmc.selectedIvrPrograms = [];
            cpmc.editFlag = true;
            cpmc.day = cpmc.modalObject.editItem.day;
            cpmc.selectedFormType = cpmc.modalObject.editItem.formType; //it is referring to FORM modal state
            var media = cpmc.modalObject.editItem.media;
            var formPrograms = cpmc.modalObject.editItem.formPrograms;
            var ivrPrograms = cpmc.modalObject.editItem.ivrPrograms;
            cpmc.selectedMedia = media ? angular.copy(media) : [];
            cpmc.selectedFormPrograms = formPrograms ? angular.copy(formPrograms) : [];
            cpmc.selectedIvrPrograms = ivrPrograms ? angular.copy(ivrPrograms) : [];
            cpmc.selectedMultimediaList = media ? angular.copy(media) : [];
            cpmc.selectedFormProgramsList = formPrograms ? angular.copy(formPrograms) : [];
            cpmc.selectedIvrProgramsList = ivrPrograms ? angular.copy(ivrPrograms) : [];
        }

        //data pre population while editing day 
        function dataPopulation() {
                if (cpmc.formList && cpmc.formList.content && cpmc.formList.content.length !== 0) {
                    angular.forEach(cpmc.formList.content, function (res) {
                        angular.forEach(cpmc.selectedFormProgramsList, function (formProgram) {
                            if (res.entity.name === formProgram.entity.name) {
                                res.select = formProgram.select;
                            }
                        })
                    })
                }
                if (cpmc.ivrList && cpmc.ivrList.content && cpmc.ivrList.content.length !== 0) {
                    angular.forEach(cpmc.ivrList.content, function (res) {
                        if (cpmc.selectedIvrProgramsList && cpmc.selectedIvrProgramsList.length === 1) {
                            if (res.entity.name === cpmc.selectedIvrProgramsList[0].entity.name) {
                                res.select = true;
                                if (res.select) {
                                    cpmc.selectedIvrPrograms[0] = res;
                                    cpmc.selectedIvrProgramsList = cpmc.selectedIvrPrograms;
                                }
                            }
                        }
                    })
                }
            if (cpmc.multimediaList && cpmc.multimediaList.content && cpmc.multimediaList.content.length !== 0) {
                angular.forEach(cpmc.multimediaList.content, function (res) {
                    angular.forEach(cpmc.selectedMultimediaList, function (media) {
                        if (res.entity.name === media.entity.name) {
                            res.select = media.select;
                        }
                    })
                })
            }
        }

        //save&close on edit
        function saveAndClose($hide) {
            var regex = /^\d+$/;
            if (cpmc.day) {
                cpmc.selectedMultimediaList = cpmc.modalObject.editItem.media;
                cpmc.selectedFormProgramsList = cpmc.modalObject.editItem.formPrograms;
                cpmc.selectedIvrProgramsList = cpmc.modalObject.editItem.ivrPrograms;
                if (regex.test(cpmc.day)) {
                    if (cpmc.modalObject.editFlag && cpmc.day !== cpmc.modalObject.editItem.day) {
                        var index = utilService.findIndex(cpmc.modalObject.list, 'day', cpmc.day);
                        if (index) {
                            cpmc.dayErrorAlert('inuse');
                        } else {
                            cpmc.save($hide);
                        }
                    } else {
                        cpmc.save($hide);
                    }
                } else {
                    cpmc.dayError = true;
                    cpmc.dayErrorAlert('numbers');
                }
            } else {
                cpmc.dayError = true;
                cpmc.dayErrorAlert('req');
            }
        }

        // save the day and close modal
        function save($hide) {
            cpmc.addObject();
            cpmc.saveCallback({ final: cpmc.dayList });
            cpmc.modalObject = {};
            $hide();
            $('body').removeClass('modal-open');
        }

        //the selected data on modal will be passed on to the day array
        function addObject() {
            cpmc.dayList = [];
            var finalMedia = cpmc.selectedMultimediaList;
            var finalFormPrograms = cpmc.selectedFormProgramsList;
            var finalIvrPrograms = cpmc.selectedIvrProgramsList;
            if (cpmc.modalObject.list && cpmc.modalObject.list.length > 0) {
                if (!cpmc.modalObject.editFlag) {
                    var index = utilService.findIndex(cpmc.modalObject.list, 'day', cpmc.day);
                    if (index) {
                        cpmc.dayErrorAlert('inuse');
                    } else {
                            cpmc.dayList.push({ day: cpmc.day, formPrograms: finalFormPrograms, media: finalMedia, ivrPrograms: finalIvrPrograms, toggleFlag: true });
                    }
                } else if (cpmc.modalObject.editFlag) {
                    var mediaList = cpmc.selectedMedia && !cpmc.selectedMedia.length ? cpmc.modalObject.editItem.media : finalMedia;
                    var formProgramsList = cpmc.selectedFormPrograms && !cpmc.selectedFormPrograms.length ? cpmc.modalObject.editItem.formPrograms : finalFormPrograms;
                    var ivrProgramsList = cpmc.selectedIvrPrograms && cpmc.selectedIvrPrograms.length ? finalIvrPrograms : [];
                    formProgramsList = addOrder(formProgramsList);
                    //ivrProgramsList = addOrder(ivrProgramsList);
                    mediaList = addOrder(mediaList);
                    if (cpmc.day !== cpmc.modalObject.editItem.day) {
                        index = utilService.findIndex(cpmc.modalObject.list, 'day', cpmc.day);
                        if (index) {
                            cpmc.dayErrorAlert('inuse');
                        } else {
                            cpmc.dayList.push({ day: cpmc.day, formType: cpmc.selectedFormType, media: mediaList, formPrograms: formProgramsList, ivrPrograms: ivrProgramsList, toggleFlag: true });
                        }
                    } else {
                        cpmc.dayList.push({ day: cpmc.day, formType: cpmc.selectedFormType, media: mediaList, formPrograms: formProgramsList, ivrPrograms: ivrProgramsList, toggleFlag: true });
                    }
                }
            } else {
                cpmc.dayList.push({ day: cpmc.day, formType: cpmc.selectedFormType, media: finalMedia, formPrograms: finalFormPrograms, ivrPrograms: finalIvrPrograms, toggleFlag: true });
            }
        }

        //cancel
        function cancel(arg, $hide) {
            if (cpmc.modalObject.editFlag && cpmc.dayList && cpmc.dayList.length) cpmc.saveCallback({ final: cpmc.dayList });
            cpmc.modalObject = {};
            $hide();
        }

        //duplicate the day data with different day number
        function copyDay(list) {
            cpmc.copyData = list;
            cpmc.modalObject.dayFlag = true;
        }

        function addOrder (mediaList) {
            if (cpmc.selectedFormType !== '' && cpmc.selectedFormType !== undefined) {
                if (!cpmc.selectedFormType.entity.order){
                    cpmc.selectedFormType.entity.order = 1;
                }
            }
            angular.forEach(mediaList, function (media){
                if (!media.entity.order){
                    media.entity.order = 1;
                }
            });
            return mediaList;
        }
    }
})(window.angular);
