(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditGenderService', EditGenderService);

    EditGenderService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditGenderService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(genderService) {
            LinkConverter.convertResource(genderService);
            if (genderService && genderService.link.self) {
                return $http.put(genderService.link.self, genderService.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
