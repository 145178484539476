(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditAssetSetCountryService', EditAssetSetCountryService);

    EditAssetSetCountryService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditAssetSetCountryService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(assetsetcountryResource) {
            LinkConverter.convertResource(assetsetcountryResource);
            if (assetsetcountryResource && assetsetcountryResource.link.self) {
                return $http.put(assetsetcountryResource.link.self, assetsetcountryResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
