(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditDialectController', EditDialectController);

    EditDialectController.$inject = ['ProgramInitService', 'EditDialectService', 'savedDialectResource', 'saveCallback', 'errorCallback'];

    function EditDialectController(programInitService, EditDialectService, savedDialectResource, saveCallback, errorCallback) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedDialectResource);
        epc.data = programInitService.getDialects(true, true);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditDialectService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
