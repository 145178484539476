(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('programPublishVersionModal', ProgramPublishVersionModal);

    ProgramPublishVersionModal.$inject = ['$modal'];

    function ProgramPublishVersionModal($modal) {
        return {
            restrict: 'A',
            link: link,
            controller: function () {},
            controllerAs: 'ctrl',
            scope: {},
            bindToController: {
                emmiCode: '=',
                systemDisplayName: '@',
                systemName: '@',
                action: '&callbackFn',
                programType: '='
            }
        };

        function link(scope, element, attrs, ctrl) {
            element.on('click', function () {
                $modal({
                    templateUrl: 'app/program/modal/publish-version/publish-version.modal.html',
                    scope: scope,
                    controller: 'ProgramPublishVersionController',
                    controllerAs: 'pvc',
                    resolve: {
                        systemDisplayName: function () {
                            return ctrl.systemDisplayName;
                        },
                        systemName : function () {
                            return ctrl.systemName;
                        },
                        emmiCode: function () {
                            return ctrl.emmiCode;
                        },
                        callbackFn : function () {
                            return function () {
                                ctrl.action();
                            }
                        },
                        programType : function () {
                            return ctrl.programType;
                        }
                    }
                })
            });
        }
    }
})(window.angular);
