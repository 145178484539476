(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewSpecialtyController', NewSpecialtyController);

    NewSpecialtyController.$inject = ['NewSpecialtyService', 'ProgramInitService', '$scope'];

    function NewSpecialtyController(NewSpecialtyService, programInitService, $scope) {
        var npc = this;

        npc.save = save;
        npc.newSpecialty = NewSpecialtyService.newSpecialty();
        npc.data = programInitService.getSpecialties();

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewSpecialtyService.create(npc.newSpecialty).then(function (response) {
                    $scope.$emit('new-specialty', response);
                    npc.data.specialty.content.push(response);
                })
            }
        }
    }

})(window.angular);
