(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('SourcesController', SourcesController);

    SourcesController.$inject = ['SourceService'];

    function SourcesController(SourceService) {
        var Sources = this;

        (function onControllerLoad() {
            Sources.nextPage = nextPage;
            Sources.hasVisible = anyVisibleSources;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                Sources.loadingNextPage = true;
                SourceService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    Sources.loadingNextPage = false;
                } else {
                    Sources.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                Sources.loadingNextPage = true;
            }
        }

        function anyVisibleSources() {
            var hasVisible = false,
                length = Sources.sources && Sources.sources.content &&
                    Sources.sources.content.length;
            for (var i = 0; i < length; i++) {
                if (!Sources.sources.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            Sources.loading = true;
            SourceService.search()
                .then(function (response) {
                    Sources.sources = response;
                    //Sources.sources = JSON.stringify(response);
                })
                .finally(function () {
                    delete Sources.loadingNextPage;
                    delete Sources.loading;
                });
        }

    }
})(window.angular);
