(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('VersionedProgramPublishController', VersionedProgramPublishController);

    VersionedProgramPublishController.$inject = ['ProgramService', 'EditionsService', 'ProgramVersionService', 'ProgramPublisherService', 'AlertService', '$state', '$stateParams', 'UtilService'];

    function VersionedProgramPublishController(programService, editionsService, programVersionService, programPublisherService, alertService, $state, $stateParams, utilService) {
        var vppc = this;
        vppc.program = programService.getProgram();
        vppc.collapsedPublishHistory = false;
        vppc.confirm = {};
        var config = {
            params: {
                sort: 'published,desc',
                size: 5
            }
        };

        (function onControllerLoad() {
            vppc.nextProdPublishHistoryPage = nextProdPublishHistoryPage;
            vppc.hasProdPublishHistory = hasProdPublishHistory;

            vppc.nextTryEmmiPublishHistoryPage = nextTryEmmiPublishHistoryPage;
            vppc.hasTryEmmiPublishHistory = hasTryEmmiPublishHistory;

            vppc.getAssetSets = editionsService.getAssetSets;

            vppc.isCheckedOutByUser = isCheckedOutByUser;

            vppc.isActiveInProd = isActiveInProd;
            vppc.isActiveInTryEmmi = isActiveInTryEmmi;
            vppc.activateDeactivateProgram = activateDeactivateProgram;

            vppc.loadPublishHistory = loadPublishHistory;
            vppc.loadProdPublishHistory = loadProdPublishHistory;
            vppc.loadTryEmmiPublishHistory = loadTryEmmiPublishHistory;
        })();

        function isActiveInProd() {
            if(vppc.hasProdPublishHistory()) {
                var program = vppc.prodPublishHistory.content[0];
                return program.entity.active;
            }
            return false;
        }

        function isActiveInTryEmmi() {
            if(vppc.hasTryEmmiPublishHistory()) {
                var program = vppc.tryEmmiPublishHistory.content[0];
                return program.entity.active;
            }
            return false;
        }

        function loadProdPublishHistory() {
            vppc.loadingProdPublishHistory = true;
            programPublisherService.getPublishHistory($stateParams.emmiCode, 'Production', config)
                .then(function (response) {
                    programPublisherService.setProgramPublished(response.total > 0);
                    vppc.prodPublishHistory = response;
                })
                .catch(function (error) {
                    alertService.error(error, 'StartEmmi publish history');
                })
                .finally(function () {
                    delete vppc.loadingProdPublishHistory;
                });
        }

        function loadTryEmmiPublishHistory() {
            vppc.loadingTryEmmiPublishHistory = true;
            programPublisherService.getPublishHistory($stateParams.emmiCode, 'TryEmmi', config)
                .then(function (response) {
                    vppc.tryEmmiPublishHistory = response;
                })
                .catch(function (error) {
                    alertService.error(error, 'TryEmmi publish history');
                })
                .finally(function () {
                    delete vppc.loadingTryEmmiPublishHistory;
                })
        }

        function loadPublishHistory() {
            loadProdPublishHistory();
            loadTryEmmiPublishHistory();
        }

        function activateDeactivateProgram(systemName, activation) {
            if(isCheckedOutByUser()) {
                var programActivationRequest = {};
                programActivationRequest.emmiCode = vppc.program.entity.emmiCode;
                programActivationRequest.systemName = systemName;
                programActivationRequest.activation = activation;
                programActivationRequest.note = vppc.confirm.inputText;
                vppc.publishing = true;
                var actDeactMsg = 'Activation';
                if (activation == false) {
                    actDeactMsg = 'Deactivation';
                }
                programPublisherService.activateDeactivateProgram(programActivationRequest, config)
                    .then(function (response) {
                        loadPublishedProgram(systemName);
                        alertService.success(vppc.program.entity.name, actDeactMsg);
                    })
                    .catch(function (response) {
                        alertService.error(response, actDeactMsg);
                    })
                    .finally(function() {
                        delete vppc.publishing;
                        $hide();
                    });
            }
        }

        function loadPublishedProgram(systemName, program) {
            if(systemName === 'Production') {
                loadProdPublishHistory();
            } else {
                loadTryEmmiPublishHistory();
            }
        }

        function nextProdPublishHistoryPage(link) {
            if (vppc.prodPublishHistory && link === vppc.prodPublishHistory.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                vppc.loadingProdPublishHistory = true;
                utilService.fetchPage(link).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    vppc.loadingProdPublishHistory = false;
                    vppc.prodPublishHistory = response;
                } else {
                    vppc.loadingProdPublishHistory = true;
                }
            }

            function err() {
                // don't load any more pages
                vppc.loadingProdPublishHistory = true;
            }
        }

        function hasProdPublishHistory() {
            var hasVisible = false,
                length = vppc.prodPublishHistory && vppc.prodPublishHistory.content &&
                    vppc.prodPublishHistory.content.length;
            for (var i = 0; i < length; i++) {
                if (!vppc.prodPublishHistory.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function nextTryEmmiPublishHistoryPage(link) {
            if (vppc.tryEmmiPublishHistory && link === vppc.tryEmmiPublishHistory.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                vppc.loadingTryEmmiPublishHistory = true;
                utilService.fetchPage(link).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    vppc.loadingTryEmmiPublishHistory = false;
                    vppc.tryEmmiPublishHistory = response;
                } else {
                    vppc.loadingTryEmmiPublishHistory = true;
                }
            }

            function err() {
                // don't load any more pages
                vppc.loadingTryEmmiPublishHistory = true;
            }
        }

        function hasTryEmmiPublishHistory() {
            var hasVisible = false,
                length = vppc.tryEmmiPublishHistory && vppc.tryEmmiPublishHistory.content &&
                    vppc.tryEmmiPublishHistory.content.length;
            for (var i = 0; i < length; i++) {
                if (!vppc.tryEmmiPublishHistory.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function isCheckedOutByUser() {
            return programVersionService.isCheckedOutByUser();
        }
    }
})(window.angular);
