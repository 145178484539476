(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('ProgramInitService', ProgramInitService);

    ProgramInitService.$inject = [
        'ProgramTypeService',
        'LanguageService',
        'PerspectiveService',
        'DialectService',
        'NarratorService',
        'CountryService',
        'AssetSetService',
        'BrandService',
        'ProductLineService',
        'SpecialtyService',
        'ContentSubscriptionService',
        'WorkflowSubscriptionService',
        'ConfigService',
        'SourceService',
        'SystemService',
        'PaginationService'
    ];

    function ProgramInitService(programTypeService,
                                languageService,
                                perspectiveService,
                                dialectService,
                                narratorService,
                                countryService,
                                assetSetService,
                                brandService,
                                productLineService,
                                specialtyService,
                                contentSubscriptionService,
                                workflowSubscriptionService,
                                configService,
                                sourceService,
                                systemService,
                                paginationService) {

        var initializer = this;
        var active = true;
        initializer.data = {
            brands: [],
            sources: [],
            specialties: {},
            productLines: [],
            workflowSubscriptions: [],
            assetSets: [],
            assetSetCountries: [],
            countries: [],
            dialects: [],
            narrators: [],
            perspectives: [],
            languages: [],
            programTypes: [],
            configs: [],
            systems: []
        };

        return {
            getProgramData: getProgramData,
            loadProgramData: loadProgramData,
            loadingProgramData: loadingProgramData,

            getBrands: getBrands,
            getSources: getSources,
            getSpecialties: getSpecialties,
            getProductLines: getProductLines,
            getWorkflowSubscriptions: getWorkflowSubscriptions,
            getContentSubscriptions: getContentSubscriptions,
            getAssetSets: getAssetSets,
            getTypeAssetSets: getTypeAssetSets,
            getAssetSetCountries: getAssetSetCountries,
            getProgramTypes: getProgramTypes,
            getCountries: getCountries,
            getNarrators: getNarrators,
            getDialects: getDialects,
            getPerspectives: getPerspectives,
            getLanguages: getLanguages,
            getConfigs: getConfigs,
            getSystems: getSystems
        };

        function paginateResponse(data) {
            var parsedResponse = paginationService.handleResponse(data);
            if (!parsedResponse.content) {
                parsedResponse.content = [];
            }
            return parsedResponse;
        }

        function loadProgramData() {
            getBrands();
            getSources();
            getSpecialties();
            getProductLines();
            getWorkflowSubscriptions(false, false); //do not load associations for programs
            getContentSubscriptions(false, false);
            getAssetSets(false, false);
        }

        function getProgramData() {
            return initializer.data;
        }

        function loadingProgramData() {
            return initializer.loadingBrands ||
                initializer.loadingSources ||
                initializer.loadingSpecialties ||
                initializer.loadingProductLines ||
                initializer.loadingWorkflowSubscriptions ||
                initializer.loadingContentSubscriptions ||
                initializer.loadingAssetSets ||
                initializer.loadingCountries ||
                initializer.loadingProgramTypes ||
                initializer.loadingNarrators ||
                initializer.loadingLanguages ||
                initializer.loadingPerspectives ||
                initializer.loadingDialects ||
                initializer.loadingConfigs ||
                initializer.loadingSystems;
        }

        function getBrands() {
            initializer.loadingBrands = true;
            brandService.search(active)
                .then(function (data) {
                    initializer.data.brands = data;
                })
                .finally(function () {
                    delete initializer.loadingBrands;
                });
            return initializer.data;
        }

        function getSources() {
            initializer.loadingSources = true;
            sourceService.search(active)
                .then(function (data) {
                    initializer.data.sources = data;
                })
                .finally(function () {
                    delete initializer.loadingSources;
                });
            return initializer.data;
        }

        function getSpecialties() {
            initializer.loadingSpecialties = true;
            specialtyService.search(active)
                .then(function (data) {
                    initializer.data.specialties = data;
                })
                .finally(function () {
                    delete initializer.loadingSpecialties;
                });
            return initializer.data;
        }

        function getProductLines() {
            initializer.loadingProductLines = true;
            productLineService.search(active)
                .then(function (data) {
                    initializer.data.productLines = data;
                })
                .finally(function () {
                    delete initializer.loadingProductLines;
                });
            return initializer.data;
        }

        function loadWorkflowSubscriptions() {
            initializer.loadingWorkflowSubscriptions = true;
            workflowSubscriptionService.search(active)
                .then(function (data) {
                    initializer.data.workflowSubscriptions = data;
                })
                .finally(function () {
                    delete initializer.loadingWorkflowSubscriptions;
                });
        }

        function getWorkflowSubscriptions(getAssociations, isEdit) {
            if(!isEdit) {
                loadWorkflowSubscriptions();
            }
            if(getAssociations) {
                getConfigs();
            }
            return initializer.data;
        }

        function getContentSubscriptions(getAssociations, isEdit) {
            if(!isEdit) {
                loadContentSubscriptions();
            }
            if(getAssociations) {
                getConfigs();
            }
            return initializer.data;
        }

        function loadContentSubscriptions() {
            initializer.loadingContentSubscriptions = true;
            contentSubscriptionService.search(active)
                .then(function (data) {
                    initializer.data.contentSubscriptions = data;
                })
                .finally(function () {
                    delete initializer.loadingContentSubscriptions;
                });
            return initializer.data;
        }

        function loadAssetSets() {
            initializer.loadingAssetSets = true;
            assetSetService.search()
                .then(function (data) {
                    initializer.data.assetSets = data;
                    initializer.data.assetSets.content.forEach(function (assetSet, index, array) {
                        array[index].entity.summary = assetSetService.getSummary(assetSet.entity);
                    });
                })
                .finally(function () {
                    delete initializer.loadingAssetSets;
                });
            return initializer.data;
        }

        function getAssetSets(getAssociations, isEdit) {
            if(!isEdit) {
                loadAssetSets();
            }
            if(getAssociations) {
                getDialects(!getAssociations, !isEdit);
                getNarrators();
                getPerspectives();
            }
            return initializer.data;
        }

        function getAssetSetCountries(getAssociations, isEdit) {
            if(getAssociations) {
                getAssetSets(!getAssociations, !isEdit);
                getCountries();
            }
            return initializer.data;
        }

        function getTypeAssetSets(getAssociations, isEdit) {
            if(getAssociations) {
                getAssetSets(!getAssociations, !isEdit);
                getProgramTypes();
            }
            return initializer.data;
        }

        function getProgramTypes() {
            initializer.loadingProgramTypes = true;
            programTypeService.search()
                .then(function (data) {
                    initializer.data.programTypes = data;
                })
                .finally(function () {
                    delete initializer.loadingProgramTypes;
                });
            return initializer.data;
        }

        function getCountries() {
            initializer.loadingCountries = true;
            countryService.search()
                .then(function (data) {
                    initializer.data.countries = data;
                })
                .finally(function () {
                    delete initializer.loadingCountries;
                });
            return initializer.data;
        }

        function getNarrators() {
            initializer.loadingNarrators = true;
            narratorService.search()
                .then(function (data) {
                    initializer.data.narrators = data;
                })
                .finally(function () {
                    delete initializer.loadingNarrators;
                });
            return initializer.data;
        }

        function loadDialects() {
            initializer.loadingDialects = true;
            dialectService.search()
                .then(function (data) {
                    initializer.data.dialects = data;
                })
                .finally(function () {
                    delete initializer.loadingDialects;
                });
            return initializer.data;
        }

        function getDialects(getAssociations, isEdit) {
            if(!isEdit) {
                loadDialects();
            }
            if(getAssociations) {
                getLanguages();
            }
            return initializer.data;
        }

        function getPerspectives() {
            initializer.loadingPerspectives = true;
            perspectiveService.search()
                .then(function (data) {
                    initializer.data.perspectives = data;
                })
                .finally(function () {
                    delete initializer.loadingPerspectives;
                });
            return initializer.data;
        }

        function getLanguages() {
            initializer.loadingLanguages = true;
            languageService.search()
                .then(function (data) {
                    initializer.data.languages = data;
                })
                .finally(function () {
                    delete initializer.loadingLanguages;
                });
            return initializer.data;
        }

        function getConfigs() {
            initializer.loadingConfigs = true;
            configService.search()
                .then(function (data) {
                    initializer.data.configs = data;
                })
                .finally(function () {
                    delete initializer.loadingConfigs;
                });
            return initializer.data;
        }

        function getSystems() {
            initializer.loadingSystems = true;
            systemService.search()
                .then(function (data) {
                    initializer.data.systems = data;
                })
                .finally(function () {
                    delete initializer.loadingSystems;
                });
            return initializer.data;
        }
    }
})(window.angular);
