(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewNarratorController', NewNarratorController);

    NewNarratorController.$inject = ['NewNarratorService', 'GenderService', 'ProgramInitService', '$scope'];

    function NewNarratorController(NewNarratorService, GenderService, programInitService, $scope) {
        var npc = this;

        npc.save = save;
        npc.newNarrator = NewNarratorService.newNarrator();
        npc.data = programInitService.getNarrators();

        (function onControllerLoad() {
            GenderService.list(npc.data);
        })();

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewNarratorService.create(npc.newNarrator).then(function (response) {
                    $scope.$emit('new-narrator', response);
                    npc.data.narrator.content.push(response);
                })
            }
        }
    }

})(window.angular);
