(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope', 'Principal', '$state', '$sessionStorage'];

    function HomeController($scope, Principal, $state, $sessionStorage) {
        var hc = this;

        hc.collapseLocal = false;

        hc.username = null;
        hc.authenticated = null;
        hc.register = register;
        hc.firstname = firstname;

        function firstname() {
            if(hc.username) {
                return hc.username.substr(0, hc.username.indexOf('.'));
            }
            return '';
        }

        $scope.$on('authenticationSuccess', function () {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function (account) {
                var username = account.entity.username;
                $sessionStorage.userName = username;
                hc.username = username;
                hc.authenticated = Principal.isAuthenticated();
            });
        }

        function register() {
            $state.go('register');
        }
    }
})(window.angular);
