(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('hateoasInterceptor', hateoasInterceptor)
        .factory('LinkConverter', convertLinks)
    ;

    hateoasInterceptor.$inject = ['$q'];

    function arrayToObject(keyItem, valueItem, array) {
        var obj = {};
        angular.forEach(array, function (item) {
            if (item[keyItem] && item[valueItem]) {
                obj[item[keyItem]] = item[valueItem];
            }
        });
        return obj;
    }

    function convertLinks() {
        return {
            convertResource: convertResource
        };

        function convertResource(resource) {
            if (resource && resource.link && angular.isArray(resource.link)) {
                resource.link = arrayToObject('rel', 'href', resource.link)
            }
        }
    }

    function hateoasInterceptor($q) {

        return {
            response: response
        };

        function response(response) {
            if (response && angular.isObject(response.data)) {
                var data = response.data;
                if (data.link) {
                    response.data.linkList = data.link;
                    response.data.link = arrayToObject('rel', 'href', data.link);
                }
            }
            return response || $q.when(response);
        }

    }

})(window.angular);
