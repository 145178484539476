(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('GenderController', GenderController);

    GenderController.$inject = ['GenderService'];

    function GenderController(GenderService) {
        var GenderCtrl = this;

        (function onControllerLoad() {
            GenderCtrl.nextPage = nextPage;
            GenderCtrl.hasVisible = anyVisibleAssetSet;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                GenderCtrl.loadingNextPage = true;
                GenderService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    GenderCtrl.loadingNextPage = false;
                } else {
                    GenderCtrl.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                GenderCtrl.loadingNextPage = true;
            }
        }

        function anyVisibleAssetSet() {
            var hasVisible = false,
                length = GenderCtrl.genders && GenderCtrl.genders.content &&
                    GenderCtrl.genders.content.length;
            for (var i = 0; i < length; i++) {
                if (!GenderCtrl.genders.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            GenderCtrl.loading = true;
            GenderService.search()
                .then(function (response) {
                    GenderCtrl.genders = response;
                })
                .finally(function () {
                    delete GenderCtrl.loadingNextPage;
                    delete GenderCtrl.loading;
                });
        }

    }
})(window.angular);
