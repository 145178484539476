(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .config(loadingBarConfig);

    loadingBarConfig.$inject = ['cfpLoadingBarProvider'];

    function loadingBarConfig(cfpLoadingBarProvider) {
        cfpLoadingBarProvider.includeSpinner = false;
    }

})(window.angular);
