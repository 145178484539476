(function(angular) {
    'use strict';

    angular
        .module('Composer')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('program-type', {
                parent: 'app',
                url: '/program-type',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/program-type/list/program-type.html',
                        controller: 'ProgramTypeController',
                        controllerAs: 'ProgramTypeCtrl'
                    }
                }
            })
            .state('url-type', {
                parent: 'app',
                url: '/url-types',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/url-type/list/url-type.html',
                        controller: 'UrlTypeController',
                        controllerAs: 'UrlTypeCtrl'
                    }
                }
            })
            .state('perspective', {
                parent: 'app',
                url: '/perspectives',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/perspective/list/list.perspectives.html',
                        controller: 'PerspectiveController',
                        controllerAs: 'PerspectivesCtrl'
                    }
                }
            })
            .state('source', {
                parent: 'app',
                url: '/sources',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/source/list/list.sources.html',
                        controller: 'SourcesController',
                        controllerAs: 'SourcesCtrl'
                    }
                }
            })
            .state('typeassetset', {
                parent: 'app',
                url: '/typeassetsets',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/typeassetset/list/list.typeassetset.html',
                        controller: 'TypeAssetSetController',
                        controllerAs: 'TypeAssetSetCtrl'
                    }
                }
            })
            .state('gender', {
                parent: 'app',
                url: '/gender',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/gender/list/gender.html',
                        controller: 'GenderController',
                        controllerAs: 'GenderCtrl'
                    }
                }
            })
            .state('language', {
                parent: 'app',
                url: '/languages',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/language/list/language.html',
                        controller: 'LanguageController',
                        controllerAs: 'LanguageCtrl'
                    }
                }
            })
            .state('narrator', {
                parent: 'app',
                url: '/narrator',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/narrator/list/narrator.html',
                        controller: 'NarratorController',
                        controllerAs: 'NarratorCtrl'
                    }
                }
            })
            .state('config', {
                parent: 'app',
                url: '/config',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/config/list/list.config.html',
                        controller: 'ConfigController',
                        controllerAs: 'ConfigCtrl'
                    }
                }
            })
            .state('dialect', {
                parent: 'app',
                url: '/dialects',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/dialect/list/list.dialects.html',
                        controller: 'DialectsController',
                        controllerAs: 'Dialects'
                    }
                }
            })
            .state('global-modules', {
                parent: 'app',
                url: '/global-modules',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/global-modules/list/list.global-modules.html',
                        controller: 'GlobalModulesController',
                        controllerAs: 'globalModules'
                    }
                }
            })
            .state('edit-global-module', {
                parent: 'app',
                url: '/global-module/:type/:gmName',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/global-modules/edit/edit.global-module.html',
                        controller: 'EditGlobalModuleController',
                        controllerAs: 'egm'
                    }
                }
            })
            .state('new-global-module', {
                parent: 'app',
                url: '/global-module/:type',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/global-modules/edit/edit.global-module.html',
                        controller: 'EditGlobalModuleController',
                        controllerAs: 'egm'
                    }
                }
            })
            .state('assetset', {
                parent: 'app',
                url: '/assetset',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/assetset/list/list.assetsets.html',
                        controller: 'AssetSetsController',
                        controllerAs: 'AssetSets'
                    }
                }
            })
            .state('assetset-country', {
                parent: 'app',
                url: '/assetset-country',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/assetset-country/list/list.assetset-country.html',
                        controller: 'AssetSetCountryController',
                        controllerAs: 'AssetSetCountry'
                    }
                }
            })
            .state('country', {
                parent: 'app',
                url: '/country',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/country/list/list.country.html',
                        controller: 'CountryController',
                        controllerAs: 'Country'
                    }
                }
            })
            .state('brand', {
                parent: 'app',
                url: '/brands',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/brands/list/list.brands.html',
                        controller: 'BrandsController',
                        controllerAs: 'Brands'
                    }
                }
            })
            .state('product-line', {
                parent: 'app',
                url: '/product-line',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/product-line/list/product-line.html',
                        controller: 'ProductLineController',
                        controllerAs: 'ProductLineCtrl'
                    }
                }
            })
            .state('specialty', {
                parent: 'app',
                url: '/specialty',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/specialty/list/specialty.html',
                        controller: 'SpecialtyController',
                        controllerAs: 'SpecialtyCtrl'
                    }
                }
            })
            .state('content-subscription', {
                parent: 'app',
                url: '/content-subscription',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/content-subscription/list/content-subscription.html',
                        controller: 'ContentSubscriptionController',
                        controllerAs: 'ContentSubscriptionCtrl'
                    }
                }
            })
            .state('workflow-subscription', {
                parent: 'app',
                url: '/workflow-subscription',
                data: {
                    authorities: ['ROLE_LINKS_USER']
                },
                views: {
                    'content@': {
                        templateUrl: 'app/reference/workflow-subscription/list/workflow-subscription.html',
                        controller: 'WorkflowSubscriptionController',
                        controllerAs: 'WorkflowSubscriptionCtrl'
                    }
                }
            })
            .state('system', {
                parent: 'app',
                url: '/systems',
                data: {
                  authorities: ['ROLE_LINKS_USER']
                },
                views: {
                  'content@': {
                    templateUrl: 'app/reference/system/list/list.systems.html',
                    controller: 'SystemsController',
                    controllerAs: 'SystemsCtrl'
                  }
                }
            });
    }
})(window.angular);
