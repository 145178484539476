/**
 * Created by hzhang on 3/10/2017.
 */
(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('radioSelect', radioSelectModal);

    radioSelectModal.$inject = ['$modal'];

    function radioSelectModal($modal) {

        return {
            restrict: 'A',
            link: link,
            controller: function () {
                var ctrl = this;
                ctrl.clearSelected = function () {
                    if(ctrl.fullList) {
                        angular.forEach(ctrl.fullList.content, function (item) {
                            if (item) {
                                item.select = undefined;
                            }
                        })
                    }
                }
            },
            controllerAs: 'ctrl',
            scope: {},
            bindToController: {
                fullList: '=',
                item: '=',
                title: '@title',
                programSelection: '@programSelection',
                searchCallback: '&',
                saveCallback: '&',
                searchEntry: '=',
                enableSearch: '='
            }
        };

        function link(scope, element, attrs, ctrl) {

            var modal = {};

            element.on('click', function (event) {
                modal = $modal({
                    backdrop: 'static',
                    keyboard: true,
                    templateUrl: 'app/modal/radio/radio.items.modal.html',
                    scope: scope,
                    controller: 'RadioSelectController',
                    controllerAs: 'sic',
                    resolve: {
                        fullList : function () {
                            return ctrl.fullList;
                        },
                        selectedItem: function () {
                            if(!ctrl.item) {
                                ctrl.item = {};
                            }
                            return ctrl.item;
                        },
                        searchEntry: function() {
                            return ctrl.searchEntry;
                        },
                        title: function () {
                            return ctrl.title;
                        },
                        enableSearch: function () {
                            return ctrl.enableSearch;
                        },
                        programSelection: function () {
                            return ctrl.programSelection;
                        },
                        searchCallback: function () {
                            return ctrl.searchCallback;
                        },
                        saveCallback: function () {
                            return ctrl.saveCallback;
                        }
                    }
                })
            });

            element.on('$destroy', function(){
                ctrl.clearSelected();
                $('body').removeClass('modal-open');
                if(modal.hide !== undefined) {
                    modal.hide();
                }
            });
        }
    }
})(window.angular);
