(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('DynaviewsController', DynaviewsController);

    DynaviewsController.$inject = ['$stateParams', 'DynaviewService'];

    function DynaviewsController($stateParams, DynaviewService) {
        var Dynaviews = this;
		Dynaviews.params = $stateParams;
		Dynaviews.orderedData = [];

        (function onControllerLoad() {

            search();
        })();

        
        function search() {

			if(Dynaviews.params) {
				if(Dynaviews.params.viewId) 
					Dynaviews.viewId = Dynaviews.params.viewId;
				if(Dynaviews.params.entryId) 
					Dynaviews.entryId = Dynaviews.params.entryId;
			} 
			
			Dynaviews.resProvider = getResProviderOf(Dynaviews.viewId);
			Dynaviews.theResource = getResourceOf(Dynaviews.resProvider.resourceList, Dynaviews.entryId);
			Dynaviews.entityDef = getEntityDefOf(Dynaviews.theResource.returnEntity);
			
			
			DynaviewService.getResourceData(Dynaviews.theResource.uri)
				.then(function (response) {
                    Dynaviews.theData = response.data;
					Dynaviews.linksDisplay = createPagedLinks(Dynaviews.theData);
					Dynaviews.dataInJson = JSON.stringify(Dynaviews.theData);
                });
				
        }
		
		function createPagedLinks(theData) {
			var links = [];
			if(theData.totalPages <= 1)
				return links;
			
			var orderNbr = 0;
			
			if(theData.first == false) {
				var uri = theData.number - 1;
				var linkname = "Prev";
				var linkClass = 'previous';
				
				links.push({
					order: orderNbr, 
					name: linkname, 
					href: uri, 
					className: linkClass
				});
				orderNbr++;
			}
			
			if(theData.totalPages > 2) {
				for(var i=0; i<theData.totalPages; i++) {
					var uri = i;
					var linkname = "" + i;
					var linkClass = '';
					
					links.push({
						order: orderNbr, 
						name: linkname, 
						href: uri, 
						className: linkClass
					});
					orderNbr++;
				}
			}
			
			if(theData.last == false) {
				var uri = theData.number + 1;
				var linkname = "Next";
				var linkClass = 'next';
				
				links.push({
					order: orderNbr, 
					name: linkname, 
					href: uri, 
					className: linkClass
				});
			}
			
			return links;
		}
		
		Dynaviews.toNextPage = function (pageNumber) {
			
			var uri = Dynaviews.theResource.uri + "?size=" + Dynaviews.theData.size;
			uri += "&page=" + pageNumber;
			
			console.log('next going to uri = ' + uri);
			
			if(pageNumber == Dynaviews.theData.number) {
				console.log('same page. no load');
				return;
			}
			
			DynaviewService.getResourceData(uri)
				.then(function (response) {
                    Dynaviews.theData = response.data;
					Dynaviews.linksDisplay = createPagedLinks();
                });
		};
		
		
		Dynaviews.typeForDisp = function (ind) {
			var endef = Dynaviews.entityDef.members[ind];
			
			var fieldType = endef.typeName;
			if(fieldType.length > 0 && fieldType.lastIndexOf('.') > 0) {
				var ldotind = fieldType.lastIndexOf('.');
				fieldType = fieldType.substring(ldotind + 1);
			}
			
			var rettype = '';
			if(fieldType == 'Timestamp')
				rettype = fieldType;
			return rettype;
		};
		
		Dynaviews.reorderData = function(rowdata) {
			//var str = '';
			//Dynaviews.orderedData = [];
			rowdata.orderedData = [];
			for(var i=0; i<Dynaviews.entityDef.members.length; i++) {
				var name = Dynaviews.entityDef.members[i].name;
				var value = rowdata[name];
				rowdata.orderedData.push(value);
				
				//Dynaviews.orderedData[i] = value;
				//str += name +':' + rowdata[name] + ';';
			}
		};
		
		Dynaviews.formatedData = function (fieldType, fieldData) {
			var ret = fieldData;
			
			if(fieldType.length > 0 && fieldType.lastIndexOf('.') > 0) {
				var ldotind = fieldType.lastIndexOf('.');
				fieldType = fieldType.substring(ldotind + 1);
			}
			
			if(fieldType == 'Timestamp') {
				var dttm = fieldData.split('T');
				var dt = dttm[0];
				var tm = dttm[1];
				var dtarr = dt.split('-');
				var tmarr = tm.split(':');
				ret = dtarr[1] + '/' + dtarr[2] + '/' + dtarr[0] + ' ' + tmarr[0] + ':' + tmarr[1];
			}
			
			return ret;
		}
		
    }
})(window.angular);
