(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('initializeCampaignModal', InitializeCampaignModal);

    InitializeCampaignModal.$inject = ['$modal'];

    function InitializeCampaignModal($modal) {
        return {
            restrict: 'A',
            link: link,
            controller: function () {},
            controllerAs: 'ctrl',
            scope: {},
            bindToController: {
                emmiCode: '=',
                action: '&callbackFn',
                campaignsent: '='
            }
        };

        function link(scope, element, attrs, ctrl) {
            element.on('click', function () {
                $modal({
                    templateUrl: 'app/program/modal/initialize-campaign/initialize-campaign.modal.html',
                    scope: scope,
                    controller: 'InitializeCampaignController',
                    controllerAs: 'icc',
                    resolve: {
                        emmiCode: function () {
                            return ctrl.emmiCode;
                        },
                        callbackFn : function () {
                            return function () {
                                ctrl.action();
                            }
                        }
                    }
                })
            });
        }
    }
})(window.angular);
