(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditDynaviewService', EditDynaviewService);

    EditDynaviewService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditDynaviewService($http, LinkConverter, $q) {

        return {
            save: save, 
			newDataResource: newDataResource
        };

        function save(theLinkUri, dataResource) {
			
			return $http.post(theLinkUri, dataResource).then(function (response) {
				return response.data;
			});
			
        }

    }
	
	function newDataResource() {
        return {
            name: ''
        }
    }
	
})(window.angular);
