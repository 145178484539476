(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditWorkflowSubscriptionService', EditWorkflowSubscriptionService);

    EditWorkflowSubscriptionService.$inject = ['$http', 'LinkConverter', '$q', 'ProgramService'];

    function EditWorkflowSubscriptionService($http, LinkConverter, $q, programService) {

        return {
            update: update,
            findProgramByEmmiCode: findProgramByEmmiCode
        };

        function update(workflowSubscriptionResource) {
            LinkConverter.convertResource(workflowSubscriptionResource);
            if (workflowSubscriptionResource && workflowSubscriptionResource.link.self) {
                return $http.put(workflowSubscriptionResource.link.self, workflowSubscriptionResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

        function findProgramByEmmiCode(emmiCode) {
            return programService.loadProgramByEmmiCode(emmiCode);
        }

    }
})(window.angular);
