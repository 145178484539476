(function (angular) {
    'use strict';

    // load the initial web-api
    var initInjector = angular.injector(['ng']),
        $http = initInjector.get('$http'),
        $log = initInjector.get('$log'),
        $window = initInjector.get('$window');
		
	//angular.module('Composer.api', []).constant('ALL_Resource','My Test App!');

    $http.get('/webapi')
        .success(function (data) {
            // transform link array in to link object
            var links = {};
            angular.forEach(data.link, function (item) {
                if (item.rel && item.href) {
                    links[item.rel] = item.href;
                }
            });
            data.links = links;

            // save as a constant
            angular.module('Composer.api', []).constant('API', data);

            // load the main application
            angular.element(document).ready(function () {
                angular.bootstrap(document, ['Composer']);
            });
        })
        .error(function (data, response) {
            $window.location.href = '/maintenance.html';
            $log.debug('Backend Down: ' + data + ' | ' + response);
        });

})(window.angular);
