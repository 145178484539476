(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('ReportingFlagService', ReportingFlagService);


    function ReportingFlagService() {

        var reportingFlags;
        var voiceFlowId;
        var voiceFlowVersionId;

        return {
            getReportingFlags: getReportingFlags,
            setReportingFlags: setReportingFlags,
            getVoiceFlowId : getVoiceFlowId,
            setVoiceFlowId : setVoiceFlowId,
            getVoiceFlowVersionId : getVoiceFlowVersionId,
            setVoiceFlowVersionId : setVoiceFlowVersionId
        };

        function getReportingFlags() {
            return reportingFlags;
        }

        function setReportingFlags(value) {
            reportingFlags = value;
        }
        function getVoiceFlowId() {
            return voiceFlowId;
        }

        function setVoiceFlowId(value) {
            voiceFlowId = value;
        }

        function getVoiceFlowVersionId() {
            return voiceFlowVersionId;
        }

        function setVoiceFlowVersionId(value) {
            voiceFlowVersionId = value;
        }

    }
})(window.angular);
