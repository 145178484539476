(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewPerspectiveService', NewPerspectiveService);

    NewPerspectiveService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewPerspectiveService($http, Principal, UriTemplate) {

        return {
            create: create,
            newPerspective: newPerspective
        };

        function create(newPerspective) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.perspectives).stringify(),
                    newPerspective).then(function (response) {
                    return response.data;
                });
            })
        }

        function newPerspective() {
            return {
                name: ''
            }
        }

    }
})(window.angular);
