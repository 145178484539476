(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('ProgramCampaignService', ProgramCampaignService);

    ProgramCampaignService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function ProgramCampaignService($http, Principal, UriTemplate) {

        var campaignFields = {
            custom6: '',
            custom7: ''
        }

        var columnFields = {
            custom6: '',
            custom7:'',
            useDoctorName:false,
            useTransfer:''
        };

        var campaign = {
            emmiCode: 0,
            campaignFields: campaignFields,
            columnFields: columnFields
        }

        var isLoading = false;
        var campaignInitialized = false;

        var cacustom6 = [
            { label: 'No Custom 6', value: ""},
            { label:'Website Flag', value: 3},
            { label: 'Multi Transfer List', value: 4},
            { label: 'Patient VM Flag', value: 5},
            { label: 'Locations', value: 6},
            { label: 'Intent Flag', value: 7}
        ];

        var cacustom7 = [
            { label: 'No Custom 7', value: ""},
            { label:'Website Flag', value: 3},
            { label: 'Multi Transfer List', value: 4},
            { label: 'Patient VM Flag', value: 5},
            { label: 'Locations', value: 6},
            { label: 'Intent Flag', value: 7}
        ];

        var cocustom6 = [
            { label: 'No Custom 6', value: ""},
            { label:'Eye Doc Number', value: 8},
            { label: 'Date Time', value: 9},
            { label: 'Location', value: 10},
            { label: 'Missing Tests (a1c/kidney/cholesterol/eye)', value: 11},
            { label: 'DOB', value: 12},
            { label: 'Missing Tests (a1c/kidney/cholesterol/visit)', value: 13},
            { label: 'Callback Number', value: 17},
            { label: 'Clinic Phone Number', value: 18},
            { label: 'Readback Number', value: 19},
            { label: 'Schedule Transfer List', value: 20},
            { label: 'Scheduling Phone Number', value: 21},
            { label: 'Confirm DOB', value: 22},
            { label: 'Email', value: 25}
        ];

        var cocustom7 = [
            { label: 'No Custom 7', value: ""},
            { label:'Eye Doc Number', value: 8},
            { label: 'Date Time', value: 9},
            { label: 'Location', value: 10},
            { label: 'Missing Tests (a1c/kidney/cholesterol/eye)', value: 11},
            { label: 'DOB', value: 12},
            { label: 'Missing Tests (a1c/kidney/cholesterol/visit)', value: 13},
            { label: 'Callback Number', value: 17},
            { label: 'Clinic Phone Number', value: 18},
            { label: 'Readback Number', value: 19},
            { label: 'Schedule Transfer List', value: 20},
            { label: 'Scheduling Phone Number', value: 21},
            { label: 'Confirm DOB', value: 22},
            { label: 'Email', value: 25}
        ];

        var useTransfer = [
            { label: 'Do Not Use Transfer', value: ""},
            { label:'14 - If applicable, 10-digit...', value: 14},
            { label: '15 - 10-digit...', value: 15}
        ];

        var data = {
            cacustom6: cacustom6,
            cacustom7: cacustom7,
            cocustom6: cocustom6,
            cocustom7: cocustom7,
            useTransfer: useTransfer
        }

        return {
            updateCampaign: updateCampaign,
            getCampaign: getCampaign,
            loadCampaign: loadCampaign,
            isLoading: isLoading,
            isCampaignInitialized: isCampaignInitialized,
            getData: getData,
            getLabelByValue: getLabelByValue
        };

        function getLabelByValue(arr, val) {
            for (var i=0; i < arr.length; i++) {
                if (arr[i].value === val) {
                    return arr[i].label;
                }
            }
        }

        function getData() {
            return data;
        }

        function isCampaignInitialized() {
            return campaignInitialized;
        }

        function updateCampaign(data) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.content + '/prevent-campaign')
                    .stringify({
                        emmiCode: data.emmiCode
                    }), data
                ).then(function (response) {
                    campaign.campaignFields = data.campaignFields;
                    campaign.columnFields = data.columnFields;
                    return response;
                });
            })
        }

        function getCampaign() {
            return campaign;
        }

        function reset() {
            campaign.campaignFields.custom6 = '';
            campaign.campaignFields.custom7 = ''

            campaign.columnFields.custom6 = '';
            campaign.columnFields.custom7 = '';
            campaign.columnFields.useDoctorName = false;
            campaign.columnFields.useTransfer = '';
        }

        function loadCampaign(emmiCode) {
            campaign.emmiCode = emmiCode;
            isLoading = true;
            reset();
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.content + '/prevent-campaign/{emmiCode}')
                    .stringify({emmiCode: emmiCode})
                ).then(function (response) {
                    var campaignData = response.data.campaignFields;
                    if(campaignData) {
                        campaign.campaignFields.custom6 = campaignData.custom6 ? campaignData.custom6 : '';
                        campaign.campaignFields.custom7 = campaignData.custom7 ? campaignData.custom7 : '';
                    }
                    var columnData = response.data.columnFields;
                    if(columnData) {
                        campaign.columnFields.custom6 = columnData.custom6 ? columnData.custom6 : '';
                        campaign.columnFields.custom7 = columnData.custom7 ? columnData.custom7 : '';
                        campaign.columnFields.useDoctorName = columnData.useDoctorName;
                        campaign.columnFields.useTransfer = columnData.useTransfer ? columnData.useTransfer : '';
                        if(columnData.custom6 >= 0 || campaignData.custom6 >= 0) {
                            campaignInitialized = true;
                        } else {
                            campaignInitialized = false;
                        }
                    }
                    return campaign;
                }).finally(function () {
                    isLoading = false;
                });
            })
        }
    }
})(window.angular);
