(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('addNewPerspectiveModal', addNewPerspectiveModal);

    addNewPerspectiveModal.$inject = ['$modal', '$alert'];

    function addNewPerspectiveModal($modal, $alert) {

        return {
            restrict: 'A',
            link: link,
            scope: {
                fullList: '='
            }
        };

        function link(scope, element) {
            element.on('click', function () {
                $modal({
                    templateUrl: 'app/reference/perspective/new/new.perspective.modal.html',
                    scope: scope
                })
            });
            scope.$on('new-perspective', function (event, data) {
                newPerspectiveAdded(event, {
                    added: data,
                    fullList: scope.fullList
                })
            });
        }

        function newPerspectiveAdded(event, data) {
            $alert({
                content: [
                    'Perspective \'', data.added.entity.description, '\' created successfully.'
                ].join('')
            });
            data.fullList.push(data.added);
        }
    }
})(window.angular);
