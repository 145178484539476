(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('addNewLanguageModal', addNewLanguageModal);

    addNewLanguageModal.$inject = ['$modal', '$alert'];

    function addNewLanguageModal($modal, $alert) {

        return {
            restrict: 'A',
            link: link,
            scope: {
                fullList: '='
            }
        };

        function link(scope, element) {
            element.on('click', function () {
                $modal({
                    templateUrl: 'app/reference/language/new/new.language.modal.html',
                    scope: scope
                })
            });
            scope.$on('new-language', function (event, data) {
                newLanguageAdded(event, {
                    added: data,
                    fullList: scope.fullList
                })
            });
        }

        function newLanguageAdded(event, data) {
            $alert({
                content: [
                    'Language \'', data.added.entity.name, '\' created successfully.'
                ].join('')
            });
            data.fullList.push(data.added);
        }
    }
})(window.angular);
