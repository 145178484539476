(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('PmtImportService', PmtImportService);

    PmtImportService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function PmtImportService($http, Principal, UriTemplate) {

        return {
            importProgram: importProgram,
            saveImportedProgram: saveImportedProgram
        };

        function importProgram(emmiCode) {
            return Principal.identity().then(function (user) {
                return $http.get(UriTemplate.create(user.link.import + '/program/{emmiCode}').stringify({
                    emmiCode: emmiCode
                })).then(function (response) {
                    return response.data;
                });
            })
        }

        function saveImportedProgram(program) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.import + '/program').stringify(),
                    program.entity).then(function (response) {
                    return response.data;
                });
            })
        }
    }
})(window.angular);
