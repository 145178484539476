(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('FormEditionService', FormEditionService);

    FormEditionService.$inject = ['AssetSetService', 'ProgramService', 'GlobalModulesService', 'AlertService'];

    function FormEditionService(assetSetService, programService, globalModulesService, alertService) {

        var currentBrokenComponents = [];
        var assetSetData = {
            assetSets: {},
        };
        var existingGlobalModules = [];
        var loadingAssetSets = false;

        return {
            isLoadingAssetSets: isLoadingAssetSets,
            loadIvrAssetSets: loadIvrAssetSets,
            getIvrAssetSets: getIvrAssetSets,
            getBrokenComponents: getBrokenComponents,
            setBrokenComponents: setBrokenComponents,
            validateForm: validateForm,
            validateName: validateName,
            getExistingGlobalModules: getExistingGlobalModules,
            setExistingGlobalModules: setExistingGlobalModules

        };

        function getBrokenComponents() {
            return currentBrokenComponents;
        }
        function setBrokenComponents(brokenComponents) {
            currentBrokenComponents = brokenComponents;
            return currentBrokenComponents;
        }


        function validateForm(component, configuration, type, form, list) {
            var valid = false;
            var isDupe = false;
            if (type === 'module') {
                isDupe = validateName(component.moduleRouteName, list, type, configuration.addFlag);
                if (component.moduleRouteName && component.moduleTitle) {
                    if (component.routing.length > 0) {
                        for (var i = 0; i < component.routing.length; ++i) {
                            if (component.routing[i].condition.length > 0) {
                                for (var j = 0; j < component.routing[i].condition.length; ++j) {
                                    if (component.routing[i].condition[j].question && component.routing[i].condition[j].operator && component.routing[i].condition[j].response) {
                                        valid = true;
                                    }
                                }
                            } else {
                                valid = true;
                            }
                        }
                    } else {
                        valid = true;
                    }
                    // name validation and dupe check
                    if (!form.moduleName.$valid || isDupe) return false;
                }
            }
             else if (type === 'element'){
                isDupe = validateName(component.elementRouteName, list, type, configuration.addFlag);
                var validType = false;
                var hasRoute = false;
                var routesValid = false;
                 if (component.elementRouteName && component.type){
                     if (component.type === 'question') {
                         if (component.questionId && component.contextText){
                             component.displayType = component.displayType ? component.displayType : 'selection';
                             validType = true;
                         }
                     } else if ((component.type === 'blockText' && component.contextText) || component.type === 'Intro'){
                         validType = true;
                     }
                     if (component.routing.length > 0) {
                         hasRoute = true;
                         for (var i = 0; i < component.routing.length; ++i) {
                             if (component.routing[i].condition.length > 0) {
                                 for (var j = 0; j < component.routing[i].condition.length; ++j) {
                                     if (component.routing[i].condition[j].question && component.routing[i].condition[j].operator && component.routing[i].condition[j].response) {
                                         routesValid = true;
                                     } else {
                                         return false;
                                     }
                                 }
                             }
                         }
                     }
                 }

                if (component.type !== 'question') {
                    if (!form.elementRouteName.$valid || isDupe) return false;
                } else {
                    if (isDupe) return false;
                }
                if ((validType && hasRoute === false) || (validType && hasRoute === true && routesValid === true)) valid = true;
            }
            else {
                valid = true;
            }
            return valid;
        }



        function validateName(name, list, type, addFlag){
            var count = 0;
            var isDupe = false;
            var i;
            if (type === 'module') {
                for (i = 0; i < list.length; ++i) {
                    if (list[i].moduleRouteName === name) {
                        count++;
                    }
                }
            }
            else if (type === 'element') {
                for (i = 0; i < list.length; ++i) {
                    if (list[i].elementRouteName === name) {
                        count++;
                    }
                }
            }
            if ((addFlag === true && count > 0) || (addFlag === false && count > 1)) {
                isDupe = true;
            }

            return isDupe;
        }

        function loadIvrAssetSets() {
            if (angular.equals(assetSetData.assetSets, {})) {
                if (!loadingAssetSets) {
                    loadingAssetSets = true;
                    assetSetService.search(['p0', 'p1']).then(function (data) {
                        var ivrAssetSets = data;
                        ivrAssetSets.content.forEach(function (assetSet, index, array) {
                            array[index].entity.summary = assetSetService.getSummary(assetSet.entity);
                        });
                        assetSetData.assetSets = ivrAssetSets;
                    }).finally(function () {
                        loadingAssetSets = false;
                    });
                }
            }
        }

        function isLoadingAssetSets() {
            return loadingAssetSets;
        }

        function getIvrAssetSets() {
            return assetSetData;
        }

        function setExistingGlobalModules() {
            globalModulesService.fetchAll().then(function (response){
                existingGlobalModules = response;
            }).catch(function(response){
                alertService.error(response, 'Unable to retrieve existing Global Modules List.')
            });

        }

        function getExistingGlobalModules(){
            return existingGlobalModules;
        }
    }
})(window.angular);
