(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('addNewDialectModal', addNewDialectModal);

    addNewDialectModal.$inject = ['$modal', '$alert'];

    function addNewDialectModal($modal, $alert) {

        return {
            restrict: 'A',
            link: link,
            scope: {
                fullList: '='
            }
        };

        function link(scope, element) {
            element.on('click', function () {
                $modal({
                    templateUrl: 'app/reference/dialect/new/new.dialect.modal.html',
                    scope: scope
                })
            });
            scope.$on('new-dialect', function (event, data) {
                newDialectAdded(event, {
                    added: data,
                    fullList: scope.fullList
                })
            });
        }

        function newDialectAdded(event, data) {
            $alert({
                content: [
                    'Dialect \'', data.added.entity.printName, '\' created successfully.'
                ].join('')
            });
            data.fullList.push(data.added);
        }
    }
})(window.angular);
