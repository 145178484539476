(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('PublishService', PublishService);

    PublishService.$inject = ['Principal', 'UriTemplate', '$http', '$q'];

    function PublishService(principal, uriTemplate, $http, $q) {

        return {
            publishReferences: publishReferences
        };

        function publishReferences(publishResource) {
            if(publishResource) {
                return principal.identity().then(function (user) {
                    return $http.post(uriTemplate.create(user.link.publish + '/references/{objectType}').stringify({
                        objectType: publishResource
                    }));
                });
            }
            return $q.reject(publishResource +' is not a valid reference');
        }
    }
})(window.angular);
