(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('SpecialtyController', SpecialtyController);

    SpecialtyController.$inject = ['SpecialtyService'];

    function SpecialtyController(SpecialtyService) {
        var SpecialtyCtrl = this;

        (function onControllerLoad() {
            SpecialtyCtrl.nextPage = nextPage;
            SpecialtyCtrl.hasVisible = anyVisibleSpecialty;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                SpecialtyCtrl.loadingNextPage = true;
                SpecialtyService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    SpecialtyCtrl.loadingNextPage = false;
                } else {
                    SpecialtyCtrl.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                SpecialtyCtrl.loadingNextPage = true;
            }
        }

        function anyVisibleSpecialty() {
            var hasVisible = false,
                length = SpecialtyCtrl.specialty && SpecialtyCtrl.specialty.content &&
                    SpecialtyCtrl.specialty.content.length;
            for (var i = 0; i < length; i++) {
                if (!SpecialtyCtrl.specialty.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            SpecialtyCtrl.loading = true;
            SpecialtyService.search()
                .then(function (response) {
                    SpecialtyCtrl.specialty = response;
                })
                .finally(function () {
                    delete SpecialtyCtrl.loadingNextPage;
                    delete SpecialtyCtrl.loading;
                });
        }

    }
})(window.angular);
