(function(angular) {
    'use strict';

    angular
        .module('Composer', [
            'ngStorage',
            'ngResource',
            'ngAria',
            'ngCacheBuster',
            'ui.router',
            'infinite-scroll',
            'angular-loading-bar',
            'mgcrea.ngStrap',
            'Composer.api'
        ])
        .run(run);

    run.$inject = ['stateHandler'];

    function run(stateHandler) {
        stateHandler.initialize();
    }

})(window.angular);
