(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('editExistingSpecialty', editExistingSpecialty);

    editExistingSpecialty.$inject = ['$modal', '$alert'];

    function editExistingSpecialty($modal, $alert) {

        return {
            restrict: 'A',
            link: link,
            scope: {
                toBeEdited: '='
            }
        };

        function link(scope, element) {

            element.on('click', function () {
                $modal({
                    templateUrl: 'app/reference/specialty/edit/edit.specialty.modal.html',
                    scope: scope,
                    controller: 'EditSpecialtyController',
                    controllerAs: 'epc',
                    resolve: {
                        savedSpecialtyResource: function () {
                            return scope.toBeEdited
                        },
                        saveCallback: function () {
                            return function (data) {
                                updatedExistingSpecialty(data, scope.toBeEdited)
                            }
                        },
                        errorCallback: function () {
                            return errorUpdating;
                        }
                    }
                })
            });

        }

        function errorUpdating() {
            $alert({
                type: 'danger',
                content: 'Error: ' + err.data.errorMessage + '. Please try again.'
            });
        }

        function updatedExistingSpecialty(newValue, existingValue) {
            $alert({
                content: [
                    'Specialty \'', newValue.entity.name, '\' edited successfully.'
                ].join('')
            });
            angular.extend(existingValue, newValue);
        }
    }
})(window.angular);
