(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditSystemService', EditSystemService);

    EditSystemService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditSystemService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(systemResource) {
            LinkConverter.convertResource(systemResource);
            if (systemResource && systemResource.link.self) {
                return $http.put(systemResource.link.self, systemResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
