(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('AssetSetsController', AssetSetsController);

    AssetSetsController.$inject = ['AssetSetService', '$scope'];

    function AssetSetsController(AssetSetService, $scope) {
        var AssetSets = this;

        (function onControllerLoad() {
            AssetSets.nextPage = nextPage;
            AssetSets.hasVisible = anyVisibleAssetSets;
            search();
        })();

        $scope.$on("updateList", function() {
            search();
        });

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                AssetSets.loadingNextPage = true;
                AssetSetService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    AssetSets.loadingNextPage = false;
                } else {
                    AssetSets.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                AssetSets.loadingNextPage = true;
            }
        }

        function anyVisibleAssetSets() {
            var hasVisible = false,
                length = AssetSets.assetsets && AssetSets.assetsets.content &&
                    AssetSets.assetsets.content.length;
            for (var i = 0; i < length; i++) {
                if (!AssetSets.assetsets.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            AssetSets.loading = true;
            AssetSetService.search()
                .then(function (response) {
                    AssetSets.assetsets = response;
                    //AssetSets.assetsets = JSON.stringify(response);
                })
                .finally(function () {
                    delete AssetSets.loadingNextPage;
                    delete AssetSets.loading;
                });
        }

    }
})(window.angular);
