(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewCountryService', NewCountryService);

    NewCountryService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewCountryService($http, Principal, UriTemplate) {

        return {
            create: create,
            newCountry: newCountry
        };

        function create(newCountry) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.countries).stringify(),
                    newCountry).then(function (response) {
                    return response.data;
                });
            })
        }

        function newCountry() {
            return {
                name: ''
            }
        }

    }
})(window.angular);
