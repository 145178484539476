(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditUrlTypeController', EditUrlTypeController);

    EditUrlTypeController.$inject = ['EditUrlTypeService', 'savedUrlTypeResource', 'saveCallback', 'errorCallback'];

    function EditUrlTypeController(EditUrlTypeService, savedUrlTypeResource, saveCallback, errorCallback) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedUrlTypeResource);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditUrlTypeService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
