(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('authInterceptor', authInterceptor);

    authInterceptor.$inject = ['$location', '$localStorage', '$sessionStorage'];

    function authInterceptor($location, $localStorage, $sessionStorage) {
        return {
            request: request
        };

        function request(config) {
            /*jshint camelcase: false */
            config.headers = config.headers || {};
            config.headers['X-Requested-With'] = 'XMLHttpRequest';
            config.headers['X-Requested-Url'] = $location.absUrl();
            config.headers['USERNAME'] = $sessionStorage.userName;
            var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
            }
            return config;
        }
    }
})(window.angular);
