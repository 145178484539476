(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('DeleteController', DeleteController);

    DeleteController.$inject = ['DeleteService', 'deleteResource', 'successCallback', 'errorCallback'];

    function DeleteController(DeleteService, deleteResource, successCallback, errorCallback) {
        var dc = this;
        dc.remove = remove;

        function remove($hide) {
            $hide();
            DeleteService.remove(deleteResource).then(function (response) {
                successCallback();
            }, errorCallback);
        }
    }
})(window.angular);
