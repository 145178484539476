(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewBrandService', NewBrandService);

    NewBrandService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewBrandService($http, Principal, UriTemplate) {

        return {
            create: create,
            newBrand: newBrand
        };

        function create(newBrand) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.brands).stringify(),
                    newBrand).then(function (response) {
                    return response.data;
                });
            })
        }

        function newBrand() {
            return {}
        }

    }
})(window.angular);
