(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .config(localStorageConfig);

    localStorageConfig.$inject = ['$localStorageProvider', '$sessionStorageProvider'];

    function localStorageConfig($localStorageProvider, $sessionStorageProvider) {
        $localStorageProvider.setKeyPrefix('Composer-');
        $sessionStorageProvider.setKeyPrefix('Composer-');
    }
})(window.angular);
