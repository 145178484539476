(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('EditGenderController', EditGenderController);

    EditGenderController.$inject = ['EditGenderService', 'savedGenderResource', 'saveCallback', 'errorCallback'];

    function EditGenderController(EditGenderService, savedGenderResource, saveCallback, errorCallback) {
        var epc = this;

        epc.save = save;
        epc.copy = angular.copy(savedGenderResource);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                EditGenderService.update(epc.copy).then(function (response) {
                    saveCallback(response);
                }, errorCallback);
            }
        }
    }
})(window.angular);
