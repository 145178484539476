(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('TransitionModalController', TransitionModalController);

    TransitionModalController.$inject = ['ProgramService', 'ProgramVersionService', '$state', 'AlertService', 'UtilService', '$scope', 'fullList', 'saveCallback'];

    function TransitionModalController(ProgramService, ProgramVersionService, state, AlertService, utilService, $scope, fullList, saveCallback) {
        var tmc = this;

        tmc.fullList = fullList;
        tmc.saveCallback = saveCallback;
        tmc.dayErrorMesg = '';
        tmc.selectedIvrType = '';
        tmc.selectedMedia = [];
        tmc.searchMediaFlag = false;
        tmc.dayList = [];
        tmc.addList = {
            day: "",
            ivrType: "",
            media: []
        };
        tmc.editFlag = false;
        tmc.dayNumber = '';
        tmc.save = save;
        tmc.buttonAction = buttonAction;
        tmc.cancel = cancel;
        tmc.loadTypes = loadTypes;
        tmc.search = search;
        tmc.nextPage = nextPage;
        tmc.onSelectMedia = onSelectMedia;
        tmc.addObject = addObject;
        tmc.editDayList = editDayList;
        tmc.dataPopulation = dataPopulation;
        tmc.copyDay = copyDay;
        tmc.mediaList = mediaList;
        tmc.saveAndClose = saveAndClose;
        tmc.dayValidation = dayValidation;
        tmc.removeDuplicate = removeDuplicate;
        tmc.dayErrorAlert = dayErrorAlert;
        tmc.addDay = addDay;
        tmc.modalFlagDefault = modalFlagDefault;
        tmc.assignModalFlag = assignModalFlag;

        // assigining data while onloading
        (function onLoad() {
            tmc.modalObject = tmc.fullList;
            tmc.selectedMedia = [];
            if (tmc.modalObject.editFlag) {
                tmc.editDayList(tmc.modalObject.editItem);
            } else if (tmc.modalObject.copyFlag) {
                tmc.copyDay(tmc.modalObject.list);
            }
            tmc.loadTypes('IVR CALL');
        })();

        // add Day button action
        function addDay() {
            if (tmc.day) tmc.day = Number(tmc.day);
        }

        //default modal 
        function modalFlagDefault() {
            tmc.dayErrorMesg = '';
            tmc.modalObject.dayFlag = false;
            tmc.modalObject.ivrFlag = false;
            tmc.modalObject.mediaFlag = false;
            tmc.modalObject.modalTitle = '';
            tmc.modalObject.buttonOne = '';
            tmc.modalObject.buttonTwo = '';
        }

        //assiging the modal data based on the modal event
        function assignModalFlag(title, btnOne, btnTwo, flag) {
            tmc.modalObject[flag] = true;
            tmc.modalObject.modalTitle = title;
            tmc.modalObject.buttonOne = btnOne;
            tmc.modalObject.buttonTwo = btnTwo;
        }
        
        //common button for all types from modal 
        function buttonAction(arg, $hide) {
            tmc.dayError = false;
            tmc.searchProgram = '';
            tmc.mediaErrorFlag = false;
            var addedList = tmc.modalObject.list ? tmc.modalObject.list : [];
            if (arg === 'ADD MEDIA') {
                if (tmc.dayValidation(addedList)) {
                    tmc.modalFlagDefault();
                    tmc.assignModalFlag('Add Programs','ADD IVR','SAVE MEDIA','mediaFlag' ); 
                    if (tmc.modalObject.editFlag && tmc.modalObject.editItem.media) tmc.selectedMultimediaList = tmc.modalObject.editItem.media;
                }
            } else if (arg === 'ADD IVR' && !tmc.modalObject.editFlag) {
                if (tmc.dayValidation(addedList)) {
                    tmc.modalFlagDefault();
                    tmc.assignModalFlag(tmc.modalObject.editFlag ? 'Edit IVR Program' : 'Add IVR Program','ADD MEDIA','SAVE IVR','ivrFlag' );
                }
            } else if (arg === 'SAVE IVR') {
                if (tmc.selectedIvrType || tmc.mediaList().length) {
                    tmc.save($hide);
                    tmc.selectedMedia = [];
                } else {
                    tmc.dayErrorAlert('select');
                }
            } else if (arg === 'SAVE MEDIA') {
                if (tmc.mediaList().length || tmc.selectedIvrType) {
                    tmc.save($hide);
                    tmc.selectedMedia = [];
                } else {
                    tmc.dayErrorAlert('select');
                }
            }
            else if (arg === 'SAVE & EDIT IVR') {
                if (tmc.dayValidation(addedList)) {
                    tmc.modalFlagDefault();
                    tmc.assignModalFlag(tmc.modalObject.editFlag ? 'Edit IVR Program' : 'Add IVR Program','SAVE & EDIT MEDIA','SAVE IVR','ivrFlag' );
                    if (tmc.modalObject.editFlag && tmc.modalObject.editItem.media) {
                        tmc.selectedMultimediaList = tmc.selectedMultimediaList ? tmc.selectedMultimediaList : tmc.modalObject.editItem.media;
                    }
                    tmc.addObject();
                }
            } else if (arg === 'SAVE & EDIT MEDIA') {
                if (tmc.modalObject.editFlag && tmc.modalObject.editItem.media) {
                    tmc.selectedMultimediaList = tmc.selectedMultimediaList ? tmc.selectedMultimediaList : tmc.modalObject.editItem.media;
                }
                if (tmc.dayValidation(addedList)) {
                    tmc.modalFlagDefault();
                    tmc.assignModalFlag(tmc.modalObject.editFlag ? 'Edit Multimedia Programs' : 'Add Multimedia Programs','SAVE & EDIT IVR','SAVE MEDIA','mediaFlag' );
                    tmc.addObject();
                }
            } else if (arg === 'copy') {
                if (tmc.dayValidation(addedList)) {
                    if (tmc.modalObject.copyFlag) {
                        var list = [];
                        var index = utilService.findIndex(tmc.modalObject, 'day', tmc.day);
                        if (index) {
                            tmc.dayErrorAlert('inuse');
                        } else {
                            list.push({ day: tmc.day, ivrType: angular.copy(tmc.modalObject.copyItem.ivrType), media: angular.copy(tmc.modalObject.copyItem.media), toggleFlag: true });
                            tmc.saveCallback({ final: list });
                            $hide();
                            $('body').removeClass('modal-open');
                        }
                    }
                }
            } else if (arg === 'CANCEL') {
                tmc.day = tmc.day ? tmc.day : tmc.modalObject.editItem.day;
                tmc.cancel(arg, $hide);
                $(".modal").modal("hide");
                $('body').removeClass('modal-open');
            }
        }

        // day validation to check duplicate days 
        function dayValidation(list) {
            var regex = /^\d+$/;
            if (tmc.day) {
                if (regex.test(tmc.day)) {
                    var index = utilService.findIndex(list, 'day', Number(tmc.day));
                    if (index && !tmc.modalObject.editFlag) {
                        tmc.dayErrorAlert('inuse');
                        return false;
                    } else if (tmc.modalObject.editFlag && tmc.day !== tmc.modalObject.editItem.day) {
                        var modalIndex = utilService.findIndex(tmc.modalObject.list, 'day', tmc.day);
                        if (modalIndex) {
                            tmc.dayErrorAlert('inuse');
                            return false;
                        } else {

                            return true;
                        }
                    } else {
                        return true;
                    }
                } else {
                    tmc.dayError = true;
                    tmc.dayErrorAlert('numbers');
                    return false;
                }
            } else {
                tmc.dayError = true;
                tmc.dayErrorAlert('req');
                return false;
            }
        }

        //day validation errors
        function dayErrorAlert(type) {
            if (type === 'numbers') {
                tmc.dayErrorMesg = 'Use numbers only.';
            } else if (type === 'inuse') {
                tmc.dayErrorMesg = 'This day is already in use.';
            } else if (type === 'req') {
                tmc.dayErrorMesg = 'Day is required.';
            } else if (type === 'select') {
                tmc.dayErrorMesg = 'Please select a program.';
            }
        }

        // search programs on IVR and Multimedia
        function search(arg, type) {
            if (type === 'ivr' && arg !== '') {
                tmc.searchProgram = arg;
                tmc.loadTypes('IVR CALL');
            }
            if (type === 'media' && arg !== '') {
                tmc.searchProgram = arg;
                tmc.searchMediaFlag = true;
                tmc.loadTypes('MULTIMEDIA PROGRAM');
            } else {
                tmc.searchMediaFlag = false;
            }
        }

        // paginaion for IVR and Multimedia
        function nextPage(link, type) {
            if (tmc.ivrList && link === tmc.ivrList.load && type === 'ivr') {
                return; //if it the same link as the current page, return
            } else if (tmc.multimediaList && link === tmc.multimediaList.load && type === 'media') {
                return; //if it the same link as the current page, return
            } else if (link) {
                tmc.loadingNextPage = true;
                utilService.fetchPage(link).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    tmc.loadingNextPage = false;
                    if (type === 'ivr') {
                        angular.forEach(response.content, function (res) {
                            if (tmc.selectedIvrType) {
                                if (res.entity.name === tmc.selectedIvrType.entity.name) {
                                    res.select = true;
                                    if (res.select) tmc.selectedIvrType = res;
                                } else {
                                    res.select = false;
                                }
                            }
                        });
                        tmc.ivrList = response;
                    }
                    if (type === 'media') tmc.multimediaList = response;
                    if (tmc.modalObject.editFlag) {
                        tmc.dataPopulation();
                    }
                } else {
                    tmc.loadingNextPage = true;
                }
            }

            function err() {
                vpc.loadingNextPage = true;
            }
        }

        function mediaList() {
            var list = [];
            if (tmc.selectedMedia) {
                angular.forEach(tmc.selectedMedia, function (res) {
                    if (res.select) {
                        list.push(res);
                    }
                });
                return list;
            }
        }

        // to remove duplicate programs
        function removeDuplicate(a, key) {
            var index = [];
            return a.filter(function (item) {
                var k = key(item);
                return index.indexOf(k) >= 0 ? false : index.push(k);
            });
        }

        //selected media is available or not 
        function onSelectMedia(item) {
            item.select = !item.select;
            var found = false;
            angular.forEach( tmc.selectedMedia, function (res,index) {
                if (item.entity.emmiCode === res.entity.emmiCode) {
                  found = true;
                  if (!item.select) {
                    tmc.selectedMedia.splice(index, 1);
                  }
                }
              });
              if (!found) {
                tmc.selectedMedia.push(item);
              }
            tmc.selectedMultimediaList = tmc.selectedMedia;
            return;
        }

        //load types/search types like ivr/multimedia (api call)
        function loadTypes(arg) {
            tmc.apiStatus = (tmc.type + '-fail').toLowerCase();
            var config = {
                params: {
                    search: tmc.searchProgram,
                    type: arg,
                    size: 10,
                    publishable: true
                }
            };
            tmc.loading = true;
            ProgramVersionService.loadVersionedPrograms(config)
                .then(function (response) {
                    if (arg === 'IVR CALL') {
                        angular.forEach(response.content, function (res) {
                            res.select = false;
                        });
                        tmc.ivrList = response;
                        if (tmc.modalObject.editFlag) {
                            tmc.dataPopulation();
                        }
                    } else {
                        angular.forEach(response.content, function (res) {
                            res.select = false;
                        });
                        tmc.multimediaList = response;
                        if (tmc.modalObject.editFlag) {
                            tmc.dataPopulation();
                        }
                    }
                })
                .catch(function (response) {
                    AlertService.error(response, "Search");
                })
                .finally(function () {
                    delete tmc.loading;
                });
        }

        //edit day modal
        function editDayList() {
            tmc.selectedMedia = [];
            tmc.editFlag = true;
            tmc.day = tmc.modalObject.editItem.day;
            tmc.selectedIvrType = tmc.modalObject.editItem.ivrType; //it is referring to IVR modal state
            tmc.selectedMedia = angular.copy(tmc.modalObject.editItem.media);
        }

        //data pre population while editing day 
        function dataPopulation() {
            if (tmc.modalObject.editFlag) {
                if (tmc.ivrList && tmc.ivrList.length !== 0) {
                    angular.forEach(tmc.ivrList.content, function (res) {
                        if (tmc.selectedIvrType) {
                            if (res.entity.name === tmc.selectedIvrType.entity.name) {
                                res.select = true;
                                if (res.select) tmc.selectedIvrType = res;
                            }
                        }
                    });
                }
                if (tmc.multimediaList && tmc.multimediaList.length !== 0) {
                    angular.forEach(tmc.multimediaList.content, function (res) {
                        angular.forEach(tmc.modalObject.editItem.media, function (media) {
                            if (res.entity.name === media.entity.name) {
                                res.select = media.select;
                            }
                        })
                    })
                }
            }
        }

        //save&close on edit
        function saveAndClose($hide) {
            var regex = /^\d+$/;
            if (tmc.day) {
                tmc.selectedMultimediaList = tmc.modalObject.editItem.media;
                if (regex.test(tmc.day)) {
                    if (tmc.modalObject.editFlag && tmc.day !== tmc.modalObject.editItem.day) {
                        var index = utilService.findIndex(tmc.modalObject.list, 'day', tmc.day);
                        if (index) {
                            tmc.dayErrorAlert('inuse');
                        } else {
                            tmc.save($hide);
                        }
                    } else {
                        tmc.save($hide);
                    }
                } else {
                    tmc.dayError = true;
                    tmc.dayErrorAlert('numbers');
                }
            } else {
                tmc.dayError = true;
                tmc.dayErrorAlert('req');
            }
        }

        // save the day and close modal
        function save($hide) {
            tmc.addObject();
            tmc.saveCallback({ final: tmc.dayList });
            tmc.modalObject = {};
            $hide();
            $('body').removeClass('modal-open');
        }

        //the selected data on modal will be passed on to the day array
        function addObject() {
            tmc.dayList = [];
            var finalMedia = tmc.selectedMultimediaList;
            if (tmc.modalObject.list && tmc.modalObject.list.length > 0) {
                if (!tmc.modalObject.editFlag) {
                    var index = utilService.findIndex(tmc.modalObject.list, 'day', tmc.day);
                    if (index) {
                        tmc.dayErrorAlert('inuse');
                    } else {
                            tmc.dayList.push({ day: tmc.day, ivrType: tmc.selectedIvrType, media: finalMedia, toggleFlag: true });
                    }
                } else if (tmc.modalObject.editFlag) {
                    var mediaList = tmc.selectedMedia && !tmc.selectedMedia.length ? tmc.modalObject.editItem.media : finalMedia;
                    if (tmc.day !== tmc.modalObject.editItem.day) {
                        var index = utilService.findIndex(tmc.modalObject.list, 'day', tmc.day);
                        if (index) {
                            tmc.dayErrorAlert('inuse');
                        } else {
                            tmc.dayList.push({ day: tmc.day, ivrType: tmc.selectedIvrType, media: mediaList, toggleFlag: true });
                        }
                    } else {
                        tmc.dayList.push({ day: tmc.day, ivrType: tmc.selectedIvrType, media: mediaList, toggleFlag: true });
                    }
                }
            } else {
                tmc.dayList.push({ day: tmc.day, ivrType: tmc.selectedIvrType, media: finalMedia, toggleFlag: true });
            }
        }

        //cancel
        function cancel(arg, $hide) {
            if (tmc.modalObject.editFlag && tmc.dayList.length) tmc.saveCallback({ final: tmc.dayList });
            tmc.modalObject = {};
            $hide();
        }

        //duplicate the day data with different day number
        function copyDay(list) {
            tmc.copyData = list;
            tmc.modalObject.dayFlag = true;
        }
    }
})(window.angular);