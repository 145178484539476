(function(angular) {
  'use strict';

  angular
    .module('Composer')
    .directive('deleteGlobalModal', deleteGlobalModal);

  deleteGlobalModal.$inject = [ '$modal' ];

  function deleteGlobalModal($modal) {
    return {
      controller: 'GlobalModulesController',
      controllerAs: "ctrl",
      link: link,
      restrict: 'A',
      scope: {
        selectedModule: "="
      }
    };

    function link(scope, element, attrs, ctrl) {
      var modal;

      element.on("click", function(event) {
        setTimeout(function() {
          modal = $modal({
            backdrop: 'static',
            controller: 'GlobalModulesController',
            controllerAs: 'ctrl',
            keyboard: true,
            scope: scope,
            templateUrl: 'app/reference/global-modules/delete-global-modal/delete-global.html',
          })
        })
      })
    }
  }
})(window.angular);

