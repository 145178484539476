(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewDialectController', NewDialectController);

    NewDialectController.$inject = ['ProgramInitService', 'NewDialectService', '$scope'];

    function NewDialectController(programInitService, NewDialectService, $scope) {
        var nbc = this;

        nbc.save = save;
        nbc.newDialect = NewDialectService.newDialect();
        nbc.data = programInitService.getDialects(true, true);

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewDialectService.create(nbc.newDialect).then(function (response) {
                    $scope.$emit('new-dialect', response);
                    nbc.data.dialects.content.push(response);
                })
            }
        }
    }

})(window.angular);
