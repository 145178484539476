(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('LanguageController', LanguageController);

    LanguageController.$inject = ['LanguageService'];

    function LanguageController(LanguageService) {
        var LanguageCtrl = this;

        (function onControllerLoad() {
            LanguageCtrl.nextPage = nextPage;
            LanguageCtrl.hasVisible = anyVisibleAssetSet;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                LanguageCtrl.loadingNextPage = true;
                LanguageService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    LanguageCtrl.loadingNextPage = false;
                } else {
                    LanguageCtrl.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                LanguageCtrl.loadingNextPage = true;
            }
        }

        function anyVisibleAssetSet() {
            var hasVisible = false,
                length = LanguageCtrl.languages && LanguageCtrl.languages.content &&
                    LanguageCtrl.languages.content.length;
            for (var i = 0; i < length; i++) {
                if (!LanguageCtrl.languages.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            LanguageCtrl.loading = true;
            LanguageService.search()
                .then(function (response) {
                    LanguageCtrl.languages = response;
                })
                .finally(function () {
                    delete LanguageCtrl.loadingNextPage;
                    delete LanguageCtrl.loading;
                });
        }

    }
})(window.angular);
