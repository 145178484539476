(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('TransitionWarningService', TransitionWarningService);

    TransitionWarningService.$inject = ['ProgramService', '$q'];

    function TransitionWarningService(programService, $q) {

        return {
            getWarningMessages: getWarningMessages
        };

        function getWarningMessages(nextProgramVersion, nextProgramMajor) {
            var messages = [];

            var prevMajor = -1;
            var cToMsg = '';
            var message = '';
            var moreComponents = false;


            var entity = programService.getProgram().entity;
            var components = entity.meta.components;
            var promises = [];
            components.forEach(function (component) {
                if (component.condition && component.condition.programType !== "MULTIMEDIA PROGRAM") {
                    promises.push(programService.getProgramByEmmiCode(component.condition.emmiCode));
                }
            });

            return $q.all(promises).then(function (data) {
                data.forEach(function (currentComponent) {
                    var componentVersions = '';
                    var version = currentComponent.entity.version;
                    var name = currentComponent.entity.name;
                    if (version) {
                        var major = version.major;
                        if (prevMajor >= 0) {
                            if (prevMajor != major) {
                                cToMsg = 'Warning: Major program versions do not match.';
                            }
                        }
                        prevMajor = major;

                        if (nextProgramMajor > 0 && major != nextProgramMajor) {
                            if (componentVersions.length > 0) {
                                componentVersions += '; ';
                                moreComponents = true;
                            }
                            componentVersions += name + ', (' + version.major + ')';
                        }
                    }

                    if (cToMsg.length > 0) {
                        messages.push(cToMsg);
                    }

                    if (componentVersions.length > 0) {
                        message = "Warning: the major version number for IVR component ";
                        if (moreComponents) {
                            message = "Warning: the major version numbers for components ";
                        }
                        message += componentVersions + " do not match the major version number (" + nextProgramMajor +
                            ") you are trying to create. ";
                        if(messages.indexOf(message) == -1) {
                            messages.push(message);
                        }
                    }
                });
                return messages;
            });
        }
    }
})(window.angular)