(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('ProgramTypeController', ProgramTypeController);

    ProgramTypeController.$inject = ['ProgramTypeService'];

    function ProgramTypeController(ProgramTypeService) {
        var ProgramTypeCtrl = this;

        (function onControllerLoad() {
            ProgramTypeCtrl.nextPage = nextPage;
            ProgramTypeCtrl.hasVisible = anyVisible;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                ProgramTypeCtrl.loadingNextPage = true;
                ProgramTypeService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    ProgramTypeCtrl.loadingNextPage = false;
                } else {
                    ProgramTypeCtrl.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                ProgramTypeCtrl.loadingNextPage = true;
            }
        }

        function anyVisible() {
            var hasVisible = false,
                length = ProgramTypeCtrl.programType && ProgramTypeCtrl.programType.content &&
                    ProgramTypeCtrl.programType.content.length;
            for (var i = 0; i < length; i++) {
                if (!ProgramTypeCtrl.programType.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            ProgramTypeCtrl.loading = true;
            ProgramTypeService.search()
                .then(function (response) {
                    ProgramTypeCtrl.programType = response;
                })
                .finally(function () {
                    delete ProgramTypeCtrl.loadingNextPage;
                    delete ProgramTypeCtrl.loading;
                });
        }

    }
})(window.angular);
