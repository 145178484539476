(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('PlaceholdersController', PlaceholdersController);

    PlaceholdersController.$inject = ['PlaceholderService'];

    function PlaceholdersController(PlaceholderService) {
        var Placeholders = this;

        (function onControllerLoad() {
           // Placeholders.nextPage = nextPage;
           // Placeholders.hasVisible = anyVisiblePlaceholders;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                Placeholders.loadingNextPage = true;
                PlaceholderService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    Placeholders.loadingNextPage = false;
                } else {
                    Placeholders.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                Placeholders.loadingNextPage = true;
            }
        }

        function anyVisiblePlaceholders() {
            var hasVisible = false,
                length = Placeholders.placeholders && Placeholders.placeholders.content &&
                    Placeholders.placeholders.content.length;
            for (var i = 0; i < length; i++) {
                if (!Placeholders.placeholders.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {

            //var resdata = getResProvidersList();
            //Placeholders.placeholders = resdata;
            //Placeholders.placeholders = JSON.stringify(resdata);

            Placeholders.loading = true;
            PlaceholderService.search()
                .then(function (response) {
                    Placeholders.placeholders = response;
                })
                .finally(function () {
                    delete Placeholders.loadingNextPage;
                    delete Placeholders.loading;
                });

        }

    }
})(window.angular);
