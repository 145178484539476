(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NarratorController', NarratorController);

  NarratorController.$inject = ['NarratorService'];

    function NarratorController(NarratorService) {
        var NarratorCtrl = this;

        (function onControllerLoad() {
            NarratorCtrl.nextPage = nextPage;
            NarratorCtrl.hasVisible = anyVisibleAssetSet;
            search();
        })();

        function nextPage(link) {
            if(NarratorCtrl.narrator && link === NarratorCtrl.narrator.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                NarratorCtrl.loadingNextPage = true;
                NarratorService.nextPage(link).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    NarratorCtrl.loadingNextPage = false;
                    NarratorCtrl.narrator = response;
                } else {
                    NarratorCtrl.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                NarratorCtrl.loadingNextPage = true;
            }
        }

        function anyVisibleAssetSet() {
            var hasVisible = false,
                length = NarratorCtrl.narrator && NarratorCtrl.narrator.content &&
                    NarratorCtrl.narrator.content.length;
            for (var i = 0; i < length; i++) {
                if (!NarratorCtrl.narrator.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            NarratorCtrl.loading = true;
            NarratorService.search()
                .then(function (response) {
                    NarratorCtrl.narrator = response;
                })
                .finally(function () {
                    delete NarratorCtrl.loadingNextPage;
                    delete NarratorCtrl.loading;
                });
        }

    }
})(window.angular);
