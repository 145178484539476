(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('FormController', FormController);

    FormController.$inject = ['ProgramService', 'EditionsService', 'ProgramVersionService', 'ProgramSummaryService', 'ProgramWarningService',
        'AssetSetService',
        'UtilService', 'VideoService', 'AlertService', '$state', '$location'];

    function FormController(programService, editionsService, programVersionService, programSummaryService, programWarningService, assetSetService,
        utilService, videoService, alertService, $state, $location) {
        var fc = this;
        fc.confirm = {};
        fc.collapsed = false;
        fc.program = programService.getProgram;
        fc.data = programSummaryService.getProgramData();
        //create versions
        fc.formatVersion = utilService.formatVersion;
        fc.createWorkingVersion = createWorkingVersion;
        fc.createMajorVersion = createMajorVersion;
        fc.createMinorVersion = createMinorVersion;

        fc.nextMajorVersion = nextMajorVersion;
        fc.nextMinorVersion = nextMinorVersion;
        fc.nextWorkingVersion = nextWorkingVersion;
        fc.getMisMatchedVersionMessage = getWarningMessages;
        fc.isValidVersion = utilService.isValidVersionProgram;
        fc.validEditions = validEditions;


        //set edit to disabled if no permissions or during loading process
        fc.isEditDisabled = isEditDisabled;

        //reset before opening modal dialog
        fc.reset = reset;

        //create copy
        fc.createCopy = createCopy;

        //save form
        fc.save = save;
        fc.cancel = cancel;
        fc.cancelButtonText = cancelButtonText;

        //discard
        fc.discard = discard;
        fc.canBeDiscarded = utilService.canBeDiscarded;
        fc.discardButtonText = discardButtonText;
        fc.discardMessage = utilService.discardMessage;
        //edition
        fc.hasVideoSections = hasVideoSections;
        fc.removeVideoSections = editionsService.removeVideoSections;

        //check if program meta has properties and values
        fc.containsValue = containsValue;
        fc.hasValue = hasValue;
        fc.removeAt = utilService.removeAt;

        fc.setFormModified = setFormModified;
        fc.requiredOnAction = requiredOnAction;
        fc.validateName = validateName;
        fc.parseKeywords = parseKeywords;
        fc.keywords = fc.program().entity.meta.keywords.toString();
        function validateName() {
            fc.submitted = false;
            fc.nameError = !!(fc.program().entity.name && fc.program().entity.name.match(/\"/g));
        }

        function reset(form) {
            alertService.removeAlert();
        }

        function isEditDisabled() {
            return programService.isEditDisabled() || fc.loading;
        }

        function hasVideoSections() {
            var isRequired = false;
            var entity = fc.program().entity;
            if (entity && entity.meta) {
                var editions = entity.meta.editions;
                if (editions) {
                    for (var i = 0; i < editions.length; i++) {
                        if (videoService.hasVideoSections(editions[i])) {
                            isRequired = true;
                            break;
                        }
                    }
                }
            }
            return isRequired;
        }

        function containsValue(prop) {
            var entity = fc.program().entity;
            var containsValue = false;
            if (entity && entity.meta && prop in entity.meta) {
                var property = entity.meta[prop];
                if (property) {
                    containsValue = property.length > 0;
                }
            }
            return containsValue;
        }

        function hasValue(prop) {
            var entity = fc.program().entity;
            if (entity && entity.meta && prop in entity.meta) {
                var obj = entity.meta[prop];
                return obj && 'id' in obj;
            }
            return false;
        }

        function requiredOnAction() {
            var curAction = programService.getCurrentAction();
            if (curAction && (curAction === "Create Major Version" || curAction === "Create Minor Version")) {
                return true;
            }
            return false;
        }

        function isFormValid(form, action) {
            var isValid = !fc.nameError && form && form.$valid;
            if (!isValid) {
                alertService.error("Validation errors", action);
            } else {
                form.$setPristine();
            }
            form.$setSubmitted();
            $('body').removeClass('modal-open');
            return isValid;
        }

        function save(form) {
            fc.submitted = true;
            if (!fc.nameError && form.description.$valid) {
                var actionWord = "Save Program";
                if (!fc.program().entity.emmiCode) {
                    actionWord = "Create Program";
                }
                fc.loading = true;
                programService.save()
                    .then(function (response) {
                        form.$setPristine();
                        alertService.success(fc.program().entity.name, actionWord);
                    })
                    .catch(function (response) {
                        alertService.error(response, actionWord);
                    })
                    .finally(function () {
                        delete fc.loading;
                        loadIvrAssetSetsSummary();
                    });
            } else {
                alertService.error(" Client Facing Name and Description cannot be blank or contain double quotes.", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function cancel() {
            var entity = fc.program().entity;
            var emmiCode = entity.emmiCode;
            var versioned = entity.versionedToLocal || entity.version;
            utilService.navigate(emmiCode, versioned, $state);
        }

        function cancelButtonText() {
            var entity = fc.program().entity;
            var emmiCode = entity.emmiCode;
            var text = "Return to Composer Home";
            if (emmiCode) {
                if (entity.version || entity.versionedToLocal) {
                    text = "Return to Version List Page";
                }
            }
            return text;
        }

        function discardButtonText() {
            var program = fc.program();
            if (program && program.entity) {
                return program.entity.versionedToLocal ? 'REVERT' : 'DELETE';
            } else {
                return 'DELETE';
            }
        }

        function discard() {
            var entity = fc.program().entity;
            var emmiCode = entity.emmiCode;
            var versionedToLocal = entity.versionedToLocal;
            fc.loading = true;
            programService.discard()
                .then(function (response) {
                    utilService.navigate(emmiCode, versionedToLocal, $state);
                })
                .catch(function (response) {
                    alertService.error(response, 'discard program');
                })
                .finally(function () {
                    delete fc.loading;
                });
        }

        function createCopy(form) {
            programService.createCopy(fc.confirm.inputText);
            programService.setEditable(true);
            fc.program().entity.meta.editions = [];
            $('body').removeClass('modal-open');
        }

        function setNotes() {
            var entity = fc.program().entity;
            if (entity) {
                if (!entity.version) {
                    entity.version = utilService.newVersion();
                }
                entity.version.comment = fc.confirm.inputText;
            }
        }

        function setFormModified(form) {
            form.$setDirty();
        }

        function createWorkingVersion(form) {
            fc.submitted = true;
            if (!fc.nameError) {
                setNotes();
                fc.loading = true;
                var operation = 'create working version';
                programVersionService.createWorkingVersion(fc.program())
                    .then(function (response) {
                        var program = fc.program();
                        program.entity.version = response.entity.version;
                        alertService.customSuccess('Working version for program ' + program.entity.name + ' is successfully created in the Composer.');
                        form.$setPristine();
                        programService.setCurrentAction("");
                    })
                    .catch(function (response) {
                        alertService.error(response, operation);
                    })
                    .finally(function () {
                        delete fc.loading;
                    });
            } else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function createMajorVersion(form) {
            fc.submitted = true;
            if (isFormValid(form, "Create Major Version")) {
                if (!fc.nameError) {
                    setNotes();
                    fc.loading = true;
                    var operation = 'create major version';
                    programVersionService.createMajorVersion(fc.program())
                        .then(function (response) {
                            var program = fc.program();
                            program.entity.version = response.entity.version;
                            alertService.customSuccess('Major version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            programService.setCurrentAction("");
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete fc.loading;
                        });
                }
                $('body').removeClass('modal-open');
            }
        }

        function createMinorVersion(form) {
            fc.submitted = true;
            if (isFormValid(form, "Create Minor Version")) {
                if (!fc.nameError) {
                    setNotes();
                    fc.loading = true;
                    var operation = 'create minor version';
                    programVersionService.createMinorVersion(fc.program())
                        .then(function (response) {
                            var program = fc.program();
                            program.entity.version = response.entity.version;
                            alertService.customSuccess('Minor version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            programService.setCurrentAction("");
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete fc.loading;
                        });
                }
                $('body').removeClass('modal-open');
            }
        }

        function getCompleteVersion(obj) {
            return obj.major + '.' + obj.minor + '.' + obj.working
        }

        function validEditions(msg) {
            var qIdsMatch = true;
            if(fc.program().entity.meta.editions.length > 0) {
                qIdsMatch = compareEditions(fc.program().entity.meta.editions);
            } else {
                alertService.error('At least one edition is required.', msg)
                return false;
            }
            if (!qIdsMatch) {
                alertService.error('The Question IDs in your editions do not match.', msg);
                return false;
            }
            return qIdsMatch;
        }

        function nextMajorVersion() {
            programService.setCurrentAction("Create Major Version");
            return programVersionService.nextMajorVersion(fc.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        warningMessages = getWarningMessages(version, verobj.major);
                    } else {
                        version = '1.0.0';
                    }
                    return {resultMessage: version, warningMessages: warningMessages};
                })
        }

        function nextMinorVersion() {
            programService.setCurrentAction("Create Minor Version");
            return programVersionService.nextMinorVersion(fc.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        warningMessages = getWarningMessages(version, verobj.major);
                    } else {
                        version = '0.1.0';
                    }
                    return {resultMessage: version, warningMessages: warningMessages};
                })
        }

        function nextWorkingVersion() {
            programService.setCurrentAction("Create Working Version");
            return programVersionService.nextWorkingVersion(fc.program())
                .then(function (response) {
                    var verobj = response;
                    var version = '0.0.1';
                    if (verobj) {
                        version = verobj.major + '.' + verobj.minor + '.' + verobj.working;
                    }
                    return {resultMessage: version, warningMessages: []};
                })
        }

        function compareEditions(editions) {
            var editionsQIds = [];
            var allMatch = true;
            if (editions) {
                for (var i = 0; i < editions.length; i++) {
                    editionsQIds.push([]);
                    var activeEd = editions[i].version.metaParsed;
                    if (activeEd.formEditionMetaData.formParams.formQuestions){
                        for (var j = 0; j < activeEd.formEditionMetaData.formParams.formQuestions.length; j++) {
                            editionsQIds[i].push(activeEd.formEditionMetaData.formParams.formQuestions[j].questionId);
                        }
                    }
                }
            }

            for (i = 0; i < editionsQIds.length; i++) {
                if (JSON.stringify(editionsQIds[0].sort()) !== JSON.stringify(editionsQIds[i].sort())) {
                    allMatch = false;
                }
            }
            return allMatch;
        }

        function getWarningMessages(nextProgramVersion, nextProgramMajor) {
            return programWarningService.getWarningMessages(nextProgramVersion, nextProgramMajor);
        }

        function parseKeywords(){
            fc.program().entity.meta.keywords = utilService.parseKeywords(fc).split(',');
            utilService.cleanKeywords(fc.program().entity.meta.keywords);
        }
    }
})(window.angular);
