(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditSpecialtyService', EditSpecialtyService);

    EditSpecialtyService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditSpecialtyService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(specialtyResource) {
            LinkConverter.convertResource(specialtyResource);
            if (specialtyResource && specialtyResource.link.self) {
                return $http.put(specialtyResource.link.self, specialtyResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
