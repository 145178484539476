(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('IvrAddModuleController', IvrAddModuleController);

    IvrAddModuleController.$inject = ['ProgramService', '$state', 'UtilService'];

    function IvrAddModuleController(programService, $state, utilService) {
        var qc = this;
        qc.collapsed = false;
        qc.addCustomQuestion = addCustomQuestion;
        qc.program = programService.getProgram;
        qc.questionTitle = '';
        qc.save = save;
        qc.qformSubmitted = false;
        qc.closeModal = closeModal;
        qc.isEditDisabled = isEditDisabled;
        qc.selectQuestion = selectQuestion;
        qc.changeQuestion = changeQuestion;
        qc.mergeQuestion = mergeQuestion;
        qc.loadQuestion = loadQuestion;
        qc.removeQuestion = removeQuestion;
        qc.validateQuestionId = validateQuestionId;
        qc.setFormModified = setFormModified;
        qc.alphaValidOnly = /^[a-zA-Z]{1,50}$/;
        qc.validQuestionID = false;
        qc.deDupe = utilService.deDupeAndClean;
        qc.resposeType = {
            1: 'Standard',
            2: 'Phone Number',
            3: 'Number'
        };
        qc.responseTypes = [
            { responseName: 'Standard', value: 1 },
            { responseName: 'Phone Number', value: 2 },
            { responseName: 'Number', value: 3 }
        ];

        (function onLoad() {
            qc.selectQuestion();
            qc.questionList = qc.program().entity.meta.questions ? qc.program().entity.meta.questions : [];
            qc.modalOpen = false;
        })();


        function setFormModified(form) {
            form.$setDirty();
        }
        // show the pop up
        function addCustomQuestion(arg, value, indx) {
            qc.questionTitle = arg === 'add' ? 'Add Custom Question' : 'Edit Question'; // changing the title in the modal

            if (arg === 'add') {
                qc.qformSubmitted = false;
                qc.uniqId = indx;
                qc.questionID = '';
                qc.questionText = '';
                qc.responseTypeId = '';
                qc.responses = '';
            }
            if (arg === 'edit') {

                qc.uniqId = indx;
                qc.questionID = value.questionID;
                qc.questionText = value.questionText;
                qc.responseTypeId = value.responseTypeId.toString();
                qc.responses = value.responses;
            }
            $(".modal").modal("show");
        }
        function closeModal() {
            $(".modal").modal("hide");
            qc.qformSubmitted = false;

        }
        function validateQuestionId() {
            qc.validQuestionID = false;
            if (qc.questionID) {
                qc.validQuestionID = qc.questionID.match(qc.alphaValidOnly);
            }
            else {
                qc.validQuestionID = true;
            }
            if (!qc.validQuestionID && qc.qformSubmitted) {
                $('#questionId').css('border', '1px solid #a94442');
            } else {
                $('#questionId').attr('style', '');
            }
        }
        //delete question from table
        function removeQuestion(data, form) {
            if (data) {
                var index = qc.questionList.indexOf(data);
                qc.questionList.splice(index, 1);
                qc.program().entity.meta.questions = qc.questionList;
                qc.setFormModified(form);
            }
        }
        //save from modal
        function save(form) {
            qc.responses = qc.deDupe(qc.responses, false, false); //(Data, removeSpaces,caseSensitive)
            qc.setFormModified(form);
            qc.qformSubmitted = true;
            qc.responses = qc.responses ? qc.responses : ''; // force responses to not be undefined
            validateQuestionId();
            if ((qc.questionID && qc.validQuestionID && qc.responses.length >= 1) || (qc.questionID && qc.validQuestionID && qc.responseTypeId >= 2)) {

                if (qc.uniqId + 1 > 0) {
                    for (var i = 0; i < qc.questionList.length; i++) {
                        if (i === qc.uniqId) {
                            qc.questionList[i].questionID = qc.questionID;
                            qc.questionList[i].questionText = qc.questionText;
                            qc.questionList[i].responseTypeId = Number(qc.responseTypeId);
                            qc.questionList[i].responses = qc.responseTypeId === '1' ? qc.responses : '';
                        }
                    }
                } else {
                    qc.questionList.push(
                        {
                            'questionID': qc.questionID,
                            'questionText': qc.questionText,
                            'responseTypeId': Number(qc.responseTypeId),
                            'responses': qc.responseTypeId === '1' ? qc.responses : ''
                        }
                    );
                }
                qc.program().entity.meta.questions = qc.questionList;
                $(".modal").modal("hide");
                qc.qformSubmitted = false;
            }
        }

        function isEditDisabled() {
            return programService.isEditDisabled();
        }

        //select the drop down option
        function changeQuestion(val) {
            qc.selectedQuestion = val;
        }
        //load the dropdown vlues
        function selectQuestion() {
            programService.selectQuestion()
                .then(function (response) {
                    qc.questionDropdownList = response.content;
                })
        }
        //add question
        function loadQuestion(form) {
            if (qc.selectedQuestion) {
                qc.setFormModified(form);
                var id = qc.selectedQuestion;
                programService.loadQuestion(id)
                    .then(function (response) {
                        mergeQuestion(response.data);
                    })
            }
        }
        //final question add into table
        function mergeQuestion(data) {
            if (data) {
                for (var i = 0; i < data.length; i++) {
                    data[i]['uniqId'] = i + 1;
                    qc.questionList.push(data[i])
                }
            }
            qc.program().entity.meta.questions = qc.questionList;
        }
    }
})(window.angular);