(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('autoFocus', autoFocus)
        .factory('focus', focus)
        .directive('focusOn', focusOn)
    ;

    autoFocus.$inject = ['$timeout'];
    focus.$inject = ['$rootScope', '$timeout'];
    focusOn.$inject = ['$timeout'];

    function autoFocus($timeout) {
        return {
            restrict: 'AC',
            link: link
        };

        function link(_scope, _element) {
            if (_element && _element[0]) {
                $timeout(function () {
                    _element[0].focus();
                }, 250);
            }
        }
    }

    function focus($rootScope, $timeout) {
        return link;

        function link(name) {
            if (name) {
                $timeout(function () {
                    $rootScope.$broadcast('focusOn', name);
                });
            }
        }
    }

    function focusOn($timeout) {
        return link;

        function link(scope, elem, attr) {
            scope.$on('focusOn', function (e, name) {
                if (name === attr.focusOn) {
                    $timeout(function () {
                        elem[0].focus();
                    }, 0);
                }
            });
        }
    }

})(window.angular);
