(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditNarratorService', EditNarratorService);

  EditNarratorService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditNarratorService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(narratorService) {
            LinkConverter.convertResource(narratorService);
            if (narratorService && narratorService.link.self) {
                return $http.put(narratorService.link.self, narratorService.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
