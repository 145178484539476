(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('CheckedoutProgramController', CheckedoutProgramController);

    CheckedoutProgramController.$inject = ['Principal', 'ProgramService', 'ProgramVersionService', '$state', 'AlertService', 'UtilService'];

    function CheckedoutProgramController(principal, programService, programVersionService, $state, alertService, utilService) {
        var cpc = this;
        cpc.authenticated = principal.isAuthenticated();
        cpc.collapse = false;

        (function onLoad() {
            cpc.hasVisible = hasVisibleData;
            cpc.nextPage = nextPage;
            cpc.nextEditionsPage = nextEditionsPage;
            cpc.getProgramDetails = getProgramDetails;
            cpc.getVersion = getVersion;

            loadCheckedoutPrograms();
            loadCheckedoutEditions();
        })();

        function nextPage(link) {
            if (cpc.programs && link === cpc.programs.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                cpc.loading = true;
                utilService.fetchPage(link).then(ok, finalize);
            }
            function ok(response) {
                if (response) {
                    cpc.programs = response;
                }
                finalize();
            }
            function finalize() {
                cpc.loading = false;
            }
        }

        function nextEditionsPage(link) {
            if (cpc.editions && link === cpc.editions.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                cpc.editionsLoading = true;
                utilService.fetchPage(link).then(ok, finalize);
            }
            function ok(response) {
                if (response) {
                    cpc.editions = response;
                }
                finalize();
            }
            function finalize() {
                cpc.editionsLoading = false;
            }
        }

        function hasVisibleData() {
            var hasVisible = false,
                length = cpc.programs && cpc.programs.content &&
                    cpc.programs.content.length;
            for (var i = 0; i < length; i++) {
                if (!cpc.programs.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function getProgramDetails(program) {
            var emmiCode = program.entity.emmiCode;
            $state.go('view-program-details', {emmiCode: emmiCode});
        }

        function getVersion(program) {
            var version = program.entity.version;
            if(version) {
                return "v" + version.major + "." + version.minor + "." + version.working;
            }
            return "v0.0.0";
        }

        function loadCheckedoutPrograms() {
            cpc.loading = true;
            programService.loadCheckedOutPrograms()
                .then(function (response) {
                    cpc.programs = response;
                })
                .finally(function () {
                    delete cpc.loading;
                });
        }

        function loadCheckedoutEditions() {
            cpc.editionsLoading = true;
            programService.loadCheckedOutEditions()
                .then(function (response) {
                    cpc.editions = response;
                })
                .finally(function () {
                    delete cpc.editionsLoading;
                });
        }
    }
})(window.angular);
