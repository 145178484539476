(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('NewSystemService', NewSystemService);

    NewSystemService.$inject = ['$http', 'Principal', 'UriTemplate'];

    function NewSystemService($http, Principal, UriTemplate) {

        return {
            create: create,
            newSystem: newSystem
        };

        function create(newSystem) {
            return Principal.identity().then(function (user) {
                return $http.post(UriTemplate.create(user.link.systems).stringify(),
                    newSystem).then(function (response) {
                    return response.data;
                });
            })
        }

        function newSystem() {
            return {
                name: ''
            }
        }

    }
})(window.angular);
