(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('EditAssetSetService', EditAssetSetService);

    EditAssetSetService.$inject = ['$http', 'LinkConverter', '$q'];

    function EditAssetSetService($http, LinkConverter, $q) {

        return {
            update: update
        };

        function update(assetsetResource) {
            LinkConverter.convertResource(assetsetResource);
            if (assetsetResource && assetsetResource.link.self) {
                return $http.put(assetsetResource.link.self, assetsetResource.entity).then(function (response) {
                    return response.data;
                });
            }
            return $q.reject('not a persistent object');
        }

    }
})(window.angular);
