(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewGenderController', NewGenderController);

    NewGenderController.$inject = ['NewGenderService', 'ProgramInitService', '$scope'];

    function NewGenderController(NewGenderService, programInitService, $scope) {
        var npc = this;

        npc.save = save;
        npc.newGender = NewGenderService.newGender();
        npc.data = [];

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewGenderService.create(npc.newGender).then(function (response) {
                    $scope.$emit('new-gender', response);
                    npc.data.genders.content.push(response);
                })
            }
        }
    }

})(window.angular);
