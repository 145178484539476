(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('editDynaviewData', editDynaviewData);

    editDynaviewData.$inject = ['$modal', '$alert'];

    function editDynaviewData($modal, $alert) {

        return {
            restrict: 'A',
            link: link,
            scope: {
				resourceProvider: '@resourceProvider', 
				resourceEntryId: '@resourceEntryId', 
				entityForEdit: '@entityForEdit', 
                toBeEdited: '=toBeEdited'
            }
        };

        function link(scope, element) {

            element.on('click', function () {
                $modal({
                    templateUrl: 'app/dynaview/addedit.dynaview.modal.html',
                    scope: scope,
                    controller: 'EditDynaviewController',
                    controllerAs: 'EditCtrl',
                    resolve: {
                        savedResource: function () {
                            return scope.toBeEdited
                        },
						entityForEditResource : function () {
							return scope.entityForEdit
						}, 
						resourceProviderResource: function() {
							return scope.resourceProvider
						}, 
						resourceEntryIdResource: function() {
							return scope.resourceEntryId
						}, 
                        saveCallback: function () {
                            return function (data) {
                                updatedExistingBrand(data, scope.toBeEdited)
                            }
                        },
                        errorCallback: function () {
                            return errorUpdating;
                        }
                    }
                })
            });

        }

        function errorUpdating() {
            $alert({
                type: 'danger',
                content: 'Data was not updated due to error. Please try again.'
            });
        }

        function updatedExistingBrand(newValue, existingValue) {
            $alert({
                content: [
                    'Data edited successfully.'
                ].join('')
            });
            angular.extend(existingValue, newValue);
        }
    }
})(window.angular);
