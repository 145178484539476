(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('DeletePlaceholderService', PlaceholderService);

    PlaceholderService.$inject = ['$http', '$q', 'LinkConverter'];

    function PlaceholderService($http, $q, LinkConverter) {

        return {
            remove: remove
        };

        function remove(placeholderResource) {
            LinkConverter.convertResource(placeholderResource);
            if (placeholderResource && placeholderResource.link.self) {
                return $http.delete(placeholderResource.link.self);
            }
            return $q.reject('not a persistent object');
        }


    }
})(window.angular);
