(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewWorkflowSubscriptionController', NewWorkflowSubscriptionController);

    NewWorkflowSubscriptionController.$inject = ['ProgramInitService', 'NewWorkflowSubscriptionService', '$scope'];

    function NewWorkflowSubscriptionController(programInitService, NewWorkflowSubscriptionService, $scope) {
        var npc = this;

        npc.save = save;
        npc.newWorkflowSubscription = NewWorkflowSubscriptionService.newWorkflowSubscription();
        npc.data = programInitService.getWorkflowSubscriptions(true, true);

        initPrograms();

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                npc.newWorkflowSubscription.targetEmmiCode = npc.newWorkflowSubscription.targetProgram['emmiCode'];
                NewWorkflowSubscriptionService.create(npc.newWorkflowSubscription).then(function (response) {
                    $scope.$emit('new-workflow-subscription', response);
                    npc.data.workflowSubscriptions.content.push(response);
                })
            }
        }

        function initPrograms() {
            NewWorkflowSubscriptionService.getProgramData()
                .then(function (response) {
                    npc.programs = response;
                });
        }

        npc.searchProgram = function(searchFor) {

            return NewWorkflowSubscriptionService.searchProgramData(searchFor)
                .then(function (response) {
                    npc.programs = response;
                    return npc.programs;
                });
        }
    }

})(window.angular);
