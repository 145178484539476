(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('NewSourceController', NewSourceController);

    NewSourceController.$inject = ['NewSourceService', 'ProgramInitService', '$scope'];

    function NewSourceController(NewSourceService, programInitService, $scope) {
        var nbc = this;

        nbc.save = save;
        nbc.newSource = NewSourceService.newSource();
        nbc.data = programInitService.getSources();

        function save(form, $hide) {
            if (form && form.$valid) {
                $hide();
                NewSourceService.create(nbc.newSource).then(function (response) {
                    $scope.$emit('new-source', response);
                    nbc.data.sources.content.push(response);
                })
            }
        }
    }

})(window.angular);
