(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('confirmModal', confirmModal);

    confirmModal.$inject = ['$modal'];

    function confirmModal($modal) {
        return {
            restrict: 'A',
            link: link,
            scope: {
                action: '&callbackFn',
                confirmHeader: '@',
                isWarning: '@',
                warningText: '@',
                confirmText:'@confirmText',
                confirmButtonText: '@confirmButtonText'
            }
        };

        function link(scope, element) {
            var modal = {};

            element.on('click', function () {
                modal = $modal({
                    templateUrl: 'app/modal/confirm/confirm.modal.html',
                    scope: scope,
                    controller: 'ConfirmController',
                    controllerAs: 'cc',
                    resolve: {
                        title: function () {
                            return scope.confirmHeader;
                        },
                        confirmText: function () {
                            return scope.confirmText;
                        },
                        isWarning: function () {
                            return scope.isWarning;
                        },
                        warningText: function () {
                            return scope.warningText;
                        },
                        confirmButtonText: function () {
                            return scope.confirmButtonText || 'OK';
                        },
                        confirmed : function () {
                            return function () {
                                scope.action();
                            }
                        }
                    }
                })
            });

            element.on('$destroy', function() {
                $('body').removeClass('modal-open');
                if(modal.hide !== undefined) {
                    modal.hide();
                }
            });
        }
    }
})(window.angular);
