(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .factory('DynaviewService', DynaviewService);

    DynaviewService.$inject = ['$http'];

    function DynaviewService($http) {

        return {
            getResourceData: getResourceData
        };

        function getResourceData(getUri) {
			
			return $http.get(getUri)
			.success(function (data) {
				
				return data;
			});
			
        }

    }
})(window.angular);
