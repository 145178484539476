(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('TypeAssetSetController', TypeAssetSetController);

    TypeAssetSetController.$inject = ['TypeAssetSetService'];

    function TypeAssetSetController(TypeAssetSetService) {
        var TypeAssetSetCtrl = this;
        TypeAssetSetCtrl.typeAssetSets = [];

        (function onControllerLoad() {
            TypeAssetSetCtrl.nextPage = nextPage;
            TypeAssetSetCtrl.hasVisible = anyVisibleTypeAssetSet;
            search();
        })();

        function nextPage(previousPage) {
            if (previousPage && previousPage.link) {
                TypeAssetSetCtrl.loadingNextPage = true;
                TypeAssetSetService.nextPage(previousPage.link['page-next']).then(ok, err);
            }

            function ok(response) {
                if (response) {
                    previousPage.content.push.apply(previousPage.content, response.content);
                    previousPage.link = response.link;
                    TypeAssetSetCtrl.loadingNextPage = false;
                } else {
                    TypeAssetSetCtrl.loadingNextPage = true;
                }
            }

            function err() {
                // don't load any more pages
                TypeAssetSetCtrl.loadingNextPage = true;
            }
        }

        function anyVisibleTypeAssetSet() {
            var hasVisible = false,
                length = TypeAssetSetCtrl.typeAssetSets && TypeAssetSetCtrl.typeAssetSets.content &&
                    TypeAssetSetCtrl.typeAssetSets.content.length;
            for (var i = 0; i < length; i++) {
                if (!TypeAssetSetCtrl.typeAssetSets.content[i].deleted) {
                    hasVisible = true;
                    break;
                }
            }
            return hasVisible;
        }

        function search() {
            TypeAssetSetCtrl.loading = true;
            TypeAssetSetService.search()
                .then(function (response) {
                    TypeAssetSetCtrl.typeAssetSets = response;
                    //TypeAssetSetCtrl.typeAssetSets = JSON.stringify(response);
                })
                .finally(function () {
                    delete TypeAssetSetCtrl.loadingNextPage;
                    delete TypeAssetSetCtrl.loading;
                });
        }

    }
})(window.angular);
