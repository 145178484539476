(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('IvrController', IvrController);

    IvrController.$inject = ['ProgramService', 'ProgramVersionService', 'ProgramSummaryService', 'ProgramWarningService',
        'AssetSetService', 'UtilService', 'AlertService', 'ReportingFlagService','$state', '$location'];

    function IvrController(programService, programVersionService, programSummaryService, programWarningService, assetSetService,
        utilService, alertService, reportingFlagService,$state, $location) {
        var ic = this;
        ic.confirm = {};
        ic.collapsed = false;
        ic.program = programService.getProgram;
        ic.callFlows = null;
        ic.loadingCallFlows = false;
        ic.loadingCallFlowVersions = false;
        ic.selectedCallFlow = null;
        ic.callFlowName = null;
        ic.data = programSummaryService.getProgramData();
        //create versions
        ic.formatVersion = utilService.formatVersion;
        ic.getQuestionType = utilService.getQuestionType;
        ic.createWorkingVersion = createWorkingVersion;
        ic.createMajorVersion = createMajorVersion;
        ic.createMinorVersion = createMinorVersion;

        ic.nextMajorVersion = nextMajorVersion;
        ic.nextMinorVersion = nextMinorVersion;
        ic.nextWorkingVersion = nextWorkingVersion;
        ic.getMisMatchedVersionMessage = getWarningMessages;
        ic.isValidVersion = utilService.isValidVersionProgram;
        ic.initCallFlows = initCallFlows;
        ic.nextCallFlowsPage = nextCallFlowsPage;

        //set edit to disabled if no permissions or during loading process
        ic.isEditDisabled = isEditDisabled;

        //reset before opening modal dialog
        ic.reset = reset;

        //create copy
        ic.createCopy = createCopy;

        //save form
        ic.save = save;
        ic.cancel = cancel;
        ic.cancelButtonText = cancelButtonText;

        //discard
        ic.discard = discard;
        ic.canBeDiscarded = utilService.canBeDiscarded;
        ic.discardButtonText = discardButtonText;
        ic.discardMessage = utilService.discardMessage;

        //check if program meta has properties and values
        ic.containsValue = containsValue;
        ic.hasValue = hasValue;
        ic.removeAt = utilService.removeAt;

        ic.requiredOnAction = requiredOnAction;
        ic.loadIvrAssetSets = loadIvrAssetSets;
        ic.loadIvrAssetSetsSummary = loadIvrAssetSetsSummary;
        ic.validateName = validateName;

        ic.getCallFlowVersions = getCallFlowVersions;
        ic.setSelectedCallFlow = setSelectedCallFlow;
        ic.getCallFlowData = getCallFlowData;
        ic.resetCallFlowSelection = resetCallFlowSelection;
        ic.clearVoiceflow = clearVoiceflow;
        ic.selectedCallFlowPre = null;
        ic.parseKeywords = parseKeywords;
        ic.keywords = ic.program().entity.meta.keywords.toString();
        ic.toggleConfigurable = toggleConfigurable;
        ic.selectedQuestion = null;
        ic.selectQuestionIndex = -1;
        ic.selectQuestion = selectQuestion;
        ic.setQuestion = setQuestion;
        ic.clearQuestionModal = clearQuestionModal;
        ic.validateQuestion = validateQuestion;
        ic.clinicalFlagText = null;
        ic.logisticalFlagText = null;
        ic.updateResponses = updateResponses;
        ic.responseIndex = responseIndex;
        ic.isChecked = isChecked;
        ic.toggleAnyValue = toggleAnyValue;
        ic.removeInvalid = utilService.removeInvalid;
        ic.changeVersionOfCallFlow = false;

        (function onLoad() {
            if (!ic.program().entity.meta) {
                ic.program().entity.meta = {};
            }
            if (ic.data) {
                loadIvrAssetSets();
            } else {
                loadIvrAssetSetsSummary();
            }
        })();

        function validateName() {
            if (ic.program().entity.name && ic.program().entity.name.match(/\"/g)) {
                ic.nameError = true;
            } else {
                ic.nameError = false;
            }
        }

        function loadIvrAssetSets() {
            return assetSetService.search(['p0', 'p1']).then(function (data) {
                ic.data.ivrAssetSets = data;
                ic.data.ivrAssetSets.content.forEach(function (assetSet, index, array) {
                    array[index].entity.summary = assetSetService.getSummary(assetSet.entity);
                });
            }).finally(function () {
                //set default value
                if (!ic.program().entity.emmiCode) {
                    ic.program().entity.meta.assetSets = [];
                    if (ic.data.ivrAssetSets && ic.data.ivrAssetSets.total && ic.data.ivrAssetSets.total > 0) {
                        ic.program().entity.meta.assetSets.push(ic.data.ivrAssetSets.content[0].entity);
                    }
                } else {
                    loadIvrAssetSetsSummary();
                }
            });
        }

        function loadIvrAssetSetsSummary() {
            if (containsValue('assetSets')) {
                ic.program().entity.meta.assetSets.forEach(function (assetSet, index, array) {
                    if (!array[index].summary) {
                        array[index].summary = assetSetService.getSummary(assetSet);
                    }
                });
            }
        }

        function reset(form) {
            alertService.removeAlert();
        }

        function isEditDisabled() {
            return programService.isEditDisabled() || ic.loading;
        }

        function containsValue(prop) {
            var entity = ic.program().entity;
            var containsValue = false;
            if (entity && entity.meta && prop in entity.meta) {
                var property = entity.meta[prop];
                if (property) {
                    containsValue = property.length > 0;
                }
            }
            return containsValue;
        }

        function hasValue(prop) {
            var entity = ic.program().entity;
            if (entity && entity.meta && prop in entity.meta) {
                var obj = entity.meta[prop];
                return obj && 'id' in obj;
            }
            return false;
        }

        function requiredOnAction() {
            var curAction = programService.getCurrentAction();
            if (curAction && (curAction === "Create Major Version" || curAction === "Create Minor Version")) {
                return true;
            }
            return false;
        }

        function isFormValid(form, action) {
            var isValid = false;
            ic.program().entity.questionDupes = [];
            if (form.$name === 'qform') {
                if (!form.uniqId.$valid || !form.questionId.$valid || !form.questionText.$valid || !form.responseTypeId.$valid || !ic.nameError || !form.voiceflowVersion.$valid) {
                    form.$valid = true;
                    isValid = form && form.$valid;
                }
            }
            if (form.$name === 'programForm') {
                var questions = ic.program().entity.meta.questions;
                if(questions) {
                    questions.forEach(function (question, index) {
                        for (var i = 0; i < questions.length; i++) {
                            if (question.questionID.toLowerCase() === questions[i].questionID.toLowerCase() && index !== i) {
                                var dupeDupe = false;
                                for (var j = 0; j < ic.program().entity.questionDupes.length; j++) {
                                    if (ic.program().entity.questionDupes[j].toLowerCase() === question.questionID.toLowerCase()) {
                                        dupeDupe = true;
                                        console.log('Duplicate QID detected - ' + question.questionID);
                                    }
                                }
                                if (!dupeDupe) {
                                    ic.program().entity.questionDupes.push(question.questionID);
                                }
                            }
                        }
                    });
                }
                if (action === 'Create Major Version' || action === "Create Minor Version") {
                    isValid = !ic.nameError && form && form.$valid && (ic.program().entity.questionDupes.length === 0) && questions.length > 0;
                } else {
                    isValid = !ic.nameError && form && form.$valid && (ic.program().entity.questionDupes.length === 0) && questions;
                }

                if (!containsValue('assetSets')) {
                    form.$valid = false;
                    isValid = false;
                }

            } else if (form.$name === 'programForm') {

                if (action === 'Create Major Version' || action === "Create Minor Version") {
                    isValid = !ic.nameError && form && form.$valid;
                }
            }

            if (!isValid) {
                if (ic.program().entity.questionDupes.length !== 0) {
                    alertService.error("The selected call flow has duplicate question IDs", action);
                }
                else {
                    alertService.error("Validation errors", action);
                }
                $('body').removeClass('modal-open');
            } else {
                form.$setPristine();
            }
            form.$setSubmitted();
            return isValid;
        }

        function ivrTypeID() {
            if (ic.program().entity.meta.ivrType && ic.program().entity.meta.ivrType.name === 'Prevent') {
                ic.program().entity.meta.ivrType.id = '1'
            } else if (ic.program().entity.meta.ivrType && ic.program().entity.meta.ivrType.name === 'Transition') {
                ic.program().entity.meta.ivrType.id = '2'

            } else {
                // nothing
            }
        }

        function save(form) {
            ic.submitted = true;
            if (!ic.nameError) {
                ivrTypeID();
                var actionWord = "Save Program";
                if (!ic.program().entity.emmiCode) {
                    actionWord = "Create Program";
                }
                ic.loading = true;

                programService.save()
                    .then(function (response) {
                        form.$setPristine();
                        alertService.success(ic.program().entity.name, actionWord);
                    })
                    .catch(function (response) {
                        alertService.error(response, actionWord);
                    })
                    .finally(function () {
                        delete ic.loading;
                        loadIvrAssetSetsSummary();
                    });
            } else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function cancel() {
            var entity = ic.program().entity;
            var emmiCode = entity.emmiCode;
            var versioned = entity.versionedToLocal || entity.version;
            utilService.navigate(emmiCode, versioned, $state);
        }

        function cancelButtonText() {
            var entity = ic.program().entity;
            var emmiCode = entity.emmiCode;
            var text = "Return to Composer Home";
            if (emmiCode) {
                if (entity.version || entity.versionedToLocal) {
                    text = "Return to Version List Page";
                }
            }
            return text;
        }

        function discardButtonText() {
            var program = ic.program();
            if (program && program.entity) {
                return program.entity.versionedToLocal ? 'REVERT' : 'DELETE';
            } else {
                return 'DELETE';
            }
        }

        function discard() {
            var entity = ic.program().entity;
            var emmiCode = entity.emmiCode;
            var versionedToLocal = entity.versionedToLocal;
            ic.loading = true;
            programService.discard()
                .then(function (response) {
                    utilService.navigate(emmiCode, versionedToLocal, $state);
                })
                .catch(function (response) {
                    alertService.error(response, 'discard program');
                })
                .finally(function () {
                    delete ic.loading;
                });
        }

        function createCopy(form) {
            programService.createCopy(ic.confirm.inputText);
            programService.setEditable(true);
            $('body').removeClass('modal-open');
        }

        function setNotes() {
            var entity = ic.program().entity;
            if (entity) {
                if (!entity.version) {
                    entity.version = utilService.newVersion();
                }
                entity.version.comment = ic.confirm.inputText;
            }
        }


        function createWorkingVersion(form) {
            ic.submitted = true;
            if (!ic.nameError) {
                setNotes();
                ic.loading = true;
                var operation = 'create working version';
                programVersionService.createWorkingVersion(ic.program())
                    .then(function (response) {
                        var program = ic.program();
                        program.entity.version = response.entity.version;
                        alertService.customSuccess('Working version for program ' + program.entity.name + ' is successfully created in the Composer.');
                        form.$setPristine();
                        programService.setCurrentAction("");
                    })
                    .catch(function (response) {
                        alertService.error(response, operation);
                    })
                    .finally(function () {
                        delete ic.loading;
                        loadIvrAssetSetsSummary();
                    });
            } else {
                alertService.error(" Client Facing Name cannot contain double quotes", "Create Program");
            }
            $('body').removeClass('modal-open');
        }

        function createMajorVersion(form) {
            ic.submitted = true;
            if (isFormValid(form, "Create Major Version")) {
                if (!ic.nameError) {
                    setNotes();
                    ic.loading = true;
                    var operation = 'create major version';
                    programVersionService.createMajorVersion(ic.program())
                        .then(function (response) {
                            var program = ic.program();
                            program.entity.version = response.entity.version;
                            alertService.customSuccess('Major version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            programService.setCurrentAction("");
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete ic.loading;
                            loadIvrAssetSetsSummary();
                        });
                }
                $('body').removeClass('modal-open');
            }
        }

        function createMinorVersion(form) {
            ic.submitted = true;
            if (isFormValid(form, "Create Minor Version")) {
                if (!ic.nameError) {
                    setNotes();
                    ic.loading = true;
                    var operation = 'create minor version';
                    programVersionService.createMinorVersion(ic.program())
                        .then(function (response) {
                            var program = ic.program();
                            program.entity.version = response.entity.version;
                            alertService.customSuccess('Minor version for program ' + program.entity.name + ' is successfully created in the Composer.');
                            programService.setCurrentAction("");
                        })
                        .catch(function (response) {
                            alertService.error(response, operation);
                        })
                        .finally(function () {
                            delete ic.loading;
                            loadIvrAssetSetsSummary();
                        });
                }
                $('body').removeClass('modal-open');
            }
        }

        function getCompleteVersion(obj) {
            return obj.major + '.' + obj.minor + '.' + obj.working
        }

        function nextMajorVersion() {
            programService.setCurrentAction("Create Major Version");
            return programVersionService.nextMajorVersion(ic.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        warningMessages = getWarningMessages(version, verobj.major);
                    } else {
                        version = '1.0.0';
                    }
                    return {resultMessage: version, warningMessages: warningMessages};
                })
        }

        function nextMinorVersion() {
            programService.setCurrentAction("Create Minor Version");
            return programVersionService.nextMinorVersion(ic.program())
                .then(function (response) {
                    var verobj = response;
                    var version;
                    var warningMessages = [];
                    if (verobj) {
                        version = getCompleteVersion(verobj);
                        warningMessages = getWarningMessages(version, verobj.major);
                    } else {
                        version = '0.1.0';
                    }
                    return {resultMessage: version, warningMessages: warningMessages};
                })
        }

        function nextWorkingVersion() {
            programService.setCurrentAction("Create Working Version");
            return programVersionService.nextWorkingVersion(ic.program())
                .then(function (response) {
                    var verobj = response;
                    var version = '0.0.1';
                    if (verobj) {
                        version = verobj.major + '.' + verobj.minor + '.' + verobj.working;
                    }
                    return {resultMessage: version, warningMessages: []};
                })
        }

        function getWarningMessages(nextProgramVersion, nextProgramMajor) {
            return programWarningService.getWarningMessages(nextProgramVersion, nextProgramMajor);
        }

        function initCallFlows() {
            ic.loadingCallFlows = true;
            if (ic.program().entity.meta.voiceflowID) {
                ic.loadingCallFlowVersions = true;
                ic.changeVersionOfCallFlow = true;
                ic.selectedCallFlow = {id: ic.program().entity.meta.voiceflowID};
                ic.existingVFVersion = ic.program().entity.meta.voiceflowVersion || null;
                ic.existingVFName = ic.program().entity.meta.voiceflowName || null;
                ic.selectedCallFlowPre = ic.selectedCallFlow;
                getCallFlowVersions();
            } else {
                programService.getCallFlows(ic.callFlowName).then(function (response) {
                        ic.callFlows = response;
                        ic.loadingCallFlows = false;
                    }
                );
            }
        }

        function setSelectedCallFlow() {
            ic.selectedCallFlow = ic.selectedCallFlowPre.entity;
            ic.getCallFlowVersions();
        }

        function getCallFlowVersions() {
            ic.loadingCallFlowVersions = true;
            programService.getCallFlowVersions(ic.selectedCallFlow).then(function (response) {
                ic.selectedCallFlowVersions = response;
                var cleanCFV = [];
                for (var i = 0; i < ic.selectedCallFlowVersions.versions.length; i++) {
                  if(ic.selectedCallFlowVersions.versions[i].version.endsWith('.0')) {
                      cleanCFV.push(ic.selectedCallFlowVersions.versions[i]);
                  }
                }
                ic.selectedCallFlowVersions.versions = cleanCFV;
                ic.loadingCallFlowVersions = false;
            })
        }
        function resetCallFlowSelection() {
            ic.selectedCallFlow = null;
            ic.selectedCallFlowVersion = null;
        }

        function clearVoiceflow() {
            reportingFlagService.setReportingFlags(ic.program().entity.meta.questions);
            reportingFlagService.setVoiceFlowId(ic.program().entity.meta.voiceflowID);
            reportingFlagService.setVoiceFlowVersionId(ic.program().entity.meta.voiceflowVersion);
            ic.program().entity.meta.voiceflowID = '';
            ic.program().entity.meta.voiceflowName = '';
            ic.program().entity.meta.voiceflowVersion = '';
            ic.program().entity.meta.questions = [];
            ic.existingVFName = null;
            ic.existingVFVersion = null;
        }

        function getCallFlowData($hide) {
            programService.getCallFlowData(ic.selectedCallFlow.id, ic.selectedCallFlowVersion.version).then(function (response) {
                $hide();

                ic.program().entity.meta.voiceflowVersion = ic.selectedCallFlowVersion.version;
                if (ic.program().entity.meta.voiceflowID || ic.changeVersionOfCallFlow) {
                    ic.program().entity.meta.questions = compareAndParseNewQuestionData(ic.program().entity.meta.questions, response);
                }
                else {
                    ic.program().entity.meta.voiceflowID = ic.selectedCallFlow.id;
                    ic.program().entity.meta.voiceflowName = ic.selectedCallFlow.displayName;
                    ic.program().entity.meta.questions = response;
                }

                var retainFlag = ic.selectedCallFlow.id === reportingFlagService.getVoiceFlowId();

                for (var i = 0; i < ic.program().entity.meta.questions.length; i++) {
                    if(ic.program().entity.meta.questions[i].configurable === undefined || ic.program().entity.meta.questions[i].configurable === null){
                        ic.program().entity.meta.questions[i].configurable = false;
                        ic.program().entity.meta.questions[i].defaultQuestion = false;
                    }

                    if(retainFlag && reportingFlagService.getReportingFlags() && reportingFlagService.getReportingFlags().length >0){
                        for (var j = 0; j < reportingFlagService.getReportingFlags().length; j++) {
                            var oldFlag = reportingFlagService.getReportingFlags()[j];
                            var newQuestion = ic.program().entity.meta.questions[i];
                            if(newQuestion.questionID === oldFlag.questionID){
                                newQuestion.configurable = oldFlag.configurable;
                                newQuestion.defaultQuestion = oldFlag.defaultQuestion;
                                if(oldFlag.hasClinicalFlag){
                                    newQuestion.hasClinicalFlag = true;
                                    newQuestion.clinicalFlagValues = oldFlag.clinicalFlagValues;
                                    newQuestion.clinicalAnyFlag = oldFlag.clinicalAnyFlag || 'false';
                                }
                                if(oldFlag.hasLogisticalFlag){
                                    newQuestion.hasLogisticalFlag = true;
                                    newQuestion.logisticalAnyFlag =  oldFlag.clinicalAnyFlag || 'false';
                                    newQuestion.logisticalFlagValues = oldFlag.logisticalFlagValues;
                                }
                                if(oldFlag.hasClinicalFlag || oldFlag.hasLogisticalFlag){
                                    newQuestion.reportOrder = oldFlag.reportOrder;
                                    newQuestion.reportType = oldFlag.reportType;
                                    newQuestion.reportName = oldFlag.reportName;
                                }
                            }
                        }
                    }
                }
                ic.changeVersionOfCallFlow = false;
                reportingFlagService.setReportingFlags([]);
            })
        }



        function compareAndParseNewQuestionData(originalData, newData){
            var revisedData = originalData;
            var dataMap = newData.reduce(function(map, obj){
                map[obj.questionID] = obj;
                return map;}, {});
            for (var j = 0; j < revisedData.length; j++) {
                var qIdExists = false;
                revisedData[j].hasBeenRemoved = true;
                if (dataMap[revisedData[j].questionID]) {
                    var newDataEle = dataMap[revisedData[j].questionID];
                    if (revisedData[j].questionID === newDataEle.questionID) {
                        qIdExists = true; // if the questionID already exists, parse it's responses
                        revisedData[j].hasBeenRemoved = false;
                        if (newDataEle.responseTypeId === revisedData[j].responseTypeId) {
                            if (newDataEle.responses !== revisedData[j].responses) {
                                var newResponses = newDataEle.responses.split(',');
                                var oldResponses = revisedData[j].responses.split(',');
                                for (var k = 0; k < oldResponses.length; k++) {
                                    var responseExists = false;
                                    for (var l = 0; l < newResponses.length; l++) {
                                        if (oldResponses[k] === newResponses[l]) {
                                            responseExists = true;
                                        }
                                    }
                                    if (!responseExists) // If any responses have been removed from a QID, remove them from flagging values
                                        if (revisedData[j].hasClinicalFlag) {
                                            revisedData[j].clinicalFlagValues.replace(oldResponses[k], '');
                                            revisedData[j].clinicalFlagValues.replace(',,', ',');
                                        }
                                    if (revisedData[j].hasLogisticalFlag) {
                                        revisedData[j].logisticalFlagValues.replace(oldResponses[k], '');
                                        revisedData[j].logisticalFlagValues.replace(',,', ',');
                                    }
                                }
                                // import new data responses and questionText
                                revisedData[j].responses = newDataEle.responses;
                                revisedData[j].questionText = newDataEle.questionText;
                            }
                        } else {
                            revisedData[j] = newDataEle;
                        }
                    }
                    delete dataMap[revisedData[j].questionID];
                }
            }
            var tempData = [];
            for (var m = 0; m < revisedData.length; m++){ // If the qID no longer exists, remove it
                if (!revisedData[m].hasBeenRemoved) tempData.push(revisedData[m]);

            }
            for (var n = 0; n < newData.length; n++){ // Add new QIDs
                if (dataMap[newData[n].questionID]){
                    tempData.push(newData[n]);
                }
            }
                revisedData = tempData;
            return revisedData;
        }

        function nextCallFlowsPage(link) {
            if (ic.callFlows && link === ic.callFlows.load) {
                return; //if it the same link as the current page, return
            } else if (link) {
                ic.loadingCallFlows = true;
                utilService.fetchPage(link).then(ok, finalize);
            }

            function ok(response) {
                if (response) {
                    ic.callFlows = response;
                }
                finalize();
            }
        }

        function finalize() {
            ic.loadingCallFlows = false;
        }

        function parseKeywords(){
            ic.program().entity.meta.keywords = utilService.parseKeywords(ic).split(',');
            utilService.cleanKeywords(ic.program().entity.meta.keywords);
        }

        function toggleConfigurable(selected, index){
            if (ic.program().entity.meta.questions[index].questionID === selected.questionID) {
                ic.program().entity.meta.questions[index].defaultQuestion = ic.program().entity.meta.questions[index].configurable;
            }
        }

        function selectQuestion(item, index){
            ic.selectedQuestionIndex = index;
            ic.selectedQuestion = angular.copy(item);

            var responses = ic.selectedQuestion.responses || "";
            var parserMatch = responses.match(/\|/);
            var isPipeDelimited = !!(parserMatch && !(parserMatch.length === 1 && parserMatch[0] === ""));

            ic.selectedQuestion.parsedResponses = isPipeDelimited ? responses.split("|") : responses.split(",");
            ic.selectedQuestion.hasClinicalFlag = ic.selectedQuestion.hasClinicalFlag || false;
            ic.selectedQuestion.clinicalAnyFlag = ic.selectedQuestion.clinicalAnyFlag || 'false';
            ic.selectedQuestion.hasLogisticalFlag = ic.selectedQuestion.hasLogisticalFlag || false;
            ic.selectedQuestion.logisticalAnyFlag = ic.selectedQuestion.logisticalAnyFlag || 'false';
            ic.selectedQuestion.reportOrder = ic.selectedQuestion.reportOrder || 1;
            ic.selectedQuestion.reportType = ic.selectedQuestion.reportType || 'F';
            ic.selectedQuestion.personTypeCds =[1, 2];
            ic.selectedQuestion.clinicalFlagValuesArray = ic.selectedQuestion.clinicalFlagValues ? ic.selectedQuestion.clinicalFlagValues.split(',') : [];
            ic.selectedQuestion.logisticalFlagValuesArray = ic.selectedQuestion.logisticalFlagValues ? ic.selectedQuestion.logisticalFlagValues.split(',') : [];
            
            if (ic.selectedQuestion.responseTypeId === 3) {
                ic.selectedQuestion.clinicalFlagRangeLow = parseInt(ic.selectedQuestion.clinicalFlagValuesArray[0]) || 0;
                ic.selectedQuestion.clinicalFlagRangeHigh = parseInt(ic.selectedQuestion.clinicalFlagValuesArray[1]) || 1;
                ic.selectedQuestion.logisticalFlagRangeLow = parseInt(ic.selectedQuestion.logisticalFlagValuesArray[0]) || 0;
                ic.selectedQuestion.logisticalFlagRangeHigh = parseInt(ic.selectedQuestion.logisticalFlagValuesArray[1]) || 1;
                ic.selectedQuestion.clinicalFlagValues = ic.selectedQuestion.clinicalFlagValues || '0,1';
                ic.selectedQuestion.logisticalFlagValues = ic.selectedQuestion.logisticalFlagValues || '0,1';                
                ic.selectedQuestion.defaultQuestion = ic.selectedQuestion.configurable ? ic.selectedQuestion.defaultQuestion : true;
            }

            if (ic.selectedQuestion.responseTypeId === 1){
                ic.selectedQuestion.logisticalAnyFlag = 'false';
                ic.selectedQuestion.clinicalAnyFlag = 'false';
            }
        }

        function setQuestion () {
            if (!ic.selectedQuestion.hasClinicalFlag) ic.selectedQuestion.clinicalFlagValues = '';
            if (!ic.selectedQuestion.hasLogisticalFlag) ic.selectedQuestion.logisticalFlagValues = '';
            ic.program().entity.meta.questions[ic.selectedQuestionIndex] = angular.copy(ic.selectedQuestion);
        }

        function clearQuestionModal() {
            ic.selectedQuestionIndex = -1;
            ic.selectedQuestion = null;
        }

        function validateFlag(responseType, flagValuesDict, flagValues, flagError, errorType) {
            if(responseType=== 1 && flagValuesDict && flagValuesDict.length===0) flagError[errorType] = true;
            if(responseType ===2 && ! /^[a-zA-Z0-9-\(\)]+$/.test(flagValues)) return flagError[errorType] = true;

            if(responseType=== 3){
                if(!flagValues) return flagError[errorType] = true;
                var numericFlagRangeArray = flagValues.split(',')
                if(numericFlagRangeArray.length !== 2) return flagError[errorType] = true;
                if(parseInt(numericFlagRangeArray[0])> parseInt(numericFlagRangeArray[1])) return flagError[errorType] = true;
            }
        }


        function validateQuestion() {
            // DESELECT THE DEFAULT OPTION FROM THE QUESTION IF IT IS NOT CONFIGURABLE.
            if(!ic.selectedQuestion.configurable){
                ic.selectedQuestion.defaultQuestion = false;
            }

            var question = ic.selectedQuestion;
            ic.errors = {};
            ic.errors.hasFormErrors = true;
            if (question.reportName === undefined || question.reportName.length <= 0){
                question.reportType = null;
                question.reportOrder = null;
            }
            if(question.hasClinicalFlag || question.hasLogisticalFlag || question.reportName){
                if(question.hasClinicalFlag){
                    ic.errors.clinicalFlagError = false;
                    validateFlag(question.responseTypeId,question.clinicalFlagValuesArray,
                        question.clinicalFlagValues,ic.errors,'clinicalFlagError');
                }
                if(question.hasLogisticalFlag){
                    ic.errors.logisticalFlagError = false;
                    validateFlag(question.responseTypeId,question.logisticalFlagValuesArray,
                        question.logisticalFlagValues,ic.errors,'logisticalFlagError');
                }

                if(question.reportName && !question.reportType) return false;
                if(ic.errors.clinicalFlagError || ic.errors.logisticalFlagError) return false;
                if(!(question.reportType && question.reportType.trim())) return false;
                if(!(question.reportName && question.reportName.trim())) return false;
                if(!question.reportOrder) return false;
            }
            ic.errors.hasFormErrors = false;
            return true;
        }

        function updateResponses(response, reportType) {

            if ((ic.selectedQuestion.responseTypeId === 1) && response !== 'bypass'){
                var reportArray = reportType === 'clinical' ? ic.selectedQuestion.clinicalFlagValuesArray : ic.selectedQuestion.logisticalFlagValuesArray;
                var idx = ic.responseIndex(response, reportType);
                if (idx.index !== -1) {
                    reportArray.splice(idx.index, 1);
                } else {
                    reportArray.push(response);
                }
                    if (reportType === 'clinical') ic.selectedQuestion.clinicalFlagValues = reportArray.toString().replace('[', '').replace(']', '');
                    else if (reportType === 'logistical') ic.selectedQuestion.logisticalFlagValues = reportArray.toString().replace('[', '').replace(']', '');

            } else if (ic.selectedQuestion.responseTypeId === 3){
                if(reportType === 'clinical' && (ic.selectedQuestion.clinicalFlagRangeLow!==null) && (ic.selectedQuestion.clinicalFlagRangeHigh!==null))
                    ic.selectedQuestion.clinicalFlagValues =  ic.selectedQuestion.clinicalFlagRangeLow.toString() + ',' + ic.selectedQuestion.clinicalFlagRangeHigh.toString();

                if(reportType === 'logistical' && (ic.selectedQuestion.logisticalFlagRangeLow!==null) && (ic.selectedQuestion.logisticalFlagRangeHigh!==null))
                    ic.selectedQuestion.logisticalFlagValues =  ic.selectedQuestion.logisticalFlagRangeLow.toString() + ',' + ic.selectedQuestion.logisticalFlagRangeHigh.toString();
            }

          }

          function responseIndex(response, reportType){
              var reportArray = reportType === 'clinical' ? ic.selectedQuestion.clinicalFlagValuesArray : ic.selectedQuestion.logisticalFlagValuesArray;
              var idx = {
                  index: reportArray.indexOf(response),
                  length: 0
              };
              idx.length = idx.index !== -1 ? reportArray[idx.index].length : 0;
              return idx;
        }

        function isChecked (response, reportType){
            var idx = responseIndex(response, reportType);
            if (idx.index !== -1 && idx.length === response.length) return true;
            else return false;

        }

        function toggleAnyValue (activeQuestion, flagType){

            if (flagType === 'clinical' && activeQuestion.clinicalAnyFlag ==='true'){
                activeQuestion.clinicalFlagRangeLow = "9999";
                activeQuestion.clinicalFlagRangeHigh = "";
            } else if (flagType === 'clinical'){
                activeQuestion.clinicalFlagRangeLow = "0";
                activeQuestion.clinicalFlagRangeHigh = "1";
            }
            if (flagType === 'logistical' && activeQuestion.logisticalAnyFlag ==='true'){
                activeQuestion.logisticalFlagRangeLow = "9999";
                activeQuestion.logisticalFlagRangeHigh = "";
            } else if (flagType === 'logistical'){
                activeQuestion.logisticalFlagRangeLow = "0";
                activeQuestion.logisticalFlagRangeHigh = "1";
            }
        }

    }
})(window.angular);
