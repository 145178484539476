(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .directive('showValidation', showValidation);

    function showValidation() {
        return {
            restrict: 'A',
            require: 'form',
            link: linkFunc
        };

        function linkFunc(scope, element, attrs, controller) {
            element.find('.form-group').each(function () {
                var $formGroup = angular.element(this);
                var $inputs = $formGroup.find('input[ng-model],textarea[ng-model],select[ng-model],' +
                    'input[data-ng-model],textarea[data-ng-model],select[data-ng-model]');

                if ($inputs.length > 0) {
                    $inputs.each(function () {
                        var $input = angular.element(this);
                        scope.$watch(function () {
                            return $input.hasClass('ng-invalid') && controller.$submitted;
                        }, function (isInvalid) {
                            $formGroup.toggleClass('has-error', isInvalid);
                        });
                    });
                }
            });
        }
    }
})(window.angular);
