(function (angular) {
    'use strict';

    angular
        .module('Composer')
        .controller('ConfirmWithCommentController', ConfirmWithCommentController);

    ConfirmWithCommentController.$inject = ['confirmed', 'title', 'confirmLabel', 'confirmText', 'inputLabel', 'maxLength', 'isWarning', 'warningText',
        'forOnAction', 'onActionCallback', 'okButtonValue', 'okButtonClass', 'program'];

    function ConfirmWithCommentController(confirmed, title, confirmLabel, confirmText, inputLabel, maxLength, isWarning, warningText,
                                          forOnAction, onActionCallback, okButtonValue, okButtonClass, program) {
        var cwc = this;
        cwc.confirm = confirm;
        cwc.title = title;
        cwc.confirmLabel = confirmLabel;
        cwc.confirmText = confirmText;
        cwc.isWarning = isWarning;
        cwc.warningText = warningText;
        cwc.inputLabel = inputLabel;
        cwc.maxLength = maxLength;
        cwc.inputText = null;
        cwc.warningMessages = [];
        cwc.program = program;
        cwc.forOnAction = forOnAction;
        cwc.resultMessage = '';
        cwc.loading = true;
        cwc.okButtonValue = (okButtonValue) ? okButtonValue : 'OK';
        cwc.okButtonClass = (okButtonClass) ? okButtonClass : '';
        cwc.cancel = cancel;
        (function init() {
            if(cwc.forOnAction) {
                onActionCallback()
                    .then(function (response) {
                        cwc.resultMessage = response.resultMessage;
                        cwc.warningMessages = response.warningMessages;
                        cwc.loading = false;
                        if (cwc.warningMessages && cwc.warningMessages.length > 0) {
                            cwc.isWarning = 'true';
                        }
                    });
            } else {
                cwc.loading = false;
            }


            if(cwc.isWarning) {
                if(cwc.warningText && cwc.warningText.length > 0) {
                    cwc.warningMessages.unshift(cwc.warningText);
                }
            }
        })();

        function confirm($hide) {
            if(cwc.inputText) {
                $hide();
                confirmed(cwc.inputText);
            }
        }
        function cancel() {
            $('body').removeClass('modal-open');
         }
    }
})(window.angular);
